import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { SerialEvidenceComponent } from "./views/serial-evidence/serial-evidence.component";
import { AuthGuard } from "../../core";
import { menuGuard } from "../../core/guards/menu.guard";

const internalsRoutes: Routes = [
    {
        path: "sewio_internal/serial_number_evidence",
        redirectTo: "sewio-internal/serial-number-evidence",
        pathMatch: "full",
    },
    {
        path: "sewio-internal/serial-number-evidence",
        component: SerialEvidenceComponent,
        data: {
            showTopPanel: true,
            showLeftPanel: true,
        },
        canActivate: [AuthGuard, menuGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(internalsRoutes)],
    exports: [RouterModule],
})
export class InternalsRoutes {}

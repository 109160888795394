<div class="mat-elevation-z2 table-container">
    <table
        *ngIf="dataSource.filteredData.length !== 0"
        class="partner-activities-table"
        mat-table
        [dataSource]="dataSource"
        multiTemplateDataRows
    >
        <ng-container matColumnDef="name">
            <th
                class="name-column"
                mat-header-cell
                *matHeaderCellDef
                mat-header
            >
                Name
            </th>
            <td class="name-column" mat-cell *matCellDef="let row">
                {{ row.name }}
            </td>
        </ng-container>

        <ng-container matColumnDef="expiration">
            <th
                class="expiration-column"
                mat-header-cell
                *matHeaderCellDef
                mat-header
            >
                Expiration
            </th>
            <td class="expiration-column" mat-cell *matCellDef="let row">
                {{ row.expiration }}
            </td>
        </ng-container>

        <ng-container matColumnDef="state">
            <th
                class="state-column"
                mat-header-cell
                *matHeaderCellDef
                mat-header
            >
                State
            </th>
            <td class="state-column" mat-cell *matCellDef="let row">
                {{ row.state }}
            </td>
        </ng-container>

        <ng-container matColumnDef="points">
            <th
                class="points-column"
                mat-header-cell
                *matHeaderCellDef
                mat-header
            >
                Points
            </th>
            <td class="points-column" mat-cell *matCellDef="let row">
                {{ row.points }}
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th
                class="actions-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Actions
            </th>
            <td class="actions-column" mat-cell *matCellDef="let row">
                <div class="icons">
                    <fa-icon
                        *ngIf="
                            row.state !== 'approved' && row.state !== 'expired'
                        "
                        class="icon-button"
                        matTooltip="Edit"
                        [icon]="['fas', 'edit']"
                        (click)="
                            $event.stopPropagation();
                            editActivity.emit({ id: row.id })
                        "
                    ></fa-icon>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="expand">
            <th class="expand-column" mat-header-cell *matHeaderCellDef>
                Expand
            </th>
            <td
                class="expand-column"
                mat-cell
                *matCellDef="let row"
                (click)="expandActivity($event, row.id)"
            >
                <fa-icon
                    class="expand-icon"
                    *ngIf="expandedActivity !== row.id"
                    [icon]="['fas', 'angle-down']"
                ></fa-icon>
                <fa-icon
                    class="expand-icon"
                    *ngIf="expandedActivity === row.id"
                    [icon]="['fas', 'angle-up']"
                ></fa-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
            <td
                mat-cell
                *matCellDef="let element"
                [attr.colspan]="displayedColumns.length"
            >
                <div
                    class="detail"
                    [@detailExpand]="
                        element.id === expandedActivity
                            ? 'expanded'
                            : 'collapsed'
                    "
                >
                    <div class="detail-container">
                        <div class="full">
                            <div class="detail-row" *ngIf="element.description">
                                <div class="bottom-offset">Description:</div>
                                <div [innerHtml]="element.description"></div>
                            </div>
                            <div class="detail-row" *ngIf="element.link">
                                <div class="bottom-offset">Link:</div>
                                <portal-link [href]="element.link">{{
                                    element.link
                                }}</portal-link>
                            </div>
                            <div class="detail-row" *ngIf="element.attachment">
                                <div class="bottom-offset">Attachment:</div>
                                <portal-link
                                    [href]="element.attachment"
                                    target="_blank"
                                    >{{
                                        element.attachment
                                            | formatAttachmentName
                                    }}</portal-link
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="partner-activity-row"
            [class.expanded-row]="expandedActivity === row.id"
            [ngClass]="{
                'sewio-red-background': row.state === 'rejected',
                'sewio-green-background': row.state === 'approved',
                'sewio-orange-background': row.state === 'expired'
            }"
        ></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: ['expandedDetail']"
            class="detail-row"
        ></tr>
    </table>

    <div class="none-row" *ngIf="dataSource.filteredData.length === 0">
        <portal-status
            [text]="'None'"
            [type]="'none'"
            [big]="true"
        ></portal-status>
    </div>
</div>

<div mat-dialog-title class="title">Delete RTLS Player</div>
<div mat-dialog-content>
    Are you sure you want to delete this RTLS Player. All the data will be lost.
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CANCEL</button>
    <button mat-button color="danger" cdkFocusInitial (click)="delete()">
        DELETE
    </button>
</mat-dialog-actions>

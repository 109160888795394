import { Component, Input } from "@angular/core";
import { PlayerLogEntry } from "../../../../core";

import moment from "moment";
import _ from "lodash";
import { MatTableDataSource } from "@angular/material/table";

@Component({
    selector: "portal-players-history-list",
    templateUrl: "./players-history-list.component.html",
    styleUrls: ["./players-history-list.component.scss"],
})
export class PlayersHistoryListComponent {
    displayedColumns: string[] = ["name", "email", "action", "detail", "at"];

    dataSource!: MatTableDataSource<PlayerLogEntry>;

    private _filter!: string;
    private _playersHistory!: PlayerLogEntry[];

    get playersHistory(): PlayerLogEntry[] {
        return this._playersHistory;
    }

    @Input()
    set playersHistory(playersHistory: PlayerLogEntry[]) {
        this._playersHistory = playersHistory
            .slice()
            .sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10))
            .map((player) => {
                const clonedPlayer = _.cloneDeep(player);

                clonedPlayer.atString = moment
                    .utc(player.atString)
                    .local()
                    .format("YYYY/MM/DD HH:mm:ss");

                return clonedPlayer;
            });

        this.dataSource = new MatTableDataSource(this._playersHistory);

        this.dataSource.filterPredicate = (data, filter) => {
            const dataStr = (data.playerName + "◬").toLowerCase();
            const transformedFilter = filter.trim().toLowerCase();

            return dataStr.indexOf(transformedFilter) !== -1;
        };

        this.applyFilter();
    }

    get filter(): string {
        return this._filter;
    }

    @Input()
    set filter(filterValue: string) {
        this._filter = filterValue;

        this.applyFilter();
    }

    applyFilter() {
        if (this._filter === undefined || this.dataSource === undefined) {
            return;
        }

        this.dataSource.filter = this._filter.trim().toLowerCase();
    }
}

<div mat-dialog-title class="title">Delete File</div>
<div mat-dialog-content>
    Are you sure you want to delete file
    <span class="text-warning-dark">{{ data }}</span
    >?
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CANCEL</button>
    <button mat-button color="warn" cdkFocusInitial (click)="delete()">
        DELETE
    </button>
</mat-dialog-actions>

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable()
export class DownloadService {
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {}

    downloadInvoice(invoice: string) {
        const url = environment.server + "/download/invoice/" + invoice;
        const token = this.authService.token;

        if (token === null) {
            throw new Error(
                "Could not download invoice without authentication."
            );
        }

        return this.httpClient
            .get(url, {
                responseType: "text",
                headers: {
                    Authorization: "Bearer " + token.token,
                },
            })
            .pipe(
                map((base64Data) => {
                    const byteCharacters = atob(base64Data);
                    const byteNumbers = new Array(byteCharacters.length);

                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }

                    const byteArray = new Uint8Array(byteNumbers);

                    const blob = new Blob([byteArray], {
                        type: "application/pdf",
                    });

                    return blob;
                })
            );
    }
}

<div
    class="w-6 h-6 flex justify-center items-center material-symbols-rounded"
    [ngClass]="{
        'text-xl': big,
        'text-base': !big,
    }"
    [ngStyle]="{
        'font-variation-settings': '\'FILL\' ' + (fill ? '1' : '0')
    }"
>
    {{ icon }}
</div>

import { Component, OnInit, OnDestroy } from "@angular/core";
import { Article, ArticlesGQL } from "../../../../core";
import { Subscription } from "rxjs";

@Component({
    selector: "portal-articles",
    templateUrl: "./articles.component.html",
    styleUrls: ["./articles.component.scss"],
})
export class ArticlesComponent implements OnInit, OnDestroy {
    articles: Article[] | null = null;

    private subscriptions: Subscription[] = [];

    constructor(private articlesGql: ArticlesGQL) {}

    ngOnInit(): void {
        this.subscriptions.push(
            this.articlesGql.watch().valueChanges.subscribe((values) => {
                this.articles = values.data.articles;
            })
        );
    }

    ngOnDestroy(): void {
        while (this.subscriptions.length) {
            this.subscriptions.pop()?.unsubscribe();
        }
    }
}

import { Component, ViewChild, ElementRef, Input } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Component({
    selector: "portal-file-upload",
    templateUrl: "./file-upload.component.html",
    styleUrls: ["./file-upload.component.css"],
})
export class FileUploadComponent {
    @ViewChild("fileElement", { static: false }) fileElement!: ElementRef;
    @ViewChild("nameElement", { static: false }) nameElement!: ElementRef;

    @Input() multipleFiles = true;

    private files: FileList | null = null;

    setName(name: string) {
        (this.nameElement.nativeElement as HTMLDivElement).innerHTML = name;
    }

    showError() {
        (this.fileElement.nativeElement as HTMLInputElement).value = "";

        (this.nameElement.nativeElement as HTMLDivElement).classList.remove(
            "black"
        );
        (this.nameElement.nativeElement as HTMLDivElement).classList.add("red");
        (this.nameElement.nativeElement as HTMLDivElement).innerHTML =
            "No files selected!";

        (this.nameElement.nativeElement as HTMLDivElement).title = "";
    }

    fileChanged($event: Event): void {
        const files = ($event.target as HTMLInputElement).files;

        if (!files || files.length === 0) {
            (this.nameElement.nativeElement as HTMLDivElement).classList.remove(
                "black"
            );
            (this.nameElement.nativeElement as HTMLDivElement).classList.remove(
                "red"
            );
            (this.nameElement.nativeElement as HTMLDivElement).innerHTML =
                "Maximum file size is 100MB";

            (this.nameElement.nativeElement as HTMLDivElement).title = "";

            this.files = null;
            return;
        }

        const fileNames = [];

        for (let i = 0; i < files.length; i++) {
            if (files[i].size > environment.maxFileSize) {
                (
                    this.nameElement.nativeElement as HTMLDivElement
                ).classList.remove("black");
                (
                    this.nameElement.nativeElement as HTMLDivElement
                ).classList.add("red");

                (this.nameElement.nativeElement as HTMLDivElement).innerHTML =
                    "Maximum file size is 100MB";

                (this.nameElement.nativeElement as HTMLDivElement).title = "";

                this.files = null;
                return;
            }

            fileNames.push(files[i].name);
        }

        (this.nameElement.nativeElement as HTMLDivElement).classList.add(
            "black"
        );

        (this.nameElement.nativeElement as HTMLDivElement).classList.remove(
            "red"
        );

        (this.nameElement.nativeElement as HTMLDivElement).innerHTML =
            fileNames.join(", ");

        (this.nameElement.nativeElement as HTMLDivElement).title =
            fileNames.join(", ");

        this.files = files;
    }

    getFiles() {
        return this.files;
    }
}

import moment from "moment";

export class TimeUtils {
    static WORKING_HOURS: {
        [key: number]:
            | null
            | [
                  { hours: number; minutes: number },
                  { hours: number; minutes: number }
              ];
    } = {
        0: null,
        1: [
            { hours: 8, minutes: 30 },
            { hours: 16, minutes: 30 },
        ],
        2: [
            { hours: 8, minutes: 30 },
            { hours: 16, minutes: 30 },
        ],
        3: [
            { hours: 8, minutes: 30 },
            { hours: 16, minutes: 30 },
        ],
        4: [
            { hours: 8, minutes: 30 },
            { hours: 16, minutes: 30 },
        ],
        5: [
            { hours: 8, minutes: 30 },
            { hours: 16, minutes: 30 },
        ],
        6: null,
    };

    static HOLIDAYS: { month: number; day: number }[] = [];

    static isDateTodayOrInThePast(time: string) {
        return moment(time).isSameOrBefore(undefined, "day");
    }

    static isDateLessThanNMonthsInTheFuture(time: string, n: number) {
        return moment().isSameOrAfter(moment(time).subtract(n, "months"));
    }

    static isDateLessThanNWeeksInTheFuture(time: string, n: number) {
        return moment().isSameOrAfter(moment(time).subtract(n, "weeks"));
    }

    static isDateMoreThanNMonthsInThePast(time: string, n: number) {
        return moment(time).isSameOrBefore(moment().subtract(n, "months"));
    }

    static isTimeBeforeOtherTime(time: string, otherTime: string) {
        return moment(time).isBefore(moment(otherTime));
    }

    /**
     * @todo: Implement this method!
     */
    static isHoliday(time: string | moment.Moment) {
        return false;
    }

    static getDayWorkingHours(
        time: string | moment.Moment
    ): [moment.Moment, moment.Moment] | null {
        const timeObject = moment(time);
        const workingHours = TimeUtils.WORKING_HOURS[timeObject.day()];

        if (workingHours === null || TimeUtils.isHoliday(timeObject)) {
            return null;
        }

        const fromTime = moment(time);
        fromTime.hours(workingHours[0].hours);
        fromTime.minutes(workingHours[0].minutes);
        fromTime.seconds(0);
        fromTime.milliseconds(0);

        const toTime = moment(time);
        toTime.hours(workingHours[1].hours);
        toTime.minutes(workingHours[1].minutes);
        toTime.seconds(0);
        toTime.milliseconds(0);

        return [fromTime, toTime];
    }

    static isTimeInWorkingHours(
        time: string | moment.Moment
    ): [boolean, "before" | "after" | null] {
        const timeObject = moment(time);

        const timeWorkingHours = TimeUtils.getDayWorkingHours(timeObject);

        if (timeWorkingHours === null) {
            return [false, null];
        }

        if (timeObject.isBefore(timeWorkingHours[0])) {
            return [false, "before"];
        }

        if (timeObject.isAfter(timeWorkingHours[1])) {
            return [false, "after"];
        }

        return [true, null];
    }

    static addBusinessDays(
        time: string | moment.Moment | undefined,
        daysToAdd: number
    ): [moment.Moment, moment.Moment] {
        let nextDay = moment(time);

        if (daysToAdd === 0) {
            const workingHours = TimeUtils.getDayWorkingHours(nextDay);

            if (workingHours !== null) {
                return workingHours;
            }

            daysToAdd = 1;
        }

        let nextWorkingHours: [moment.Moment, moment.Moment] | null;
        let added = 1;

        do {
            nextDay = nextDay.add(1, "day");
            nextWorkingHours = TimeUtils.getDayWorkingHours(nextDay);
        } while (
            TimeUtils.getDayWorkingHours(nextDay) === null ||
            added++ !== daysToAdd
        );

        if (nextWorkingHours === null) {
            throw new Error("Could not resolve working hours.");
        }

        return nextWorkingHours;
    }

    static addBusinessHours(
        time: string | moment.Moment | undefined,
        hoursToAdd: number
    ): moment.Moment {
        const timeObject = moment(time);
        const isInWorkingHours = TimeUtils.isTimeInWorkingHours(timeObject);
        const workingHours = TimeUtils.getDayWorkingHours(timeObject);

        let addDays, newHoursToAdd;

        if (
            workingHours === null ||
            isInWorkingHours[1] === "after" ||
            isInWorkingHours[1] === "before"
        ) {
            addDays = Math.floor(hoursToAdd / 8);
            newHoursToAdd = hoursToAdd % 8;
            let offset = 1;

            if (isInWorkingHours[1] === "before") {
                offset = 0;
            }

            return TimeUtils.addBusinessDays(
                timeObject,
                offset + addDays
            )[0].add(newHoursToAdd, "hours");
        }

        const addedTime = timeObject.add(hoursToAdd, "hours");

        if (addedTime.isBefore(workingHours[1])) {
            return addedTime;
        }

        addDays = Math.floor(
            moment.duration(timeObject.diff(workingHours[1])).asSeconds() /
                28800
        );

        const newSecondsToAdd =
            moment.duration(timeObject.diff(workingHours[1])).asSeconds() %
            28800;

        return TimeUtils.addBusinessDays(
            TimeUtils.addBusinessDays(timeObject, 1)[0],
            addDays
        )[0].add(newSecondsToAdd, "seconds");
    }

    static formatDateToReadableString(date: string) {
        if (!date) {
            return "";
        }

        const timeObject = moment(date);

        return timeObject.format("MMMM D, YYYY");
    }

    static formatDateToRelativeTimeFromNow(date: string) {
        if (!date) {
            return "";
        }

        const timeObject = moment(date);

        return timeObject.fromNow();
    }

    static getMinutesFromNowInReadableString(minutes: number) {
        return moment().add(minutes, "minutes").format("YYYY/MM/DD HH:mm");
    }

    static getSecondsFromNowInReadableString(seconds: number) {
        return moment().add(seconds, "seconds").format("YYYY/MM/DD HH:mm");
    }
}

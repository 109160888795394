<div class="mat-elevation-z2 table-container">
    <table
        class="business-reviews-table"
        mat-table
        [dataSource]="dataSource"
        multiTemplateDataRows
    >
        <ng-container matColumnDef="date">
            <th class="date-column" mat-header-cell *matHeaderCellDef>
                Created At
            </th>
            <td class="date-column" mat-cell *matCellDef="let row">
                {{ row.createdAt }}
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th class="actions-column" mat-header-cell *matHeaderCellDef>
                Actions
            </th>
            <td class="actions-column" mat-cell *matCellDef="let row">
                <button
                    mat-icon-button
                    aria-label="Edit"
                    matTooltip="Edit"
                    (click)="editReview.emit(row)"
                >
                    <portal-icon icon="edit"></portal-icon>
                </button>
                <button
                    mat-icon-button
                    aria-label="Expand"
                    [matTooltip]="
                        expandedBusinessReview === row.id
                            ? 'Collapse'
                            : 'Expand'
                    "
                    (click)="expandBusinessReview($event, row.id)"
                >
                    <portal-icon
                        [icon]="
                            expandedBusinessReview === row.id
                                ? 'expand_less'
                                : 'expand_more'
                        "
                    ></portal-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
            <td
                mat-cell
                *matCellDef="let element"
                [attr.colspan]="displayedColumns.length"
            >
                <div
                    class="detail"
                    [@detailExpand]="
                        element.id === expandedBusinessReview
                            ? 'expanded'
                            : 'collapsed'
                    "
                >
                    <div
                        class="detail-container"
                        [innerHTML]="element.description | markdownToHtml"
                    ></div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="business-review-row"
            [class.expanded-row]="expandedBusinessReview === row.id"
        ></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: ['expandedDetail']"
            class="detail-row"
        ></tr>
    </table>

    <mat-paginator
        class="paginator"
        [pageSizeOptions]="pageSizes"
    ></mat-paginator>
</div>

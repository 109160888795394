<div class="flex justify-between items-center pr-4">
    <portal-section-header
        [text]="headline | uppercase"
        [subtext]="subtitle || ''"
    ></portal-section-header>
    <ng-content select="[card-actions]"></ng-content>
</div>
<div class="bg-white rounded-lg shadow p-4 flex flex-col">
    <ng-content select="[card-content]"></ng-content>
</div>

<div class="file" [ngClass]="{ loading: loading }" (click)="openFile()">
    <div class="file-name">
        <fa-icon
            *ngIf="!loading"
            class="file-icon"
            [icon]="['fas', 'file']"
        ></fa-icon>
        <fa-icon
            *ngIf="loading"
            class="file-icon"
            [icon]="['fas', 'spinner']"
            [spin]="true"
        ></fa-icon>
        {{ getFileDisplayName(file.name) }}
        <span class="size">[{{ prettifyFileSize(file.size) }}]</span>
    </div>
    <div>
        <fa-icon
            *ngIf="isUserFile(file.name)"
            matTooltip="Delete"
            class="icon-button"
            [icon]="['fas', 'trash-alt']"
            (click)="
                $event.stopPropagation();
                deleteFile.emit({
                    name: file.name,
                    path: file.path
                })
            "
        ></fa-icon>
    </div>
</div>

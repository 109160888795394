import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription, combineLatest } from "rxjs";
import {
    PlayerTypesGQL,
    ProjectsGQL,
    CreatePlayerGQL,
    OtherPlayersQuery,
    PlayersQuery,
    PlayersGQL,
    PlayersDocument,
    CompanyQuery,
    CompanyGQL,
    StopPlayerGQL,
    DeletePlayerGQL,
    StartPlayerGQL,
    PlayersHistoryQuery,
    PlayersHistoryGQL,
    ProlongPlayerGQL,
    OtherPlayersGQL,
} from "../../../../core";
import { first } from "rxjs/operators";
import { CreatePlayerDialogComponent } from "../../components/create-player-dialog/create-player-dialog.component";
import { ToastrService } from "ngx-toastr";
import * as _ from "lodash";
import { StartPlayerDialogComponent } from "../../components/start-palyer-dialog/start-player-dialog.component";
import { ProlongPlayerDialogComponent } from "../../components/prolong-player-dialog/prolong-player-dialog.component";
import { DeletePlayerDialogComponent } from "../../components/delete-player-dialog/delete-player-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { Player } from "@portal/shared";

@Component({
    selector: "portal-players",
    templateUrl: "./players.component.html",
    styleUrls: ["./players.component.scss"],
})
export class PlayersComponent implements OnInit, OnDestroy {
    players: PlayersQuery["players"] | null = null;
    otherPlayers: Player[] | null = null;
    playersHistory: PlayersHistoryQuery["playersHistory"] | null = null;
    company: CompanyQuery["company"] | null = null;
    filterValue = "";

    loading = true;

    private subscriptions: Subscription[] = [];

    constructor(
        private playerTypesGql: PlayerTypesGQL,
        private projectsGql: ProjectsGQL,
        private toastrService: ToastrService,
        private playersGql: PlayersGQL,
        private companyGql: CompanyGQL,
        private otherPlayersGql: OtherPlayersGQL,
        private startPlayerGql: StartPlayerGQL,
        private createPlayerGql: CreatePlayerGQL,
        private stopPlayerGql: StopPlayerGQL,
        private playersHistoryGql: PlayersHistoryGQL,
        private deletePlayerGql: DeletePlayerGQL,
        private prolongPlayerGql: ProlongPlayerGQL,
        private dialog: MatDialog
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.playersGql
                .watch({}, { pollInterval: 15000 })
                .valueChanges.subscribe((result) => {
                    this.players = result.data.players;

                    if (this.company !== null) {
                        this.loading = false;
                    }
                }),
            this.companyGql.watch().valueChanges.subscribe((result) => {
                this.company = result.data.company;

                if (this.company.id === "1") {
                    this.subscriptions.push(
                        this.otherPlayersGql
                            .watch()
                            .valueChanges.subscribe((otherResult) => {
                                this.otherPlayers =
                                    otherResult.data.otherPlayers;
                            })
                    );
                }

                if (this.players !== null) {
                    this.loading = false;
                }
            }),
            this.playersHistoryGql.watch().valueChanges.subscribe((result) => {
                this.playersHistory = result.data.playersHistory;
            })
        );
    }

    ngOnDestroy() {
        while (this.subscriptions.length) {
            this.subscriptions.pop()?.unsubscribe();
        }
    }

    openPlayer(event: { id: string }) {
        window.open(
            this.players?.find((player) => player.id === event.id)?.url ||
                "https://sewio-rtls-player-" +
                    event.id +
                    ".westeurope.cloudapp.azure.com",
            "_blank"
        );
    }

    stopPlayer(event: { id: string }) {
        this.stopPlayerGql
            .mutate(
                {
                    id: event.id,
                },
                {
                    update: (store, { data }) => {
                        if (!data) {
                            return;
                        }

                        this.toastrService.info(
                            "Stop has been requested. It may take a few minutes before the RTLS Player is stopped completely.",
                            "",
                            { disableTimeOut: true }
                        );

                        const clonedData = _.cloneDeep(
                            store.readQuery<PlayersQuery>({
                                query: PlayersDocument,
                            })
                        );

                        if (clonedData === null) {
                            return;
                        }

                        const stoppedPlayer = clonedData.players.find(
                            (player) => player.id === event.id
                        );

                        if (stoppedPlayer === undefined) {
                            return;
                        }

                        stoppedPlayer.status = data.stopPlayer.status;

                        store.writeQuery({
                            query: PlayersDocument,
                            data: clonedData,
                        });
                    },
                }
            )
            .subscribe()
            .unsubscribe();
    }

    deletePlayer(event: { id: string }) {
        const dialogReference = this.dialog.open(DeletePlayerDialogComponent, {
            width: window.innerWidth >= 768 ? "600px" : "300px",
        });

        dialogReference
            .afterClosed()
            .pipe(first())
            .subscribe((result) => {
                if (result) {
                    this.deletePlayerGql
                        .mutate(
                            {
                                id: event.id,
                            },
                            {
                                update: (store, { data }) => {
                                    if (!data) {
                                        return;
                                    }

                                    this.toastrService.info(
                                        "Delete has been requested. It may take a few minutes before the RTLS Player is deleted.",
                                        "",
                                        { disableTimeOut: true }
                                    );

                                    const clonedData = _.cloneDeep(
                                        store.readQuery<PlayersQuery>({
                                            query: PlayersDocument,
                                        })
                                    );

                                    if (clonedData === null) {
                                        return;
                                    }

                                    const deletedPlayer =
                                        clonedData.players.find(
                                            (player) => player.id === event.id
                                        );

                                    if (deletedPlayer === undefined) {
                                        return;
                                    }

                                    deletedPlayer.status =
                                        data.deletePlayer.status;

                                    store.writeQuery({
                                        query: PlayersDocument,
                                        data: clonedData,
                                    });
                                },
                            }
                        )
                        .subscribe()
                        .unsubscribe();
                }
            });
    }

    startPlayer(event: { id: string }) {
        const dialogReference = this.dialog.open(StartPlayerDialogComponent, {
            width: window.innerWidth >= 768 ? "600px" : "300px",
        });

        dialogReference
            .afterClosed()
            .pipe(first())
            .subscribe((result) => {
                if (result) {
                    this.startPlayerGql
                        .mutate(
                            {
                                time: result.time,
                                id: event.id,
                                deleteOnTime: result.deleteOnTime,
                            },
                            {
                                update: (store, { data }) => {
                                    if (!data) {
                                        return;
                                    }

                                    this.toastrService.info(
                                        "Start has been requested. It may take a few minutes to start the RTLS Player again.",
                                        "",
                                        { disableTimeOut: true }
                                    );

                                    const clonedData = _.cloneDeep(
                                        store.readQuery<PlayersQuery>({
                                            query: PlayersDocument,
                                        })
                                    );

                                    if (clonedData === null) {
                                        return;
                                    }

                                    const startedPlayer =
                                        clonedData.players.find(
                                            (player) => player.id === event.id
                                        );

                                    if (startedPlayer === undefined) {
                                        return;
                                    }

                                    startedPlayer.status =
                                        data.startPlayer.status;

                                    store.writeQuery({
                                        query: PlayersDocument,
                                        data: clonedData,
                                    });
                                },
                            }
                        )
                        .subscribe()
                        .unsubscribe();
                }
            });
    }

    prolongPlayer(event: { id: string }) {
        const dialogReference = this.dialog.open(ProlongPlayerDialogComponent, {
            width: window.innerWidth >= 768 ? "600px" : "300px",
        });

        dialogReference
            .afterClosed()
            .pipe(first())
            .subscribe((result) => {
                if (result) {
                    this.prolongPlayerGql
                        .mutate(
                            {
                                time: result.time,
                                id: event.id,
                                deleteOnTime: result.deleteOnTime,
                            },
                            {
                                update: (store, { data }) => {
                                    if (!data) {
                                        return;
                                    }

                                    this.toastrService.info(
                                        "Successfully prolonged player uptime.",
                                        "",
                                        { disableTimeOut: true }
                                    );

                                    const clonedData = _.cloneDeep(
                                        store.readQuery<PlayersQuery>({
                                            query: PlayersDocument,
                                        })
                                    );

                                    if (clonedData === null) {
                                        return;
                                    }

                                    const startedPlayer =
                                        clonedData.players.find(
                                            (player) => player.id === event.id
                                        );

                                    if (startedPlayer === undefined) {
                                        return;
                                    }

                                    startedPlayer.status =
                                        data.prolongPlayer.status;
                                    startedPlayer.deleteOnTime =
                                        data.prolongPlayer.deleteOnTime;
                                    startedPlayer.remainingTime =
                                        data.prolongPlayer.remainingTime;

                                    store.writeQuery({
                                        query: PlayersDocument,
                                        data: clonedData,
                                    });
                                },
                            }
                        )
                        .subscribe()
                        .unsubscribe();
                }
            });
    }

    createPlayer() {
        this.loading = true;

        combineLatest([
            this.playerTypesGql.watch().valueChanges,
            this.projectsGql.watch().valueChanges,
        ])
            .pipe(first())
            .subscribe((results) => {
                this.loading = false;

                const dialogReference = this.dialog.open(
                    CreatePlayerDialogComponent,
                    {
                        width: window.innerWidth >= 768 ? "600px" : "300px",
                        data: {
                            playerTypes: results[0].data.playerTypes,
                            projects: results[1].data.projects.map(
                                (project) => ({
                                    id: project.id,
                                    name: project.name,
                                })
                            ),
                        },
                    }
                );

                dialogReference
                    .afterClosed()
                    .pipe(first())
                    .subscribe((result) => {
                        if (result) {
                            this.createPlayerGql
                                .mutate(
                                    {
                                        version: result.version,
                                        time: result.time,
                                        id: result.project,
                                        deleteOnTime: result.deleteOnTime,
                                    },
                                    {
                                        update: (store, { data }) => {
                                            if (!data) {
                                                return;
                                            }

                                            this.toastrService.info(
                                                "RTLS Player is being deployed. This may take up to 10 minutes. We will send you an email once the RTLS Player is ready.",
                                                "",
                                                { disableTimeOut: true }
                                            );

                                            const players =
                                                store.readQuery<PlayersQuery>({
                                                    query: PlayersDocument,
                                                });

                                            if (players === null) {
                                                return;
                                            }

                                            store.writeQuery({
                                                query: PlayersDocument,
                                                data: {
                                                    players:
                                                        players.players.concat([
                                                            data.createPlayer,
                                                        ]),
                                                },
                                            });
                                        },
                                    }
                                )
                                .subscribe()
                                .unsubscribe();
                        }
                    });
            });
    }

    applyFilter(value: string) {
        this.filterValue = value;
    }
}

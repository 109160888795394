<div *ngIf="siteUpdates; else loading">
    <table *ngIf="siteUpdates.length > 0" mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>Title</th>
            <td mat-cell *matCellDef="let row">
                <span
                    *ngIf="row.internal"
                    class="bg-sewio text-white rounded-full px-2 mr-2 text-sm"
                    >INTERNAL</span
                >
                {{ row.title }}
            </td>
        </ng-container>

        <ng-container matColumnDef="opportunity">
            <th mat-header-cell *matHeaderCellDef>Opportunity</th>
            <td mat-cell *matCellDef="let row">
                <span *ngIf="row.opportunityId !== null">
                    [{{ row.opportunityId }}]
                </span>
                {{ opportunityIdToName(row.opportunityId) }}
            </td>
        </ng-container>

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let row">
                {{ row.date }}
            </td>
        </ng-container>

        <ng-container matColumnDef="updated-at">
            <th mat-header-cell *matHeaderCellDef>Updated At</th>
            <td mat-cell *matCellDef="let row">
                {{ row.updatedAt | utcToLocal : "YYYY-MM-DD HH:mm:ss" }}
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th
                class="actions-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Actions
            </th>
            <td class="actions-column" mat-cell *matCellDef="let row">
                <div class="flex gap-2">
                    <button
                        *ngIf="row.id[0] !== 's'"
                        mat-icon-button
                        aria-label="Edit"
                        matTooltip="Edit"
                        (click)="$event.stopPropagation(); editUpdate(row)"
                    >
                        <portal-icon icon="edit"></portal-icon>
                    </button>
                    <button
                        *ngIf="row.id[0] !== 's'"
                        mat-icon-button
                        aria-label="Delete"
                        matTooltip="Delete"
                        (click)="$event.stopPropagation(); deleteUpdate(row.id)"
                    >
                        <portal-icon icon="delete"></portal-icon>
                    </button>
                </div>
            </td>
        </ng-container>

        <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
            mat-row
            class="cursor-pointer hover:bg-stone-50"
            *matRowDef="let row; columns: displayedColumns"
            (click)="openUpdate(row)"
        ></tr>
    </table>
    <div *ngIf="siteUpdates.length === 0" class="flex justify-center py-3">
        <portal-status
            [text]="'None'"
            [type]="'none'"
            [big]="true"
        ></portal-status>
    </div>
</div>
<ng-template #loading>
    <portal-loading [height]="48"></portal-loading>
</ng-template>

import EditorJS, { OutputData } from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/nested-list";
import Quote from "@editorjs/quote";
import Raw from "@editorjs/raw";
import Delimiter from "@editorjs/delimiter";
import Table from "@editorjs/table";

import { Component, Inject, AfterViewInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Site, SiteUpdate } from "@portal/shared";
import { FormControl, UntypedFormControl, Validators } from "@angular/forms";
import moment from "moment";

@Component({
    selector: "portal-edit-update-dialog",
    styleUrls: [],
    templateUrl: "edit-update-dialog.component.html",
})
export class EditUpdateDialogComponent implements AfterViewInit {
    opportunityValidator = new UntypedFormControl("null", [
        Validators.required,
    ]);

    titleValidator = new UntypedFormControl("", [Validators.required]);
    dateValidator = new UntypedFormControl("", []);

    internalValidator = new FormControl(false);

    site: Site;
    siteUpdate: SiteUpdate;
    editor!: EditorJS;
    sewio: boolean;

    constructor(
        public dialogRef: MatDialogRef<EditUpdateDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { site: Site; siteUpdate: SiteUpdate; sewio: boolean }
    ) {
        this.site = data.site;
        this.sewio = data.sewio;
        this.siteUpdate = data.siteUpdate;
    }

    ngAfterViewInit(): void {
        if (!this.siteUpdate) {
            console.error("No Site Update provided");
            this.dialogRef.close();
            return;
        }

        this.editor = new EditorJS({
            holder: "edit-update-dialog-content",
            autofocus: false,
            tools: {
                header: Header,
                list: { class: List, inlineToolbar: true },
                quote: Quote,
                raw: Raw,
                delimiter: Delimiter,
                table: { class: Table, inlineToolbar: true },
            },
            data: JSON.parse(this.siteUpdate.content),
        });

        this.opportunityValidator.setValue(
            this.siteUpdate.opportunityId === null
                ? "null"
                : this.siteUpdate.opportunityId
        );
        this.titleValidator.setValue(this.siteUpdate.title);
        this.dateValidator.setValue(moment(this.siteUpdate.date).toDate());
        this.internalValidator.setValue(this.siteUpdate.internal);
    }

    opportunityIdToName(id: string | null): string | undefined {
        return (
            this.site?.projects?.find((opportunity) => opportunity.id === id)
                ?.name || "N/A"
        );
    }

    async edit() {
        if (this.opportunityValidator.invalid) {
            return;
        }

        if (this.titleValidator.invalid) {
            return;
        }

        if (this.dateValidator.invalid) {
            return;
        }

        let editorContent: OutputData;

        try {
            editorContent = await this.editor.save();
        } catch (error) {
            console.error(error);
            return;
        }

        this.dialogRef.close({
            id: this.siteUpdate.id,
            title: this.titleValidator.value,
            content: editorContent,
            opportunityId:
                this.opportunityValidator.value === "null"
                    ? null
                    : this.opportunityValidator.value,
            internal: this.internalValidator.value,
            date: moment(this.dateValidator.value).format("YYYY-MM-DD"),
        });
    }
}

<div class="table-container mat-elevation-z2">
    <table
        class="invoices-table"
        mat-table
        [dataSource]="dataSource"
        matSort
        *ngIf="invoices.length !== 0"
    >
        <ng-container matColumnDef="number">
            <th
                class="number-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Number
            </th>
            <td class="number-column" mat-cell *matCellDef="let row">
                {{ row.number }}
            </td>
        </ng-container>

        <ng-container matColumnDef="origin">
            <th
                class="origin-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Origin
            </th>
            <td class="origin-column" mat-cell *matCellDef="let row">
                {{ row.origin }}
            </td>
        </ng-container>

        <ng-container matColumnDef="due">
            <th
                class="due-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Due Date
            </th>
            <td class="due-column" mat-cell *matCellDef="let row">
                {{ row.dueDate }}
            </td>
        </ng-container>

        <ng-container matColumnDef="amounts">
            <th
                class="amounts-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Amount Due / Amount Total
            </th>
            <td class="amounts-column" mat-cell *matCellDef="let row">
                {{ row.dueAmount | round | numberToLocaleString }} /
                {{ row.totalAmount | round | numberToLocaleString }}
                {{ row.currency }}
            </td>
        </ng-container>

        <ng-container matColumnDef="state">
            <th
                class="state-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                State
            </th>
            <td class="state-column" mat-cell *matCellDef="let row">
                {{ row.state | format }}
            </td>
        </ng-container>

        <ng-container matColumnDef="track">
            <th
                class="track-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Tracking
            </th>
            <td class="track-column" mat-cell *matCellDef="let row">
                <fa-icon
                    *ngIf="row.trackingNumber"
                    class="icon-button"
                    matTooltip="Track"
                    [icon]="['fas', 'route']"
                    (click)="track(row.trackingNumber)"
                ></fa-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="print">
            <th
                class="print-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Print
            </th>
            <td class="print-column" mat-cell *matCellDef="let row">
                <fa-icon
                    *ngIf="!downloading"
                    class="icon-button"
                    matTooltip="Print"
                    [icon]="['fas', 'print']"
                    (click)="print(row.id)"
                ></fa-icon>
                <fa-icon
                    *ngIf="downloading"
                    class="icon-button text-sewio"
                    matTooltip="Print"
                    [icon]="['fas', 'spinner']"
                    [spin]="true"
                ></fa-icon>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="order-row"
            [ngClass]="{
                'sewio-red-background':
                    isDueDate(row.dueDate) && row.dueAmount > 0,
                'sewio-blue-background':
                    !isDueDate(row.dueDate) && row.dueAmount > 0,
                'sewio-green-background': row.state === 'paid'
            }"
        ></tr>
    </table>

    <div class="none-row" *ngIf="invoices.length === 0">
        <portal-status
            [text]="'None'"
            [type]="'none'"
            [big]="true"
        ></portal-status>
    </div>
</div>

import { NgModule } from "@angular/core";
import { DashboardRoutes } from "./dashboard.routes";
import { DashboardComponent } from "./views/dashboard/dashboard.component";
import { SharedModule } from "../../shared";

@NgModule({
    imports: [
        SharedModule,
        DashboardRoutes,
    ],
    declarations: [DashboardComponent],
    providers: [],
})
export class DashboardModule {}

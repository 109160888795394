import { PlansComponent } from "./views/plans/plans.component";
import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared";
import { PlansRoutes } from "./plans.routes";
import { AllPlansListComponent } from "./components/all-plans-list/all-plans-list.component";

@NgModule({
    imports: [SharedModule, PlansRoutes],
    declarations: [PlansComponent, AllPlansListComponent],
    providers: [],
})
export class PlansModule {}

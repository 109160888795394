import { Component } from "@angular/core";

@Component({
    selector: "portal-partners",
    templateUrl: "./partners.component.html",
    styleUrls: ["./partners.component.scss"],
})
export class PartnersComponent {
    filterValue = "";

    applyFilter(filterValue: string) {
        this.filterValue = filterValue;
    }
}

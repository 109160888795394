import {
    Component,
    ViewChild,
    Input,
    Output,
    EventEmitter,
} from "@angular/core";
import { UserQuery, UsersQuery } from "../../../../core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";

type User = UserQuery["user"];
type Users = UsersQuery["users"][0];

@Component({
    selector: "portal-user-list",
    templateUrl: "./user-list.component.html",
    styleUrls: ["./user-list.component.scss"],
})
export class UserListComponent {
    private _users!: UsersQuery["users"];

    get users(): UsersQuery["users"] {
        return this._users;
    }

    @Input()
    set users(users: UsersQuery["users"]) {
        this._users = users;
        this.dataSource = new MatTableDataSource(this._users);

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    @Input() user: User | null = null;

    @Output() deleteUser = new EventEmitter<{ id: string }>();
    @Output() editUser = new EventEmitter<{ id: string }>();

    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort!: MatSort;

    displayedColumns: string[] = ["email", "name", "state", "role", "actions"];
    pageSizes: number[] = [10, 20, 30];
    dataSource!: MatTableDataSource<Users>;
}

import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";

import { AuthGuard } from "../../core";
import { UsersComponent } from "./views/users/users.component";
import { menuGuard } from "../../core/guards/menu.guard";

const usersRoutes: Routes = [
    {
        path: "users",
        component: UsersComponent,
        data: {
            showTopPanel: true,
            showLeftPanel: true,
        },
        canActivate: [AuthGuard, menuGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(usersRoutes)],
    exports: [RouterModule],
})
export class UsersRoutes {}

<button
    class="ml-4 flex md:hidden"
    mat-icon-button
    aria-label="Toggle Menu"
    matTooltip="Toggle Menu"
    (click)="togglePanel()"
>
    <portal-icon icon="menu"></portal-icon>
</button>
<div
    class="flex items-center cursor-pointer ml-4"
    (click)="redirectToDashboard()"
>
    <img class="h-6" src="./assets/header-logo.svg" />
</div>
<div class="flex gap-4 items-center mr-2">
    <div *ngIf="user !== null" class="flex-col hidden md:flex">
        <div class="font-sans font-bold text-sm">USER</div>
        <div>{{ user.email }}</div>
    </div>
    <div class="flex-col hidden md:flex">
        <div class="font-sans font-bold text-sm">COMPANY</div>
        <div *ngIf="company !== null">{{ company.name }}</div>
        <div *ngIf="company === null">loading</div>
    </div>
    <button
        *ngIf="user !== null && user.sewio"
        mat-icon-button
        aria-label="Reset Company"
        matTooltip="Reset Company"
        (click)="resetCompany()"
    >
        <fa-icon
            class="flex items-center justify-center h-6 w-6 [&>svg]:w-4 [&>svg]:h-4"
            [icon]="sewioIcon"
        ></fa-icon>
    </button>
    <button
        mat-icon-button
        aria-label="Log Out"
        matTooltip="Log Out"
        (click)="logOut()"
    >
        <portal-icon icon="logout"></portal-icon>
    </button>
</div>

import { Component, ViewChild, ElementRef } from "@angular/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { UntypedFormControl, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { countries, unknownValueValidator } from "../../../../shared";
import { Observable } from "rxjs";
import { startWith, map } from "rxjs/operators";
import {
    DateAdapter,
    MAT_DATE_LOCALE,
    MAT_DATE_FORMATS,
} from "@angular/material/core";
import { MatStepper } from "@angular/material/stepper";
import { MatDatepicker } from "@angular/material/datepicker";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";

export const CUSTOM_FORMATS = {
    parse: {
        dateInput: "YYYY-MM-DD",
    },
    display: {
        dateInput: "YYYY-MM-DD",
        monthYearLabel: "MMMM YYYY",
        dateA11yLabel: "YYYY-MM-DD",
        monthYearA11yLabel: "MMMM YYYY",
    },
};

export const ProjectUseCases = [
    { value: "industry_material_flow", text: "Industry - Material Flow" },
    { value: "industry_fleet_tracking", text: "Industry - Fleet Tracking" },
    { value: "industry_general", text: "Industry - General" },
    { value: "safety", text: "Safety" },
    { value: "entertainment", text: "Entertainment" },
    { value: "healthcare", text: "Healthcare" },
    { value: "retail", text: "Retail" },
    { value: "sport", text: "Sport" },
    { value: "virtual_reality", text: "Virtual Reality" },
    { value: "livestock", text: "Livestock" },
    { value: "showroom", text: "Showroom" },
    { value: "construction", text: "Construction Site" },
    { value: "research", text: "Research" },
    { value: "buildings", text: "Buildings" },
    {
        value: "employee_tracking_performance",
        text: "Employee Tracking - Performance",
    },
    { value: "training_Army_etc", text: "Training - Army, etc." },
    { value: "other", text: "Other" },
    { value: "na_yet", text: "N/A yet" },
];

@Component({
    selector: "portal-register-dialog",
    styleUrls: ["register-dialog.component.scss"],
    templateUrl: "register-dialog.component.html",
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: CUSTOM_FORMATS },
    ],
})
export class RegisterDialogComponent {
    @ViewChild("stepper", { static: false }) stepper!: MatStepper;

    @ViewChild("nameInput", { static: false }) nameInput!: ElementRef;
    @ViewChild("customerInput", { static: false }) customerInput!: ElementRef;
    @ViewChild("cityInput", { static: false }) cityInput!: ElementRef;
    @ViewChild("countryInput", { static: false }) countryInput!: ElementRef;
    @ViewChild("shortInput", { static: false }) shortInput!: ElementRef;
    @ViewChild("problemInput", { static: false }) problemInput!: ElementRef;
    @ViewChild("solutionInput", { static: false }) solutionInput!: ElementRef;
    @ViewChild("useCaseSelect", { static: false }) useCaseSelect!: MatSelect;
    @ViewChild("revenueInput", { static: false }) revenueInput!: ElementRef;
    @ViewChild("datepickerInput", { static: false })
    datepickerInput!: ElementRef;
    @ViewChild("closingdatepicker", { static: false })
    closingdatepicker!: MatDatepicker<string>;
    @ViewChild("descriptionInput", { static: false })
    descriptionInput!: ElementRef;

    @ViewChild("trackedInput", { static: false }) trackedInput!: ElementRef;
    @ViewChild("countInput", { static: false }) countInput!: ElementRef;
    @ViewChild("mountingInput", { static: false }) mountingInput!: ElementRef;
    @ViewChild("updateInput", { static: false }) updateInput!: ElementRef;
    @ViewChild("accuracyInput", { static: false }) accuracyInput!: ElementRef;
    @ViewChild("batteryInput", { static: false }) batteryInput!: ElementRef;

    zAxis = false;

    stepOneComplete = false;
    stepOneEditable = true;

    stepTwoComplete = false;
    stepTwoEditable = false;

    stepThreeEditable = false;

    filteredCountries: Observable<{ id: number; name: string }[]>;

    nameValidator = new UntypedFormControl("", [Validators.required]);
    customerValidator = new UntypedFormControl("", [Validators.required]);
    cityValidator = new UntypedFormControl("", [Validators.required]);
    countryValidator = new UntypedFormControl("", [
        Validators.required,
        unknownValueValidator(countries.map((country) => country.name)),
    ]);
    shortValidator = new UntypedFormControl("", [Validators.required]);
    problemValidator = new UntypedFormControl("", [Validators.required]);
    solutionValidator = new UntypedFormControl("", [Validators.required]);
    useCaseTypeValidator = new UntypedFormControl("", [Validators.required]);
    revenueValidator = new UntypedFormControl("", [Validators.required]);

    useCases = ProjectUseCases;

    constructor(
        public dialogRef: MatDialogRef<RegisterDialogComponent>,
        public toastrService: ToastrService
    ) {
        this.filteredCountries = this.countryValidator.valueChanges.pipe(
            startWith(""),
            map((state) =>
                state ? this.filterCountries(state) : countries.slice()
            )
        );
    }

    private filterCountries(value: string): { id: number; name: string }[] {
        const filterValue = value.toLowerCase();
        return countries.filter(
            (state) => state.name.toLowerCase().indexOf(filterValue) === 0
        );
    }

    next(): void {
        if (!this.stepOneComplete) {
            if (this.customerValidator.invalid) {
                this.customerInput.nativeElement.focus();
                return;
            }

            if (this.cityValidator.invalid) {
                this.cityInput.nativeElement.focus();
                return;
            }

            if (this.countryValidator.invalid) {
                this.countryInput.nativeElement.focus();
                return;
            }

            if (this.shortValidator.invalid) {
                this.shortInput.nativeElement.focus();
                return;
            }

            if (this.useCaseTypeValidator.invalid) {
                this.useCaseSelect.open();
                return;
            }

            if (this.problemValidator.invalid) {
                this.problemInput.nativeElement.focus();
                return;
            }

            if (this.solutionValidator.invalid) {
                this.solutionInput.nativeElement.focus();
                return;
            }

            if (this.revenueValidator.invalid) {
                this.revenueInput.nativeElement.focus();
                return;
            }

            if (this.datepickerInput.nativeElement.value.length === 0) {
                this.closingdatepicker.open();
                return;
            }

            this.stepOneComplete = true;
            this.stepOneEditable = false;
            this.stepTwoEditable = true;

            setTimeout(() => this.stepper.next(), 100);
        } else {
            this.stepTwoComplete = true;
            this.stepTwoEditable = false;
            this.stepThreeEditable = true;

            setTimeout(() => this.stepper.next(), 100);
        }
    }

    back(): void {
        if (!this.stepTwoComplete) {
            this.stepOneComplete = false;
            this.stepOneEditable = true;
            this.stepTwoEditable = false;

            setTimeout(() => this.stepper.previous(), 100);
        } else {
            this.stepTwoComplete = false;
            this.stepTwoEditable = true;
            this.stepThreeEditable = false;

            setTimeout(() => this.stepper.previous(), 100);
        }
    }

    register(): void {
        const country = countries.find(
            (country) => country.name === this.countryInput.nativeElement.value
        );

        if (!country) {
            return;
        }

        this.dialogRef.close({
            name: this.nameInput.nativeElement.value,
            customer: this.customerInput.nativeElement.value,
            country: country.id,
            problem: this.problemInput.nativeElement.value,
            solution: this.solutionInput.nativeElement.value,
            useCase: this.useCaseTypeValidator.value,
            revenue: this.revenueInput.nativeElement.value,
            closing: this.datepickerInput.nativeElement.value,
            description: this.descriptionInput.nativeElement.value,
            trackedObjects: this.trackedInput.nativeElement.value,
            numberOfObjects: this.countInput.nativeElement.value,
            tagMounting: this.mountingInput.nativeElement.value,
            refreshRate: this.updateInput.nativeElement.value,
            accuracy: this.accuracyInput.nativeElement.value,
            batteryLife: this.batteryInput.nativeElement.value,
            zAxis: this.zAxis,
        });
    }
}

import { Pipe, PipeTransform } from "@angular/core";
import { Plan, Project } from "@portal/shared";

@Pipe({ name: "mergeProjectsPlans" })
export class MergeProjectsPlansPipe implements PipeTransform {
    transform(projects: Project[]) {
        return projects.reduce<Plan[]>((acc, project) => {
            if (project) {
                acc.push(...project.plans);
            }

            return acc;
        }, []);
    }
}

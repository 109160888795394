import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { DashboardComponent } from "./views/dashboard/dashboard.component";
import { AuthGuard } from "../../core";
import { menuGuard } from "../../core/guards/menu.guard";

const dashboardRoutes: Routes = [
    {
        path: "dashboard",
        component: DashboardComponent,
        data: {
            showTopPanel: true,
            showLeftPanel: true,
        },
        canActivate: [AuthGuard, menuGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(dashboardRoutes)],
    exports: [RouterModule],
})
export class DashboardRoutes {}

<div mat-dialog-title class="title">Delete Folder</div>
<div mat-dialog-content>
    Are you sure you want to delete folder
    <span class="text-warning-dark">{{ data }}</span> and all its content?
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CANCEL</button>
    <button mat-button color="warn" cdkFocusInitial (click)="delete()">
        DELETE
    </button>
</mat-dialog-actions>

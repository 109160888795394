import { Component, OnInit, OnDestroy } from "@angular/core";
import {
    PlansGQL,
    Plan,
    ProjectInfoGQL,
    ProjectInfo,
    ApprovePlanGQL,
    PlansDocument,
} from "../../../../core";
import { Subscription } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { first } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "portal-plans",
    templateUrl: "./plans.component.html",
    styleUrls: ["./plans.component.scss"],
})
export class PlansComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    plans: Plan[] | null = null;
    projects: { [key: string]: ProjectInfo } | null = null;

    states = [
        { text: "Draft", value: "draft" },
        { text: "Pending Approval", value: "pending" },
        { text: "Approved", value: "approved" },
        { text: "Returned", value: "returned" },
    ];

    stages = [
        { text: "Prj. Qualification", value: "37" },
        { text: "Prj. Quotation", value: "38" },
        { text: "Prj. Discovery", value: "33" },
        { text: "Prj. Design", value: "34" },
        { text: "Prj. Order", value: "35" },
        { text: "Prj. Shipment", value: "36" },
        { text: "Prj. Invoiced (Closed)", value: "4" },
    ];

    selectedStates = ["pending", "returned"];
    selectedStages = ["34", "35"];
    hideSewioPlans = true;
    blockActions = false;

    constructor(
        private plansGql: PlansGQL,
        private projectInfoGql: ProjectInfoGQL,
        private approvePlanGql: ApprovePlanGQL,
        private toastrService: ToastrService
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.plansGql.watch().valueChanges.subscribe((result) => {
                this.plans = result.data.plans;

                this.projectInfoGql
                    .watch({
                        ids: [
                            ...new Set(
                                this.plans.map((plan) => plan.projectId)
                            ), // set removes duplicites
                        ].join(","),
                    })
                    .valueChanges.pipe(first())
                    .subscribe((nameResult) => {
                        const projects: {
                            [key: string]: ProjectInfo;
                        } = {};

                        nameResult.data.projectInfo.forEach((info) => {
                            if (!info) {
                                return;
                            }

                            projects[info.id] = info;
                        });

                        this.projects = projects;
                    });
            })
        );
    }

    ngOnDestroy() {
        while (this.subscriptions.length) {
            this.subscriptions.pop()?.unsubscribe();
        }
    }

    approvePlan(event: { id: string }) {
        this.blockActions = true;

        this.approvePlanGql
            .mutate(
                { id: event.id, approved: true },
                {
                    refetchQueries: [{ query: PlansDocument }],
                    update: () => {
                        this.toastrService.success(
                            "Plan approved successfully."
                        );

                        this.blockActions = false;
                    },
                }
            )
            .subscribe()
            .unsubscribe();
    }

    returnPlan(event: { id: string }) {
        this.blockActions = true;

        this.approvePlanGql
            .mutate(
                { id: event.id, approved: false },
                {
                    refetchQueries: [{ query: PlansDocument }],
                    update: (_store, result) => {
                        this.blockActions = false;

                        if (!result.data) {
                            return;
                        }

                        window.open(
                            environment.sd_address +
                                "/scp/tickets.php?id=" +
                                result.data.approvePlan,
                            "_blank"
                        );
                    },
                }
            )
            .subscribe()
            .unsubscribe();
    }

    removeFilters() {
        this.selectedStages = [];
        this.selectedStates = [];
        this.hideSewioPlans = false;
    }
}

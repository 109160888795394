import { Component, Input } from "@angular/core";
import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { PartnerStatus } from "../../../core";

@Component({
    selector: "portal-partner-level",
    templateUrl: "./partner-level.component.html",
    styleUrls: ["./partner-level.component.scss"],
})
export class PartnerLevelComponent {
    RADIUS = 54;
    CIRCUMFERENCE = 2 * Math.PI * this.RADIUS;

    sewioIcon: [IconPrefix, IconName] = ["fab", "sewio" as IconName];

    @Input() status!: PartnerStatus;

    getOffset(percent: number) {
        const progress = percent / 100;
        const dashoffset = this.CIRCUMFERENCE * (1 - progress);

        return dashoffset;
    }

    calculatePercentage() {
        if (!this.status.nextLevel) {
            return 100;
        }

        if (!this.status.currentLevel) {
            return 0;
        }

        let max =
            this.status.nextLevel.minAnchors +
            this.status.nextLevel.minL1Trainings +
            this.status.nextLevel.minL2Trainings +
            this.status.nextLevel.minL3Trainings +
            this.status.nextLevel.minPoints -
            this.status.currentLevel.minAnchors -
            this.status.currentLevel.minL1Trainings -
            this.status.currentLevel.minL2Trainings -
            this.status.currentLevel.minL3Trainings -
            this.status.currentLevel.minPoints;

        let current =
            Math.min(
                this.status.anchors - this.status.currentLevel.minAnchors,
                this.status.nextLevel.minAnchors
            ) +
            Math.min(
                (this.status.l1Trainings > 0 ? 1 : 0) -
                    this.status.currentLevel.minL1Trainings,
                this.status.nextLevel.minL1Trainings
            ) +
            Math.min(
                (this.status.l2Trainings > 0 ? 1 : 0) -
                    this.status.currentLevel.minL2Trainings,
                this.status.nextLevel.minL2Trainings
            ) +
            Math.min(
                (this.status.l3Trainings > 0 ? 1 : 0) -
                    this.status.currentLevel.minL3Trainings,
                this.status.nextLevel.minL3Trainings
            ) +
            Math.min(
                this.status.points - this.status.currentLevel.minPoints,
                this.status.nextLevel.minPoints
            );

        if (max === 0) {
            max = 1;
        }

        if (current < 0) {
            current = 0;
        }

        return Math.floor((current / max) * 100);
    }
}

import { Component, Input, EventEmitter, Output } from "@angular/core";
import { FolderStructure } from "../../../modules/project/components/storage/storage.component";
import { File } from "../../../core";

@Component({
    selector: "portal-explorer",
    templateUrl: "./explorer.component.html",
    styleUrls: ["./explorer.component.scss"],
})
export class ExplorerComponent {
    @Input() folders!: FolderStructure[];
    @Input() files!: File[];

    @Output() deleteFolder = new EventEmitter<{ path: string; name: string }>();
    @Output() deleteFile = new EventEmitter<{ path: string; name: string }>();
    @Output() openFileRequest = new EventEmitter();
}

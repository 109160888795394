import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CalculatedSite, Company, SiteUtils } from "@portal/shared";
import { Apollo, QueryRef } from "apollo-angular";
import { Subscription } from "rxjs";
import {
    CompanyGQL,
    Project,
    SetCompanyGQL,
    SiteGQL,
    SiteQuery,
    SiteQueryVariables,
    UserGQL,
} from "../../../core";
import { PlanListComponent } from "../../../shared/components/plan-list/plan-list.component";
import { UpdatesListComponent } from "../components/updates-list/updates-list.component";
import { TicketListComponent } from "../../../shared/components/ticket-list/ticket-list.component";

@Component({
    selector: "portal-site",
    templateUrl: "./site.component.html",
    styleUrls: ["./site.component.scss"],
})
export class SiteComponent implements OnInit, OnDestroy {
    @ViewChild("planList") planList?: PlanListComponent;
    @ViewChild("ticketList") ticketList?: TicketListComponent;
    @ViewChild("updateList") updateList?: UpdatesListComponent;

    site: CalculatedSite | null = null;
    company: Company | null = null;
    sewio = false;
    loading = 1;

    private subscriptions: Subscription[] = [];
    private siteWatch!: QueryRef<SiteQuery, SiteQueryVariables>;

    constructor(
        private siteGql: SiteGQL,
        private userGql: UserGQL,
        private companyGql: CompanyGQL,
        private setCompanyGql: SetCompanyGQL,
        private apollo: Apollo,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                this.siteWatch = this.siteGql.watch({
                    siteId: params["id"],
                });

                this.subscriptions.push(
                    this.siteWatch.valueChanges.subscribe((value) => {
                        this.site = SiteUtils.getCalculatedSite(
                            value.data.site
                        );

                        if (this.loading > 0) {
                            this.loading--;
                        }

                        this.setCompany();
                    }),
                    this.userGql.watch().valueChanges.subscribe((value) => {
                        this.sewio = value.data.user.sewio;
                        this.setCompany();
                    }),
                    this.companyGql.watch().valueChanges.subscribe((value) => {
                        this.company = value.data.company;
                        this.setCompany();
                    })
                );
            })
        );
    }

    ngOnDestroy(): void {
        while (this.subscriptions.length) {
            this.subscriptions.pop()?.unsubscribe();
        }
    }

    setCompany() {
        if (this.sewio && this.site && this.company) {
            if (this.site.partnerId !== this.company.id) {
                this.setCompanyGql
                    .mutate(
                        { id: this.site.partnerId },
                        {
                            update: () => {
                                this.apollo.client.resetStore();
                            },
                        }
                    )
                    .subscribe()
                    .unsubscribe();
            }
        }
    }

    createPlan() {
        if (this.planList && this.site && this.site.projects) {
            this.planList.createPlan(this.site.projects as Project[]);
        }
    }

    createTicket() {
        if (this.ticketList && this.site && this.site.projects) {
            this.ticketList.createTicket(this.site.projects as Project[]);
        }
    }

    createUpdate() {
        if (this.updateList) {
            this.updateList.createUpdate();
        }
    }
}

export const countries = [
    { "id": 1, "name": "Andorra, Principality of" },
    { "id": 2, "name": "United Arab Emirates" },
    { "id": 3, "name": "Afghanistan, Islamic State of" },
    { "id": 4, "name": "Antigua and Barbuda" },
    { "id": 5, "name": "Anguilla" },
    { "id": 6, "name": "Albania" },
    { "id": 7, "name": "Armenia" },
    { "id": 8, "name": "Netherlands Antilles" },
    { "id": 9, "name": "Angola" },
    { "id": 10, "name": "Antarctica" },
    { "id": 11, "name": "Argentina" },
    { "id": 12, "name": "American Samoa" },
    { "id": 13, "name": "Austria" },
    { "id": 14, "name": "Australia" },
    { "id": 15, "name": "Aruba" },
    { "id": 16, "name": "Åland Islands" },
    { "id": 17, "name": "Azerbaijan" },
    { "id": 18, "name": "Bosnia-Herzegovina" },
    { "id": 19, "name": "Barbados" },
    { "id": 20, "name": "Bangladesh" },
    { "id": 21, "name": "Belgium" },
    { "id": 22, "name": "Burkina Faso" },
    { "id": 23, "name": "Bulgaria" },
    { "id": 24, "name": "Bahrain" },
    { "id": 25, "name": "Burundi" },
    { "id": 26, "name": "Benin" },
    { "id": 27, "name": "Saint Barthélémy" },
    { "id": 28, "name": "Bermuda" },
    { "id": 29, "name": "Brunei Darussalam" },
    { "id": 30, "name": "Bolivia" },
    { "id": 31, "name": "Bonaire, Sint Eustatius and Saba" },
    { "id": 32, "name": "Brazil" },
    { "id": 33, "name": "Bahamas" },
    { "id": 34, "name": "Bhutan" },
    { "id": 35, "name": "Bouvet Island" },
    { "id": 36, "name": "Botswana" },
    { "id": 37, "name": "Belarus" },
    { "id": 38, "name": "Belize" },
    { "id": 39, "name": "Canada" },
    { "id": 40, "name": "Cocos (Keeling) Islands" },
    { "id": 41, "name": "Central African Republic" },
    { "id": 42, "name": "Congo, Democratic Republic of the" },
    { "id": 43, "name": "Congo" },
    { "id": 44, "name": "Switzerland" },
    { "id": 45, "name": "Ivory Coast (Cote D'Ivoire)" },
    { "id": 46, "name": "Cook Islands" },
    { "id": 47, "name": "Chile" },
    { "id": 48, "name": "Cameroon" },
    { "id": 49, "name": "China" },
    { "id": 50, "name": "Colombia" },
    { "id": 51, "name": "Costa Rica" },
    { "id": 52, "name": "Cuba" },
    { "id": 53, "name": "Cape Verde" },
    { "id": 54, "name": "Curaçao" },
    { "id": 55, "name": "Christmas Island" },
    { "id": 56, "name": "Cyprus" },
    { "id": 57, "name": "Czech Republic" },
    { "id": 58, "name": "Germany" },
    { "id": 59, "name": "Djibouti" },
    { "id": 60, "name": "Denmark" },
    { "id": 61, "name": "Dominica" },
    { "id": 62, "name": "Dominican Republic" },
    { "id": 63, "name": "Algeria" },
    { "id": 64, "name": "Ecuador" },
    { "id": 65, "name": "Estonia" },
    { "id": 66, "name": "Egypt" },
    { "id": 67, "name": "Western Sahara" },
    { "id": 68, "name": "Eritrea" },
    { "id": 69, "name": "Spain" },
    { "id": 70, "name": "Ethiopia" },
    { "id": 71, "name": "Finland" },
    { "id": 72, "name": "Fiji" },
    { "id": 73, "name": "Falkland Islands" },
    { "id": 74, "name": "Micronesia" },
    { "id": 75, "name": "Faroe Islands" },
    { "id": 76, "name": "France" },
    { "id": 77, "name": "Gabon" },
    { "id": 78, "name": "Grenada" },
    { "id": 79, "name": "Georgia" },
    { "id": 80, "name": "French Guyana" },
    { "id": 81, "name": "Ghana" },
    { "id": 82, "name": "Gibraltar" },
    { "id": 83, "name": "Guernsey" },
    { "id": 84, "name": "Greenland" },
    { "id": 85, "name": "Gambia" },
    { "id": 86, "name": "Guinea" },
    { "id": 87, "name": "Guadeloupe (French)" },
    { "id": 88, "name": "Equatorial Guinea" },
    { "id": 89, "name": "Greece" },
    { "id": 90, "name": "South Georgia and the South Sandwich Islands" },
    { "id": 91, "name": "Guatemala" },
    { "id": 92, "name": "Guam (USA)" },
    { "id": 93, "name": "Guinea Bissau" },
    { "id": 94, "name": "Guyana" },
    { "id": 95, "name": "Hong Kong" },
    { "id": 96, "name": "Heard and McDonald Islands" },
    { "id": 97, "name": "Honduras" },
    { "id": 98, "name": "Croatia" },
    { "id": 99, "name": "Haiti" },
    { "id": 100, "name": "Hungary" },
    { "id": 101, "name": "Indonesia" },
    { "id": 102, "name": "Ireland" },
    { "id": 103, "name": "Israel" },
    { "id": 104, "name": "Isle of Man" },
    { "id": 105, "name": "India" },
    { "id": 106, "name": "British Indian Ocean Territory" },
    { "id": 107, "name": "Iraq" },
    { "id": 108, "name": "Iran" },
    { "id": 109, "name": "Iceland" },
    { "id": 110, "name": "Italy" },
    { "id": 111, "name": "Jersey" },
    { "id": 112, "name": "Jamaica" },
    { "id": 113, "name": "Jordan" },
    { "id": 114, "name": "Japan" },
    { "id": 115, "name": "Kenya" },
    { "id": 116, "name": "Kyrgyz Republic (Kyrgyzstan)" },
    { "id": 117, "name": "Cambodia, Kingdom of" },
    { "id": 118, "name": "Kiribati" },
    { "id": 119, "name": "Comoros" },
    { "id": 120, "name": "Saint Kitts & Nevis Anguilla" },
    { "id": 121, "name": "North Korea" },
    { "id": 122, "name": "South Korea" },
    { "id": 123, "name": "Kuwait" },
    { "id": 124, "name": "Cayman Islands" },
    { "id": 125, "name": "Kazakhstan" },
    { "id": 126, "name": "Laos" },
    { "id": 127, "name": "Lebanon" },
    { "id": 128, "name": "Saint Lucia" },
    { "id": 129, "name": "Liechtenstein" },
    { "id": 130, "name": "Sri Lanka" },
    { "id": 131, "name": "Liberia" },
    { "id": 132, "name": "Lesotho" },
    { "id": 133, "name": "Lithuania" },
    { "id": 134, "name": "Luxembourg" },
    { "id": 135, "name": "Latvia" },
    { "id": 136, "name": "Libya" },
    { "id": 137, "name": "Morocco" },
    { "id": 138, "name": "Monaco" },
    { "id": 139, "name": "Moldavia" },
    { "id": 140, "name": "Montenegro" },
    { "id": 141, "name": "Saint Martin (French part)" },
    { "id": 142, "name": "Madagascar" },
    { "id": 143, "name": "Marshall Islands" },
    { "id": 144, "name": "Macedonia, the former Yugoslav Republic of" },
    { "id": 145, "name": "Mali" },
    { "id": 146, "name": "Myanmar" },
    { "id": 147, "name": "Mongolia" },
    { "id": 148, "name": "Macau" },
    { "id": 149, "name": "Northern Mariana Islands" },
    { "id": 150, "name": "Martinique (French)" },
    { "id": 151, "name": "Mauritania" },
    { "id": 152, "name": "Montserrat" },
    { "id": 153, "name": "Malta" },
    { "id": 154, "name": "Mauritius" },
    { "id": 155, "name": "Maldives" },
    { "id": 156, "name": "Malawi" },
    { "id": 157, "name": "Mexico" },
    { "id": 158, "name": "Malaysia" },
    { "id": 159, "name": "Mozambique" },
    { "id": 160, "name": "Namibia" },
    { "id": 161, "name": "New Caledonia (French)" },
    { "id": 162, "name": "Niger" },
    { "id": 163, "name": "Norfolk Island" },
    { "id": 164, "name": "Nigeria" },
    { "id": 165, "name": "Nicaragua" },
    { "id": 166, "name": "Netherlands" },
    { "id": 167, "name": "Norway" },
    { "id": 168, "name": "Nepal" },
    { "id": 169, "name": "Nauru" },
    { "id": 170, "name": "Neutral Zone" },
    { "id": 171, "name": "Niue" },
    { "id": 172, "name": "New Zealand" },
    { "id": 173, "name": "Oman" },
    { "id": 174, "name": "Panama" },
    { "id": 175, "name": "Peru" },
    { "id": 176, "name": "Polynesia (French)" },
    { "id": 177, "name": "Papua New Guinea" },
    { "id": 178, "name": "Philippines" },
    { "id": 179, "name": "Pakistan" },
    { "id": 180, "name": "Poland" },
    { "id": 181, "name": "Saint Pierre and Miquelon" },
    { "id": 182, "name": "Pitcairn Island" },
    { "id": 183, "name": "Puerto Rico" },
    { "id": 184, "name": "Palestinian Territory, Occupied" },
    { "id": 185, "name": "Portugal" },
    { "id": 186, "name": "Palau" },
    { "id": 187, "name": "Paraguay" },
    { "id": 188, "name": "Qatar" },
    { "id": 189, "name": "Reunion (French)" },
    { "id": 190, "name": "Romania" },
    { "id": 191, "name": "Serbia" },
    { "id": 192, "name": "Russian Federation" },
    { "id": 193, "name": "Rwanda" },
    { "id": 194, "name": "Saudi Arabia" },
    { "id": 195, "name": "Solomon Islands" },
    { "id": 196, "name": "Seychelles" },
    { "id": 197, "name": "Sudan" },
    { "id": 198, "name": "Sweden" },
    { "id": 199, "name": "Singapore" },
    { "id": 200, "name": "Saint Helena" },
    { "id": 201, "name": "Slovenia" },
    { "id": 202, "name": "Svalbard and Jan Mayen Islands" },
    { "id": 203, "name": "Slovakia" },
    { "id": 204, "name": "Sierra Leone" },
    { "id": 205, "name": "San Marino" },
    { "id": 206, "name": "Senegal" },
    { "id": 207, "name": "Somalia" },
    { "id": 208, "name": "Suriname" },
    { "id": 209, "name": "South Sudan" },
    { "id": 210, "name": "Saint Tome (Sao Tome) and Principe" },
    { "id": 211, "name": "El Salvador" },
    { "id": 212, "name": "Sint Maarten (Dutch part)" },
    { "id": 213, "name": "Syria" },
    { "id": 214, "name": "Swaziland" },
    { "id": 215, "name": "Turks and Caicos Islands" },
    { "id": 216, "name": "Chad" },
    { "id": 217, "name": "French Southern Territories" },
    { "id": 218, "name": "Togo" },
    { "id": 219, "name": "Thailand" },
    { "id": 220, "name": "Tajikistan" },
    { "id": 221, "name": "Tokelau" },
    { "id": 222, "name": "Turkmenistan" },
    { "id": 223, "name": "Tunisia" },
    { "id": 224, "name": "Tonga" },
    { "id": 225, "name": "East Timor" },
    { "id": 226, "name": "Turkey" },
    { "id": 227, "name": "Trinidad and Tobago" },
    { "id": 228, "name": "Tuvalu" },
    { "id": 229, "name": "Taiwan" },
    { "id": 230, "name": "Tanzania" },
    { "id": 231, "name": "Ukraine" },
    { "id": 232, "name": "Uganda" },
    { "id": 233, "name": "United Kingdom" },
    { "id": 234, "name": "USA Minor Outlying Islands" },
    { "id": 235, "name": "United States" },
    { "id": 236, "name": "Uruguay" },
    { "id": 237, "name": "Uzbekistan" },
    { "id": 238, "name": "Holy See (Vatican City State)" },
    { "id": 239, "name": "Saint Vincent & Grenadines" },
    { "id": 240, "name": "Venezuela" },
    { "id": 241, "name": "Virgin Islands (British)" },
    { "id": 242, "name": "Virgin Islands (USA)" },
    { "id": 243, "name": "Vietnam" },
    { "id": 244, "name": "Vanuatu" },
    { "id": 245, "name": "Wallis and Futuna Islands" },
    { "id": 246, "name": "Samoa" },
    { "id": 247, "name": "Yemen" },
    { "id": 248, "name": "Mayotte" },
    { "id": 249, "name": "Yugoslavia" },
    { "id": 250, "name": "South Africa" },
    { "id": 251, "name": "Zambia" },
    { "id": 252, "name": "Zaire" },
    { "id": 253, "name": "Zimbabwe" }
]

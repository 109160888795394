<div class="input-group">
    <input
        #fileElement
        class="invisible"
        type="file"
        (change)="fileChanged($event)"
        [attr.multiple]="multipleFiles ? '' : null"
    />
    <div #nameElement class="input-element" (click)="fileElement.click()">
        Maximum file size is 100 MB
    </div>
    <fa-icon
        class="icon-button"
        [icon]="['fas', 'file-upload']"
        matTooltip="Select Files"
        (click)="fileElement.click()"
    ></fa-icon>
</div>

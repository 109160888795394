import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";

export class SetPanel {
    static readonly type = "[UI] Set Panel";
    constructor(public panel: boolean) {}
}

export class TogglePanel {
    static readonly type = "[UI] Toggle Panel";
}

export interface UiStateModel {
    panel: boolean;
}

@State<UiStateModel>({
    name: "ui",
    defaults: {
        panel: true,
    },
})
@Injectable()
export class UiState {
    @Action(SetPanel)
    setPanel(ctx: StateContext<UiStateModel>, action: SetPanel) {
        ctx.patchState({
            panel: action.panel,
        });
    }

    @Action(TogglePanel)
    togglePanel(ctx: StateContext<UiStateModel>) {
        const state = ctx.getState();

        ctx.patchState({
            panel: !state.panel,
        });
    }

    @Selector()
    static panel(state: UiStateModel) {
        return state.panel;
    }
}

<div
    class="bg-stone-100"
    [ngClass]="{
        panelsBackground: showLeftPanel || showTopPanel,
        simpleBackground: !showLeftPanel && !showTopPanel
    }"
>
    <portal-left-panel
        *ngIf="showLeftPanel"
        class="lower mat-elevation-z4"
    ></portal-left-panel>
    <portal-top-panel
        *ngIf="showTopPanel"
        class="higher mat-elevation-z4"
    ></portal-top-panel>
    <div #content class="content">
        <router-outlet></router-outlet>
    </div>
</div>

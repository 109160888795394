import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { MenuItem } from "@portal/shared";

@Component({
    selector: "portal-menu-item",
    templateUrl: "./menu-item.component.html",
    styleUrls: ["./menu-item.component.scss"],
})
export class MenuItemComponent implements OnInit, OnDestroy {
    @Input() menuItem!: MenuItem;

    selected = false;
    menuOpen = false;

    private subscriptions: Subscription[] = [];

    constructor(private router: Router) {}

    ngOnInit() {
        this.subscriptions.push(
            this.router.events
                .pipe(filter((event) => event instanceof NavigationStart))
                .subscribe((change) => {
                    if (
                        (change as NavigationStart).url
                            .split("/")
                            .indexOf(this.menuItem.slug) !== -1
                    ) {
                        this.selected = true;
                        this.menuOpen = true;
                    } else {
                        this.selected = false;
                    }
                })
        );

        if (this.router.url.split("/").indexOf(this.menuItem.slug) !== -1) {
            this.selected = true;
            this.menuOpen = true;
        } else {
            this.selected = false;
        }
    }

    ngOnDestroy() {
        while (this.subscriptions.length) {
            this.subscriptions.pop()?.unsubscribe();
        }
    }

    openPage() {
        if (this.menuItem.url) {
            if (this.menuItem.url.indexOf("http") === 0) {
                // external url
                window.open(this.menuItem.url, "_blank");
            } else {
                // internal url
                void this.router.navigate([this.menuItem.url]);
            }

            return;
        }

        if (this.menuItem.parent) {
            const path = [
                "other",
                this.menuItem.parent.slug,
                this.menuItem.slug,
            ];

            void this.router.navigate(path);
        } else {
            void this.router.navigate(["other", this.menuItem.slug]);
        }
    }
}

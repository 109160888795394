import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";

import { AuthGuard } from "../../core";
import { SiteComponent } from "./views/site.component";

const siteRoutes: Routes = [
    {
        path: "sites/:id",
        component: SiteComponent,
        data: {
            showTopPanel: true,
            showLeftPanel: true,
        },
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(siteRoutes)],
    exports: [RouterModule],
})
export class SiteRoutes {}

import { Component, ViewChild, ElementRef, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../../../core";
import { catchError, first } from "rxjs/operators";
import * as owasp from "owasp-password-strength-test";

@Component({
    selector: "portal-new-password",
    templateUrl: "./new-password.component.html",
    styleUrls: ["./new-password.component.scss"],
})
export class NewPasswordComponent implements OnInit {
    loading = false;

    email!: string;
    token!: string;

    @ViewChild("password", { static: false }) password!: ElementRef;
    @ViewChild("confirmPassword", { static: false })
    confirmPassword!: ElementRef;

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private toastrService: ToastrService
    ) {}

    ngOnInit() {
        this.route.queryParamMap.pipe(first()).subscribe((map) => {
            if (!map.has("email") || !map.has("token")) {
                void this.router.navigate(["/login"]);
                return;
            }

            this.email = map.get("email") as string;
            this.token = map.get("token") as string;
        });
    }

    changePassword() {
        const passwordValue = (this.password.nativeElement as HTMLInputElement)
            .value;

        const confirmPasswordValue = (
            this.confirmPassword.nativeElement as HTMLInputElement
        ).value;

        if (passwordValue === "") {
            this.toastrService.error("Can't choose empty password.");
            return;
        }

        if (passwordValue !== confirmPasswordValue) {
            this.toastrService.error("Passwords do not match.");
            return;
        }

        owasp.config({
            allowPassphrases: true,
            maxLength: 100,
            minLength: 8,
            minPhraseLength: 20,
            minOptionalTestsToPass: 3,
        });

        const result = owasp.test(passwordValue);

        if (!result.strong) {
            this.toastrService.error(result.errors[0]);
            return;
        }

        this.loading = true;

        this.authService
            .changePassword(this.email, this.token, passwordValue)
            .pipe(
                first(),
                catchError((error) => {
                    this.toastrService.error("Something went wrong.");
                    return error;
                })
            )
            .subscribe(() => {
                this.toastrService.success(
                    "Password changed successfuly. Redirecting to login page."
                );

                setTimeout(() => {
                    void this.router.navigate(["/login"]);
                }, 3000);
            });
    }
}

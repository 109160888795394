<div mat-dialog-title class="title">Create Ticket</div>
<div mat-dialog-content>
    <div class="form pt-2">
        <mat-form-field>
            <mat-label>Opportunity</mat-label>
            <mat-select #opportunitySelect [formControl]="opportunityValidator">
                <mat-option
                    *ngFor="let project of data.projects"
                    [value]="project.id"
                >
                    [{{ project.id }}] {{ project.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Ticket Type</mat-label>
            <mat-select #typeSelect [formControl]="typeValidator" required>
                <mat-option
                    *ngFor="let type of ticketTypes"
                    [value]="type.value"
                >
                    {{ type.viewValue }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div
            *ngIf="typeValidator.value === 'question'"
            class="response-time mb-4"
        >
            <portal-status
                [type]="'warning'"
                [text]="
                    'In case of business questions, please contact your account manager.'
                "
            ></portal-status>
        </div>
        <div *ngIf="typeValidator.value === 'incident'" class="response-time">
            <div class="response-time-header">Response Time:</div>
            <div *ngIf="levelValidator.value === 'critical'">
                <portal-status
                    *ngIf="!responseTimes.critical"
                    [type]="'danger'"
                    [text]="'Not Guaranteed'"
                ></portal-status>
                <portal-status
                    *ngIf="responseTimes.critical"
                    [type]="'success'"
                    [text]="responseTimes.critical"
                ></portal-status>
            </div>
            <div *ngIf="levelValidator.value === 'major'">
                <portal-status
                    *ngIf="!responseTimes.major"
                    [type]="'danger'"
                    [text]="'Not Guaranteed'"
                ></portal-status>
                <portal-status
                    *ngIf="responseTimes.major"
                    [type]="'success'"
                    [text]="responseTimes.major"
                ></portal-status>
            </div>
            <div
                *ngIf="
                    levelValidator.value === 'minor' ||
                    levelValidator.value === 'other'
                "
            >
                <portal-status
                    *ngIf="!responseTimes.minor"
                    [type]="'danger'"
                    [text]="'Not Guaranteed'"
                ></portal-status>
                <portal-status
                    *ngIf="responseTimes.minor"
                    [type]="'success'"
                    [text]="responseTimes.minor"
                ></portal-status>
            </div>
        </div>
        <mat-form-field>
            <input
                #subjectInput
                matInput
                placeholder="Ticket Subject"
                [formControl]="subjectValidator"
                required
            />
        </mat-form-field>
        <mat-form-field>
            <mat-label>Priority Level</mat-label>
            <mat-select #levelSelect [formControl]="levelValidator" required>
                <mat-option
                    *ngFor="let level of ticketLevels"
                    [value]="level.value"
                >
                    {{ level.viewValue }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="typeValidator.value === 'incident'">
            <textarea
                #expectedBehaviourInput
                class="long"
                matInput
                placeholder="Expected Behaviour"
                [formControl]="expectedBehaviourValidator"
                oninput='this.style.height = ""; this.style.height = this.scrollHeight + "px"'
                required
            ></textarea>
        </mat-form-field>
        <mat-form-field *ngIf="typeValidator.value === 'incident'">
            <textarea
                #currentBehaviourInput
                class="long"
                matInput
                placeholder="Current Behaviour"
                [formControl]="currentBehaviourValidator"
                oninput='this.style.height = ""; this.style.height = this.scrollHeight + "px"'
                required
            ></textarea>
        </mat-form-field>
        <div
            class="environment-variables"
            *ngIf="typeValidator.value === 'incident'"
        >
            <div class="environment-header">Environment:</div>
            <mat-form-field>
                <input
                    #studioVersionInput
                    matInput
                    placeholder="RTLS Studio Version"
                    [formControl]="studioVersionValidator"
                />
            </mat-form-field>
            <mat-form-field>
                <mat-label>Deployment Type</mat-label>
                <mat-select
                    #deploymentSelect
                    [formControl]="deploymentTypeValidator"
                >
                    <mat-option
                        *ngFor="let type of deploymentTypes"
                        [value]="type.value"
                    >
                        {{ type.viewValue }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <input
                    #anchorCountInput
                    matInput
                    placeholder="Number of Anchors"
                    [formControl]="anchorCountValidator"
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    #anchorTypesInput
                    matInput
                    placeholder="Anchor Types & Versions"
                    [formControl]="anchorTypesValidator"
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    #tagCountInput
                    matInput
                    placeholder="Number of Tags"
                    [formControl]="tagCountValidator"
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    #tagTypesInput
                    matInput
                    placeholder="Tag Types & Versions"
                    [formControl]="tagTypesValidator"
                />
            </mat-form-field>
        </div>
        <div class="info">
            After the ticket is created a new tab will open, where you can share
            detailed information.
        </div>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CANCEL</button>
    <button mat-button color="primary" cdkFocusInitial (click)="create()">
        CREATE
    </button>
</mat-dialog-actions>

<div class="my-2 flex justify-between items-center">
    <portal-section-header
        text="Plans"
        subtext="All plans requiring attention."
    ></portal-section-header>
    <div class="flex gap-2 items-center">
        <mat-form-field>
            <mat-label>Plan States</mat-label>
            <mat-select [(value)]="selectedStates" multiple>
                <mat-option
                    *ngFor="let state of states"
                    [value]="state.value"
                    >{{ state.text }}</mat-option
                >
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Project Stages</mat-label>
            <mat-select [(value)]="selectedStages" multiple>
                <mat-option
                    *ngFor="let stage of stages"
                    [value]="stage.value"
                    >{{ stage.text }}</mat-option
                >
            </mat-select>
        </mat-form-field>
        <mat-checkbox [(ngModel)]="hideSewioPlans"
            >Hide Sewio Plans</mat-checkbox
        >
        <button mat-stroked-button color="primary" (click)="removeFilters()">
            REMOVE FILTERS
        </button>
    </div>
</div>
<portal-loading
    *ngIf="plans === null || projects === null"
    [height]="48"
></portal-loading>
<portal-all-plans-list
    *ngIf="plans !== null && projects !== null"
    [plans]="plans"
    [projects]="projects"
    [selectedStates]="selectedStates"
    [selectedStages]="selectedStages"
    [hideSewioPlans]="hideSewioPlans"
    [blockActions]="blockActions"
    (approvePlan)="approvePlan($event)"
    (returnPlan)="returnPlan($event)"
></portal-all-plans-list>

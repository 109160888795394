import { Component, Input } from "@angular/core";

@Component({
    selector: "portal-icon",
    templateUrl: "./icon.component.html",
    styleUrls: ["./icon.component.scss"],
})
export class IconComponent {
    private _icon!: string;

    @Input() fill = false;
    @Input() big = false;

    @Input()
    set icon(icon: string) {
        this._icon = icon;
    }

    get icon() {
        return this._icon;
    }
}

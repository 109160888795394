import { Component, Input, Output, EventEmitter } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Player } from "../../../../core";

@Component({
    selector: "portal-players-active-list",
    templateUrl: "./players-active-list.component.html",
    styleUrls: ["./players-active-list.component.scss"],
})
export class PlayersActiveListComponent {
    displayedColumns: string[] = [
        "id",
        "user",
        "version",
        "time",
        "username",
        "password",
        "state",
        "actions",
    ];
    dataSource!: MatTableDataSource<Player>;

    private _players!: Player[];

    get players(): Player[] {
        return this._players;
    }

    @Input()
    set players(players: Player[]) {
        this._players = players;
        this.dataSource = new MatTableDataSource(this._players);
    }

    @Output() openPlayer = new EventEmitter();
    @Output() stopPlayer = new EventEmitter();
    @Output() startPlayer = new EventEmitter();
    @Output() prolongPlayer = new EventEmitter();
    @Output() deletePlayer = new EventEmitter();
}

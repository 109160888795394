import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    OnDestroy,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import {
    CreateSiteUpdateGQL,
    DeleteSiteUpdateGQL,
    EditSiteUpdateGQL,
    Site,
    SiteGQL,
    SiteUpdate,
    SiteUpdatesDocument,
    SiteUpdatesGQL,
    UserGQL,
} from "../../../../core";
import { Subscription, first } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { UpdateModalComponent } from "../update-modal/update-modal.component";
import { CreateUpdateDialogComponent } from "../create-update-dialog/create-update-dialog.component";
import { ToastrService } from "ngx-toastr";
import { EditUpdateDialogComponent } from "../edit-update-dialog/edit-update-dialog.component";

@Component({
    selector: "portal-updates-list",
    templateUrl: "./updates-list.component.html",
    styleUrls: [],
})
export class UpdatesListComponent implements OnInit, OnDestroy {
    @Output() openSiteUpdate = new EventEmitter();

    displayedColumns: string[] = [
        "title",
        "opportunity",
        "date",
        "updated-at",
        "actions",
    ];
    dataSource!: MatTableDataSource<SiteUpdate>;
    siteUpdates?: SiteUpdate[];
    site?: Site;
    sewio?: boolean;

    private _subscriptions: Subscription[] = [];

    constructor(
        private createSiteUpdateGql: CreateSiteUpdateGQL,
        private editSiteUpdateGql: EditSiteUpdateGQL,
        private deleteSiteUpdateGql: DeleteSiteUpdateGQL,
        private siteUpdatesGql: SiteUpdatesGQL,
        private siteGql: SiteGQL,
        private userGql: UserGQL,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private toastrService: ToastrService
    ) {}

    ngOnInit() {
        this._subscriptions.push(
            this.route.params.subscribe((params) => {
                this._subscriptions.push(
                    this.siteUpdatesGql
                        .watch({ siteId: params["id"] })
                        .valueChanges.subscribe((value) => {
                            this.siteUpdates = value.data.siteUpdates;
                            this.dataSource = new MatTableDataSource(
                                this.siteUpdates
                            );
                        }),
                    this.siteGql
                        .watch({ siteId: params["id"] })
                        .valueChanges.subscribe((value) => {
                            this.site = value.data.site;
                        }),
                    this.userGql.watch().valueChanges.subscribe((result) => {
                        if (result.data.user) {
                            this.sewio = result.data.user.sewio;
                        }
                    })
                );
            })
        );
    }

    ngOnDestroy() {
        while (this._subscriptions.length) {
            this._subscriptions.pop()?.unsubscribe();
        }
    }

    openUpdate(update: SiteUpdate) {
        this.dialog.open(UpdateModalComponent, {
            width: window.innerWidth >= 768 ? "600px" : "95%",
            data: { update: update, site: this.site },
        });
    }

    opportunityIdToName(id: string | null): string | undefined {
        return (
            this.site?.projects?.find((opportunity) => opportunity.id === id)
                ?.name || "N/A"
        );
    }

    createUpdate() {
        const dialogReference = this.dialog.open(CreateUpdateDialogComponent, {
            width: window.innerWidth >= 768 ? "600px" : "95%",
            data: { site: this.site, sewio: this.sewio || false },
        });

        dialogReference
            .afterClosed()
            .pipe(first())
            .subscribe((result) => {
                if (result && this.site) {
                    this.createSiteUpdateGql
                        .mutate(
                            {
                                content: JSON.stringify(result.content),
                                title: result.title,
                                opportunityId: result.opportunityId,
                                siteId: this.site.id,
                                internal: result.internal,
                                date: result.date,
                            },
                            {
                                refetchQueries: [SiteUpdatesDocument],
                            }
                        )
                        .pipe(first())
                        .subscribe(() => {
                            this.toastrService.success(
                                "Site Update created successfully."
                            );
                        });
                }
            });
    }

    editUpdate(siteUpdate: SiteUpdate) {
        const dialogReference = this.dialog.open(EditUpdateDialogComponent, {
            width: window.innerWidth >= 768 ? "600px" : "95%",
            data: { site: this.site, siteUpdate, sewio: this.sewio || false },
        });

        dialogReference
            .afterClosed()
            .pipe(first())
            .subscribe((result) => {
                if (result && this.site) {
                    this.editSiteUpdateGql
                        .mutate(
                            {
                                id: siteUpdate.id,
                                content: JSON.stringify(result.content),
                                title: result.title,
                                opportunityId: result.opportunityId,
                                siteId: this.site.id,
                                internal: result.internal,
                                date: result.date,
                            },
                            {
                                refetchQueries: [SiteUpdatesDocument],
                            }
                        )
                        .pipe(first())
                        .subscribe(() => {
                            this.toastrService.success(
                                "Site Update edited successfully."
                            );
                        });
                }
            });
    }

    deleteUpdate(updateId: string) {
        if (!this.site) {
            return;
        }

        this.deleteSiteUpdateGql
            .mutate(
                { id: updateId, siteId: this.site.id },
                { refetchQueries: [SiteUpdatesDocument] }
            )
            .pipe(first())
            .subscribe(() => {
                this.toastrService.success("Site Update deleted successfully.");
            });
    }
}

import { DateTime } from "luxon";
import { TimeUtils } from "./time.utils";
import { Site } from "../interfaces/gql.interface";
import { DeepPartial } from "../interfaces/partial.interface";

export interface SiteReponseTimes {
    critical: () => string;
    major: () => string;
    minor: () => string;
}

export type SiteSubscriptionState =
    | "danger"
    | "info"
    | "warning"
    | "none"
    | "success";

export interface SiteCalculatedProps {
    subscription: {
        type: SiteSubscriptionState;
        text: string;
    };
    success: string[];
    warning: string[];
    danger: string[];
    info: string[];
    expanded: boolean;
    responseTimes: SiteReponseTimes;
}

export enum SubscriptionTypes {
    "myRTLS Care+" = "myRTLS Care+",
    "myRTLS Care" = "myRTLS Care",
    "NONE" = "NONE",
}

export const SUBSCRIPTION_RESPONSE_TIMES: {
    [key in SubscriptionTypes]: {
        critical: () => string;
        major: () => string;
        minor: () => string;
    };
} = {
    "myRTLS Care+": {
        critical: () =>
            TimeUtils.addBusinessHours(undefined, 4).format(
                "YYYY-MM-DD HH:mm:ss"
            ),
        major: () =>
            TimeUtils.addBusinessDays(undefined, 2)[1].format(
                "YYYY-MM-DD HH:mm:ss"
            ),
        minor: () =>
            TimeUtils.addBusinessDays(undefined, 5)[1].format(
                "YYYY-MM-DD HH:mm:ss"
            ),
    },
    "myRTLS Care": {
        critical: () =>
            TimeUtils.addBusinessDays(undefined, 1)[1].format(
                "YYYY-MM-DD HH:mm:ss"
            ),
        major: () =>
            TimeUtils.addBusinessDays(undefined, 5)[1].format(
                "YYYY-MM-DD HH:mm:ss"
            ),
        minor: () =>
            TimeUtils.addBusinessDays(undefined, 10)[1].format(
                "YYYY-MM-DD HH:mm:ss"
            ),
    },
    NONE: {
        critical: () => "",
        major: () => "",
        minor: () => "",
    },
};

export type CalculatedSite = Site & SiteCalculatedProps;

export class SiteUtils {
    static getCalculatedSite(site: Site): CalculatedSite {
        const subscriptionStates = SiteUtils.getSubscriptionStates(site);

        const invoiceStates = SiteUtils.getInvoiceStates(site);
        const orderStates = SiteUtils.getOrderStates(site);
        const ticketStates = SiteUtils.getTicketStates(site);
        const planStates = SiteUtils.getPlansStates(site);

        const responseTimes = SiteUtils.getResponseTimes(
            site,
            subscriptionStates.subscription.type
        );

        return {
            ...site,
            subscription: subscriptionStates.subscription,
            success: [
                ...subscriptionStates.success,
                ...invoiceStates.success,
                ...orderStates.success,
                ...planStates.success,
            ],
            warning: [
                ...subscriptionStates.warning,
                ...invoiceStates.warning,
                ...ticketStates.warning,
                ...planStates.warning,
            ],
            danger: [
                ...subscriptionStates.danger,
                ...invoiceStates.danger,
                ...ticketStates.danger,
                ...planStates.danger,
            ],
            info: [
                ...subscriptionStates.info,
                ...invoiceStates.info,
                ...orderStates.info,
                ...ticketStates.info,
                ...planStates.info,
            ],
            expanded: false,
            responseTimes,
        };
    }

    static getSubscriptionStates(site: DeepPartial<Site>) {
        const success: string[] = [];
        const warning: string[] = [];
        const danger: string[] = [];
        const info: string[] = [];

        let type: "none" | "info" | "danger" | "warning" | "success" = "none";
        let text = "None";

        if (site.plan && site.planActivation && site.planExpiration) {
            const now = DateTime.now();
            const activation = DateTime.fromISO(site.planActivation);
            const expiration = DateTime.fromISO(site.planExpiration).endOf(
                "day"
            );

            if (now < activation) {
                type = "info";
                info.push("Subscription activation is delayed.");
            } else if (now > expiration) {
                type = "danger";
                danger.push("Subscription has expired.");
            } else if (now > expiration.minus({ week: 1 })) {
                type = "warning";
                warning.push("Subscription will expire soon.");
            } else {
                type = "success";
                success.push("Subscription is active.");
            }

            text = `${site.plan} (${activation.toFormat(
                "DD"
            )} - ${expiration.toFormat("DD")})`;
        }

        return {
            subscription: {
                type,
                text,
            },
            success,
            warning,
            danger,
            info,
        };
    }

    static getInvoiceStates(site: DeepPartial<Site>) {
        const info = [];
        const warning = [];
        const danger = [];
        const success = [];

        if (site.projects) {
            for (const project of site.projects) {
                if (!project || !project.invoices) {
                    continue;
                }

                for (const invoice of project.invoices) {
                    if (
                        !invoice ||
                        !invoice.state ||
                        !invoice.dueDate ||
                        !invoice.number
                    ) {
                        continue;
                    }

                    if (invoice.state === "open") {
                        if (TimeUtils.isDateTodayOrInThePast(invoice.dueDate)) {
                            danger.push(invoice.number);
                        } else if (
                            TimeUtils.isDateLessThanNWeeksInTheFuture(
                                invoice.dueDate,
                                1
                            )
                        ) {
                            warning.push(invoice.number);
                        } else {
                            info.push(invoice.number);
                        }
                    } else if (invoice.state === "paid") {
                        success.push(invoice.number);
                    }
                }
            }
        }

        return {
            info: info.length
                ? [
                      `Invoice${
                          info.length > 1 ? "s" : ""
                      } [${SiteUtils.condenseIds(info)}] ${
                          info.length > 1 ? "are" : "is"
                      } not fully paid.`,
                  ]
                : [],
            warning: warning.length
                ? [
                      `Invoice${
                          warning.length > 1 ? "s" : ""
                      } [${SiteUtils.condenseIds(
                          warning
                      )}] should be paid in less than a week.`,
                  ]
                : [],
            danger: danger.length
                ? [
                      `Invoice${
                          danger.length > 1 ? "s" : ""
                      } [${SiteUtils.condenseIds(danger)}] ${
                          danger.length > 1 ? "are" : "is"
                      } overdue!`,
                  ]
                : [],
            success: success.length
                ? [
                      `Invoice${
                          success.length > 1 ? "s" : ""
                      } [${SiteUtils.condenseIds(success)}] ${
                          success.length > 1 ? "are" : "is"
                      } fully paid.`,
                  ]
                : [],
        };
    }

    static getTicketStates(site: DeepPartial<Site>) {
        const info = [];
        const warning = [];
        const danger = [];

        if (site.projects) {
            for (const project of site.projects) {
                if (!project || !project.tickets) {
                    continue;
                }

                for (const ticket of project.tickets) {
                    if (
                        !ticket ||
                        !ticket.status ||
                        !ticket.priority ||
                        !ticket.id
                    ) {
                        continue;
                    }

                    if (ticket.status === "open") {
                        if (ticket.priority === "critical") {
                            danger.push(ticket.id);
                        } else {
                            warning.push(ticket.id);
                        }
                    } else {
                        info.push(ticket.id);
                    }
                }
            }
        }

        return {
            info: info.length
                ? [
                      `Incident${
                          info.length > 1 ? "s" : ""
                      } [${SiteUtils.condenseIds(info)}] has been resolved.`,
                  ]
                : [],
            warning: warning.length
                ? [
                      `Incident${
                          warning.length > 1 ? "s" : ""
                      } [${SiteUtils.condenseIds(warning)}] ${
                          warning.length > 1 ? "are" : "is"
                      } not resolved.`,
                  ]
                : [],
            danger: danger.length
                ? [
                      `Critical incident${
                          danger.length > 1 ? "s" : ""
                      } [${SiteUtils.condenseIds(danger)}] ${
                          danger.length > 1 ? "are" : "is"
                      } not resolved!`,
                  ]
                : [],
        };
    }

    static getPlansStates(site: DeepPartial<Site>) {
        const info = [];
        const success = [];
        const danger = [];
        const warning = [];

        if (site.projects) {
            for (const project of site.projects) {
                if (!project || !project.plans) {
                    continue;
                }

                for (const plan of project.plans) {
                    if (!plan || !plan.state || !plan.id) {
                        continue;
                    }

                    switch (plan.state) {
                        case "returned":
                            danger.push(plan.id);
                            break;
                        case "draft":
                            info.push(plan.id);
                            break;
                        case "approved":
                            success.push(plan.id);
                            break;
                        case "pending":
                            warning.push(plan.id);
                            break;
                    }
                }
            }
        }

        return {
            info: info.length
                ? [
                      `Plan${
                          info.length > 1 ? "s" : ""
                      } [${SiteUtils.condenseIds(info)}] ${
                          info.length > 1 ? "are" : "is"
                      } in a draft state.`,
                  ]
                : [],
            warning: warning.length
                ? [
                      `Plan${
                          warning.length > 1 ? "s" : ""
                      } [${SiteUtils.condenseIds(warning)}] ${
                          info.length > 1 ? "are" : "is"
                      } pending approval.`,
                  ]
                : [],
            danger: danger.length
                ? [
                      `Plan${
                          danger.length > 1 ? "s" : ""
                      } [${SiteUtils.condenseIds(danger)}] has been rejected!`,
                  ]
                : [],
            success: success.length
                ? [
                      `Plan${
                          success.length > 1 ? "s" : ""
                      } [${SiteUtils.condenseIds(success)}] ${
                          success.length > 1 ? "are" : "is"
                      } approved.`,
                  ]
                : [],
        };
    }

    static getOrderStates(site: DeepPartial<Site>) {
        const success = [];
        const info = [];

        if (site.projects) {
            for (const project of site.projects) {
                if (!project || !project.orders) {
                    continue;
                }

                for (const order of project.orders) {
                    if (!order || !order.state || !order.name) {
                        continue;
                    }

                    if (["payment", "prepare", "sale"].includes(order.state)) {
                        info.push(order.name);
                    } else if (order.state === "done") {
                        success.push(order.name);
                    }
                }
            }
        }

        return {
            info: info.length
                ? [
                      `Order${
                          info.length > 1 ? "s" : ""
                      } [${SiteUtils.condenseIds(info)}] ${
                          info.length > 1 ? "are" : "is"
                      } being processed.`,
                  ]
                : [],
            success: success.length
                ? [
                      `Order${
                          success.length > 1 ? "s" : ""
                      } [${SiteUtils.condenseIds(
                          success
                      )}] has been processed.`,
                  ]
                : [],
        };
    }

    static getResponseTimes(
        site: DeepPartial<Site>,
        state: SiteSubscriptionState
    ) {
        if (
            state === "danger" ||
            state === "none" ||
            typeof site.plan !== "string" ||
            !{}.hasOwnProperty.call(SUBSCRIPTION_RESPONSE_TIMES, site.plan)
        ) {
            return SUBSCRIPTION_RESPONSE_TIMES.NONE;
        }

        return SUBSCRIPTION_RESPONSE_TIMES[site.plan as SubscriptionTypes];
    }

    static condenseIds(ids: string[]) {
        if (ids.length < 10) {
            return ids.join(", ");
        }

        return `${ids.slice(0, 10).join(", ")} and ${ids.length - 10} others`;
    }
}

import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { AuthGuard } from "../../core";
import { ProjectComponent } from "./views/project/project.component";

const projectRoutes: Routes = [
    {
        path: "project/:id",
        redirectTo: "opportunities/:id",
        pathMatch: "full",
    },
    {
        path: "opportunities/:id",
        component: ProjectComponent,
        data: {
            showTopPanel: true,
            showLeftPanel: true,
        },
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(projectRoutes)],
    exports: [RouterModule],
})
export class ProjectRoutes {}

import { Component, ViewChild, ElementRef, Inject } from "@angular/core";
import { Validators, UntypedFormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "portal-rename-plan-dialog",
    styleUrls: ["rename-plan-dialog.component.scss"],
    templateUrl: "rename-plan-dialog.component.html",
})
export class RenamePlanDialogComponent {
    @ViewChild("nameInput", { static: false }) nameInput!: ElementRef;

    nameValidator = new UntypedFormControl("", [Validators.required]);

    constructor(
        public dialogRef: MatDialogRef<RenamePlanDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { name: string }
    ) {
        this.nameValidator.setValue(data.name);
    }

    rename(): void {
        if (this.nameValidator.invalid) {
            this.nameInput.nativeElement.focus();
            return;
        }

        this.dialogRef.close({
            name: this.nameValidator.value,
        });
    }
}

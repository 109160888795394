import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { MenuItemsGQL } from "../queries/gqls";
import { first, map } from "rxjs";

export const menuGuard: CanActivateFn = (route) => {
    const router = inject(Router);

    return inject(MenuItemsGQL)
        .watch()
        .valueChanges.pipe(
            first(),
            map((result) => {
                if (route.url.length === 0) {
                    return false;
                }

                const fallbackRoute =
                    route.url[route.url.length - 1].path === "dashboard"
                        ? router.parseUrl("/login")
                        : router.parseUrl("/dashboard");

                if (route.url.length === 1) {
                    return (
                        result.data.menuItems.find(
                            (item) =>
                                item.slug ===
                                route.url[route.url.length - 1].path
                        ) !== undefined || fallbackRoute
                    );
                } else {
                    const folder = result.data.menuItems.find(
                        (item) =>
                            item.slug === route.url[route.url.length - 2].path
                    );

                    return (
                        folder?.children?.find(
                            (item) =>
                                item.slug ===
                                route.url[route.url.length - 1].path
                        ) !== undefined || fallbackRoute
                    );
                }
            })
        );
};

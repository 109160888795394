import { Component, Input } from "@angular/core";

@Component({
    selector: "portal-section-header[text]",
    templateUrl: "./section-header.component.html",
    styleUrls: [],
})
export class SectionHeaderComponent {
    @Input() text!: string;
    @Input() subtext?: string;
}

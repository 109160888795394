import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";

import { AuthGuard } from "../../core";
import { TrainingsComponent } from "./views/trainings/trainings.component";
import { TrainingResultsComponent } from "./views/training-results/training-results.component";
import { menuGuard } from "../../core/guards/menu.guard";

const trainingsRoutes: Routes = [
    {
        path: "trainings/results",
        component: TrainingResultsComponent,
        data: {
            showTopPanel: true,
            showLeftPanel: true,
        },
        canActivate: [AuthGuard, menuGuard],
    },
    {
        path: "trainings/:name",
        component: TrainingsComponent,
        data: {
            showTopPanel: true,
            showLeftPanel: true,
        },
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(trainingsRoutes)],
    exports: [RouterModule],
})
export class TrainingsRoutes {}

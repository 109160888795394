import { Component, ViewChild, ElementRef, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { first } from "rxjs/operators";
import * as owasp from "owasp-password-strength-test";
import { AuthService } from "../../../../core";

@Component({
    selector: "portal-activate-user",
    templateUrl: "./activate-user.component.html",
    styleUrls: ["./activate-user.component.scss"],
})
export class ActivateUserComponent implements OnInit {
    loading = false;

    email!: string;
    token!: string | null;

    newsletter = true;

    @ViewChild("name", { static: false }) name!: ElementRef;
    @ViewChild("password", { static: false }) password!: ElementRef;
    @ViewChild("confirmPassword", { static: false })
    confirmPassword!: ElementRef;

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private toastrService: ToastrService
    ) {}

    ngOnInit() {
        this.route.queryParamMap.pipe(first()).subscribe((map) => {
            if (!map.has("token")) {
                void this.router.navigate(["/login"]);
                return;
            }

            this.token = map.get("token");
        });
    }

    changePassword() {
        const nameValue = (this.name.nativeElement as HTMLInputElement).value;

        const passwordValue = (this.password.nativeElement as HTMLInputElement)
            .value;

        const confirmPasswordValue = (
            this.confirmPassword.nativeElement as HTMLInputElement
        ).value;

        if (nameValue === "") {
            this.toastrService.error("Full name is required.");
            return;
        }

        if (passwordValue === "") {
            this.toastrService.error("Can't choose empty password.");
            return;
        }

        if (passwordValue !== confirmPasswordValue) {
            this.toastrService.error("Passwords do not match.");
            return;
        }

        if (this.token === null) {
            return;
        }

        owasp.config({
            allowPassphrases: true,
            maxLength: 100,
            minLength: 8,
            minPhraseLength: 20,
            minOptionalTestsToPass: 3,
        });

        const result = owasp.test(passwordValue);

        if (!result.strong) {
            this.toastrService.error(result.errors[0]);
            return;
        }

        this.loading = true;

        this.authService
            .activateAccount(
                this.token,
                nameValue,
                passwordValue,
                this.newsletter
            )
            .pipe(first())
            .subscribe(
                () => {
                    this.toastrService.success(
                        "Account activated. Redirecting to login page."
                    );

                    setTimeout(() => {
                        void this.router.navigate(["/login"]);
                    }, 3000);
                },
                () => {
                    this.toastrService.error(
                        "User token invalid or expired. Please ask the person who invited you to send you a new invitation."
                    );

                    this.loading = false;
                }
            );
    }
}

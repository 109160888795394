export const environment = {
    production: true,
    server: "",
    planner_address: "https://planner.sewio.net",
    sd_address: "https://sd.sewio.net",
    maxFileSize: 104857600,
    trainingUrl: "https://www.classmarker.com/online-test/start/?iframe=1",
    analyticsKey: "G-G81F33NSFG",
    odoo_address: "https://odoo.sewio.net",
};

import { Pipe, PipeTransform } from "@angular/core";
import { EdjsUtils } from "@portal/shared";

@Pipe({ name: "edjsToHtml" })
export class EdjsToHtmlPipe implements PipeTransform {
    transform(value: string) {
        const edjsJsObject = JSON.parse(value);
        return EdjsUtils.toHtml(edjsJsObject.blocks);
    }
}

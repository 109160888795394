<div
    *ngIf="!menuItem.children || menuItem.children.length === 0"
    class="hover:bg-stone-200 cursor-pointer p-2 rounded-md flex gap-2"
    [ngClass]="{
        'bg-sewio-50 text-sewio': selected,
        'text-stone-900': !selected
    }"
    (click)="openPage()"
>
    <portal-icon [icon]="menuItem.icon || 'arrow_right'"></portal-icon>
    <div class="grow flex items-center">
        {{ menuItem.title }}
    </div>
    <portal-icon
        class="text-stone-400"
        *ngIf="menuItem.url && menuItem.url.indexOf('https://') !== -1"
        icon="open_in_new"
    ></portal-icon>
</div>
<ng-container *ngIf="menuItem.children && menuItem.children.length > 0">
    <div
        class="cursor-pointer p-2 flex gap-2 rounded-md hover:bg-stone-200"
        [ngClass]="{
            'text-sewio': selected,
            'rounded-b-none': menuOpen,
            'text-stone-900': !selected
        }"
        (click)="menuOpen = !menuOpen"
    >
        <portal-icon
            [icon]="menuOpen ? 'expand_more' : 'expand_less'"
        ></portal-icon>
        <div class="flex items-center">{{ menuItem.title }}</div>
    </div>
    <div
        class="rounded-b-lg border-t bg-stone-100 p-2 flex flex-col gap-1"
        [ngClass]="{ hidden: !menuOpen }"
    >
        <portal-menu-item
            *ngFor="let child of menuItem.children"
            [menuItem]="child"
        ></portal-menu-item>
    </div>
</ng-container>

<div mat-dialog-title class="title">Create Plan</div>
<div mat-dialog-content>
    <div class="form pt-2">
        <mat-form-field>
            <mat-label>Opportunity</mat-label>
            <mat-select #opportunitySelect [formControl]="opportunityValidator">
                <mat-option
                    *ngFor="let project of data.projects"
                    [value]="project.id"
                >
                    [{{ project.id }}] {{ project.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <input
                #nameInput
                matInput
                placeholder="Plan Name"
                [formControl]="nameValidator"
                required
            />
        </mat-form-field>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CANCEL</button>
    <button mat-button color="primary" cdkFocusInitial (click)="create()">
        CREATE
    </button>
</mat-dialog-actions>

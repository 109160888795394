<div class="table-container mat-elevation-z2">
    <table
        *ngIf="tickets.length !== 0"
        class="tickets-table"
        mat-table
        [dataSource]="dataSource"
        matSort
    >
        <ng-container matColumnDef="number">
            <th
                class="number-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Ticket
            </th>
            <td class="number-column" mat-cell *matCellDef="let row">
                {{ row.number }}
            </td>
        </ng-container>

        <ng-container matColumnDef="opportunity">
            <th
                class="opportunity-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Opportunity
            </th>
            <td class="opportunity-column" mat-cell *matCellDef="let row">
                <span *ngIf="row.projectId !== null">
                    [{{ row.projectId }}]
                </span>
                {{ opportunityIdToName(row.projectId) }}
            </td>
        </ng-container>

        <ng-container matColumnDef="subject">
            <th
                class="subject-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Subject
            </th>
            <td class="subject-column" mat-cell *matCellDef="let row">
                {{ row.subject }}
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th
                class="status-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Status
            </th>
            <td class="status-column" mat-cell *matCellDef="let row">
                {{ row.status }}
            </td>
        </ng-container>

        <ng-container matColumnDef="from">
            <th
                class="from-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                From
            </th>
            <td class="from-column" mat-cell *matCellDef="let row">
                {{ row.from }}
            </td>
        </ng-container>

        <ng-container matColumnDef="priority">
            <th
                class="priority-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Priority
            </th>
            <td class="priority-column" mat-cell *matCellDef="let row">
                {{ row.priority }}
            </td>
        </ng-container>

        <ng-container matColumnDef="type">
            <th
                class="type-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Type
            </th>
            <td class="type-column" mat-cell *matCellDef="let row">
                {{ row.type }}
            </td>
        </ng-container>

        <ng-container matColumnDef="updated">
            <th
                class="updated-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Last Update
            </th>
            <td class="updated-column" mat-cell *matCellDef="let row">
                {{ row.updatedString }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="cursor-pointer hover:bg-stone-50"
            (click)="openTicket({ id: row.id, from: row.from })"
            [ngClass]="{
                'sewio-red-background':
                    row.status === 'open' &&
                    row.priority === 'critical' &&
                    row.type === 'incident',
                'sewio-orange-background':
                    row.status === 'open' &&
                    row.priority !== 'critical' &&
                    row.type === 'incident',
                'sewio-green-background': row.status === 'closed'
            }"
        ></tr>
    </table>

    <div class="none-row" *ngIf="tickets.length === 0">
        <portal-status
            [text]="'None'"
            [type]="'none'"
            [big]="true"
        ></portal-status>
    </div>
</div>

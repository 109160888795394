<div class="w-full h-full flex items-center justify-center">
    <mat-card *ngIf="sent">
        <mat-card-content class="login-content">
            <div class="login-header">
                <img class="login-logo" src="./assets/header-logo.svg" />
            </div>
            <div class="margin text-sewio">
                Reset link has been sent to your email.
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card *ngIf="!sent">
        <div *ngIf="loading" class="loading">
            <mat-spinner [diameter]="50" [strokeWidth]="5"></mat-spinner>
        </div>
        <mat-card-content class="login-content">
            <div class="login-header">
                <img class="login-logo" src="./assets/header-logo.svg" />
            </div>
            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input #email matInput />
            </mat-form-field>
            <button
                class="login-button"
                mat-flat-button
                color="primary"
                (click)="sendLink()"
            >
                RESET PASSWORD
            </button>
        </mat-card-content>
    </mat-card>
</div>

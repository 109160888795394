<mat-card>
    <mat-card-content class="company-card">
        <h1 class="text-2xl font-bold mb-2">
            {{ company.name }}
        </h1>
        <br />
        <div>
            <b>My Account Manager: </b><br />
            {{ company.salesPersonName }}
            <portal-link [href]="'mailto:' + company.salesPersonEmail"
                ><{{ company.salesPersonEmail }}></portal-link
            >, {{ company.salesPersonPhone }}
        </div>
        <div *ngIf="company.admin">
            <b>My Admin: </b><br />
            {{ company.admin }}
        </div>
        <div
            class="flex"
            *ngIf="company.level === 'prospect' || company.level === 'partner'"
        >
            <b>My Level: </b>
            <ng-container *ngIf="partnerStatus.currentLevel">
                <portal-level
                    [type]="getLevelType(partnerStatus.currentLevel)"
                ></portal-level>
            </ng-container>
        </div>
        <div *ngIf="company.ndaFrom">
            <b>Non-disclosure Agreement (NDA): </b><br />
            From {{ company.ndaFrom }}
        </div>
        <div *ngIf="!company.ndaFrom">
            <b>Non-disclosure Agreement (NDA): </b><br />
            <portal-icon icon="close" class="text-error-dark"></portal-icon>
        </div>
        <div>
            <b>Invoicing In Last 12 Months: </b><br />
            {{ company.invoicingFy | round }} EUR
        </div>
        <div *ngIf="lastBusinessReview">
            <b>Last Business Review: </b><br />
            <div class="flex flex-row items-center">
                <portal-icon
                    *ngIf="lastBusinessReview.icon"
                    [icon]="lastBusinessReview.icon"
                    [matTooltip]="lastBusinessReview.tooltip"
                    [ngClass]="lastBusinessReview.color"
                ></portal-icon>
                <portal-link href="/business_reviews">{{
                    lastBusinessReview.date
                }}</portal-link>
            </div>
        </div>
        <div
            *ngIf="company.level === 'prospect' || company.level === 'partner'"
        >
            <b>My Level Progress: </b><br />
            <portal-link href="/partner_status">See the details</portal-link>
        </div>
        <div *ngIf="players.length > 0">
            <b>Currently Used RTLS Player Instances: </b><br />
            <span>{{ players.length }}</span>
        </div>
    </mat-card-content>
</mat-card>

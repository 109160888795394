import { Component, OnInit, OnDestroy } from "@angular/core";
import { Article, ArticleGQL } from "../../../../core";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { TimeUtils } from "@portal/shared";

@Component({
    selector: "portal-article",
    templateUrl: "./article.component.html",
    styleUrls: ["./article.component.scss"],
})
export class ArticleComponent implements OnInit, OnDestroy {
    article: Article | null = null;
    loading = true;

    private subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private articleGql: ArticleGQL
    ) {}

    ngOnInit(): void {
        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                this.subscriptions.push(
                    this.articleGql
                        .watch({ slug: params["slug"] })
                        .valueChanges.subscribe((values) => {
                            this.article = values.data.article;
                            this.loading = false;
                        })
                );
            })
        );
    }

    ngOnDestroy(): void {
        while (this.subscriptions.length) {
            this.subscriptions.pop()?.unsubscribe();
        }
    }

    formatDate(date: string): string {
        return TimeUtils.formatDateToReadableString(date);
    }
}

<mat-card class="mat-elevation-z2 storage-container p-4">
    <div class="headline">
        <fa-icon class="files-icon" [icon]="['fas', 'hdd']"></fa-icon>
        Project Storage
    </div>

    <portal-explorer
        *ngIf="flatFolders.length > 0 && folders !== null && files !== null"
        class="explorer"
        [folders]="folders"
        [files]="files"
        (deleteFolder)="deleteFolder.emit($event)"
        (deleteFile)="deleteFile.emit($event)"
        (openFileRequest)="openFileRequest.emit()"
    ></portal-explorer>
    <div class="center" *ngIf="flatFolders.length === 0">
        <portal-status
            [type]="'warning'"
            [text]="'Storage is currently unavailable.'"
            [big]="true"
        ></portal-status>
    </div>
</mat-card>

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Token } from "@portal/shared";
import { BehaviorSubject, Observable } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { environment } from "../../../environments/environment";

export type TokenSubject = Token | null;

@Injectable()
export class AuthService {
    private tokenSubject: BehaviorSubject<TokenSubject>;

    token$!: Observable<TokenSubject>;

    constructor(
        private http: HttpClient,
        private cookieService: CookieService
    ) {
        try {
            const token = localStorage.getItem("token");

            if (!token) {
                this.tokenSubject = new BehaviorSubject<TokenSubject>(null);
                return;
            }

            this.tokenSubject = new BehaviorSubject<TokenSubject>(
                JSON.parse(token)
            );
        } catch (error) {
            console.error("Could not parse stored JWT. Resetting...");
            localStorage.removeItem("token");
            this.tokenSubject = new BehaviorSubject<TokenSubject>(null);
        }

        this.token$ = this.tokenSubject.asObservable();
    }

    get token(): TokenSubject {
        return this.tokenSubject.value;
    }

    logIn(email: string, password: string) {
        return this.http
            .post<Token>(environment.server + "/auth", {
                email,
                password,
            })
            .pipe(
                map((response) => {
                    if (response && response.token) {
                        localStorage.setItem("token", JSON.stringify(response));
                        this.cookieService.set(
                            "portal_token",
                            JSON.stringify(response),
                            1,
                            "/",
                            "sewio.net",
                            true
                        );
                        this.tokenSubject.next(response);
                        return true;
                    }

                    return false;
                })
            );
    }

    logOut() {
        localStorage.removeItem("token");
        this.cookieService.delete("portal_token", "/", "sewio.net");
        this.tokenSubject.next(null);
    }

    resetPassword(email: string) {
        return this.http.post(environment.server + "/auth/reset", {
            email,
        });
    }

    changePassword(email: string, token: string, password: string) {
        return this.http.post(environment.server + "/auth/new_password", {
            email,
            token,
            password,
        });
    }

    activateAccount(
        token: string,
        name: string,
        password: string,
        newsletter: boolean
    ) {
        return this.http.post(environment.server + "/auth/activate_user", {
            token,
            name,
            password,
            newsletter,
        });
    }
}

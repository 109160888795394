import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";

export enum Layouts {
    ContentOnly,
    Full,
}

@Component({
    selector: "portal-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
    showTopPanel = false;
    showLeftPanel = false;

    @ViewChild("content", { static: false }) content!: ElementRef;

    constructor(private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.showTopPanel =
                    this.route.firstChild?.snapshot.data["showTopPanel"] !==
                    false;
                this.showLeftPanel =
                    this.route.firstChild?.snapshot.data["showLeftPanel"] !==
                    false;

                this.content.nativeElement.scrollTop = 0;
            }
        });
    }
}

import { Component, ViewChild, ElementRef } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "portal-add-business-review-dialog",
    styleUrls: ["add-business-review-dialog.component.scss"],
    templateUrl: "add-business-review-dialog.component.html",
})
export class AddBusinessReviewDialogComponent {
    @ViewChild("descriptionInput", { static: false })
    descriptionInput!: ElementRef;

    descriptionValidator = new UntypedFormControl("", [Validators.required]);

    constructor(
        public dialogRef: MatDialogRef<AddBusinessReviewDialogComponent>
    ) {}

    add(): void {
        if (this.descriptionValidator.invalid) {
            this.descriptionInput.nativeElement.focus();
            return;
        }

        this.dialogRef.close({
            description: this.descriptionInput.nativeElement.value,
        });
    }
}

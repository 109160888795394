import { NgModule } from "@angular/core";
import { PartnersComponent } from "./views/partners.component";
import { SharedModule } from "../../shared";
import { PartnersRoutes } from "./partners.routes";
import { PartnerListComponent } from "./components/partner-list/partner-list.component";

@NgModule({
    imports: [SharedModule, PartnersRoutes],
    declarations: [PartnersComponent, PartnerListComponent],
    providers: [],
})
export class PartnersModule {}

import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import {
    CompanyQuery,
    ProjectsQuery,
    ArticlesQuery,
    PlayersQuery,
    PartnerStatus,
    CompanyGQL,
    ProjectsGQL,
    PartnerStatusGQL,
    PlayersGQL,
    ArticlesGQL,
} from "../../../../core";

@Component({
    selector: "portal-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, OnDestroy {
    company: CompanyQuery["company"] | null = null;
    projects: ProjectsQuery["projects"] | null = null;
    articles: ArticlesQuery["articles"] | null = null;
    players: PlayersQuery["players"] | null = null;
    partnerStatus: PartnerStatus | null = null;

    private subscriptions: Subscription[] = [];

    constructor(
        private companyGql: CompanyGQL,
        private projectsGql: ProjectsGQL,
        private partnerStatusGql: PartnerStatusGQL,
        private playersGql: PlayersGQL,
        private articlesGql: ArticlesGQL,
        private router: Router
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.companyGql.watch().valueChanges.subscribe((result) => {
                this.company = result.data.company;
            }),
            this.projectsGql.watch().valueChanges.subscribe((result) => {
                this.projects = result.data.projects;
            }),
            this.articlesGql.watch().valueChanges.subscribe((result) => {
                this.articles = result.data.articles;
            }),
            this.playersGql.watch().valueChanges.subscribe((result) => {
                this.players = result.data.players;
            }),
            this.partnerStatusGql.watch().valueChanges.subscribe((result) => {
                this.partnerStatus = result.data.partnerStatus;
            })
        );
    }

    ngOnDestroy() {
        while (this.subscriptions.length) {
            this.subscriptions.pop()?.unsubscribe();
        }
    }

    showAllNews() {
        void this.router.navigate(["/articles"]);
    }
}

<portal-loading *ngIf="articles === null" [height]="112"></portal-loading>
<div
    *ngIf="articles !== null"
    class="flex flex-col md:container md:mx-auto max-w-screen-lg gap-2"
>
    <portal-article-preview
        class="max-w-5xl"
        *ngFor="let article of articles | sortArticlesByDate"
        [article]="article"
    ></portal-article-preview>
</div>

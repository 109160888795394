import { Injectable } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { environment } from "../../../environments/environment";
import { ToastrService } from "ngx-toastr";
import { first } from "rxjs/operators";

declare const gtag: (
    a: string,
    b: string,
    c: { [key: string]: string }
) => void;

@Injectable()
export class AnalyticsService {
    constructor(private router: Router, private toastrService: ToastrService) {
        const scriptA = document.createElement("script");
        scriptA.async = true;
        scriptA.src = `https://www.googletagmanager.com/gtag/js?id=${environment.analyticsKey}`;

        document.head.appendChild(scriptA);

        const scriptB = document.createElement("script");
        scriptB.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${environment.analyticsKey}');
        `;

        document.head.appendChild(scriptB);

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                gtag("event", "page_view", {
                    page_path: event.urlAfterRedirects,
                    page_location: document.location.href,
                });
            }
        });

        const cookieApproval = localStorage.getItem("cookieApproval");

        if (cookieApproval !== "approved") {
            this.showCookieApproval();
        }
    }

    setUser(userId: string) {
        gtag("config", "GA_MEASURMENT_ID", { user_id: userId });
    }

    showCookieApproval() {
        this.toastrService
            .info(
                "We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.",
                "",
                { disableTimeOut: true }
            )
            .onTap.pipe(first())
            .subscribe(() => {
                localStorage.setItem("cookieApproval", "approved");
            });
    }
}

import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared";
import { ArticlesComponent } from "./views/articles/articles.component";
import { ArticleComponent } from "./views/article/article.component";
import { ArticlesRoutes } from "./articles.routes";

@NgModule({
    imports: [SharedModule, ArticlesRoutes],
    declarations: [ArticlesComponent, ArticleComponent],
    providers: [],
})
export class ArticlesModule {}

<div mat-dialog-title class="title">Register New Project</div>
<mat-horizontal-stepper #stepper [linear]="true">
    <ng-template matStepperIcon="done">
        <fa-icon class="stepper-icon" [icon]="['fas', 'check']"></fa-icon>
    </ng-template>
    <ng-template matStepperIcon="edit">
        <fa-icon class="stepper-icon" [icon]="['fas', 'ellipsis-h']"></fa-icon>
    </ng-template>
    <mat-step
        #stepOne
        [completed]="stepOneComplete"
        [editable]="stepOneEditable"
        label="Qualification"
    >
    </mat-step>
    <mat-step
        #stepTwo
        label="Discovery"
        [completed]="stepTwoComplete"
        [editable]="stepTwoEditable"
    >
    </mat-step>
    <mat-step #stepThree
        label="Notes & Updates"
        [completed]="false"
        [editable]="stepThreeEditable"
    > </mat-step>
</mat-horizontal-stepper>
<div mat-dialog-content>
    <div [hidden]="stepOneComplete">
        <div class="form">
            <mat-form-field>
                <input
                    #nameInput
                    matInput
                    placeholder="Project Name (generated automatically)"
                    [value]="
                        (
                            [
                                customerInput.value,
                                cityInput.value,
                                shortInput.value
                            ] | removeEmptyFromArray
                        ).join(', ')
                    "
                    disabled
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    #customerInput
                    matInput
                    placeholder="End Customer (used in name)"
                    [formControl]="customerValidator"
                    required
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    #cityInput
                    matInput
                    placeholder="City of Installation (used in name)"
                    [formControl]="cityValidator"
                    required
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    #countryInput
                    matInput
                    placeholder="Country of Installation"
                    aria-label="Country of Installation"
                    [matAutocomplete]="auto"
                    [formControl]="countryValidator"
                    required
                />
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option
                        *ngFor="let country of filteredCountries | async"
                        [value]="country.name"
                    >
                        {{ country.name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field>
                <input
                    #shortInput
                    matInput
                    placeholder="Customer Reference (used in name)"
                    [formControl]="shortValidator"
                    required
                />
            </mat-form-field>
            <mat-form-field>
                <mat-label>Use Case</mat-label>
                <mat-select
                    #useCaseSelect
                    [formControl]="useCaseTypeValidator"
                    required
                >
                    <mat-option
                        *ngFor="let useCase of useCases"
                        [value]="useCase.value"
                    >
                        {{ useCase.text }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="small-bottom-offset">
                <textarea
                    #problemInput
                    class="description"
                    matInput
                    placeholder="Customer Problem"
                    [formControl]="problemValidator"
                    oninput='this.style.height = ""; this.style.height = this.scrollHeight + "px"'
                    required
                ></textarea>
                <mat-hint align="start" class="hint">
                    What is the customer’s problem? What is the situation they
                    are dealing with?
                </mat-hint>
            </mat-form-field>
            <mat-form-field class="small-bottom-offset">
                <textarea
                    #solutionInput
                    class="description"
                    matInput
                    placeholder="Sewio Solution"
                    [formControl]="solutionValidator"
                    oninput='this.style.height = ""; this.style.height = this.scrollHeight + "px"'
                    required
                ></textarea>
                <mat-hint align="start" class="hint">
                    How will the customer benefit from using Sewio?
                </mat-hint>
            </mat-form-field>
            <div class="datepicker-container">
                <mat-form-field class="revenue">
                    <input
                        #revenueInput
                        matInput
                        placeholder="Expected Revenue"
                        [formControl]="revenueValidator"
                        type="number"
                        step="500"
                        required
                    />
                    <span matSuffix>EUR</span>
                </mat-form-field>
                <div class="datepicker-container">
                    <mat-form-field class="date">
                        <input
                            #datepickerInput
                            matInput
                            [matDatepicker]="closingdatepicker"
                            placeholder="Closing Date"
                            readonly
                            required
                            (click)="closingdatepicker.open()"
                        />
                        <mat-datepicker
                            #closingdatepicker
                            disabled="false"
                        ></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <portal-status
                [text]="
                    'By submitting the form you are registering the project. To be eligible for the discount, the project has to be approved by your Account Manager.'
                "
                [type]="'warning'"
            >
            </portal-status>
        </div>
    </div>
    <div>
        <div [hidden]="!stepOneComplete || stepTwoComplete">
            <div class="form">
                <mat-form-field class="large-bottom-offset">
                    <textarea
                        #trackedInput
                        class="description"
                        matInput
                        placeholder="Tracked Objects"
                        oninput='this.style.height = ""; this.style.height = this.scrollHeight + "px"'
                    ></textarea>
                    <mat-hint align="start" class="hint">
                        What is the object of tracking? Are there more various
                        objects? Please upload photos to Project Storage if
                        possible.
                    </mat-hint>
                </mat-form-field>
                <mat-form-field class="small-bottom-offset">
                    <input
                        #countInput
                        matInput
                        placeholder="Number of Objects"
                        type="number"
                        step="1"
                    />
                    <mat-hint align="start" class="hint">
                        How many objects should be tracked?
                    </mat-hint>
                </mat-form-field>
                <mat-form-field class="small-bottom-offset">
                    <textarea
                        #mountingInput
                        class="description"
                        matInput
                        placeholder="Tag Mounting"
                        oninput='this.style.height = ""; this.style.height = this.scrollHeight + "px"'
                    ></textarea>
                    <mat-hint align="start" class="hint">
                        How the Tag will be mounted on the object?
                    </mat-hint>
                </mat-form-field>
                <mat-form-field class="small-bottom-offset">
                    <input #updateInput matInput placeholder="Refresh Rate" />
                    <mat-hint align="start" class="hint">
                        How often you need location data? (e.g. 100ms, 2s, 30s,
                        every minute, hour...)
                    </mat-hint>
                </mat-form-field>
                <mat-form-field class="small-bottom-offset">
                    <textarea
                        #accuracyInput
                        class="description"
                        matInput
                        placeholder="Accuracy"
                        oninput='this.style.height = ""; this.style.height = this.scrollHeight + "px"'
                    ></textarea>
                    <mat-hint align="start" class="hint">
                        What is the desired accuracy and why?
                    </mat-hint>
                </mat-form-field>
                <mat-form-field class="large-bottom-offset">
                    <input #batteryInput matInput placeholder="Battery Life" />
                    <mat-hint align="start" class="hint">
                        How long the battery in tags should last?
                    </mat-hint>
                </mat-form-field>
                <div class="small-bottom-offset">
                    <mat-checkbox [(ngModel)]="zAxis"
                        >3D Localization</mat-checkbox
                    ><br />
                    <mat-hint align="start" class="hint">
                        Do you need planar localization in the form of X, Y or
                        3D localization is required (+ Z axis)?
                    </mat-hint>
                </div>
                <portal-status
                    [text]="
                        'Please provide us with the floorplan with highlighted zones
                of tracking and also actual photographs of the area in
                Project Storage.'
                    "
                    [type]="'info'"
                >
                </portal-status>
            </div>
        </div>
    </div>
    <div>
        <div [hidden]="!stepTwoComplete">
            <div class="form">
                <mat-form-field>
                    <textarea
                        #descriptionInput
                        class="description"
                        matInput
                        placeholder="Notes & Updates"
                        oninput='this.style.height = ""; this.style.height = this.scrollHeight + "px"'
                    ></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CANCEL</button>
    <button *ngIf="stepOneComplete" mat-button (click)="back()">
        BACK
    </button>
    <button
        *ngIf="!stepOneComplete || !stepTwoComplete"
        mat-button
        color="primary"
        cdkFocusInitial
        (click)="next()"
    >
        NEXT
    </button>
    <button
        *ngIf="stepOneComplete && stepTwoComplete"
        mat-button
        color="primary"
        cdkFocusInitial
        (click)="register()"
    >
        REGISTER
    </button>
</mat-dialog-actions>

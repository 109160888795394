import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ActivityFileUrlGQL } from "../../../../core";
import { first } from "rxjs/operators";

@Component({
    selector: "portal-download",
    templateUrl: "./download.component.html",
    styleUrls: ["./download.component.scss"],
})
export class DownloadComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private activityFileUrlGql: ActivityFileUrlGQL
    ) {}

    ngOnInit() {
        const type = this.route.snapshot.queryParamMap.get("type");
        const link = this.route.snapshot.queryParamMap.get("link");

        if (!type || !link) {
            void this.router.navigate(["/dashboard"]);
            return;
        }

        switch (type) {
            case "activity":
                this.downloadActivity(link);
                break;
            default:
                void this.router.navigate(["/dashboard"]);
                return;
        }
    }

    downloadActivity(link: string) {
        this.activityFileUrlGql
            .watch({ path: link })
            .valueChanges.pipe(first())
            .subscribe((result) => {
                window.location.href = result.data.activityFileUrl;
            });
    }
}

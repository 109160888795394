import {
    Component,
    ViewChild,
    ElementRef,
    Inject,
    OnInit,
} from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "portal-edit-business-review-dialog",
    styleUrls: ["edit-business-review-dialog.component.scss"],
    templateUrl: "edit-business-review-dialog.component.html",
})
export class EditBusinessReviewDialogComponent implements OnInit {
    @ViewChild("descriptionInput", { static: false })
    descriptionInput!: ElementRef;

    descriptionValidator = new UntypedFormControl("", [Validators.required]);

    constructor(
        public dialogRef: MatDialogRef<EditBusinessReviewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { description: string }
    ) {
        this.descriptionValidator.setValue(data.description);
    }

    ngOnInit() {
        setTimeout(() => {
            this.descriptionInput.nativeElement.style.height = "";
            this.descriptionInput.nativeElement.style.height =
                this.descriptionInput.nativeElement.scrollHeight + "px";
        }, 100);
    }

    edit(): void {
        if (this.descriptionValidator.invalid) {
            this.descriptionInput.nativeElement.focus();
            return;
        }

        this.dialogRef.close({
            description: this.descriptionInput.nativeElement.value,
        });
    }
}

import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { EditDialogComponent } from "../../components/edit-dialog/edit-dialog.component";
import { ToastrService } from "ngx-toastr";
import _ from "lodash";
import { environment } from "../../../../../environments/environment";
import {
    ProjectQuery,
    ProjectGQL,
    EditProjectGQL,
    UserGQL,
    ProjectDocument,
    CreateFolderGQL,
    DeleteFolderGQL,
    DeleteFileGQL,
    ProjectQueryVariables,
    Project,
    CompanyGQL,
    SetCompanyGQL,
} from "../../../../core";
import { CreateFolderDialogComponent } from "../../components/create-folder-dialog/create-folder-dialog.component";
import { DeleteFolderDialogComponent } from "../../components/delete-folder-dialog/delete-folder-dialog.component";
import { UploadFileDialogComponent } from "../../components/upload-file-dialog/upload-file-dialog.component";
import { DeleteFileDialogComponent } from "../../components/delete-file-dialog/delete-file-dialog.component";
import { Apollo, QueryRef } from "apollo-angular";
import { first } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { UploadService } from "../../../../core/services/upload.service";
import { PlanListComponent } from "../../../../shared/components/plan-list/plan-list.component";
import { TicketListComponent } from "../../../../shared/components/ticket-list/ticket-list.component";
import { Company } from "@portal/shared";

@Component({
    selector: "portal-project",
    templateUrl: "./project.component.html",
    styleUrls: ["./project.component.scss"],
})
export class ProjectComponent implements OnInit, OnDestroy {
    @ViewChild("planList") planList?: PlanListComponent;
    @ViewChild("ticketList") ticketList?: TicketListComponent;

    project: Project | null = null;
    sewio: boolean | null = null;
    company: Company | null = null;
    userEmail = "";

    loading = 1;
    uploading = 100;

    private subscriptions: Subscription[] = [];
    private projectWatch!: QueryRef<ProjectQuery, ProjectQueryVariables>;

    constructor(
        private projectGql: ProjectGQL,
        private editProjectGql: EditProjectGQL,
        private userGql: UserGQL,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private toastrService: ToastrService,
        private createFolderGql: CreateFolderGQL,
        private deleteFolderGql: DeleteFolderGQL,
        private deleteFileGql: DeleteFileGQL,
        private setCompanyGql: SetCompanyGQL,
        private companyGql: CompanyGQL,
        private uploadService: UploadService,
        private apollo: Apollo
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                this.projectWatch = this.projectGql.watch({
                    projectId: params["id"],
                });

                this.subscriptions.push(
                    this.projectWatch.valueChanges.subscribe((value) => {
                        this.project = value.data.project;

                        if (this.loading > 0) {
                            this.loading--;
                        }

                        this.setCompany();
                    }),
                    this.userGql.watch().valueChanges.subscribe((value) => {
                        this.sewio = value.data.user.sewio;
                        this.userEmail = value.data.user.email;
                        this.setCompany();
                    }),
                    this.companyGql.watch().valueChanges.subscribe((value) => {
                        this.company = value.data.company;
                        this.setCompany();
                    })
                );
            })
        );
    }

    ngOnDestroy() {
        while (this.subscriptions.length) {
            this.subscriptions.pop()?.unsubscribe();
        }
    }

    setCompany() {
        if (this.sewio && this.project && this.company) {
            if (this.project.partnerId !== this.company.id) {
                this.setCompanyGql
                    .mutate(
                        { id: this.project.partnerId },
                        {
                            update: () => {
                                this.apollo.client.resetStore();
                            },
                        }
                    )
                    .subscribe()
                    .unsubscribe();
            }
        }
    }

    openFileRequest() {
        //empty
    }

    createPlan() {
        if (this.planList && this.project) {
            this.planList.createPlan([this.project]);
        }
    }

    createTicket() {
        if (this.ticketList && this.project) {
            this.ticketList.createTicket([this.project]);
        }
    }

    editProject() {
        if (!this.project) {
            return;
        }

        const dialogReference = this.dialog.open(EditDialogComponent, {
            width: window.innerWidth >= 768 ? "600px" : "95%",
            data: {
                name: this.project.name,
                customer: this.project.customer,
                problem: this.project.problem,
                solution: this.project.solution,
                useCase: this.project.useCase,
                revenue: this.project.revenue,
                closing: this.project.deadline,
                description: this.project.description,
                country: this.project.country,
                trackedObjects: this.project.trackedObjects,
                numberOfObjects: this.project.numberOfObjects,
                tagMounting: this.project.tagMounting,
                refreshRate: this.project.refreshRate,
                accuracy: this.project.accuracy,
                batteryLife: this.project.batteryLife,
                zAxis: this.project.zAxis,
            },
        });

        dialogReference
            .afterClosed()
            .pipe(first())
            .subscribe((result) => {
                if (result && this.project) {
                    this.loading++;
                    this.editProjectGql
                        .mutate(
                            {
                                id: this.project.id,
                                name: result.name,
                                closing: result.closing,
                                customer: result.customer,
                                useCase: result.useCase,
                                problem: result.problem,
                                solution: result.solution,
                                revenue: result.revenue,
                                description: result.description,
                                accuracy: result.accuracy,
                                batteryLife: result.batteryLife,
                                country: result.country,
                                numberOfObjects: result.numberOfObjects,
                                refreshRate: result.refreshRate,
                                tagMounting: result.tagMounting,
                                trackedObjects: result.trackedObjects,
                                zAxis: result.zAxis,
                            },
                            {
                                update: (store, { data }) => {
                                    if (!this.project || !data) {
                                        return;
                                    }

                                    this.toastrService.success(
                                        "Project edited."
                                    );

                                    store.writeQuery({
                                        query: ProjectDocument,
                                        variables: {
                                            id: this.project.id,
                                        },
                                        data: {
                                            project: data.editProject,
                                        },
                                    });
                                },
                            }
                        )
                        .subscribe()
                        .unsubscribe();
                }
            });
    }

    uploadFile() {
        if (!this.project) {
            return;
        }

        const dialogReference = this.dialog.open(UploadFileDialogComponent, {
            width: window.innerWidth >= 768 ? "600px" : "300px",
            data: [
                { value: "", viewValue: "/" },
                ...this.project.folders
                    .filter((folder) => {
                        if (
                            folder.root ||
                            folder.name.charAt(0) === "$" ||
                            folder.name.match(
                                /^\$?[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/
                            )
                        ) {
                            return false;
                        }

                        return true;
                    })
                    .map((folder) => ({
                        value: folder.path,
                        viewValue: folder.path + "/",
                    })),
            ],
        });

        dialogReference
            .afterClosed()
            .pipe(first())
            .subscribe((result) => {
                if (result && this.project) {
                    this.loading++;
                    this.uploading = 0;

                    const uploadSubscription = this.uploadService
                        .upload(result.files, this.project.id, result.parent)
                        .subscribe((data) => {
                            switch (data.status) {
                                case "progress":
                                    this.uploading = data.message;
                                    break;
                                case "done":
                                    uploadSubscription.unsubscribe();
                                    this.uploading = data.message;
                                    void this.projectWatch
                                        .refetch()
                                        .then(() => {
                                            this.toastrService.success(
                                                "File Uploaded"
                                            );
                                        });
                                    break;
                            }
                        });
                }
            });
    }

    createFolder() {
        if (!this.project) {
            return;
        }

        const dialogReference = this.dialog.open(CreateFolderDialogComponent, {
            width: window.innerWidth >= 768 ? "600px" : "300px",
            data: [
                { value: "", viewValue: "/" },
                ...this.project.folders
                    .filter((folder) => {
                        if (
                            folder.root ||
                            folder.name.charAt(0) === "$" ||
                            folder.name.match(
                                /^\$?[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/
                            )
                        ) {
                            return false;
                        }

                        return true;
                    })
                    .map((folder) => ({
                        value: folder.path,
                        viewValue: folder.path + "/",
                    })),
            ],
        });

        dialogReference
            .afterClosed()
            .pipe(first())
            .subscribe((result) => {
                if (result && this.project) {
                    this.loading++;
                    this.createFolderGql
                        .mutate(
                            {
                                id: this.project.id,
                                path: result.parent,
                                name: result.name,
                            },
                            {
                                update: (store, { data }) => {
                                    this.loading--;

                                    if (!data || !this.project) {
                                        return;
                                    }

                                    this.toastrService.success(
                                        "Folder created."
                                    );

                                    const clonedData = _.cloneDeep(
                                        store.readQuery<ProjectQuery>({
                                            query: ProjectDocument,
                                            variables: {
                                                projectId: this.project.id,
                                            },
                                        })
                                    );

                                    if (clonedData === null) {
                                        return;
                                    }

                                    clonedData.project.folders =
                                        data.createFolder;

                                    store.writeQuery({
                                        query: ProjectDocument,
                                        variables: {
                                            projectId: this.project.id,
                                        },
                                        data: clonedData,
                                    });
                                },
                            }
                        )
                        .pipe(first())
                        .subscribe();
                }
            });
    }

    deleteFolder(folder: { path: string; name: string }) {
        const dialogReference = this.dialog.open(DeleteFolderDialogComponent, {
            width: "300px",
            data: folder.name,
        });

        dialogReference
            .afterClosed()
            .pipe(first())
            .subscribe((result) => {
                if (result && this.project) {
                    this.loading++;
                    this.deleteFolderGql
                        .mutate(
                            {
                                id: this.project.id,
                                path: folder.path,
                            },
                            {
                                update: (store, { data }) => {
                                    if (!data || !this.project) {
                                        return;
                                    }

                                    this.toastrService.success(
                                        "Folder deleted."
                                    );

                                    const clonedData = _.cloneDeep(
                                        store.readQuery<ProjectQuery>({
                                            query: ProjectDocument,
                                            variables: {
                                                projectId: this.project.id,
                                            },
                                        })
                                    );

                                    if (clonedData === null) {
                                        return;
                                    }

                                    clonedData.project.folders =
                                        data.deleteFolder;

                                    store.writeQuery({
                                        query: ProjectDocument,
                                        variables: {
                                            projectId: this.project.id,
                                        },
                                        data: clonedData,
                                    });
                                },
                            }
                        )
                        .subscribe()
                        .unsubscribe();
                }
            });
    }

    deleteFile(file: { path: string; name: string }) {
        const dialogReference = this.dialog.open(DeleteFileDialogComponent, {
            width: "300px",
            data: file.name,
        });

        dialogReference
            .afterClosed()
            .pipe(first())
            .subscribe((result) => {
                if (result && this.project) {
                    this.loading++;
                    this.deleteFileGql
                        .mutate(
                            {
                                id: this.project.id,
                                path: file.path,
                                name: file.name,
                            },
                            {
                                update: (store, { data }) => {
                                    if (!data || !this.project) {
                                        return;
                                    }

                                    this.toastrService.success("File deleted.");

                                    const clonedData = _.cloneDeep(
                                        store.readQuery<ProjectQuery>({
                                            query: ProjectDocument,
                                            variables: {
                                                projectId: this.project.id,
                                            },
                                        })
                                    );

                                    if (clonedData === null) {
                                        return;
                                    }

                                    clonedData.project.folders =
                                        data.deleteFile;

                                    store.writeQuery({
                                        query: ProjectDocument,
                                        variables: {
                                            projectId: this.project.id,
                                        },
                                        data: clonedData,
                                    });
                                },
                            }
                        )
                        .subscribe()
                        .unsubscribe();
                }
            });
    }

    openServiceDeskLogin() {
        window.open(environment.sd_address + "/login.php");
    }
}

<div class="mat-elevation-z2 table-container">
    <table
        *ngIf="dataSource.filteredData.length !== 0"
        class="partner-activities-table"
        mat-table
        [dataSource]="dataSource"
        multiTemplateDataRows
    >
        <ng-container matColumnDef="name">
            <th
                class="name-column"
                mat-header-cell
                *matHeaderCellDef
                mat-header
            >
                Name
            </th>
            <td class="name-column" mat-cell *matCellDef="let row">
                {{ row.name }}
            </td>
        </ng-container>

        <ng-container matColumnDef="expiration">
            <th
                class="expiration-column"
                mat-header-cell
                *matHeaderCellDef
                mat-header
            >
                Expiration
            </th>
            <td class="expiration-column" mat-cell *matCellDef="let row">
                {{ row.expiration }}
            </td>
        </ng-container>

        <ng-container matColumnDef="state">
            <th
                class="state-column"
                mat-header-cell
                *matHeaderCellDef
                mat-header
            >
                State
            </th>
            <td class="state-column" mat-cell *matCellDef="let row">
                {{ row.state }}
            </td>
        </ng-container>

        <ng-container matColumnDef="points">
            <th
                class="points-column"
                mat-header-cell
                *matHeaderCellDef
                mat-header
            >
                Points
            </th>
            <td class="points-column" mat-cell *matCellDef="let row">
                {{ row.points }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="partner-activity-row"
            [ngClass]="{
                'sewio-red-background': row.state === 'rejected',
                'sewio-green-background': row.state === 'approved',
                'sewio-orange-background': row.state === 'expired'
            }"
        ></tr>
    </table>

    <div class="none-row" *ngIf="dataSource.filteredData.length === 0">
        <portal-status
            [text]="'None'"
            [type]="'none'"
            [big]="true"
        ></portal-status>
    </div>
    <mat-paginator [hidden]="dataSource.filteredData.length === 0" class="paginator" [pageSizeOptions]="[5]"></mat-paginator>
</div>

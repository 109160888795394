<div mat-dialog-title class="title">Add Business Review</div>
<div mat-dialog-content>
    <div class="form">
        <mat-form-field>
            <textarea
                    #descriptionInput
                    class="description"
                    matInput
                    placeholder="Description"
                    [formControl]="descriptionValidator"
                    oninput='this.style.height = ""; this.style.height = this.scrollHeight + "px"'
                    required
                ></textarea>
        </mat-form-field>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CANCEL</button>
    <button mat-button color="primary" cdkFocusInitial (click)="add()">
        ADD
    </button>
</mat-dialog-actions>

<portal-loading *ngIf="project === null" [height]="152"></portal-loading>
<div class="main-info" *ngIf="project !== null">
    <div class="project-detail">
        <portal-project-detail [project]="project"></portal-project-detail>
        <button
            class="edit-button"
            mat-flat-button
            color="primary"
            (click)="editProject()"
            [disabled]="loading > 0"
        >
            <span *ngIf="loading > 0">LOADING</span>
            <span *ngIf="loading === 0">EDIT</span>
        </button>
    </div>
    <div class="project-storage">
        <portal-storage
            class="storage"
            [flatFolders]="project.folders"
            (deleteFolder)="deleteFolder($event)"
            (deleteFile)="deleteFile($event)"
            (openFileRequest)="openFileRequest()"
        ></portal-storage>
        <button
            *ngIf="project.folders.length > 0"
            class="edit-button"
            mat-flat-button
            color="primary"
            (click)="createFolder()"
            [disabled]="loading > 0"
        >
            <span *ngIf="loading > 0">LOADING</span>
            <span *ngIf="loading === 0">CREATE FOLDER</span>
        </button>
        <button
            *ngIf="project.folders.length > 0"
            class="edit-button margin-left"
            mat-flat-button
            color="primary"
            (click)="uploadFile()"
            [disabled]="loading > 0"
        >
            <span *ngIf="loading > 0 && uploading === 100">LOADING</span>
            <span *ngIf="loading === 0 && uploading === 100">UPLOAD FILES</span>
            <span *ngIf="uploading < 100">UPLOADING ({{ uploading }}%)</span>
        </button>
    </div>
</div>
<portal-card headline="plans" subtitle="Project plans in RTLS Planner.">
    <div card-content class="w-full max-h-96 overflow-y-auto">
        <portal-loading *ngIf="project === null" [height]="48"></portal-loading>
        <portal-plan-list
            #planList
            *ngIf="
                project !== null &&
                project.plannerLoaded &&
                project.plans &&
                sewio !== null
            "
            [plans]="project.plans"
        ></portal-plan-list>
        <mat-card
            *ngIf="project !== null && !project.plannerLoaded"
            class="flex justify-center p-4"
        >
            <portal-status
                [type]="'warning'"
                [text]="'RTLS Planner is currently unavailable.'"
                [big]="true"
            ></portal-status>
        </mat-card>
    </div>
    <div card-actions>
        <button
            class="mr-4 w-36"
            mat-stroked-button
            color="primary"
            (click)="createPlan()"
            [disabled]="loading > 0"
        >
            <span *ngIf="loading > 0">LOADING</span>
            <span *ngIf="loading === 0">CREATE PLAN</span>
        </button>
    </div>
</portal-card>
<portal-card headline="Issues" subtitle="Tickets and RMAs in Service Desk.">
    <div card-content class="w-full max-h-96 overflow-y-auto">
        <portal-loading *ngIf="project === null" [height]="48"></portal-loading>
        <mat-card
            *ngIf="project !== null && !project.ticketsLoaded"
            class="flex-center"
        >
            <portal-status
                [type]="'warning'"
                [text]="
                    'Your email was not found. Please log in to service desk with your portal account at least once, then refresh this page to update all the data.'
                "
                [big]="true"
            ></portal-status>
            <fa-icon
                matRipple
                matTooltip="Log In"
                class="icon-button margin-left"
                [icon]="['fas', 'sign-in-alt']"
                (click)="openServiceDeskLogin()"
            ></fa-icon>
        </mat-card>
        <portal-ticket-list
            #ticketList
            *ngIf="project !== null && project.ticketsLoaded"
            [tickets]="project.tickets"
        ></portal-ticket-list>
    </div>
    <div card-actions>
        <button
            class="mr-4 w-40"
            mat-stroked-button
            color="primary"
            (click)="createTicket()"
            [disabled]="loading > 0 || !project?.ticketsLoaded"
        >
            <span *ngIf="loading > 0">LOADING</span>
            <span *ngIf="loading === 0">CREATE TICKET</span>
        </button>
    </div>
</portal-card>
<portal-section-header
    text="quotes"
    subtext="All issued quotes."
></portal-section-header>
<portal-loading *ngIf="project === null" [height]="48"></portal-loading>
<portal-quote-list
    *ngIf="project !== null"
    [quotes]="project.orders | filterQuotesFromOrders"
></portal-quote-list>
<portal-section-header
    text="orders"
    subtext="All issued orders."
></portal-section-header>
<portal-loading *ngIf="project === null" [height]="48"></portal-loading>
<portal-order-list
    *ngIf="project !== null"
    [orders]="project.orders | filterOrdersFromOrders"
></portal-order-list>
<portal-section-header
    text="invoices"
    subtext="All issued invoices."
></portal-section-header>
<portal-loading *ngIf="project === null" [height]="48"></portal-loading>
<portal-invoice-list
    *ngIf="project !== null"
    [invoices]="project.invoices"
></portal-invoice-list>

import { NgModule } from "@angular/core";
import { LoginComponent } from "./views/login/login.component";
import { LoginRoutes } from "./login.routes";
import { ResetComponent } from "./views/reset/reset.component";
import { NewPasswordComponent } from "./views/new-password/new-password.component";
import { ActivateUserComponent } from "./views/activate-user/activate-user.component";
import { SharedModule } from "../../shared";

@NgModule({
    imports: [
        SharedModule,
        LoginRoutes,
    ],
    declarations: [
        LoginComponent,
        ResetComponent,
        NewPasswordComponent,
        ActivateUserComponent,
    ],
    providers: [],
})
export class LoginModule {}

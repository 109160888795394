import { Component, Input, ViewChild, OnInit } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Order } from "../../../../core";

@Component({
    selector: "portal-quote-list",
    templateUrl: "./quote-list.component.html",
    styleUrls: ["./quote-list.component.scss"],
})
export class QuoteListComponent implements OnInit {
    @ViewChild(MatSort, { static: false }) sort!: MatSort;

    displayedColumns: string[] = ["id", "amount", "expiration", "update"];
    dataSource!: MatTableDataSource<Order>;

    private _quotes!: Order[];

    get quotes(): Order[] {
        return this._quotes;
    }

    @Input()
    set quotes(quotes: Order[]) {
        if (quotes) {
            this._quotes = quotes;
        } else {
            this._quotes = [];
        }

        this.dataSource = new MatTableDataSource(this._quotes);
    }

    ngOnInit() {
        this.dataSource.sort = this.sort;
    }
}

<div class="table-container mat-elevation-z2">
    <table
        class="orders-table"
        mat-table
        [dataSource]="dataSource"
        matSort
        *ngIf="orders.length !== 0"
    >
        <ng-container matColumnDef="id">
            <th
                class="id-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                ID
            </th>
            <td class="id-column" mat-cell *matCellDef="let row">
                {{ row.name }}
            </td>
        </ng-container>

        <ng-container matColumnDef="amount">
            <th
                class="amount-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Amount
            </th>
            <td class="amount-column" mat-cell *matCellDef="let row">
                {{ row.amount | round | numberToLocaleString }}
                {{ row.currency }}
            </td>
        </ng-container>

        <ng-container matColumnDef="delivery">
            <th
                class="delivery-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Delivery Method
            </th>
            <td class="delivery-column" mat-cell *matCellDef="let row">
                {{ row.deliveryMethod }}
            </td>
        </ng-container>

        <ng-container matColumnDef="shipping">
            <th
                class="shipping-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Shipping Date
            </th>
            <td class="shipping-column" mat-cell *matCellDef="let row">
                {{ row.shippingDate }}
            </td>
        </ng-container>

        <ng-container matColumnDef="sns">
            <th
                class="sns-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                SNs
            </th>
            <td class="sns-column" mat-cell *matCellDef="let row">
                <button
                    *ngIf="row.items.length > 0"
                    mat-icon-button
                    aria-label="Download"
                    matTooltip="Download"
                    (click)="downloadItems(row.name, row.items)"
                >
                    <portal-icon icon="download"></portal-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="order-row"
            [ngClass]="{
                'sewio-blue-background':
                    row.state === 'payment' ||
                    row.state === 'prepare' ||
                    row.state === 'sale',
                'sewio-green-background': row.state === 'done'
            }"
        ></tr>
    </table>

    <div class="none-row" *ngIf="orders.length === 0">
        <portal-status
            [text]="'None'"
            [type]="'none'"
            [big]="true"
        ></portal-status>
    </div>
</div>

import { Component, ViewChild, ElementRef, Inject } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";

@Component({
    selector: "portal-edit-user-dialog",
    styleUrls: ["edit-user-dialog.component.scss"],
    templateUrl: "edit-user-dialog.component.html",
})
export class EditUserDialogComponent {
    @ViewChild("nameInput", { static: false }) nameInput!: ElementRef;
    @ViewChild("roleSelect", { static: false }) roleSelect!: MatSelect;

    nameValidator = new UntypedFormControl("", [Validators.required]);
    roleValidator = new UntypedFormControl("", [Validators.required]);

    roles = ["user", "admin"];

    constructor(
        public dialogRef: MatDialogRef<EditUserDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { name: string; role: string }
    ) {
        this.nameValidator.setValue(data.name);
        this.roleValidator.setValue(data.role);
    }

    invite(): void {
        if (this.nameValidator.invalid) {
            this.nameInput.nativeElement.focus();
            return;
        }

        if (this.roleValidator.invalid) {
            this.roleSelect.open();
            return;
        }

        this.dialogRef.close({
            name: this.nameInput.nativeElement.value,
            role: this.roleValidator.value,
        });
    }
}

<div class="rounded-lg shadow relative bg-white overflow-hidden">
    <div
        class="absolute top-0 left-0 right-0 bottom-0 bg-slate-900 bg-opacity-10 z-10 flex items-center justify-center"
        *ngIf="isLoading"
    >
        <mat-spinner class="w-10 h-10"></mat-spinner>
    </div>
    <div class="min-h-[488px]">
        <table mat-table [dataSource]="data">
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef class="w-1/12">ID</th>
                <td mat-cell *matCellDef="let row" class="w-1/12">
                    {{ row.id }}
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef class="w-7/12">Name</th>
                <td mat-cell *matCellDef="let row" class="w-7/12">
                    {{ row.name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="manager">
                <th mat-header-cell *matHeaderCellDef class="w-2/12">
                    Account Manager
                </th>
                <td mat-cell *matCellDef="let row" class="w-2/12">
                    {{ row.salesPersonName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="level">
                <th mat-header-cell *matHeaderCellDef class="w-2/12">
                    Account Level
                </th>
                <td mat-cell *matCellDef="let row" class="w-2/12">
                    <portal-level
                        [type]="row.level"
                        [prospectOnly]="false"
                    ></portal-level>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                class="cursor-pointer hover:bg-stone-50 border-b border-stone-200"
                (click)="setCompany(row.id)"
            ></tr>
        </table>
    </div>
    <mat-paginator [length]="resultsLength" [pageSize]="10"></mat-paginator>
</div>

<div class="flex flex-col pt-4 px-4 gap-1 md:hidden">
    <div *ngIf="user !== null" class="flex-col flex">
        <div class="font-sans font-bold text-sm">USER</div>
        <div>{{ user.email }}</div>
    </div>
    <div class="flex-col flex">
        <div class="font-sans font-bold text-sm">COMPANY</div>
        <div *ngIf="company !== null">{{ company.name }}</div>
        <div *ngIf="company === null">loading</div>
    </div>
</div>
<div class="flex flex-col py-4 px-2 gap-1">
    <portal-menu-item
        *ngFor="let menuItem of menuItems"
        [menuItem]="menuItem"
    ></portal-menu-item>
</div>

import { NgModule } from "@angular/core";
import { DownloadComponent } from "./views/download/download.component";
import { DownloadRoutes } from "./download.routes";
import { SharedModule } from "../../shared";

@NgModule({
    imports: [SharedModule, DownloadRoutes],
    declarations: [DownloadComponent],
    providers: [],
})
export class DownloadModule {}

import { Component, OnInit, OnDestroy } from "@angular/core";
import {
    UsersGQL,
    UsersQuery,
    InviteUserGQL,
    UsersDocument,
    UserGQL,
    UserQuery,
    DeleteUserGQL,
    EditUserGQL,
} from "../../../../core";
import { Subscription } from "rxjs";
import { InviteUserDialogComponent } from "../../components/invite-user-dialog/invite-user-dialog.component";
import { first } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import * as _ from "lodash";
import { EditUserDialogComponent } from "../../components/edit-user-dialog/edit-user-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "portal-users",
    templateUrl: "./users.component.html",
    styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit, OnDestroy {
    user: UserQuery["user"] | null = null;
    users: UsersQuery["users"] | null = null;

    private subscriptions: Subscription[] = [];

    constructor(
        private userGql: UserGQL,
        private usersGql: UsersGQL,
        private deleteUserGql: DeleteUserGQL,
        private inviteUserGql: InviteUserGQL,
        private editUserGql: EditUserGQL,
        private dialog: MatDialog,
        private toastrService: ToastrService
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.usersGql.watch().valueChanges.subscribe((value) => {
                this.users = value.data.users;
            }),
            this.userGql.watch().valueChanges.subscribe((value) => {
                this.user = value.data.user;
            })
        );
    }

    ngOnDestroy() {
        while (this.subscriptions.length) {
            this.subscriptions.pop()?.unsubscribe();
        }
    }

    editUser(event: { id: string }) {
        const user = _.find(
            this.users,
            (foundUser) => foundUser.id === event.id
        );

        if (!user) {
            return;
        }

        const dialogReference = this.dialog.open(EditUserDialogComponent, {
            width: window.innerWidth >= 768 ? "600px" : "300px",
            data: {
                name: user.name,
                role: user.companyRole,
            },
        });

        dialogReference
            .afterClosed()
            .pipe(first())
            .subscribe((result) => {
                if (result) {
                    this.editUserGql
                        .mutate(
                            {
                                name: result.name,
                                role: result.role,
                                id: event.id,
                            },
                            {
                                update: (store, { data }) => {
                                    if (!data) {
                                        return;
                                    }

                                    this.toastrService.success("User edited.");

                                    const clonedData: UsersQuery | null =
                                        _.cloneDeep(
                                            store.readQuery<UsersQuery>({
                                                query: UsersDocument,
                                            })
                                        );

                                    if (clonedData === null) {
                                        return;
                                    }

                                    for (
                                        let i = 0;
                                        i < clonedData.users.length;
                                        i++
                                    ) {
                                        if (
                                            clonedData.users[i].id ===
                                            data.editUser.id
                                        ) {
                                            clonedData.users[i].name =
                                                data.editUser.name;
                                            clonedData.users[i].companyRole =
                                                data.editUser.companyRole;
                                            break;
                                        }
                                    }

                                    store.writeQuery({
                                        query: UsersDocument,
                                        data: clonedData,
                                    });
                                },
                            }
                        )
                        .subscribe()
                        .unsubscribe();
                }
            });
    }

    deleteUser(event: { id: string }) {
        this.deleteUserGql
            .mutate(
                { id: event.id },
                {
                    update: (store, { data }) => {
                        if (!data) {
                            return;
                        }

                        this.toastrService.success("User deleted.");

                        const clonedData: UsersQuery | null = _.cloneDeep(
                            store.readQuery<UsersQuery>({
                                query: UsersDocument,
                            })
                        );

                        if (clonedData === null) {
                            return;
                        }

                        for (let i = 0; i < clonedData.users.length; i++) {
                            if (clonedData.users[i].id === data.deleteUser) {
                                clonedData.users.splice(i, 1);
                                break;
                            }
                        }

                        store.writeQuery({
                            query: UsersDocument,
                            data: clonedData,
                        });
                    },
                }
            )
            .subscribe()
            .unsubscribe();
    }

    inviteUser() {
        const dialogReference = this.dialog.open(InviteUserDialogComponent, {
            width: window.innerWidth >= 768 ? "600px" : "300px",
        });

        dialogReference
            .afterClosed()
            .pipe(first())
            .subscribe((result) => {
                if (result) {
                    this.inviteUserGql
                        .mutate(
                            { email: result.email, role: result.role },
                            {
                                update: (store, { data }) => {
                                    if (!data) {
                                        return;
                                    }

                                    this.toastrService.success(
                                        "User invited via email."
                                    );

                                    const clonedData: UsersQuery | null =
                                        _.cloneDeep(
                                            store.readQuery<UsersQuery>({
                                                query: UsersDocument,
                                            })
                                        );

                                    if (clonedData === null) {
                                        return;
                                    }

                                    clonedData.users.push(data.inviteUser);

                                    store.writeQuery({
                                        query: UsersDocument,
                                        data: clonedData,
                                    });
                                },
                            }
                        )
                        .subscribe()
                        .unsubscribe();
                }
            });
    }
}

import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import {
    trigger,
    state,
    style,
    transition,
    animate,
} from "@angular/animations";
import { TimeUtils } from "@portal/shared";
import { ProjectsQuery } from "../../../core";
import { MatSort } from "@angular/material/sort";
import { StatusType } from "../status/status.component";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";

type Project = ProjectsQuery["projects"][0];

export interface ProjectValues {
    success: number;
    warning: number;
    danger: number;
    info: number;
    ordersDone: number;
    ordersInProgress: number;
    invoicesPaid: number;
    invoicesOverdue: number;
    invoicesToBePaid: number;
    approvedPlans: number;
    plans: number;
    invoicesToBePaidInAWeek: number;
    invoicesDue: string;
    closedIncidents: number;
    criticalIncidents: number;
    otherIncidents: number;
    supportStatus: StatusType;
    supportText: string;
    supportNone: boolean;
}

@Component({
    selector: "portal-project-list",
    templateUrl: "./project-list.component.html",
    styleUrls: ["./project-list.component.scss"],
    animations: [
        trigger("detailExpand", [
            state("collapsed", style({ height: "0px", minHeight: "0" })),
            state("expanded", style({ height: "*" })),
            transition(
                "expanded <=> collapsed",
                animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
            ),
        ]),
    ],
})
export class ProjectListComponent implements OnInit {
    private _projects!: ProjectsQuery["projects"];
    private _filter!: string;
    private _attention!: boolean;
    private _assigned!: boolean;
    private _closed!: boolean;
    private _archived!: boolean;

    private stageIdsOrder: { [key: number]: number } = {
        37: 0, // active leads
        33: 1, // discovery
        38: 2, // quoted
        34: 3, // design
        35: 4, // order
        4: 5, // invoiced
    };

    @Input() showCreationDate = false;

    get projects(): ProjectsQuery["projects"] {
        return this._projects;
    }

    @Input()
    set projects(projects: ProjectsQuery["projects"]) {
        if (projects) {
            this._projects = projects;
        } else {
            this._projects = [];
        }

        for (const project of this._projects) {
            this.fillProjectValues(project);
        }

        this.dataSource = new MatTableDataSource(this._projects);

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = (data, filter) => {
            if (
                filter[0] === "A" &&
                this.projectValues[data.id].danger === 0 &&
                this.projectValues[data.id].warning === 0
            ) {
                return false;
            }

            if (filter[1] === "C" && data.stageId === 4) {
                return false;
            }

            if (filter[2] === "X" && data.active === false) {
                return false;
            }

            if (filter[3] === "S" && typeof data.siteId === "number") {
                return false;
            }

            filter = filter.substr(4, filter.length - 1);

            const dataStr = (data.name + "◬" + data.stage + "◬").toLowerCase();
            const transformedFilter = filter.trim().toLowerCase();

            return dataStr.indexOf(transformedFilter) !== -1;
        };

        this.dataSource.sortingDataAccessor = (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            item: { createdAt: string; stageId: number; [key: string]: any },
            property
        ) => {
            switch (property) {
                case "date":
                    return new Date(item["createdAt"]);
                case "stage":
                    return this.stageIdsOrder[item["stageId"]];
                default:
                    return item[property];
            }
        };

        this.applyFilter();
    }

    get filter(): string {
        return this._filter;
    }

    @Input()
    set filter(filterValue: string) {
        this._filter = filterValue;

        this.applyFilter();
    }

    get attention() {
        return this._attention;
    }

    @Input()
    set attention(attention: boolean) {
        this._attention = attention;

        this.applyFilter();
    }

    get assigned() {
        return this._assigned;
    }

    @Input()
    set assigned(assigned: boolean) {
        this._assigned = assigned;

        this.applyFilter();
    }

    get closed() {
        return this._closed;
    }

    @Input()
    set closed(closed: boolean) {
        this._closed = closed;

        this.applyFilter();
    }

    get archived() {
        return this._archived;
    }

    @Input()
    set archived(archived: boolean) {
        this._archived = archived;

        this.applyFilter();
    }

    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort!: MatSort;

    displayedColumns: string[] = ["name", "stage", "status", "expand"];
    pageSizes: number[] = [10, 20, 30];
    dataSource!: MatTableDataSource<Project>;

    expandedProject!: string | null;

    projectValues: {
        [key: string]: ProjectValues;
    } = {};

    constructor(private router: Router) {}

    ngOnInit() {
        if (this.showCreationDate) {
            this.displayedColumns.splice(1, 0, "date");
        }
    }

    applyFilter() {
        if (
            this._attention === undefined ||
            this._closed === undefined ||
            this._archived === undefined ||
            this._assigned === undefined ||
            this._filter === undefined ||
            this.dataSource === undefined
        ) {
            return;
        }

        const attentionPrefix = this._attention ? "A" : "N";
        const closedPrefix = this._closed ? "C" : "N";
        const archivedPrefix = this._archived ? "X" : "N";
        const assignedPrefix = this._assigned ? "S" : "N";

        this.dataSource.filter =
            attentionPrefix +
            closedPrefix +
            archivedPrefix +
            assignedPrefix +
            this._filter.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    openProject(id: string) {
        void this.router.navigate(["/opportunities", id]);
    }

    checkExpired(time: string) {
        return TimeUtils.isDateTodayOrInThePast(time);
    }

    checkExpiring(time: string, months: number) {
        return TimeUtils.isDateLessThanNMonthsInTheFuture(time, months);
    }

    expandProject($event: Event, id: string) {
        $event.stopPropagation();
        $event.preventDefault();

        this.expandedProject = this.expandedProject === id ? null : id;
    }

    fillProjectValues(project: Project) {
        const values: ProjectValues = {
            success: 0,
            warning: 0,
            danger: 0,
            info: 0,
            ordersInProgress: 0,
            ordersDone: 0,
            invoicesOverdue: 0,
            invoicesToBePaid: 0,
            invoicesToBePaidInAWeek: 0,
            invoicesDue: "",
            invoicesPaid: 0,
            supportNone: false,
            supportText: "",
            supportStatus: "none",
            plans: 0,
            approvedPlans: 0,
            criticalIncidents: 0,
            otherIncidents: 0,
            closedIncidents: 0,
        };

        const implementationValid =
            project.implementationSupportTo !== "" &&
            !this.checkExpired(project.implementationSupportTo);
        const supportValid =
            project.supportTo !== "" && !this.checkExpired(project.supportTo);

        if (implementationValid || supportValid) {
            const implementationExpiring =
                implementationValid &&
                this.checkExpiring(project.implementationSupportTo, 1);
            const supportExpiring =
                supportValid && this.checkExpiring(project.supportTo, 3);

            if (implementationExpiring && supportExpiring) {
                values.warning++;
                values.supportStatus = "warning";
                values.supportText =
                    "Expiring (" +
                    project.implementationSupportTo +
                    ", " +
                    project.supportTo +
                    ")";
            } else if (implementationExpiring) {
                values.warning++;
                values.supportStatus = "warning";
                values.supportText =
                    "Implementation Expiring (" +
                    project.implementationSupportTo +
                    ")";
            } else if (supportExpiring) {
                values.warning++;
                values.supportStatus = "warning";
                values.supportText =
                    "Maintenance Expiring (" + project.supportTo + ")";
            } else {
                if (implementationValid && supportValid) {
                    values.success++;
                    values.supportStatus = "success";
                    values.supportText = "Full";
                } else if (implementationValid) {
                    values.success++;
                    values.supportStatus = "success";
                    values.supportText = "Implementation Support";
                } else {
                    values.success++;
                    values.supportStatus = "success";
                    values.supportText = "Maintenance Support";
                }
            }
        } else {
            if (project.stageId !== 4 || project.useCase === "showroom") {
                values.supportStatus = "none";
                values.supportText = "None";
            } else {
                if (
                    project.implementationSupportTo === "" &&
                    project.supportTo === ""
                ) {
                    values.warning++;
                    values.supportStatus = "warning";
                    values.supportText = "None";
                } else {
                    values.warning++;
                    values.supportStatus = "warning";
                    values.supportText = "None";
                }
            }
        }

        for (const order of project.orders) {
            if (["payment", "prepare", "sale"].includes(order.state)) {
                values.ordersInProgress++;
            } else if (order.state === "done") {
                values.ordersDone++;
            }
        }

        for (const invoice of project.invoices) {
            if (invoice.state === "open") {
                if (TimeUtils.isDateTodayOrInThePast(invoice.dueDate)) {
                    values.invoicesOverdue++;
                } else if (
                    TimeUtils.isDateLessThanNWeeksInTheFuture(
                        invoice.dueDate,
                        1
                    )
                ) {
                    values.invoicesToBePaidInAWeek++;

                    if (values.invoicesDue === "") {
                        values.invoicesDue = invoice.dueDate;
                    } else if (
                        TimeUtils.isTimeBeforeOtherTime(
                            invoice.dueDate,
                            values.invoicesDue
                        )
                    ) {
                        values.invoicesDue = invoice.dueDate;
                    }
                } else {
                    values.invoicesToBePaid++;
                }
            } else if (invoice.state === "paid") {
                values.invoicesPaid++;
            }
        }

        if (values.ordersInProgress > 0) {
            values.info++;
        } else if (values.ordersDone > 0) {
            values.success++;
        }

        if (values.invoicesOverdue > 0) {
            values.danger++;
        } else if (values.invoicesToBePaidInAWeek > 0) {
            values.warning++;
        } else if (values.invoicesToBePaid > 0) {
            values.info++;
        } else if (values.invoicesPaid > 0) {
            values.success++;
        }

        for (const plan of project.plans) {
            values.plans++;

            if (plan.state === "approved") {
                values.approvedPlans++;
            }
        }

        if (values.plans > 0) {
            if (values.approvedPlans === values.plans) {
                values.success++;
            } else {
                values.info++;
            }
        }

        for (const ticket of project.tickets) {
            if (ticket.status === "open") {
                if (ticket.priority === "critical") {
                    values.criticalIncidents++;
                } else {
                    values.otherIncidents++;
                }
            } else {
                values.closedIncidents++;
            }
        }

        if (values.criticalIncidents > 0) {
            values.danger++;
        } else if (values.otherIncidents > 0) {
            values.warning++;
        } else if (values.closedIncidents > 0) {
            values.success++;
        }

        this.projectValues[project.id] = values;
    }
}

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PlansComponent } from "./views/plans/plans.component";
import { AuthGuard } from "../../core";
import { menuGuard } from "../../core/guards/menu.guard";

const plansRoutes: Routes = [
    {
        path: "plans",
        component: PlansComponent,
        data: {
            showTopPanel: true,
            showLeftPanel: true,
        },
        canActivate: [AuthGuard, menuGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(plansRoutes)],
    exports: [RouterModule],
})
export class PlansRoutes {}

import { Component, Input, ViewChild, OnInit } from "@angular/core";
import { Invoice } from "../../../../core";
import { TimeUtils } from "@portal/shared";
import { DownloadService } from "../../../../core/services/download.service";
import { ToastrService } from "ngx-toastr";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

@Component({
    selector: "portal-invoice-list",
    templateUrl: "./invoice-list.component.html",
    styleUrls: ["./invoice-list.component.scss"],
})
export class InvoiceListComponent implements OnInit {
    @ViewChild(MatSort, { static: false }) sort!: MatSort;

    displayedColumns: string[] = [
        "number",
        "origin",
        "due",
        "amounts",
        "state",
        "track",
        "print",
    ];
    dataSource!: MatTableDataSource<Invoice>;

    private _invoices!: Invoice[];

    get invoices(): Invoice[] {
        return this._invoices;
    }

    @Input()
    set invoices(invoices: Invoice[]) {
        if (invoices) {
            this._invoices = invoices;
        } else {
            this._invoices = [];
        }

        this.dataSource = new MatTableDataSource(this._invoices);
    }

    downloading = false;

    constructor(
        private downloadService: DownloadService,
        private toastrService: ToastrService
    ) {}

    ngOnInit() {
        this.dataSource.sort = this.sort;
    }

    isDueDate(date: string) {
        return TimeUtils.isDateTodayOrInThePast(date);
    }

    track(trackingNumber: string) {
        window.open(
            `https://www.dhl.com/en/express/tracking.html?AWB=${trackingNumber}&brand=DHL`,
            "_blank"
        );
    }

    print(id: string) {
        this.downloading = true;

        const subscription = this.downloadService.downloadInvoice(id).subscribe(
            (done) => {
                subscription.unsubscribe();

                const blobUrl = window.URL.createObjectURL(done);
                const a = document.createElement("a");

                a.style.display = "none";
                a.href = blobUrl;
                a.download = id + ".pdf";

                document.body.appendChild(a);
                a.click();
                a.remove();

                this.downloading = false;
            },
            () => {
                subscription.unsubscribe();
                this.toastrService.error("Could not download invoice PDF.");
                this.downloading = false;
            }
        );
    }
}

import {
    Component,
    ViewChild,
    ElementRef,
    Inject,
    OnInit,
} from "@angular/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { UntypedFormControl, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ProjectUseCases } from "../../../projects/components/register-dialog/register-dialog.component";
import { countries, unknownValueValidator } from "../../../../shared";
import { Observable } from "rxjs";
import { startWith, map } from "rxjs/operators";
import {
    DateAdapter,
    MAT_DATE_LOCALE,
    MAT_DATE_FORMATS,
} from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { MatStepper } from "@angular/material/stepper";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";

export const CUSTOM_FORMATS = {
    parse: {
        dateInput: "YYYY-MM-DD",
    },
    display: {
        dateInput: "YYYY-MM-DD",
        monthYearLabel: "MMMM YYYY",
        dateA11yLabel: "YYYY-MM-DD",
        monthYearA11yLabel: "MMMM YYYY",
    },
};

@Component({
    selector: "portal-edit-dialog",
    styleUrls: ["edit-dialog.component.scss"],
    templateUrl: "edit-dialog.component.html",
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: CUSTOM_FORMATS },
    ],
})
export class EditDialogComponent implements OnInit {
    @ViewChild("stepper", { static: false }) stepper!: MatStepper;
    @ViewChild("nameInput", { static: false }) nameInput!: ElementRef;
    @ViewChild("customerInput", { static: false }) customerInput!: ElementRef;
    @ViewChild("problemInput", { static: false }) problemInput!: ElementRef;
    @ViewChild("countryInput", { static: false }) countryInput!: ElementRef;
    @ViewChild("useCaseSelect", { static: false }) useCaseSelect!: MatSelect;
    @ViewChild("solutionInput", { static: false }) solutionInput!: ElementRef;
    @ViewChild("revenueInput", { static: false }) revenueInput!: ElementRef;
    @ViewChild("datepickerInput", { static: false })
    datepickerInput!: ElementRef;
    @ViewChild("closingdatepicker", { static: false })
    closingdatepicker!: MatDatepicker<string>;
    @ViewChild("descriptionInput", { static: false })
    descriptionInput!: ElementRef;

    @ViewChild("trackedInput", { static: false }) trackedInput!: ElementRef;
    @ViewChild("countInput", { static: false }) countInput!: ElementRef;
    @ViewChild("mountingInput", { static: false }) mountingInput!: ElementRef;
    @ViewChild("updateInput", { static: false }) updateInput!: ElementRef;
    @ViewChild("accuracyInput", { static: false }) accuracyInput!: ElementRef;
    @ViewChild("batteryInput", { static: false }) batteryInput!: ElementRef;

    filteredCountries: Observable<{ id: number; name: string }[]>;

    nameValidator = new UntypedFormControl("", [Validators.required]);
    customerValidator = new UntypedFormControl("", [Validators.required]);
    problemValidator = new UntypedFormControl("", [Validators.required]);
    solutionValidator = new UntypedFormControl("", [Validators.required]);
    useCaseTypeValidator = new UntypedFormControl("", [Validators.required]);
    revenueValidator = new UntypedFormControl("", [Validators.required]);
    descriptionValidator = new UntypedFormControl("", []);
    countryValidator = new UntypedFormControl("", [
        Validators.required,
        unknownValueValidator(countries.map((country) => country.name)),
    ]);
    datepickerValidator = new UntypedFormControl("", []);
    trackedValidator = new UntypedFormControl("", []);
    countValidator = new UntypedFormControl("", []);
    mountingValidator = new UntypedFormControl("", []);
    updateValidator = new UntypedFormControl("", []);
    accuracyValidator = new UntypedFormControl("", []);
    batteryValidator = new UntypedFormControl("", []);

    zAxis = false;

    useCases = ProjectUseCases;

    stepOneComplete = false;
    stepOneEditable = true;

    stepTwoComplete = false;
    stepTwoEditable = false;

    stepThreeEditable = false;

    constructor(
        public dialogRef: MatDialogRef<EditDialogComponent>,
        public toastrService: ToastrService,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            name: string;
            customer: string;
            country: number;
            problem: string;
            solution: string;
            useCase: string;
            revenue: string;
            closing: string;
            description: string;
            trackedObjects: string;
            numberOfObjects: string;
            tagMounting: string;
            refreshRate: string;
            accuracy: string;
            batteryLife: string;
            zAxis: boolean;
        }
    ) {
        this.filteredCountries = this.countryValidator.valueChanges.pipe(
            startWith(""),
            map((state) =>
                state ? this.filterCountries(state) : countries.slice()
            )
        );

        this.nameValidator.setValue(data.name);
        this.customerValidator.setValue(data.customer);

        const selectedCountry = countries.find(
            (country) => country.id === data.country
        );

        if (selectedCountry) {
            this.countryValidator.setValue(selectedCountry.name);
        }

        this.problemValidator.setValue(data.problem);
        this.solutionValidator.setValue(data.solution);
        this.useCaseTypeValidator.setValue(data.useCase);
        this.revenueValidator.setValue(data.revenue);
        this.datepickerValidator.setValue(data.closing);
        this.descriptionValidator.setValue(data.description);
        this.trackedValidator.setValue(data.trackedObjects);
        this.countValidator.setValue(data.numberOfObjects);
        this.mountingValidator.setValue(data.tagMounting);
        this.updateValidator.setValue(data.refreshRate);
        this.accuracyValidator.setValue(data.accuracy);
        this.batteryValidator.setValue(data.batteryLife);

        this.zAxis = data.zAxis;
    }

    ngOnInit() {
        setTimeout(() => {
            this.solutionInput.nativeElement.style.height = "";
            this.solutionInput.nativeElement.style.height =
                this.solutionInput.nativeElement.scrollHeight + "px";

            this.problemInput.nativeElement.style.height = "";
            this.problemInput.nativeElement.style.height =
                this.problemInput.nativeElement.scrollHeight + "px";

            this.descriptionInput.nativeElement.style.height = "";
            this.descriptionInput.nativeElement.style.height =
                this.descriptionInput.nativeElement.scrollHeight + "px";
        }, 100);
    }

    private filterCountries(value: string): { id: number; name: string }[] {
        const filterValue = value.toLowerCase();
        return countries.filter(
            (state) => state.name.toLowerCase().indexOf(filterValue) === 0
        );
    }

    next(): void {
        if (!this.stepOneComplete) {
            if (this.nameValidator.invalid) {
                this.nameInput.nativeElement.focus();
                return;
            }

            if (this.customerValidator.invalid) {
                this.customerInput.nativeElement.focus();
                return;
            }

            if (this.countryValidator.invalid) {
                this.countryInput.nativeElement.focus();
                return;
            }

            if (this.useCaseTypeValidator.invalid) {
                this.useCaseSelect.open();
                return;
            }

            if (this.problemValidator.invalid) {
                this.problemInput.nativeElement.focus();
                return;
            }

            if (this.solutionValidator.invalid) {
                this.solutionInput.nativeElement.focus();
                return;
            }

            if (this.revenueValidator.invalid) {
                this.revenueInput.nativeElement.focus();
                return;
            }

            if (this.datepickerInput.nativeElement.value.length === 0) {
                this.closingdatepicker.open();
                return;
            }

            this.stepOneComplete = true;
            this.stepOneEditable = false;
            this.stepTwoEditable = true;

            setTimeout(() => this.stepper.next(), 100);
        } else {
            this.stepTwoComplete = true;
            this.stepTwoEditable = false;
            this.stepThreeEditable = true;

            setTimeout(() => this.stepper.next(), 100);
        }
    }

    back(): void {
        if (!this.stepTwoComplete) {
            this.stepOneComplete = false;
            this.stepOneEditable = true;
            this.stepTwoEditable = false;

            setTimeout(() => this.stepper.previous(), 100);
        } else {
            this.stepTwoComplete = false;
            this.stepTwoEditable = true;
            this.stepThreeEditable = false;

            setTimeout(() => this.stepper.previous(), 100);
        }
    }

    edit(): void {
        const country = countries.find(
            (country) => country.name === this.countryInput.nativeElement.value
        );

        if (!country) {
            return;
        }

        this.dialogRef.close({
            name: this.nameInput.nativeElement.value,
            customer: this.customerInput.nativeElement.value,
            country: country.id,
            useCase: this.useCaseTypeValidator.value,
            problem: this.problemInput.nativeElement.value,
            solution: this.solutionInput.nativeElement.value,
            revenue: this.revenueInput.nativeElement.value,
            closing: this.datepickerInput.nativeElement.value,
            description: this.descriptionInput.nativeElement.value,
            trackedObjects: this.trackedInput.nativeElement.value,
            numberOfObjects: this.countInput.nativeElement.value,
            tagMounting: this.mountingInput.nativeElement.value,
            refreshRate: this.updateInput.nativeElement.value,
            accuracy: this.accuracyInput.nativeElement.value,
            batteryLife: this.batteryInput.nativeElement.value,
            zAxis: this.zAxis,
        });
    }
}

<div class="projects-controls-container">
    <div></div>
    <div>
        <mat-checkbox
            [labelPosition]="'before'"
            class="checkbox"
            [checked]="closed"
            (change)="onHideClosedChanged()"
        >
            Hide Closed
        </mat-checkbox>
        <mat-checkbox
            [labelPosition]="'before'"
            class="checkbox"
            [checked]="attention"
            (change)="onAttentionChanged()"
        >
            Needs Attention Only
        </mat-checkbox>
        <mat-checkbox
            [labelPosition]="'before'"
            class="checkbox"
            [checked]="archived"
            (change)="onHideArchivedChanged()"
        >
            Hide Archived
        </mat-checkbox>
        <mat-checkbox
            [labelPosition]="'before'"
            class="checkbox"
            [checked]="assigned"
            (change)="onHideAssignedChanged()"
        >
            Hide Assigned To Site
        </mat-checkbox>
        <mat-form-field class="projects-filter">
            <input
                #filter
                matInput
                (keyup)="applyFilter(filter.value)"
                placeholder="Filter"
            />
        </mat-form-field>
    </div>
</div>
<portal-loading *ngIf="projects === null" [height]="112"></portal-loading>
<portal-project-list
    *ngIf="projects !== null"
    [filter]="filterValue"
    [projects]="projects"
    [attention]="attention"
    [closed]="closed"
    [archived]="archived"
    [assigned]="assigned"
    [showCreationDate]="true"
></portal-project-list>
<button
    class="register-button"
    mat-flat-button
    color="primary"
    (click)="registerProject()"
    [disabled]="loading !== 0"
>
    <span *ngIf="loading === 0">REGISTER NEW</span>
    <span *ngIf="loading !== 0">LOADING</span>
</button>

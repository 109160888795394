import { Component, Input, ViewChild, OnInit, OnDestroy } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
    CreateTicketGQL,
    ProjectDocument,
    SiteDocument,
    Ticket,
    UserGQL,
} from "../../../core";
import { environment } from "../../../../environments/environment";
import { ToastrService } from "ngx-toastr";
import { CreateTicketDialogComponent } from "../../../modules/project/components/create-ticket-dialog/create-ticket-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { CalculatedSite, Project } from "@portal/shared";
import { Subscription, first } from "rxjs";
import { InternalRefetchQueriesInclude } from "@apollo/client/core";

@Component({
    selector: "portal-ticket-list",
    templateUrl: "./ticket-list.component.html",
    styleUrls: ["./ticket-list.component.scss"],
})
export class TicketListComponent implements OnInit, OnDestroy {
    @ViewChild(MatSort, { static: false }) sort!: MatSort;

    displayedColumns: string[] = [
        "number",
        "subject",
        "status",
        "from",
        "priority",
        "type",
        "updated",
    ];

    dataSource!: MatTableDataSource<Ticket>;
    loading = 0;

    private _tickets!: Ticket[];
    private _site?: CalculatedSite;
    private sewio: boolean | null = null;
    private userEmail = "";
    private subscriptions: Subscription[] = [];

    get tickets(): Ticket[] {
        return this._tickets;
    }

    @Input()
    set tickets(tickets: Ticket[]) {
        if (tickets) {
            this._tickets = tickets;
        } else {
            this._tickets = [];
        }

        this.dataSource = new MatTableDataSource(this._tickets);
    }

    get site(): CalculatedSite | undefined {
        return this._site;
    }

    @Input()
    set site(site: CalculatedSite | undefined) {
        if (site !== undefined && site !== null) {
            this.displayedColumns = [
                "number",
                "opportunity",
                "subject",
                "status",
                "from",
                "priority",
                "type",
                "updated",
            ];
        } else {
            this.displayedColumns = [
                "number",
                "subject",
                "status",
                "from",
                "priority",
                "type",
                "updated",
            ];
        }

        this._site = site;
    }

    constructor(
        private dialog: MatDialog,
        private createTicketGql: CreateTicketGQL,
        private toastrService: ToastrService,
        private userGql: UserGQL
    ) {
        this.subscriptions.push(
            this.userGql.watch().valueChanges.subscribe((value) => {
                this.sewio = value.data.user.sewio;
                this.userEmail = value.data.user.email;
            })
        );
    }

    ngOnInit() {
        this.dataSource.sort = this.sort;
    }

    ngOnDestroy(): void {
        while (this.subscriptions.length) {
            this.subscriptions.pop()?.unsubscribe();
        }
    }

    createTicket(projects: Project[]) {
        const responseTimes = {
            critical: "",
            major: "",
            minor: "",
        };

        if (this.site) {
            responseTimes.critical = this.site.responseTimes.critical();
            responseTimes.major = this.site.responseTimes.major();
            responseTimes.minor = this.site.responseTimes.minor();
        }

        const dialogReference = this.dialog.open(CreateTicketDialogComponent, {
            width: window.innerWidth >= 768 ? "600px" : "300px",
            data: { responseTimes, projects },
        });

        dialogReference
            .afterClosed()
            .pipe(first())
            .subscribe((result) => {
                if (result) {
                    this.loading++;

                    const queries: InternalRefetchQueriesInclude = [
                        {
                            query: ProjectDocument,
                            variables: {
                                projectId: result.projectId as string,
                            },
                        },
                    ];

                    if (this._site?.id) {
                        queries.push({
                            query: SiteDocument,
                            variables: {
                                siteId: this._site.id,
                            },
                        });
                    }

                    this.createTicketGql
                        .mutate(
                            result.info
                                ? {
                                      id: result.projectId,
                                      type: result.type,
                                      subject: result.subject,
                                      priority: result.level,
                                      info: result.info,
                                  }
                                : {
                                      id: result.projectId,
                                      type: result.type,
                                      subject: result.subject,
                                      priority: result.level,
                                  },
                            {
                                refetchQueries: queries,
                            }
                        )
                        .pipe(first())
                        .subscribe();
                }
            });
    }

    openTicket(ticket: { id: string; from: string }) {
        if (this.sewio) {
            window.open(
                environment.sd_address + "/scp/tickets.php?id=" + ticket.id,
                "_blank"
            );

            return;
        }

        if (this.userEmail !== ticket.from) {
            this.toastrService.warning(
                "Opening tickets from other users is not possible at the moment."
            );
            return;
        }

        window.open(
            environment.sd_address + "/tickets.php?id=" + ticket.id,
            "_blank"
        );
    }

    opportunityIdToName(id: string | null): string | undefined {
        return (
            this.site?.projects?.find((opportunity) => opportunity?.id === id)
                ?.name || "N/A"
        );
    }
}

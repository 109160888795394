<mat-card class="full">
    <mat-card-content>
        <h1 class="text-2xl font-bold mb-2">
            {{ project.name }}
            <ng-container
                *ngIf="
                    project.customer &&
                    project.name.indexOf(project.customer) === -1
                "
                >({{ project.customer }})</ng-container
            >
        </h1>
        <div class="detail-options">
            <span
                [class.selected]="selection === 'basic'"
                (click)="selection = 'basic'"
                >Qualification</span
            >
            |
            <span
                [class.selected]="selection === 'pre-sales'"
                (click)="selection = 'pre-sales'"
                >Discovery</span
            >
            |
            <span
                [class.selected]="selection === 'notes'"
                (click)="selection = 'notes'"
                >Notes & Updates</span
            >
        </div>
        <ng-container *ngIf="selection === 'basic'">
            <div class="margin">
                <b>Partner: </b><br />{{ project.partner }}
            </div>
            <div class="margin" *ngIf="project.country !== -1">
                <b>Country: </b><br />{{
                    project.country | idToArrayItem : allCountries
                }}
            </div>
            <div class="margin">
                <b>Expected Closing: </b><br />{{ project.deadline }}
            </div>
            <div class="margin">
                <b>Expected Revenue: </b><br />{{
                    project.revenue | round | numberToLocaleString
                }}
                EUR
            </div>
            <div class="margin" *ngIf="project.locked">
                <b>Locked: </b><br />
                <fa-icon
                    class="icon text-sewio"
                    [icon]="['fas', 'check']"
                ></fa-icon>
            </div>
            <div class="margin" *ngIf="!project.locked">
                <b>Locked: </b><br />
                <fa-icon
                    class="icon text-error-dark"
                    [icon]="['fas', 'times']"
                ></fa-icon>
            </div>
            <div class="margin">
                <b>Customer Problem: </b><br />{{ project.problem }}
            </div>
            <div class="margin">
                <b>Sewio Solution: </b><br />{{ project.solution }}
            </div>
            <div class="margin">
                <b>Use Case: </b><br />{{ project.useCase | format }}
            </div>
            <div class="margin">
                <div><b>Implementation Support:</b></div>
                <portal-status
                    class="without-padding"
                    *ngIf="project.implementationSupportTo === ''"
                    [type]="'info'"
                    [text]="'None'"
                ></portal-status>
                <portal-status
                    class="without-padding"
                    *ngIf="
                        project.implementationSupportTo !== '' &&
                        checkExpired(project.implementationSupportTo)
                    "
                    [type]="'info'"
                    [text]="'Expired'"
                ></portal-status>
                <portal-status
                    class="without-padding"
                    *ngIf="
                        project.implementationSupportTo !== '' &&
                        !checkExpired(project.implementationSupportTo) &&
                        checkExpiring(project.implementationSupportTo, 1)
                    "
                    [type]="'warning'"
                    [text]="'Will Expire Soon'"
                ></portal-status>
                <portal-status
                    class="without-padding"
                    *ngIf="
                        project.implementationSupportTo !== '' &&
                        !checkExpired(project.implementationSupportTo) &&
                        !checkExpiring(project.implementationSupportTo, 1)
                    "
                    [type]="'success'"
                    [text]="'Valid'"
                ></portal-status>
                <div
                    *ngIf="
                        project.implementationSupportFrom &&
                        project.implementationSupportTo
                    "
                >
                    ({{ project.implementationSupportFrom }} -
                    {{ project.implementationSupportTo }})
                </div>
                <div
                    *ngIf="
                        !project.implementationSupportFrom &&
                        project.implementationSupportTo
                    "
                >
                    ({{ project.implementationSupportTo }})
                </div>
            </div>
            <div class="margin">
                <div><b>Maintenance Support:</b></div>
                <portal-status
                    class="without-padding"
                    *ngIf="project.supportTo === ''"
                    [type]="'info'"
                    [text]="'None'"
                ></portal-status>
                <portal-status
                    class="without-padding"
                    *ngIf="
                        project.supportTo !== '' &&
                        checkExpired(project.supportTo)
                    "
                    [type]="'warning'"
                    [text]="'Expired'"
                ></portal-status>
                <portal-status
                    class="without-padding"
                    *ngIf="
                        project.supportTo !== '' &&
                        !checkExpired(project.supportTo) &&
                        checkExpiring(project.supportTo, 3)
                    "
                    [type]="'warning'"
                    [text]="'Will Expire Soon'"
                ></portal-status>
                <portal-status
                    class="without-padding"
                    *ngIf="
                        project.supportTo !== '' &&
                        !checkExpired(project.supportTo) &&
                        !checkExpiring(project.supportTo, 3)
                    "
                    [type]="'success'"
                    [text]="'Valid'"
                ></portal-status>
                <div *ngIf="project.supportFrom && project.supportTo">
                    ({{ project.supportFrom }} - {{ project.supportTo }})
                </div>
                <div *ngIf="!project.supportFrom && project.supportTo">
                    ({{ project.supportTo }})
                </div>
                <div
                    class="sla-type"
                    *ngIf="project.supportTo && project.slaType"
                >
                    {{ project.slaType }}
                </div>
            </div>
            <br />
        </ng-container>
        <ng-container *ngIf="selection === 'pre-sales'">
            <div class="margin">
                <b>Object of Tracking: </b><br />{{ project.trackedObjects }}
            </div>
            <div class="margin">
                <b>Number of Objects: </b><br />{{ project.numberOfObjects }}
            </div>
            <div class="margin">
                <b>Tag Mounting: </b><br />{{ project.tagMounting }}
            </div>
            <div class="margin">
                <b>Refresh Rate: </b><br />{{ project.refreshRate }}
            </div>
            <div class="margin">
                <b>Accuracy: </b><br />{{ project.accuracy }}
            </div>
            <div class="margin">
                <b>Battery Life: </b><br />{{ project.batteryLife }}
            </div>
            <div class="margin">
                <b>3D Localization: </b><br />
                <fa-icon
                    *ngIf="project.zAxis"
                    class="icon text-sewio"
                    [icon]="['fas', 'check']"
                ></fa-icon>
                <fa-icon
                    *ngIf="!project.zAxis"
                    class="icon text-error-dark"
                    [icon]="['fas', 'times']"
                ></fa-icon>
            </div>
        </ng-container>
        <ng-container *ngIf="selection === 'notes'">
            <div><b>Notes & Updates: </b></div>
            <div [innerHTML]="project.description | markdownToHtml"></div>
        </ng-container>
    </mat-card-content>
</mat-card>

import { NgModule } from "@angular/core";
import { BusinessReviewsRoutes } from "./business-reviews.routes";
import { AddBusinessReviewDialogComponent } from "./components/add-business-review-dialog/add-business-review-dialog.component";
import { BusinessReviewsListComponent } from "./components/business-reviews-list/business-reviews-list.component";
import { EditBusinessReviewDialogComponent } from "./components/edit-business-review-dialog/edit-business-review-dialog.component";
import { BusinessReviewsComponent } from "./views/business-reviews.component";
import { SharedModule } from "../../shared";

@NgModule({
    imports: [
        SharedModule,
        BusinessReviewsRoutes,
    ],
    declarations: [
        BusinessReviewsComponent,
        BusinessReviewsListComponent,
        AddBusinessReviewDialogComponent,
        EditBusinessReviewDialogComponent,
    ],
    providers: [],
})
export class BusinessReviewsModule {}

import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { BusinessReviewsComponent } from "./views/business-reviews.component";
import { AuthGuard } from "../../core";
import { menuGuard } from "../../core/guards/menu.guard";

const businessReviewsRoutes: Routes = [
    {
        path: "business_reviews",
        redirectTo: "business-reviews",
        pathMatch: "full",
    },
    {
        path: "business-reviews",
        component: BusinessReviewsComponent,
        data: {
            showTopPanel: true,
            showLeftPanel: true,
        },
        canActivate: [AuthGuard, menuGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(businessReviewsRoutes)],
    exports: [RouterModule],
})
export class BusinessReviewsRoutes {}

import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared";
import { TrainingsRoutes } from "./trainings.routes";
import { TrainingsComponent } from "./views/trainings/trainings.component";
import { TrainingResultsComponent } from "./views/training-results/training-results.component";
import { ResultListComponent } from "./components/result-list/result-list.component";

@NgModule({
    imports: [SharedModule, TrainingsRoutes],
    declarations: [
        TrainingsComponent,
        TrainingResultsComponent,
        ResultListComponent,
    ],
    providers: [],
})
export class TrainingsModule {}

import { NgModule } from "@angular/core";
import { ProjectRoutes } from "./project.routes";
import { SharedModule } from "../../shared";
import { ProjectComponent } from "./views/project/project.component";
import { ProjectDetailComponent } from "./components/project-detail/project-detail.component";
import { OrderListComponent } from "./components/order-list/order-list.component";
import { QuoteListComponent } from "./components/quote-list/quote-list.component";
import { EditDialogComponent } from "./components/edit-dialog/edit-dialog.component";
import { InvoiceListComponent } from "./components/invoice-list/invoice-list.component";
import { CreateDialogComponent } from "./components/create-dialog/create-dialog.component";
import { CreateTicketDialogComponent } from "./components/create-ticket-dialog/create-ticket-dialog.component";
import { StorageComponent } from "./components/storage/storage.component";
import { CreateFolderDialogComponent } from "./components/create-folder-dialog/create-folder-dialog.component";
import { DeleteFolderDialogComponent } from "./components/delete-folder-dialog/delete-folder-dialog.component";
import { UploadFileDialogComponent } from "./components/upload-file-dialog/upload-file-dialog.component";
import { DeleteFileDialogComponent } from "./components/delete-file-dialog/delete-file-dialog.component";
import { RenamePlanDialogComponent } from "./components/rename-plan-dialog/rename-plan-dialog.component";

@NgModule({
    imports: [SharedModule, ProjectRoutes],
    declarations: [
        ProjectComponent,
        ProjectDetailComponent,
        OrderListComponent,
        QuoteListComponent,
        InvoiceListComponent,
        EditDialogComponent,
        CreateDialogComponent,
        CreateTicketDialogComponent,
        CreateFolderDialogComponent,
        DeleteFolderDialogComponent,
        DeleteFileDialogComponent,
        UploadFileDialogComponent,
        RenamePlanDialogComponent,
        StorageComponent,
    ],
    providers: [],
})
export class ProjectModule {}

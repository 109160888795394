<div class="mat-elevation-z2 card">
    <div>
        <div>
            <mat-form-field>
                <input
                    #pickingName
                    matInput
                    placeholder="Picking Name"
                    (keyup.enter)="loadPicking(pickingName.value)"
                />
            </mat-form-field>
            <button
                class="horizontal-offset"
                mat-flat-button
                (click)="loadPicking(pickingName.value)"
            >
                LOAD
            </button>
        </div>
        <mat-spinner
            class="vertical-offset"
            *ngIf="loading"
            [diameter]="50"
            [strokeWidth]="5"
        ></mat-spinner>
        <table class="vertical-offset" *ngIf="scanning !== null">
            <thead>
                <tr>
                    <th>Product</th>
                    <th class="center">Scanned</th>
                    <th class="center">Saved</th>
                    <th class="center">Required</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let scannedProduct of scanning.scannedProducts"
                    [ngClass]="{
                        done: scannedProduct.saved === scannedProduct.quantity
                    }"
                >
                    <td>{{ scannedProduct.name }}</td>
                    <td class="center">
                        {{ countCurrentScans(scannedProduct.id) }}
                    </td>
                    <td class="center">{{ scannedProduct.saved }}</td>
                    <td class="center">{{ scannedProduct.quantity }}</td>
                </tr>
            </tbody>
        </table>
        <div class="legend">
            <h3>Status Legend</h3>
            <b class="legend-item text-stone-500">LOADING</b> product with this
            serial number is loading<br />
            <b class="legend-item text-sewio">SUCCESS</b> product loaded without
            errors<br />
            <b class="legend-item text-error-dark">C. DUPLICIT</b> product
            already scanned in this batch<br />
            <b class="legend-item text-error-dark">S. DUPLICIT</b> product
            already scanned in saved batch<br />
            <b class="legend-item text-error-dark">NOT FOUND</b> product with
            this serial was not found<br />
            <b class="legend-item text-error-dark">DIFFERENT</b> there are two
            types of products in this batch<br />
            <b class="legend-item text-error-dark">OVERFLOW</b> number of
            scanned & saved items is bigger than required amount<br />
            <b class="legend-item text-error-dark">UNWANTED</b> product not
            required by this picking<br />
        </div>
    </div>
    <div class="scanning-column">
        <div *ngIf="scanning !== null">
            <mat-form-field>
                <input
                    #serialInput
                    matInput
                    placeholder="Serial Number"
                    (keyup.enter)="addScan(serialInput.value)"
                />
            </mat-form-field>
            <button
                class="horizontal-offset"
                mat-flat-button
                (click)="addScan(serialInput.value)"
            >
                ENTER
            </button>
            <button
                class="horizontal-offset"
                color="warn"
                mat-flat-button
                [disabled]="currentScans.length === 0 || lastRemoved"
                (click)="removeLast()"
            >
                REMOVE LAST
            </button>
            <button
                class="horizontal-offset"
                color="warn"
                mat-flat-button
                (click)="clearScans()"
                [disabled]="currentScans.length === 0"
            >
                CLEAR
            </button>
            <button
                class="horizontal-offset"
                color="primary"
                mat-flat-button
                [disabled]="!isSuccess()"
                (click)="saveScans(pickingName.value)"
            >
                SAVE
            </button>
        </div>
        <div class="scannings vertical-offset" *ngIf="scanning !== null">
            <div
                class="scan"
                *ngFor="let scan of currentScans.slice().reverse()"
            >
                <div class="column-mac">{{ scan.serial }}</div>
                <div class="column-type">{{ scan.name }}</div>
                <portal-state
                    class="column-state"
                    [text]="getStateText(scan.state)"
                    [color]="getStateColor(scan.state)"
                ></portal-state>
            </div>
        </div>
    </div>
</div>

import { UsersComponent } from "./views/users/users.component";
import { SharedModule } from "../../shared";
import { UsersRoutes } from "./users.routes";
import { NgModule } from "@angular/core";
import { UserListComponent } from "./components/user-list/user-list.component";
import { InviteUserDialogComponent } from "./components/invite-user-dialog/invite-user-dialog.component";
import { EditUserDialogComponent } from "./components/edit-user-dialog/edit-user-dialog.component";

@NgModule({
    imports: [SharedModule, UsersRoutes],
    declarations: [
        UsersComponent,
        UserListComponent,
        InviteUserDialogComponent,
        EditUserDialogComponent,
    ],
    providers: [],
})
export class UsersModule {}

import { Component, Input, ViewChild, OnInit } from "@angular/core";
import { Order, Item } from "../../../../core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

@Component({
    selector: "portal-order-list",
    templateUrl: "./order-list.component.html",
    styleUrls: ["./order-list.component.scss"],
})
export class OrderListComponent implements OnInit {
    @ViewChild(MatSort, { static: false }) sort!: MatSort;

    displayedColumns: string[] = [
        "id",
        "amount",
        "delivery",
        "shipping",
        "sns",
    ];
    dataSource!: MatTableDataSource<Order>;

    private _orders!: Order[];

    get orders(): Order[] {
        return this._orders;
    }

    @Input()
    set orders(orders: Order[]) {
        if (orders) {
            this._orders = orders;
        } else {
            this._orders = [];
        }

        this.dataSource = new MatTableDataSource(this._orders);
    }

    ngOnInit() {
        this.dataSource.sort = this.sort;
    }

    downloadItems(name: string, items: Item[]) {
        let fileContent = "Name;MAC\n";

        for (const item of items) {
            fileContent += item.type + ";" + item.mac + "\n";
        }

        const file = new Blob([fileContent], { type: "text/plain" });

        const a = document.createElement("a"),
            url = URL.createObjectURL(file);

        a.href = url;
        a.download = name + ".csv";
        document.body.appendChild(a);
        a.click();

        setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    }
}

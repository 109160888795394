import { Component, Inject, ViewChild, ElementRef } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { PartnerActivityType } from "../../../../core";
import { FileUploadComponent } from "../../../../shared/components/file-upload/file-upload.component";
import { UploadService } from "../../../../core/services/upload.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "portal-create-partner-activity-dialog",
    styleUrls: ["create-partner-activity-dialog.component.scss"],
    templateUrl: "create-partner-activity-dialog.component.html",
})
export class CreatePartnerActivityDialogComponent {
    type: PartnerActivityType;

    typeValidator = new UntypedFormControl("", []);
    nameValidator = new UntypedFormControl("", []);
    descriptionValidator = new UntypedFormControl("", []);
    linkValidator = new UntypedFormControl("", []);

    uploading = false;
    uploadingProgress = 0;

    @ViewChild("fileUpload", { static: false })
    fileUpload!: FileUploadComponent;
    @ViewChild("nameInput", { static: false }) nameInput!: ElementRef;
    @ViewChild("linkInput", { static: false }) linkInput!: ElementRef;
    @ViewChild("descriptionInput", { static: false })
    descriptionInput!: ElementRef;

    constructor(
        private uploadService: UploadService,
        public dialogRef: MatDialogRef<CreatePartnerActivityDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { type: PartnerActivityType }
    ) {
        this.type = data.type;
        this.typeValidator.setValue(this.type.name);

        if (this.type.requireName) {
            this.nameValidator.setValidators([Validators.required]);
        }

        if (this.type.requireLink) {
            this.linkValidator.setValidators([Validators.required]);
        }

        if (this.type.requireDescription) {
            this.descriptionValidator.setValidators([Validators.required]);
        }
    }

    create(): void {
        if (this.nameValidator.invalid) {
            this.nameInput.nativeElement.focus();
            return;
        }

        if (this.linkValidator.invalid) {
            this.linkInput.nativeElement.focus();
            return;
        }

        if (this.descriptionValidator.invalid) {
            this.descriptionInput.nativeElement.focus();
            return;
        }

        const files = this.fileUpload ? this.fileUpload.getFiles() || [] : [];

        if (files.length !== 1 && !this.type.requireAttachment) {
            this.dialogRef.close({
                type: this.type.id,
                name: this.nameValidator.value,
                description: this.descriptionValidator.value,
                link: this.linkValidator.value,
                attachment: "",
            });
        } else if (files.length !== 1) {
            this.fileUpload.showError();
        } else {
            this.uploading = true;

            const files = this.fileUpload.getFiles();

            if (files === null) {
                return;
            }

            const uploadSubscription = this.uploadService
                .uploadActivity(files[0])
                .subscribe((observer) => {
                    switch (observer.status) {
                        case "progress":
                            this.uploadingProgress = observer.message as number;
                            break;
                        case "done":
                            uploadSubscription.unsubscribe();

                            this.dialogRef.close({
                                type: this.type.id,
                                name: this.nameValidator.value,
                                description: this.descriptionValidator.value,
                                link: this.linkValidator.value,
                                attachment: (
                                    observer.message as {
                                        url: string;
                                    }
                                ).url,
                            });
                            break;
                    }
                });
        }
    }
}

import { Component, Input, Output, EventEmitter } from "@angular/core";
import { File, FileUrlGQL } from "../../../core";
import { first } from "rxjs/operators";

@Component({
    selector: "portal-file",
    templateUrl: "./file.component.html",
    styleUrls: ["./file.component.scss"],
})
export class FileComponent {
    @Input() file!: File;

    @Output() deleteFile = new EventEmitter<{ path: string; name: string }>();
    @Output() openFileRequest = new EventEmitter();

    loading = false;

    constructor(private fileUrlGql: FileUrlGQL) {}

    openFile() {
        this.openFileRequest.emit();
        this.loading = true;
        this.fileUrlGql
            .watch({
                path: this.file.path,
                name: this.file.name,
                id: this.file.project,
            })
            .valueChanges.pipe(first())
            .subscribe((result) => {
                window.open(result.data.fileUrl, "_blank");
                this.loading = false;
            });
    }

    getFileDisplayName(name: string) {
        let displayName = name;

        if (!this.isUserFile(displayName)) {
            displayName = displayName.slice(1, displayName.length);
        }

        displayName = displayName.replace(
            /[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}\./g,
            ""
        );

        return displayName;
    }

    isUserFile(name: string) {
        return name.length > 0 && name.charAt(0) !== "$";
    }

    prettifyFileSize(size: string) {
        const bytes = parseInt(size, 10);

        if (bytes > 1024) {
            return Math.round(bytes / 1024) + " kB";
        }

        return bytes + " B";
    }
}

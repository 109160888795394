import { Component, Input } from "@angular/core";
import {
    TrainingResultsQuery,
    TrainingResult,
    UserQuery,
} from "../../../../core";
import { TimeUtils } from "@portal/shared";
import { MatTableDataSource } from "@angular/material/table";

@Component({
    selector: "portal-result-list",
    templateUrl: "./result-list.component.html",
    styleUrls: ["./result-list.component.scss"],
})
export class ResultListComponent {
    private _results!: TrainingResultsQuery["trainingResults"];
    private _meOnly!: boolean;
    private _user!: UserQuery["user"];

    get results(): TrainingResultsQuery["trainingResults"] {
        return this._results;
    }

    @Input()
    set results(results: TrainingResultsQuery["trainingResults"]) {
        this._results = results
            .slice()
            .sort((a, b) =>
                TimeUtils.isTimeBeforeOtherTime(a.time, b.time) ? 1 : -1
            );
        this.dataSource = new MatTableDataSource(this._results);
        this.dataSource.filterPredicate = (data, filter) => {
            if (filter === "Y" && this._user.email !== data.userEmail) {
                return false;
            }

            return true;
        };

        this.applyFilter();
    }

    get meOnly() {
        return this._meOnly;
    }

    @Input()
    set meOnly(meOnly: boolean) {
        this._meOnly = meOnly;

        this.applyFilter();
    }

    get user(): UserQuery["user"] {
        return this._user;
    }

    @Input()
    set user(user: UserQuery["user"]) {
        this._user = user;

        this.applyFilter();
    }

    displayedColumns: string[] = [
        "email",
        "test",
        "score",
        "result",
        "time",
        "open",
    ];
    pageSizes: number[] = [10, 20, 30];
    dataSource!: MatTableDataSource<TrainingResult>;

    applyFilter() {
        if (
            this._meOnly === undefined ||
            this.dataSource === undefined ||
            this._user === undefined
        ) {
            return;
        }

        this.dataSource.filter = this._meOnly ? "Y" : "N";
    }

    showResults(link: string) {
        window.open(link, "_blank");
    }
}

import { Component, OnInit, OnDestroy } from "@angular/core";
import {
    PartnerActivitiesGQL,
    PartnerActivity,
    PartnerActivityTypesGQL,
    PartnerActivityType,
    CreatePartnerActivityGQL,
    EditPartnerActivityGQL,
    PartnerActivitiesQuery,
    PartnerActivitiesDocument,
    PartnerStatusGQL,
    PartnerStatus,
    Invoice,
    PartnerInvoicesGQL,
    PartnerTrainingsGQL,
} from "../../../../core";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { CreatePartnerActivityDialogComponent } from "../../components/create-partner-activity-dialog/create-partner-activity-dialog.component";
import { EditPartnerActivityDialogComponent } from "../../components/edit-partner-activity-dialog/edit-partner-activity-dialog.component";
import { ToastrService } from "ngx-toastr";
import * as _ from "lodash";
import { LevelType } from "../../../../shared/components/level/level.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "portal-partner-program",
    templateUrl: "./partner-program.component.html",
    styleUrls: ["./partner-program.component.scss"],
})
export class PartnerProgramComponent implements OnInit, OnDestroy {
    activities: PartnerActivity[] | null = null;
    trainings: PartnerActivity[] | null = null;
    activityTypes: PartnerActivityType[] | null = null;
    partnerStatus: PartnerStatus | null = null;
    invoices: Invoice[] | null = null;

    selectedFilter = "all";

    loading = true;

    private subscriptions: Subscription[] = [];

    activityLimit: { [key: string]: boolean } = {};

    constructor(
        private partnerActivitiesGql: PartnerActivitiesGQL,
        private partnerActivityTypesGql: PartnerActivityTypesGQL,
        private partnerInvoicesGql: PartnerInvoicesGQL,
        private createPartnerActivityGql: CreatePartnerActivityGQL,
        private editPartnerActivityGql: EditPartnerActivityGQL,
        private partnerStatusGql: PartnerStatusGQL,
        private partnerTrainingsGql: PartnerTrainingsGQL,
        private toastrService: ToastrService,
        private dialog: MatDialog
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.partnerActivitiesGql
                .watch()
                .valueChanges.subscribe((result) => {
                    this.activities = result.data.partnerActivities;

                    if (
                        this.activityTypes !== null &&
                        this.invoices !== null &&
                        this.trainings !== null
                    ) {
                        this.checkActivityLimits();
                        this.loading = false;
                    }
                }),
            this.partnerActivityTypesGql
                .watch()
                .valueChanges.subscribe((result) => {
                    this.activityTypes = result.data.partnerActivityTypes
                        .slice()
                        .sort((a, b) => a.order - b.order);

                    if (
                        this.activities !== null &&
                        this.invoices !== null &&
                        this.trainings !== null
                    ) {
                        this.checkActivityLimits();
                        this.loading = false;
                    }
                }),
            this.partnerStatusGql.watch().valueChanges.subscribe((result) => {
                this.partnerStatus = result.data.partnerStatus;
            }),
            this.partnerInvoicesGql.watch().valueChanges.subscribe((result) => {
                this.invoices = result.data.partnerInvoices;

                if (
                    this.activities !== null &&
                    this.activityTypes !== null &&
                    this.trainings !== null
                ) {
                    this.checkActivityLimits();
                    this.loading = false;
                }
            }),
            this.partnerTrainingsGql
                .watch()
                .valueChanges.subscribe((result) => {
                    this.trainings = result.data.partnerTrainings;

                    if (
                        this.activities !== null &&
                        this.activityTypes !== null &&
                        this.invoices !== null
                    ) {
                        this.checkActivityLimits();
                        this.loading = false;
                    }
                })
        );
    }

    checkActivityLimits() {
        if (this.activityTypes === null || this.activities === null) {
            return;
        }

        for (const activityType of this.activityTypes) {
            const activitiesOfType = this.activities.filter(
                (activity) =>
                    activity.type === activityType.id &&
                    activity.state !== "expired"
            );

            if (
                activityType.limit === 0 ||
                activityType.limit > activitiesOfType.length
            ) {
                this.activityLimit[activityType.id] = false;
            } else {
                this.activityLimit[activityType.id] = true;
            }
        }
    }

    ngOnDestroy() {
        while (this.subscriptions.length) {
            this.subscriptions.pop()?.unsubscribe();
        }
    }

    createActivity(activityType: PartnerActivityType) {
        const dialogReference = this.dialog.open(
            CreatePartnerActivityDialogComponent,
            {
                width: window.innerWidth >= 768 ? "600px" : "300px",
                data: { activityTypes: this.activityTypes, type: activityType },
            }
        );

        dialogReference
            .afterClosed()
            .pipe(first())
            .subscribe((result) => {
                if (result) {
                    this.createPartnerActivityGql
                        .mutate(
                            {
                                name: result.name,
                                type: result.type,
                                description: result.description,
                                link: result.link,
                                attachment: result.attachment,
                            },
                            {
                                update: (store, { data }) => {
                                    if (!data) {
                                        return;
                                    }

                                    this.toastrService.success(
                                        "Activity created."
                                    );

                                    const partnerActivities =
                                        store.readQuery<PartnerActivitiesQuery>(
                                            {
                                                query: PartnerActivitiesDocument,
                                            }
                                        );

                                    if (partnerActivities === null) {
                                        return;
                                    }

                                    store.writeQuery({
                                        query: PartnerActivitiesDocument,
                                        data: {
                                            partnerActivities:
                                                partnerActivities.partnerActivities.concat(
                                                    [data.createPartnerActivity]
                                                ),
                                        },
                                    });
                                },
                            }
                        )
                        .subscribe()
                        .unsubscribe();
                }
            });
    }

    editActivity(event: { id: string }) {
        if (this.activities === null || this.activityTypes === null) {
            return;
        }

        const activity = this.activities.find(
            (foundActivity) => foundActivity.id === event.id
        );

        if (!activity) {
            return;
        }

        const dialogReference = this.dialog.open(
            EditPartnerActivityDialogComponent,
            {
                width: window.innerWidth >= 768 ? "600px" : "300px",
                data: {
                    activityTypes: this.activityTypes,
                    activity: activity,
                    type: this.activityTypes.find(
                        (activityType) => activityType.id === activity.type
                    ),
                },
            }
        );

        dialogReference
            .afterClosed()
            .pipe(first())
            .subscribe((result) => {
                if (result) {
                    this.editPartnerActivityGql
                        .mutate(
                            {
                                id: result.id,
                                name: result.name,
                                link: result.link,
                                description: result.description,
                                attachment: result.attachment,
                            },
                            {
                                update: (store, { data }) => {
                                    this.toastrService.success(
                                        "Activity edited."
                                    );

                                    if (!data) {
                                        return;
                                    }

                                    const clonedData = _.cloneDeep(
                                        store.readQuery<PartnerActivitiesQuery>(
                                            {
                                                query: PartnerActivitiesDocument,
                                            }
                                        )
                                    );

                                    if (clonedData === null) {
                                        return;
                                    }

                                    for (
                                        let i = 0;
                                        i < clonedData.partnerActivities.length;
                                        i++
                                    ) {
                                        if (
                                            clonedData.partnerActivities[i]
                                                .id ===
                                            data.editPartnerActivity.id
                                        ) {
                                            clonedData.partnerActivities[i] =
                                                data.editPartnerActivity;
                                            break;
                                        }
                                    }

                                    store.writeQuery({
                                        query: PartnerActivitiesDocument,
                                        data: clonedData,
                                    });
                                },
                            }
                        )
                        .subscribe()
                        .unsubscribe();
                }
            });
    }

    toLevelType(type: string): LevelType {
        return type as LevelType;
    }
}

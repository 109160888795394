<div mat-dialog-title class="title">Upload Files</div>
<div mat-dialog-content>
    <div class="form">
        <mat-form-field>
            <mat-label>Parent Folder</mat-label>
            <mat-select #parentSelect [formControl]="parentValidator">
                <mat-option *ngFor="let type of parents" [value]="type.value">
                    {{ type.viewValue }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <portal-file-upload #fileUpload></portal-file-upload>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CANCEL</button>
    <button mat-button color="primary" cdkFocusInitial (click)="upload()">
        UPLOAD
    </button>
</mat-dialog-actions>

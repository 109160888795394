import { Component, Input } from "@angular/core";

@Component({
    selector: "portal-card[headline]",
    templateUrl: "./card.component.html",
    styleUrls: ["./card.component.scss"],
})
export class CardComponent {
    @Input() headline!: string;
    @Input() subtitle?: string;
}

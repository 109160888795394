import { Component, Input } from "@angular/core";

export type StatusType =
    | "success"
    | "warning"
    | "danger"
    | "info"
    | "none"
    | "running"
    | "installing"
    | "stopped"
    | "stopping"
    | "prolonged"
    | "starting"
    | "started"
    | "deleted"
    | "deleting";

@Component({
    selector: "portal-status",
    templateUrl: "./status.component.html",
    styleUrls: ["./status.component.scss"],
})
export class StatusComponent {
    private _type!: StatusType;
    colorClass = "sewio-blue";
    icon = "info";

    @Input() text!: string | number;
    @Input() big = false;
    @Input() disabled = false;

    get type() {
        return this._type;
    }

    @Input()
    set type(type: StatusType) {
        switch (type) {
            case "success":
                this.colorClass = "text-sewio";
                this.icon = "check_box";
                break;
            case "warning":
                this.colorClass = "text-warning";
                this.icon = "warning";
                break;
            case "danger":
                this.colorClass = "text-error";
                this.icon = "dangerous";
                break;
            case "info":
                this.colorClass = "text-info";
                this.icon = "info";
                break;
            case "none":
                this.colorClass = "text-stone-400";
                this.icon = "do_not_disturb";
                break;
            case "running":
                this.colorClass = "text-sewio";
                this.icon = "play_arrow";
                break;
            case "prolonged":
                this.colorClass = "text-info";
                this.icon = "hourglass_top";
                break;
            case "stopped":
                this.colorClass = "text-error";
                this.icon = "stop";
                break;
            case "stopping":
                this.colorClass = "text-warning";
                this.icon = "stop";
                break;
            case "starting":
                this.colorClass = "text-warning";
                this.icon = "play_arrow";
                break;
            case "installing":
                this.colorClass = "text-warning";
                this.icon = "settings";
                break;
            case "started":
                this.colorClass = "text-sewio";
                this.icon = "play_arrow";
                break;
            case "deleted":
                this.colorClass = "text-error";
                this.icon = "delete_forever";
                break;
            case "deleting":
                this.colorClass = "text-warning";
                this.icon = "delete_forever";
                break;
        }

        this._type = type;
    }
}

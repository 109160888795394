import {
    Component,
    OnInit,
    HostListener,
    ViewChild,
    ElementRef,
} from "@angular/core";
import { AuthService, ResetCompanyGQL } from "../../../../core";
import { first } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Apollo } from "apollo-angular";

@Component({
    selector: "portal-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
    private returnUrl!: string;

    loading = false;

    @ViewChild("email", { static: false }) email!: ElementRef;
    @ViewChild("password", { static: false }) password!: ElementRef;

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private toastrService: ToastrService,
        private apollo: Apollo,
        private resetCompanyGql: ResetCompanyGQL
    ) {}

    ngOnInit() {
        this.authService.logOut();
        this.resetCompanyGql.mutate().pipe(first()).subscribe();
        this.returnUrl = this.route.snapshot.queryParams["from"] || "/";
    }

    @HostListener("window:keydown", ["$event"])
    handleKeyDown(event: KeyboardEvent) {
        if (event.key === "Enter") {
            this.logIn();
        }
    }

    logIn() {
        const emailValue = (this.email.nativeElement as HTMLInputElement).value;
        const passwordValue = (this.password.nativeElement as HTMLInputElement)
            .value;

        if (emailValue === "" || passwordValue === "") {
            this.toastrService.error("Email or password not entered.");
            return;
        }

        this.loading = true;

        this.authService
            .logIn(emailValue, passwordValue)
            .pipe(first())
            .subscribe(
                () => {
                    this.apollo.client.resetStore();

                    if (this.returnUrl.indexOf("http") !== -1) {
                        window.location.href = this.returnUrl;
                    } else {
                        void this.router.navigate([this.returnUrl]);
                    }
                },
                (error) => {
                    this.loading = false;
                    this.toastrService.error(error.error.message);
                }
            );
    }
}

import { Component, Input, Output, EventEmitter } from "@angular/core";
import {
    trigger,
    state,
    style,
    transition,
    animate,
} from "@angular/animations";
import { PartnerActivity, PartnerActivityType } from "../../../../core";
import { MatTableDataSource } from "@angular/material/table";

@Component({
    selector: "portal-partner-activity-list",
    templateUrl: "./partner-activity-list.component.html",
    styleUrls: ["./partner-activity-list.component.scss"],
    animations: [
        trigger("detailExpand", [
            state("collapsed", style({ height: "0px", minHeight: "0" })),
            state("expanded", style({ height: "*" })),
            transition(
                "expanded <=> collapsed",
                animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
            ),
        ]),
    ],
})
export class PartnerActivityListComponent {
    private _activities!: PartnerActivity[];
    private _activityType!: PartnerActivityType;
    private _filter!: string;

    get filter(): string {
        return this._filter;
    }

    @Input()
    set filter(filter: string) {
        this._filter = filter;

        if (this.dataSource) {
            this.dataSource.filter = this._filter;
        }
    }

    get activities(): PartnerActivity[] {
        return this._activities;
    }

    @Input()
    set activities(activities: PartnerActivity[]) {
        if (activities) {
            this._activities = activities
                .slice()
                .sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10));
        } else {
            this._activities = [];
        }

        this.dataSource = new MatTableDataSource(this._activities);
        this.dataSource.filterPredicate = (data, filter) => {
            if (filter === "active" && data.state === "expired") {
                return false;
            } else if (filter === "expired" && data.state !== "expired") {
                return false;
            }

            return true;
        };
    }

    get activityType(): PartnerActivityType {
        return this._activityType;
    }

    @Input()
    set activityType(type: PartnerActivityType) {
        this._activityType = type;

        if (type.showName) {
            this.displayedColumns = ["name", ...this.displayedColumns];
        }
    }

    @Output() editActivity = new EventEmitter<{ id: string }>();

    displayedColumns: string[] = [
        "expiration",
        "state",
        "points",
        "actions",
        "expand",
    ];

    dataSource!: MatTableDataSource<PartnerActivity>;

    expandedActivity!: string | null;

    expandActivity($event: Event, id: string) {
        $event.stopPropagation();
        $event.preventDefault();

        this.expandedActivity = this.expandedActivity === id ? null : id;
    }
}

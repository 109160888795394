import { Component, ViewChild, ElementRef } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";

@Component({
    selector: "portal-invite-user-dialog",
    styleUrls: ["invite-user-dialog.component.scss"],
    templateUrl: "invite-user-dialog.component.html",
})
export class InviteUserDialogComponent {
    @ViewChild("emailInput", { static: false }) emailInput!: ElementRef;
    @ViewChild("roleSelect", { static: false }) roleSelect!: MatSelect;

    emailValidator = new UntypedFormControl("", [Validators.required]);
    roleValidator = new UntypedFormControl("", [Validators.required]);

    roles = ["user", "admin"];

    constructor(public dialogRef: MatDialogRef<InviteUserDialogComponent>) {}

    invite(): void {
        if (this.emailValidator.invalid) {
            this.emailInput.nativeElement.focus();
            return;
        }

        if (this.roleValidator.invalid) {
            this.roleSelect.open();
            return;
        }

        this.dialogRef.close({
            email: this.emailInput.nativeElement.value,
            role: this.roleValidator.value,
        });
    }
}

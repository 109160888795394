import { Component, ViewChild, Inject } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { FileUploadComponent } from "../../../../shared/components/file-upload/file-upload.component";

@Component({
    selector: "portal-upload-file-dialog",
    styleUrls: ["upload-file-dialog.component.scss"],
    templateUrl: "upload-file-dialog.component.html",
})
export class UploadFileDialogComponent {
    @ViewChild("parentSelect", { static: false }) parentSelect!: MatSelect;
    @ViewChild("fileUpload", { static: false })
    fileUpload!: FileUploadComponent;

    parentValidator = new UntypedFormControl("", []);

    parents: { value: string; viewValue: string }[];

    constructor(
        public dialogRef: MatDialogRef<UploadFileDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { value: string; viewValue: string }[]
    ) {
        this.parents = data;
    }

    upload(): void {
        if (this.parentValidator.invalid) {
            this.parentSelect.open();
            return;
        }

        const files = this.fileUpload.getFiles();

        if (files === null) {
            this.fileUpload.showError();
            return;
        }

        this.dialogRef.close({
            parent: this.parentValidator.value,
            files: files,
        });
    }
}

<div mat-dialog-title class="title">Rename Plan</div>
<div mat-dialog-content>
    <div class="form">
        <mat-form-field>
            <input
                #nameInput
                matInput
                placeholder="Plan Name"
                [formControl]="nameValidator"
                required
            />
        </mat-form-field>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CANCEL</button>
    <button mat-button color="primary" cdkFocusInitial (click)="rename()">
        RENAME
    </button>
</mat-dialog-actions>

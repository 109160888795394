import {
    IconPrefix,
    IconDefinition,
    IconName
} from "@fortawesome/fontawesome-svg-core";

const faSewio = {
    prefix: "fab" as IconPrefix,
    iconName: "sewio" as IconName,
    icon: [
        400,
        400,
        [],
        "",
        "M 159.053 78.23 c -5.076 0.45 -7.636 0.917 -12.68 1.55 c -13.317 1.672 -20.048 7.125 -20.052 20.308 c -0.004 0.88 -0.004 1.761 -0.004 2.645 c 0.004 15.739 4.91 22.694 20.649 25.4 c 13.292 2.284 26.889 3.538 40.376 3.741 c 21.704 0.321 59.792 0.272 90.556 0.227 c 11.759 -0.016 22.446 -0.032 30.748 -0.032 c 0 0 -8.444 -15.759 -17.2 -24.219 c -12.801 -12.376 -26.07 -30.35 -82.502 -30.35 C 187.561 77.5 166.932 77.865 159.053 78.23 M 60 175 v 0.156 c 0 4.049 0.885 15.463 4.33 22.661 c 7.04 14.704 19.596 24.552 36.179 30.939 c 21.623 8.33 44.352 10.854 67.224 11.527 c 48.922 1.432 107.43 1.85 152.213 -3.124 c 8.902 -0.986 14.021 -5.352 16.966 -10.805 c 0 -0.008 0.001 -0.028 -0.003 -0.037 c 0.016 -0.012 0.042 -0.012 0.054 -0.024 c 2.077 -3.883 3.037 -8.764 3.037 -15.037 v -2.646 c 0 -15.082 -4.381 -21.062 -19.325 -24.276 c -8.196 -1.769 -16.719 -2.816 -25.097 -2.897 c -16.526 -0.154 -33.061 -0.154 -49.584 -0.15 c -22.596 0 -45.199 0.004 -67.787 -0.402 c -22.876 -0.41 -45.628 -3.193 -67.246 -11.523 c -20.685 -7.969 -35.249 -21.237 -40.239 -42.803 C 65.811 133.141 60 152.714 60 174.889 M 121.622 283.569 c 1.384 1.81 48.422 51.206 89.837 51.206 c 38.133 0 80.258 -50.463 80.655 -51.23 C 229.43 293.068 177.287 291.205 121.622 283.569"
    ]
} as IconDefinition;

export default faSewio;

import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { CompanyComponent } from "./views/company/company.component";
import { AuthGuard } from "../../core";

const companyRoutes: Routes = [
    {
        path: "company",
        component: CompanyComponent,
        data: {
            showTopPanel: true,
            showLeftPanel: true,
        },
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(companyRoutes)],
    exports: [RouterModule],
})
export class CompanyRoutes {}

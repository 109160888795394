import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Site, SiteUpdate } from "@portal/shared";

@Component({
    selector: "portal-update-modal",
    styleUrls: [],
    templateUrl: "update-modal.component.html",
})
export class UpdateModalComponent {
    update: SiteUpdate;
    site: Site;

    constructor(
        public dialogRef: MatDialogRef<UpdateModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { update: SiteUpdate; site: Site }
    ) {
        this.update = data.update;
        this.site = data.site;
    }

    opportunityIdToName(id: string | null): string | undefined {
        return (
            this.site?.projects?.find((opportunity) => opportunity.id === id)
                ?.name || "N/A"
        );
    }
}

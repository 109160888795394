<div mat-dialog-title class="title">Start RTLS Player</div>
<div mat-dialog-content>
    <div class="form">
        <div class="pr-3">
            <div class="mb-2">Hours Available</div>
            <mat-slider
                class="w-full"
                [max]="60 * 8"
                [min]="30"
                [step]="30"
                [displayWith]="formatMinutes"
                (change)="onSliderChange()"
                discrete
                showTickMarks
            >
                <input matSliderThumb [(ngModel)]="value" />
            </mat-slider>
            <div class="columns">
                <div>Will be deleted approx. at:</div>
                <div>{{ endTime }}</div>
            </div>
        </div>
        <div class="top-margin">
            <mat-checkbox [(ngModel)]="deleteOnTime"
                >Delete After Time's Up</mat-checkbox
            ><br />
            <mat-hint align="start" class="hint">
                All the data will be lost.
            </mat-hint>
        </div>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CANCEL</button>
    <button mat-button color="primary" cdkFocusInitial (click)="start()">
        START
    </button>
</mat-dialog-actions>

import { Component, Input } from "@angular/core";

@Component({
    selector: "portal-link[href]",
    templateUrl: "./link.component.html",
    styleUrls: [],
})
export class LinkComponent {
    @Input() href!: string;
    @Input() target?: string;
}

<div *ngIf="user !== null && user.companyRole === 'admin'" class="results-controls-container">
    <div></div>
    <mat-checkbox
        [labelPosition]="'before'"
        class="checkbox"
        [checked]="meOnly"
        (change)="onMeOnlyChanged()"
    >
        My Results Only
    </mat-checkbox>
</div>
<portal-loading *ngIf="results === null" [height]="48"></portal-loading>
<portal-result-list
    *ngIf="results !== null && user !== null"
    [results]="results"
    [user]="user"
    [meOnly]="meOnly"
></portal-result-list>

import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared";
import { SitesRoutes } from "./sites.routes";
import { SitesComponent } from "./views/sites.component";

@NgModule({
    imports: [SharedModule, SitesRoutes],
    declarations: [SitesComponent],
    providers: [],
})
export class SitesModule {}

import { SerialEvidenceComponent } from "./views/serial-evidence/serial-evidence.component";
import { InternalsRoutes } from "./internals.routes";
import { NgModule } from "@angular/core";
import { StateComponent } from "./components/state/state.component";
import { SharedModule } from "../../shared";

@NgModule({
    imports: [
        SharedModule,
        InternalsRoutes,
    ],
    declarations: [SerialEvidenceComponent, StateComponent],
    providers: [],
})
export class InternalsModule {}

import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import {
    TrainingResultsQuery,
    TrainingResultsGQL,
    UserQuery,
    UserGQL,
} from "../../../../core";

@Component({
    selector: "portal-training-results",
    templateUrl: "./training-results.component.html",
    styleUrls: ["./training-results.component.scss"],
})
export class TrainingResultsComponent implements OnInit, OnDestroy {
    results: TrainingResultsQuery["trainingResults"] | null = null;
    user: UserQuery["user"] | null = null;

    meOnly = false;

    private subscriptions: Subscription[] = [];

    constructor(
        private trainingResultsGql: TrainingResultsGQL,
        private userGql: UserGQL
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.trainingResultsGql.watch().valueChanges.subscribe((result) => {
                this.results = result.data.trainingResults;
            }),
            this.userGql.watch().valueChanges.subscribe((result) => {
                this.user = result.data.user;
            })
        );
    }

    ngOnDestroy() {
        while (this.subscriptions.length) {
            this.subscriptions.pop()?.unsubscribe();
        }
    }

    onMeOnlyChanged() {
        this.meOnly = !this.meOnly;
    }
}

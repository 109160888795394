import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import {
    UserQuery,
    CompanyQuery,
    UserGQL,
    CompanyGQL,
    SetCompanyGQL,
} from "../../../../core";
import { AnalyticsService } from "../../../../core/services/analytics.service";
import { Store } from "@ngxs/store";
import { TogglePanel } from "../../../../core/store/ui.state";
import { IconName, IconProp } from "@fortawesome/fontawesome-svg-core";
import { Apollo } from "apollo-angular";

@Component({
    selector: "portal-top-panel",
    templateUrl: "./top-panel.component.html",
    styleUrls: ["./top-panel.component.scss"],
})
export class TopPanelComponent implements OnInit, OnDestroy {
    user: UserQuery["user"] | null = null;
    company: CompanyQuery["company"] | null = null;

    sewioIcon: IconProp = ["fab", "sewio" as IconName];

    private subscriptions: Subscription[] = [];

    constructor(
        private store: Store,
        private userGql: UserGQL,
        private companyGql: CompanyGQL,
        private router: Router,
        private analyticsService: AnalyticsService,
        private setCompanyGql: SetCompanyGQL,
        private apollo: Apollo
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.userGql.watch().valueChanges.subscribe((result) => {
                this.user = result.data.user;

                if (this.user) {
                    this.analyticsService.setUser(result.data.user.id);
                }
            }),
            this.companyGql.watch().valueChanges.subscribe((result) => {
                this.company = result.data.company;
            })
        );
    }

    ngOnDestroy() {
        while (this.subscriptions.length) {
            this.subscriptions.pop()?.unsubscribe();
        }
    }

    togglePanel() {
        this.store.dispatch(new TogglePanel());
    }

    logOut() {
        void this.router.navigate(["/login"]);
    }

    redirectToDashboard() {
        window.location.href = "/dashboard";
    }

    resetCompany() {
        this.setCompanyGql
            .mutate(
                { id: "1" },
                {
                    update: () => {
                        this.apollo.client.resetStore();
                    },
                }
            )
            .subscribe()
            .unsubscribe();

        void this.router.navigate(["/dashboard"]);
    }
}

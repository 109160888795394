import { Component, OnInit } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { CompanyQuery, CompanyGQL } from "../../../../core";

@Component({
    selector: "portal-company",
    templateUrl: "./company.component.html",
    styleUrls: ["./company.component.scss"],
})
export class CompanyComponent implements OnInit {
    company!: Observable<CompanyQuery["company"]>;

    constructor(private companyGql: CompanyGQL) {}

    ngOnInit() {
        this.company = this.companyGql
            .watch()
            .valueChanges.pipe(map((result) => result.data.company));
    }
}

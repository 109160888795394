import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared";
import { ProjectsRoutes } from "./projects.routes";
import { ProjectsComponent } from "./views/projects/projects.component";
import { RegisterDialogComponent } from "./components/register-dialog/register-dialog.component";

@NgModule({
    imports: [SharedModule, ProjectsRoutes],
    declarations: [ProjectsComponent, RegisterDialogComponent],
    providers: [],
})
export class ProjectsModule {}

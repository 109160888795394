import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "portal-delete-file-dialog",
    styleUrls: ["delete-file-dialog.component.scss"],
    templateUrl: "delete-file-dialog.component.html",
})
export class DeleteFileDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<DeleteFileDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: object
    ) {}

    delete(): void {
        this.dialogRef.close(true);
    }
}

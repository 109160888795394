<div class="mat-elevation-z2 table-container">
    <table
        class="projects-table"
        mat-table
        [dataSource]="dataSource"
        matSort
        multiTemplateDataRows
    >
        <ng-container matColumnDef="name">
            <th
                class="name-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Name
            </th>
            <td class="name-column" mat-cell *matCellDef="let row">
                {{ row.name }}
            </td>
        </ng-container>

        <ng-container matColumnDef="date">
            <th
                class="date-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Created At
            </th>
            <td class="date-column" mat-cell *matCellDef="let row">
                {{ row.createdAt }}
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th
                class="status-column"
                mat-header-cell
                *matHeaderCellDef
                mat-header
            >
                Status
            </th>
            <td class="status-column" mat-cell *matCellDef="let row">
                <div class="statuses gap-2">
                    <portal-status
                        [type]="'success'"
                        [big]="true"
                        [text]="projectValues[row.id].success"
                        [disabled]="projectValues[row.id].success === 0"
                    ></portal-status>
                    <portal-status
                        [type]="'warning'"
                        [big]="true"
                        [text]="projectValues[row.id].warning"
                        [disabled]="projectValues[row.id].warning === 0"
                    ></portal-status>
                    <portal-status
                        [type]="'danger'"
                        [big]="true"
                        [text]="projectValues[row.id].danger"
                        [disabled]="projectValues[row.id].danger === 0"
                    ></portal-status>
                    <portal-status
                        [type]="'info'"
                        [big]="true"
                        [text]="projectValues[row.id].info"
                        [disabled]="projectValues[row.id].info === 0"
                    ></portal-status>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="stage">
            <th
                class="stage-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Stage
            </th>
            <td class="stage-column" mat-cell *matCellDef="let row">
                {{ row.stage }}
            </td>
        </ng-container>

        <ng-container matColumnDef="expand">
            <th class="expand-column" mat-header-cell *matHeaderCellDef>
                Expand
            </th>
            <td
                class="expand-column"
                mat-cell
                *matCellDef="let row"
                (click)="expandProject($event, row.id)"
            >
                <fa-icon
                    class="expand-icon"
                    *ngIf="expandedProject !== row.id"
                    [icon]="['fas', 'angle-down']"
                ></fa-icon>
                <fa-icon
                    class="expand-icon"
                    *ngIf="expandedProject === row.id"
                    [icon]="['fas', 'angle-up']"
                ></fa-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
            <td
                mat-cell
                *matCellDef="let element"
                [attr.colspan]="displayedColumns.length"
            >
                <div
                    class="detail"
                    [@detailExpand]="
                        element.id === expandedProject
                            ? 'expanded'
                            : 'collapsed'
                    "
                >
                    <div class="detail-container">
                        <div class="full">
                            <div class="left-column">Support Services:</div>
                            <portal-status
                                [text]="projectValues[element.id].supportText"
                                [type]="projectValues[element.id].supportStatus"
                            ></portal-status>
                        </div>
                        <div class="full">
                            <div class="left-column">Incidents:</div>
                            <portal-status
                                *ngIf="
                                    projectValues[element.id]
                                        .criticalIncidents > 0
                                "
                                [type]="'danger'"
                                [text]="
                                    projectValues[element.id]
                                        .criticalIncidents +
                                    ' Critical Incident(s)'
                                "
                            ></portal-status>
                            <portal-status
                                *ngIf="
                                    projectValues[element.id]
                                        .criticalIncidents === 0 &&
                                    projectValues[element.id].otherIncidents > 0
                                "
                                [type]="'warning'"
                                [text]="
                                    projectValues[element.id].otherIncidents +
                                    ' Incidents'
                                "
                            ></portal-status>
                            <portal-status
                                *ngIf="
                                    projectValues[element.id].otherIncidents ===
                                        0 &&
                                    projectValues[element.id]
                                        .criticalIncidents === 0 &&
                                    projectValues[element.id].closedIncidents >
                                        0
                                "
                                [type]="'success'"
                                [text]="'All Resolved'"
                            ></portal-status>
                            <portal-status
                                *ngIf="
                                    projectValues[element.id].otherIncidents ===
                                        0 &&
                                    projectValues[element.id]
                                        .criticalIncidents === 0 &&
                                    projectValues[element.id]
                                        .closedIncidents === 0
                                "
                                [type]="'none'"
                                [text]="'None'"
                            ></portal-status>
                        </div>
                        <div class="full">
                            <div class="left-column">Orders:</div>
                            <portal-status
                                *ngIf="
                                    projectValues[element.id].ordersInProgress >
                                    0
                                "
                                [type]="'info'"
                                [text]="
                                    projectValues[element.id].ordersInProgress +
                                    ' In Progress'
                                "
                            ></portal-status>
                            <portal-status
                                *ngIf="
                                    projectValues[element.id]
                                        .ordersInProgress === 0 &&
                                    projectValues[element.id].ordersDone === 0
                                "
                                [type]="'none'"
                                [text]="'None'"
                            ></portal-status>
                            <portal-status
                                *ngIf="
                                    projectValues[element.id]
                                        .ordersInProgress === 0 &&
                                    projectValues[element.id].ordersDone > 0
                                "
                                [type]="'success'"
                                [text]="'All Done'"
                            ></portal-status>
                        </div>
                        <div class="full">
                            <div class="left-column">Invoices:</div>
                            <portal-status
                                *ngIf="
                                    projectValues[element.id]
                                        .invoicesOverdue === 0 &&
                                    projectValues[element.id]
                                        .invoicesToBePaid === 0 &&
                                    projectValues[element.id]
                                        .invoicesToBePaidInAWeek === 0 &&
                                    projectValues[element.id].invoicesPaid > 0
                                "
                                [type]="'success'"
                                [text]="'All Paid'"
                            ></portal-status>
                            <portal-status
                                *ngIf="
                                    projectValues[element.id]
                                        .invoicesOverdue === 0 &&
                                    projectValues[element.id]
                                        .invoicesToBePaid === 0 &&
                                    projectValues[element.id]
                                        .invoicesToBePaidInAWeek === 0 &&
                                    projectValues[element.id].invoicesPaid === 0
                                "
                                [type]="'none'"
                                [text]="'None'"
                            ></portal-status>
                            <portal-status
                                *ngIf="
                                    projectValues[element.id].invoicesOverdue >
                                    0
                                "
                                [type]="'danger'"
                                [text]="
                                    projectValues[element.id].invoicesOverdue +
                                    ' Overdue'
                                "
                            ></portal-status>
                            <portal-status
                                *ngIf="
                                    projectValues[element.id]
                                        .invoicesOverdue === 0 &&
                                    projectValues[element.id]
                                        .invoicesToBePaidInAWeek > 0
                                "
                                [type]="'warning'"
                                [text]="
                                    projectValues[element.id].invoicesToBePaid +
                                    projectValues[element.id]
                                        .invoicesToBePaidInAWeek +
                                    ' Unpaid (' +
                                    projectValues[element.id].invoicesDue +
                                    ')'
                                "
                            ></portal-status>
                            <portal-status
                                *ngIf="
                                    projectValues[element.id]
                                        .invoicesOverdue === 0 &&
                                    projectValues[element.id]
                                        .invoicesToBePaidInAWeek === 0 &&
                                    projectValues[element.id].invoicesToBePaid >
                                        0
                                "
                                [type]="'info'"
                                [text]="
                                    projectValues[element.id].invoicesToBePaid +
                                    ' Unpaid'
                                "
                            ></portal-status>
                        </div>
                        <div class="full">
                            <div class="left-column">Plans:</div>
                            <portal-status
                                *ngIf="projectValues[element.id].plans === 0"
                                [type]="'none'"
                                [text]="'None'"
                            ></portal-status>
                            <portal-status
                                *ngIf="
                                    projectValues[element.id].plans !== 0 &&
                                    projectValues[element.id].plans ===
                                        projectValues[element.id].approvedPlans
                                "
                                [type]="'success'"
                                [text]="'All Approved'"
                            ></portal-status>
                            <portal-status
                                *ngIf="
                                    projectValues[element.id].plans !== 0 &&
                                    projectValues[element.id].plans !==
                                        projectValues[element.id].approvedPlans
                                "
                                [type]="'info'"
                                [text]="
                                    projectValues[element.id].plans -
                                    projectValues[element.id].approvedPlans +
                                    ' Unapproved'
                                "
                            ></portal-status>
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="project-row"
            [class.expanded-row]="expandedProject === row.id"
            (click)="openProject(row.id)"
        ></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: ['expandedDetail']"
            class="detail-row"
        ></tr>
    </table>

    <mat-paginator
        class="paginator"
        [pageSizeOptions]="pageSizes"
    ></mat-paginator>
</div>

import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";

import { AuthGuard } from "../../core";
import { ProjectsComponent } from "./views/projects/projects.component";
import { menuGuard } from "../../core/guards/menu.guard";

const projectsRoutes: Routes = [
    {
        path: "projects",
        redirectTo: "opportunities",
        pathMatch: "full",
    },
    {
        path: "opportunities",
        component: ProjectsComponent,
        data: {
            showTopPanel: true,
            showLeftPanel: true,
        },
        canActivate: [AuthGuard, menuGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(projectsRoutes)],
    exports: [RouterModule],
})
export class ProjectsRoutes {}

<div class="table-container mat-elevation-z2">
    <table
        class="plans-table"
        mat-table
        [dataSource]="dataSource"
        *ngIf="plans.length !== 0"
    >
        <ng-container matColumnDef="number">
            <th class="number-column" mat-header-cell *matHeaderCellDef>#</th>
            <td
                class="number-column"
                mat-cell
                *matCellDef="let row; let i = index"
            >
                {{ i + 1 }}
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th class="name-column" mat-header-cell *matHeaderCellDef>Name</th>
            <td class="name-column" mat-cell *matCellDef="let row">
                <portal-link
                    [href]="plannerUrl + '/plan/' + row.id"
                    target="_blank"
                    >{{ row.name }}</portal-link
                >
            </td>
        </ng-container>

        <ng-container matColumnDef="project">
            <th class="project-column" mat-header-cell *matHeaderCellDef>
                Project Name [ID]
            </th>
            <td class="project-column" mat-cell *matCellDef="let row">
                <portal-link
                    *ngIf="projects[row.projectId]"
                    [href]="'/project/' + row.projectId"
                >
                    {{ projects[row.projectId].name }}
                </portal-link>
                [<portal-link
                    target="_blank"
                    [href]="
                        odooUrl +
                        '/web#id=' +
                        row.projectId +
                        '&view_type=form&model=crm.lead&menu_id=130&action=133'
                    "
                    >{{ row.projectId }}</portal-link
                >]
            </td>
        </ng-container>

        <ng-container matColumnDef="stage">
            <th class="stage-column" mat-header-cell *matHeaderCellDef>
                Project Stage
            </th>
            <td class="stage-column" mat-cell *matCellDef="let row">
                <span
                    *ngIf="
                        projects[row.projectId] && projects[row.projectId].stage
                    "
                >
                    {{ projects[row.projectId].stage?.name }}
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="state">
            <th class="state-column" mat-header-cell *matHeaderCellDef>
                State
            </th>
            <td class="state-column" mat-cell *matCellDef="let row">
                {{ row.state }}
            </td>
        </ng-container>

        <ng-container matColumnDef="created">
            <th class="created-column" mat-header-cell *matHeaderCellDef>
                Created At
            </th>
            <td class="created-column" mat-cell *matCellDef="let row">
                {{ row.createdAt | formatDate }}
            </td>
        </ng-container>

        <ng-container matColumnDef="requested">
            <th class="requested-column" mat-header-cell *matHeaderCellDef>
                Requested At
            </th>
            <td class="requested-column" mat-cell *matCellDef="let row">
                {{ row.requestedAt | formatDate }}
            </td>
        </ng-container>

        <ng-container matColumnDef="approved">
            <th class="approved-column" mat-header-cell *matHeaderCellDef>
                Approved At
            </th>
            <td class="approved-column" mat-cell *matCellDef="let row">
                {{ row.approvedAt | formatDate }}
            </td>
        </ng-container>

        <ng-container matColumnDef="ticket">
            <th class="ticket-column" mat-header-cell *matHeaderCellDef>
                Ticket
            </th>
            <td class="ticket-column" mat-cell *matCellDef="let row">
                <portal-link
                    *ngIf="row.ticketId !== null"
                    [href]="sdUrl + '/scp/tickets.php?id=' + row.ticketId"
                    target="_blank"
                >
                    link
                </portal-link>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th class="actions-column" mat-header-cell *matHeaderCellDef>
                Actions
            </th>
            <td class="actions-column" mat-cell *matCellDef="let row">
                <div class="flex gap-2" *ngIf="!blockActions">
                    <button
                        *ngIf="
                            row.state === 'pending' || row.state === 'returned'
                        "
                        mat-icon-button
                        aria-label="Approve Plan"
                        matTooltip="Approve Plan"
                        (click)="
                            $event.stopPropagation();
                            approvePlan.emit({ id: row.id })
                        "
                    >
                        <portal-icon icon="thumb_up"></portal-icon>
                    </button>
                    <button
                        *ngIf="row.state === 'pending'"
                        mat-icon-button
                        aria-label="Return Plan"
                        matTooltip="Return Plan"
                        (click)="
                            $event.stopPropagation();
                            returnPlan.emit({ id: row.id })
                        "
                    >
                        <portal-icon icon="thumb_down"></portal-icon>
                    </button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="plan-row"
        ></tr>
    </table>

    <div class="none-row" *ngIf="plans.length === 0">
        <portal-status
            [text]="'None'"
            [type]="'none'"
            [big]="true"
        ></portal-status>
    </div>
</div>

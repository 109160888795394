<mat-card class="card">
    <mat-card-content class="test-card">
        <portal-loading
            *ngIf="loading"
            [type]="'spinner'"
            [height]="600"
        ></portal-loading>
        <iframe
            #testFrame
            frameborder="0"
            [hidden]="loading"
            style="width: 100%; max-width: 600px; height: 100%"
            (load)="onIframeLoaded()"
        ></iframe>
    </mat-card-content>
</mat-card>

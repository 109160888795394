<div class="dashboard-layout">
    <div class="right-layout">
        <div class="news">
            <portal-section-header
                text="articles"
                subtext="Latest news from Sewio."
            ></portal-section-header>
            <portal-loading
                *ngIf="articles === null"
                [height]="112"
            ></portal-loading>
            <div *ngIf="articles !== null" class="flex flex-col gap-2">
                <portal-article-preview
                    *ngFor="
                        let article of articles
                            | sortArticlesByDate
                            | firstN : 6
                    "
                    [article]="article"
                ></portal-article-preview>
                <button
                    class="show-button"
                    mat-flat-button
                    color="primary"
                    (click)="showAllNews()"
                    [disabled]="articles === null"
                >
                    <span *ngIf="articles !== null">SHOW ALL</span>
                    <span *ngIf="articles === null">LOADING</span>
                </button>
            </div>
        </div>
    </div>
    <div class="left-layout">
        <portal-section-header
            text="company"
            subtext="Details about your company."
        ></portal-section-header>
        <portal-loading
            *ngIf="company === null || players === null"
            [height]="242"
        ></portal-loading>
        <portal-company-detail
            *ngIf="
                company !== null && players !== null && partnerStatus !== null
            "
            [company]="company"
            [players]="players"
            [partnerStatus]="partnerStatus"
        ></portal-company-detail>
        <div class="portal-space"></div>
        <portal-section-header
            text="projects"
            subtext="Projects that require attention."
        ></portal-section-header>
        <portal-loading
            *ngIf="projects === null"
            [height]="112"
        ></portal-loading>
        <portal-project-list
            *ngIf="projects !== null"
            [filter]="''"
            [projects]="projects"
            [attention]="true"
            [closed]="false"
            [archived]="true"
            [assigned]="false"
        ></portal-project-list>
    </div>
</div>

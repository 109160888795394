import {
    NgModule,
    Optional,
    SkipSelf,
    ModuleWithProviders,
    ErrorHandler,
    APP_INITIALIZER,
} from "@angular/core";
import { NgxsModule } from "@ngxs/store";
import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { AuthService } from "./services/auth.service";
import { ApolloLink } from "@apollo/client/link/core";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { HttpLink } from "apollo-angular/http";
import { HttpClientModule } from "@angular/common/http";
import { InMemoryCache } from "@apollo/client/core";
import { UploadService } from "./services/upload.service";
import { CookieService } from "ngx-cookie-service";
import { DownloadService } from "./services/download.service";
import { AnalyticsService } from "./services/analytics.service";
import { MatNativeDateModule, MAT_DATE_LOCALE } from "@angular/material/core";
import { UiState } from "./store/ui.state";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import * as Sentry from "@sentry/angular";

@NgModule({
    imports: [
        ToastrModule.forRoot({
            positionClass: "toast-bottom-center",
            maxOpened: 5,
            autoDismiss: true,
        }),
        HttpClientModule,
        ApolloModule,
        MatNativeDateModule,
        NgxsModule.forRoot([UiState], {
            developmentMode: !environment.production,
        }),
    ],
    exports: [],
    providers: [
        AnalyticsService,
        AuthService,
        UploadService,
        DownloadService,
        CookieService,
        {
            provide: APOLLO_OPTIONS,
            useFactory(
                httpLink: HttpLink,
                authService: AuthService,
                router: Router,
                toastrService: ToastrService
            ) {
                const basic = setContext(() => ({
                    headers: {
                        Accept: "charset=utf-8",
                    },
                }));

                const auth = setContext(() => {
                    const token = authService.token;

                    if (!token) {
                        return {};
                    } else {
                        return {
                            headers: {
                                Authorization: "Bearer " + token.token,
                            },
                        };
                    }
                });

                const error = onError(({ graphQLErrors }) => {
                    console.log(graphQLErrors);

                    if (graphQLErrors === undefined) {
                        return;
                    }

                    for (const graphQLError of graphQLErrors) {
                        let message = undefined;

                        if (graphQLError.extensions["response"]) {
                            message = graphQLError.extensions["response"] as {
                                statusCode: number;
                                error: string;
                                message: string;
                            };
                        } else {
                            message = graphQLError.extensions[
                                "originalError"
                            ] as {
                                statusCode: number;
                                error: string;
                                message: string;
                            };
                        }

                        if (message && message.statusCode) {
                            if (
                                message.statusCode === 401 ||
                                message.statusCode === 403
                            ) {
                                void router.navigate(["/login"]);
                            } else if (message.error) {
                                toastrService.error(
                                    message.message,
                                    message.error
                                );
                            }
                        }
                    }
                });

                return {
                    cache: new InMemoryCache({
                        typePolicies: {
                            Query: {
                                fields: {
                                    articles: {
                                        keyArgs: [],
                                        merge(existing, incoming, { args }) {
                                            const offset =
                                                (args && args["offset"]) || 0;

                                            const merged = existing
                                                ? existing.slice(0)
                                                : [];

                                            for (
                                                let i = 0;
                                                i < incoming.length;
                                                i++
                                            ) {
                                                merged[offset + i] =
                                                    incoming[i];
                                            }

                                            return merged;
                                        },
                                    },
                                },
                            },
                        },
                    }),
                    link: ApolloLink.from([
                        error,
                        basic,
                        auth,
                        httpLink.create({
                            uri: environment.server + "/graphql",
                        }),
                    ]),
                };
            },
            deps: [HttpLink, AuthService, Router, ToastrService],
        },
        { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: { disableClose: true, hasBackdrop: true },
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: "outline" },
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler(),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() core: CoreModule) {
        if (core) {
            throw new Error("Core module cannot be imported multiple times!");
        }
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
        };
    }
}

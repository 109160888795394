import { Pipe, PipeTransform } from "@angular/core";
import { Article } from "@portal/shared";

import moment from "moment";

@Pipe({ name: "sortArticlesByDate" })
export class SortArticlesByDatePipe implements PipeTransform {
    transform(values: Article[]) {
        return values.slice().sort((valueA, valueB) => {
            const dateA = moment(valueA.date);
            const dateB = moment(valueB.date);

            if (dateA.isAfter(dateB)) {
                return -1;
            } else {
                return 1;
            }
        });
    }
}

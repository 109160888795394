<div class="mat-elevation-z2">
    <table
        class="w-full"
        mat-table
        multiTemplateDataRows
        [dataSource]="dataSource"
    >
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>ID</th>
            <td mat-cell *matCellDef="let element">S{{ element.id }}</td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">
                {{ element.name }}
            </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">
                <div class="flex gap-2">
                    <portal-status
                        [type]="'danger'"
                        [big]="true"
                        [text]="element.danger.length"
                        [disabled]="element.danger.length === 0"
                    ></portal-status>
                    <portal-status
                        [type]="'warning'"
                        [big]="true"
                        [text]="element.warning.length"
                        [disabled]="element.warning.length === 0"
                    ></portal-status>
                    <portal-status
                        [type]="'info'"
                        [big]="true"
                        [text]="element.info.length"
                        [disabled]="element.info.length === 0"
                    ></portal-status>
                    <portal-status
                        [type]="'success'"
                        [big]="true"
                        [text]="element.success.length"
                        [disabled]="element.success.length === 0"
                    ></portal-status>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="subscription">
            <th mat-header-cell *matHeaderCellDef>Subscription</th>
            <td mat-cell *matCellDef="let element">
                <portal-status
                    [type]="element.subscription.type"
                    [big]="true"
                    [text]="element.subscription.text"
                ></portal-status>
            </td>
        </ng-container>

        <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef>Expand</th>
            <td
                mat-cell
                *matCellDef="let element"
                (click)="expandSite($event, element)"
            >
                <portal-icon
                    class="cursor-pointer text-stone-300 hover:text-stone-500"
                    [icon]="element.expanded ? 'expand_less' : 'expand_more'"
                ></portal-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
            <td
                mat-cell
                *matCellDef="let element"
                [attr.colspan]="displayedColumns.length"
            >
                <div
                    class="overflow-hidden flex flex-col gap-1"
                    [@detailExpand]="
                        element.expanded ? 'expanded' : 'collapsed'
                    "
                >
                    <div class="py-2">
                        <portal-status
                            *ngFor="let status of element.danger"
                            [type]="'danger'"
                            [big]="true"
                            [text]="status"
                        ></portal-status>
                        <portal-status
                            *ngFor="let status of element.warning"
                            [type]="'warning'"
                            [big]="true"
                            [text]="status"
                        ></portal-status>
                        <portal-status
                            *ngFor="let status of element.info"
                            [type]="'info'"
                            [big]="true"
                            [text]="status"
                        ></portal-status>
                        <portal-status
                            *ngFor="let status of element.success"
                            [type]="'success'"
                            [big]="true"
                            [text]="status"
                        ></portal-status>
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
            class="[&>td]:border-b-0 cursor-pointer hover:bg-stone-50"
            mat-row
            *matRowDef="let element; columns: displayedColumns"
            (click)="openSite(element.id)"
        ></tr>
        <tr
            class="h-0"
            mat-row
            *matRowDef="let element; columns: ['expandedDetail']"
        ></tr>
    </table>
    <mat-paginator
        class="bg-white border-t border-stone-200"
        [hidePageSize]="true"
        [length]="tableSites.length"
        [pageSize]="15"
    ></mat-paginator>
</div>

import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { PartnersComponent } from "./views/partners.component";
import { menuGuard } from "../../core/guards/menu.guard";

const partnersRoutes: Routes = [
    {
        path: "partners",
        component: PartnersComponent,
        data: {
            showTopPanel: true,
            showLeftPanel: true,
        },
        canActivate: [menuGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(partnersRoutes)],
    exports: [RouterModule],
})
export class PartnersRoutes {}

import { NgModule } from "@angular/core";
import { OtherRoutes } from "./other.routes";
import { OtherComponent } from "./views/other/other.component";
import { SharedModule } from "../../shared";

@NgModule({
    imports: [OtherRoutes, SharedModule],
    declarations: [OtherComponent],
    providers: [],
})
export class OtherModule {}

import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    ElementRef,
} from "@angular/core";
import { Subscription } from "rxjs";
import { TrainingAccessGQL, UserGQL } from "../../../../core";
import { environment } from "../../../../../environments/environment";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "portal-trainings",
    templateUrl: "./trainings.component.html",
    styleUrls: ["./trainings.component.scss"],
})
export class TrainingsComponent implements OnInit, OnDestroy {
    @ViewChild("testFrame", { static: false }) testFrame!: ElementRef;

    loading = true;

    private subscriptions: Subscription[] = [];

    constructor(
        private userGql: UserGQL,
        private trainingAccessGql: TrainingAccessGQL,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                this.subscriptions.push(
                    this.userGql
                        .watch()
                        .valueChanges.subscribe((userResult) => {
                            this.subscriptions.push(
                                this.trainingAccessGql
                                    .watch({ name: params["name"] })
                                    .valueChanges.subscribe((result) => {
                                        const url =
                                            environment.trainingUrl +
                                            "&quiz=" +
                                            result.data.trainingAccess
                                                .linkUrlId +
                                            "&cm_access_list_item=" +
                                            result.data.trainingAccess
                                                .accessCode +
                                            "&cm_user_id=" +
                                            userResult.data.user.email;

                                        (
                                            this.testFrame
                                                .nativeElement as HTMLIFrameElement
                                        ).src = url;
                                    })
                            );
                        })
                );
            })
        );
    }

    ngOnDestroy() {
        while (this.subscriptions.length) {
            this.subscriptions.pop()?.unsubscribe();
        }
    }

    onIframeLoaded() {
        this.loading = false;
    }
}

import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { AuthGuard } from "../../core";
import { ArticlesComponent } from "./views/articles/articles.component";
import { ArticleComponent } from "./views/article/article.component";
import { menuGuard } from "../../core/guards/menu.guard";

const newsRoutes: Routes = [
    {
        path: "articles",
        component: ArticlesComponent,
        data: {
            showTopPanel: true,
            showLeftPanel: true,
        },
        canActivate: [AuthGuard, menuGuard],
    },
    {
        path: "articles/:slug",
        component: ArticleComponent,
        data: {
            showTopPanel: true,
            showLeftPanel: true,
        },
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(newsRoutes)],
    exports: [RouterModule],
})
export class ArticlesRoutes {}

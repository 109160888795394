import {
    Component,
    ViewChild,
    ElementRef,
    OnDestroy,
    AfterViewInit,
} from "@angular/core";

import { first } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import {
    ScanningQuery,
    ScanningGQL,
    ProductGQL,
    SaveScanningGQL,
} from "../../../../core";

export type ScanState =
    | "loading"
    | "success"
    | "current_duplicit"
    | "saved_duplicit"
    | "different_type"
    | "invalid_type"
    | "not_found"
    | "too_many";

export interface Scan {
    id: string;
    lotId?: number;
    name: string;
    serial: string;
    state: ScanState;
}

@Component({
    selector: "portal-serial-evidence",
    templateUrl: "./serial-evidence.component.html",
    styleUrls: ["./serial-evidence.component.scss"],
})
export class SerialEvidenceComponent implements AfterViewInit, OnDestroy {
    loading = false;
    lastRemoved = false;
    scanning: ScanningQuery["scanning"] | null = null;

    currentScans: Scan[] = [];

    private subscriptions: Subscription[] = [];

    @ViewChild("pickingName", { static: false }) pickingName!: ElementRef;
    @ViewChild("serialInput", { static: false }) serialInput!: ElementRef;

    constructor(
        private route: ActivatedRoute,
        private scanningGql: ScanningGQL,
        private productGql: ProductGQL,
        private saveScanningGql: SaveScanningGQL
    ) {}

    ngAfterViewInit() {
        this.route.queryParamMap.subscribe((query) => {
            if (query.has("picking")) {
                (this.pickingName.nativeElement as HTMLInputElement).value =
                    query.get("picking") as string;
                this.loadPicking(query.get("picking") as string);
            }
        });
    }

    ngOnDestroy() {
        while (this.subscriptions.length) {
            this.subscriptions.pop()?.unsubscribe();
        }
    }

    loadPicking(name: string) {
        this.scanning = null;
        this.currentScans.length = 0;
        this.loading = true;

        this.scanningGql
            .watch({ name: name }, { fetchPolicy: "network-only" })
            .valueChanges.pipe(first())
            .subscribe(
                (result) => {
                    this.scanning = {
                        ...result.data.scanning,
                        scannedProducts:
                            result.data.scanning.scannedProducts.filter(
                                (scanning) =>
                                    scanning.name.match(/^\[SW(A|T|K|F)/) !==
                                    null
                            ),
                    };

                    this.loading = false;
                },
                () => {
                    this.scanning = null;
                    this.loading = false;
                }
            );
    }

    addScan(serial: string) {
        serial = serial.trim();

        if (/^(0x)?[0-9A-Fa-f]{12}$/.test(serial)) {
            serial = serial.replace(
                /(0x)?([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})/,
                "$2:$3:$4:$5:$6:$7"
            );
        }

        this.serialInput.nativeElement.value = "";

        const scan: Scan = {
            serial: serial,
            state: "loading",
            id: "",
            name: "",
        };

        for (const currentScan of this.currentScans) {
            if (currentScan.serial === serial) {
                scan.state = "current_duplicit";
                this.currentScans.push(scan);
                const sound = new Audio("assets/error.wav");
                void sound.play();
                return;
            }
        }

        this.currentScans.push(scan);
        this.lastRemoved = false;

        this.productGql
            .watch({ serial: serial }, { fetchPolicy: "network-only" })
            .valueChanges.pipe(first())
            .subscribe(
                (result) => {
                    if (this.scanning === null) {
                        return;
                    }

                    scan.id = result.data.product.id;
                    scan.name = result.data.product.name;
                    scan.lotId = result.data.product.lotId;

                    const productIndex = this.scanning?.scannedProducts
                        .map((product) => product.id)
                        .indexOf(result.data.product.id);

                    let error = true;

                    if (productIndex === -1) {
                        scan.state = "invalid_type";
                    } else if (
                        this.scanning?.scannedProducts[
                            productIndex
                        ].serials.includes(scan.serial)
                    ) {
                        scan.state = "saved_duplicit";
                    } else if (
                        this.currentScans.filter(
                            (currentScan) =>
                                currentScan.id !== "" && // skip the ones not loaded yet
                                currentScan.id !== result.data.product.id
                        ).length > 0
                    ) {
                        scan.state = "different_type";
                    } else {
                        const scanned = this.scanning.scannedProducts.filter(
                            (product) => product.id === result.data.product.id
                        )[0];

                        if (
                            scanned.saved +
                                this.countCurrentScans(result.data.product.id) >
                            scanned.quantity
                        ) {
                            scan.state = "too_many";
                        } else {
                            scan.state = "success";
                            error = false;
                        }
                    }

                    if (error) {
                        const sound = new Audio("./assets/error.wav");
                        void sound.play();
                    }
                },
                () => {
                    scan.state = "not_found";

                    const sound = new Audio("./assets/error.wav");
                    void sound.play();
                }
            );
    }

    clearScans() {
        this.currentScans.length = 0;
    }

    saveScans(name: string) {
        this.loading = true;
        this.scanning = null;

        this.saveScanningGql
            .mutate(
                {
                    name: name,
                    ids: this.currentScans
                        .map((scan) => scan.lotId)
                        .filter(
                            (lotId): lotId is number => lotId !== undefined
                        ),
                },
                {
                    update: (_store, { data }) => {
                        if (!data?.saveScanning) {
                            return;
                        }

                        this.scanning = {
                            ...data.saveScanning,
                            scannedProducts:
                                data.saveScanning.scannedProducts.filter(
                                    (scanning) =>
                                        scanning.name.match(
                                            /^\[SW(A|T|K|F)/
                                        ) !== null
                                ),
                        };

                        this.loading = false;
                    },
                }
            )
            .pipe(first())
            .subscribe(
                () => {
                    return;
                },
                () => {
                    this.loading = false;
                }
            );

        this.currentScans.length = 0;
    }

    isSuccess() {
        if (this.currentScans.length === 0) {
            return false;
        }

        for (const currentScan of this.currentScans) {
            if (currentScan.state !== "success") {
                return false;
            }
        }

        return true;
    }

    countCurrentScans(id: string) {
        return this.currentScans.reduce((total, current) => {
            return id === current.id ? ++total : total;
        }, 0);
    }

    removeLast() {
        this.lastRemoved = true;
        this.currentScans.length = this.currentScans.length - 1;
    }

    getStateColor(state: ScanState) {
        switch (state) {
            case "loading":
                return "#888888";
            case "success":
                return "#059b39";
            default:
                return "#d30000";
        }
    }

    getStateText(state: ScanState) {
        switch (state) {
            case "loading":
                return "LOADING";
            case "success":
                return "SUCCESS";
            case "current_duplicit":
                return "C. DUPLICIT";
            case "saved_duplicit":
                return "S. DUPLICIT";
            case "not_found":
                return "NOT FOUND";
            case "different_type":
                return "DIFFERENT";
            case "invalid_type":
                return "UNWANTED";
            case "too_many":
                return "OVERFLOW";
        }
    }
}

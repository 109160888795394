import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { SitesGQL, SitesQuery } from "../../../core";

@Component({
    selector: "portal-sites",
    templateUrl: "./sites.component.html",
    styleUrls: ["./sites.component.scss"],
})
export class SitesComponent implements OnInit, OnDestroy {
    sites: SitesQuery["sites"] | null = null;

    loading = 1;

    private subscriptions: Subscription[] = [];

    constructor(private sitesGql: SitesGQL) {}

    ngOnInit(): void {
        this.subscriptions.push(
            this.sitesGql.watch().valueChanges.subscribe((result) => {
                this.sites = result.data.sites;
                this.loading = 0;
            })
        );
    }

    ngOnDestroy(): void {
        while (this.subscriptions.length) {
            this.subscriptions.pop()?.unsubscribe();
        }
    }
}

<div mat-dialog-title class="title">Edit Partner Activity</div>
<div mat-dialog-content>
    <div [ngClass]="{ form: !uploading }" [hidden]="uploading">
        <mat-form-field>
            <input
                #typeInput
                matInput
                placeholder="Type"
                [value]="type.name"
                disabled
            />
        </mat-form-field>
        <div class="type-info">
            <div>
                <b class="right-offset">Points:</b>
                {{ type.points }}
            </div>
            <div>
                <b class="right-offset">Expiration:</b>
                {{ type.expiration }}
                {{ type.expiration > 1 ? "years" : "year" }}
            </div>
        </div>
        <mat-form-field *ngIf="type.showName">
            <input
                #nameInput
                matInput
                placeholder="Name"
                [formControl]="nameValidator"
                [required]="type.requireName ? '' : false"
            />
        </mat-form-field>
        <mat-form-field *ngIf="type.showDescription">
            <textarea
                #descriptionInput
                class="description"
                matInput
                placeholder="Description"
                [formControl]="descriptionValidator"
                [required]="type.requireDescription ? '' : false"
                oninput='this.style.height = ""; this.style.height = this.scrollHeight + "px"'
            ></textarea>
        </mat-form-field>
        <mat-form-field *ngIf="type.showLink">
            <input
                #linkInput
                matInput
                placeholder="Link"
                [formControl]="linkValidator"
                [required]="type.requireLink ? '' : false"
            />
        </mat-form-field>
        <div *ngIf="type.showAttachment">
            <div class="attachment-header">
                Attachment <span *ngIf="type.requireAttachment">*</span>
            </div>
            <portal-file-upload
                #fileUpload
                [multipleFiles]="false"
            ></portal-file-upload>
        </div>
    </div>
    <div [hidden]="!uploading">
        <div class="uploading-header">Uploading...</div>
        <mat-progress-bar [value]="uploadingProgress"></mat-progress-bar>
    </div>
</div>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close *ngIf="!uploading">CANCEL</button>
    <button
        mat-button
        color="primary"
        cdkFocusInitial
        (click)="edit()"
        *ngIf="!uploading"
    >
        EDIT
    </button>
</mat-dialog-actions>

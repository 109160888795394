<mat-card class="flex flex-col p-8 pb-6">
    <div
        class="text-stone-900 text-2xl font-bold w-full max-w-2xl mx-auto cursor-pointer"
        (click)="openArticle()"
    >
        {{ article.title }}
    </div>
    <div
        class="text-stone-400 text-xs w-full max-w-2xl mx-auto flex gap-2 pb-4 border-b border-stone-200"
    >
        <div *ngIf="article.date">
            {{ formatDate(article.date) }}
        </div>
        <div class="first-of-type:hidden last-of-type:hidden">|</div>
        <div *ngIf="article.author">By {{ article.author }}</div>
        <div class="first-of-type:hidden last-of-type:hidden">|</div>
        <div *ngIf="article.minutes">
            {{ article.minutes }} minute{{ article.minutes > 1 ? "s" : "" }}
            read
        </div>
    </div>
    <div
        class="my-2 prose prose-sm max-w-2xl mx-auto"
        [innerHTML]="article.preview | edjsToHtml | safeHtml"
    ></div>
    <div
        class="flex justify-center items-center text-stone-300 hover:text-stone-500 text-sm cursor-pointer"
        (click)="openArticle()"
    >
        --- read full article ---
    </div>
</mat-card>

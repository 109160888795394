<div class="w-full h-full flex items-center justify-center">
    <mat-card>
        <div *ngIf="loading" class="loading">
            <mat-spinner [diameter]="50" [strokeWidth]="5"></mat-spinner>
        </div>
        <mat-card-content class="login-content">
            <div class="login-header">
                <img class="login-logo" src="./assets/header-logo.svg" />
            </div>
            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input id="login" autocomplete="login" #email matInput />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Password</mat-label>
                <input
                    id="password"
                    autocomplete="password"
                    #password
                    matInput
                    [type]="'password'"
                />
            </mat-form-field>
            <button
                class="w-full"
                mat-flat-button
                color="primary"
                (click)="logIn()"
            >
                LOG IN
            </button>
            <portal-link class="forgot-password" href="/reset">
                Forgot your password?
            </portal-link>
            <portal-link class="forgot-password" href="mailto:sales@sewio.net">
                Don't have an account? Contact us!
            </portal-link>
        </mat-card-content>
    </mat-card>
</div>

import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { LoginModule } from "./modules/login/login.module";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared";
import { DashboardModule } from "./modules/dashboard/dashboard.module";
import { LayoutModule } from "./modules/layout/layout.module";
import { ProjectModule } from "./modules/project/project.module";
import { ProjectsModule } from "./modules/projects/projects.module";
import { CompanyModule } from "./modules/company/company.module";
import { PartnersModule } from "./modules/partners/partners.module";
import { OtherModule } from "./modules/other/other.module";
import { AppRoutes } from "./app.routes";
import { UsersModule } from "./modules/users/users.module";
import { ArticlesModule } from "./modules/articles/articles.module";
import { TrainingsModule } from "./modules/trainings/trainings.module";
import { InternalsModule } from "./modules/internals/internals.module";
import { PlayersModule } from "./modules/players/players.module";
import { PartnerProgramModule } from "./modules/partner-program/partner-program.module";
import { DownloadModule } from "./modules/download/download.module";
import { PlansModule } from "./modules/plans/plans.module";
import { BusinessReviewsModule } from "./modules/business-reviews/business-reviews.module";
import { SitesModule } from "./modules/sites/sites.module";
import { SiteModule } from "./modules/site/site.module";

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule.forRoot(),
        SharedModule.forRoot(),
        DashboardModule,
        CompanyModule,
        LoginModule,
        ProjectModule,
        ProjectsModule,
        SitesModule,
        SiteModule,
        BusinessReviewsModule,
        PartnersModule,
        DownloadModule,
        TrainingsModule,
        InternalsModule,
        PlayersModule,
        PlansModule,
        PartnerProgramModule,
        UsersModule,
        OtherModule,
        ArticlesModule,
        AppRoutes,
        LayoutModule,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

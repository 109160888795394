/* eslint-disable no-case-declarations */
import { Injectable } from "@angular/core";
import { HttpClient, HttpEventType } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable()
export class UploadService {
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {}

    uploadActivity(file: File) {
        const url = environment.server + "/upload_activity";
        const token = this.authService.token;
        const data = new FormData();

        data.append("files[]", file, file.name);

        if (token === null) {
            throw new Error(
                "Could not upload activity without authentication."
            );
        }

        return this.httpClient
            .post(url, data, {
                reportProgress: true,
                observe: "events",
                headers: {
                    Authorization: "Bearer " + token.token,
                },
            })
            .pipe(
                map((event) => {
                    switch (event.type) {
                        case HttpEventType.UploadProgress:
                            const progress = event.total
                                ? Math.round((100 * event.loaded) / event.total)
                                : 0;

                            return { status: "progress", message: progress };
                        case HttpEventType.Response:
                            return { status: "done", message: event.body };
                        default:
                            return { status: "unknown", message: event };
                    }
                })
            );
    }

    upload(files: FileList, project: string, path: string) {
        const url =
            environment.server +
            "/upload?project=" +
            project +
            (path ? "&path=" + path : "");
        const token = this.authService.token;
        const data = new FormData();

        if (token === null) {
            throw new Error("Could not upload files without authentication.");
        }

        for (let i = 0; i < files.length; i++) {
            data.append("files[]", files[i], files[i].name);
        }

        return this.httpClient
            .post(url, data, {
                reportProgress: true,
                observe: "events",
                headers: {
                    Authorization: "Bearer " + token.token,
                },
            })
            .pipe(
                map((event) => {
                    switch (event.type) {
                        case HttpEventType.UploadProgress:
                            const progress = event.total
                                ? Math.round((100 * event.loaded) / event.total)
                                : 0;

                            return { status: "progress", message: progress };
                        case HttpEventType.Response:
                            return { status: "done", message: 100 };
                        default:
                            return { status: "unknown", message: event.type };
                    }
                })
            );
    }
}

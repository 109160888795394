import { NgModule, ModuleWithProviders } from "@angular/core";
import { UserComponent } from "./components/user/user.component";
import {
    FontAwesomeModule,
    FaIconLibrary,
} from "@fortawesome/angular-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faFolder, faFolderOpen } from "@fortawesome/free-regular-svg-icons";
import { BreadcrumbsComponent } from "./components/breadcrumbs/breadcrumbs.component";
import { CommonModule } from "@angular/common";
import { StatusComponent } from "./components/status/status.component";
import { LoadingComponent } from "./components/loading/loading.coponent";
import { RoundPipe } from "./pipes/round.pipe";
import { NumberToLocaleStringPipe } from "./pipes/number-to-locale-string.pipe";
import { FirstNPipe } from "./pipes/first-n.pipe";
import { SortByIdPipe } from "./pipes/sort-by-id.pipe";
import { FilterOrdersFromOrdersPipe } from "./pipes/filter-orders-from-orders.pipe";
import { FilterQuotesFromOrdersPipe } from "./pipes/filter-quotes-from-orders.pipe";
import { CompanyDetailComponent } from "./components/company-detail/company-detail.component";
import { ProjectListComponent } from "./components/project-list/project-list.component";
import { LevelComponent } from "./components/level/level.component";
import { ExplorerComponent } from "./components/explorer/explorer.component";
import { FolderComponent } from "./components/folder/folder.component";
import { FileComponent } from "./components/file/file.component";
import { FileUploadComponent } from "./components/file-upload/file-upload.component";
import { MenuItemComponent } from "./components/menu-item/menu-item.component";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { ArticlePreviewComponent } from "./components/article-preview/article-preview.component";
import { SortArticlesByDatePipe } from "./pipes/sort-by-date.pipe";
import { SortByPriorityPipe } from "./pipes/sort-by-priority.pipe";
import { FormatPipe } from "./pipes/format.pipe";
import { RemoveEmptyFromArrayPipe } from "./pipes/remove-empty-from-array.pipe";
import { IdToArrayItemPipe } from "./pipes/id-to-array-item.pipe";
import { MinutesFromNowToDatePipe } from "./pipes/minutes-from-now-to-date.pipe";
import { SecondsFromNowToDatePipe } from "./pipes/seconds-from-now-to-date.pipe";
import { PlayerStatusToStatePipe } from "./pipes/player-status-to-state.pipe";
import { FormatNewLinesPipe } from "./pipes/format-new-lines.pipe";
import { PartnerLevelComponent } from "./components/partner-level/partner-level.component";
import { FilterActivitiesByTypePipe } from "./pipes/filter-activities-by-type.pipe";
import { FormatAttachmentNamePipe } from "./pipes/format-attachment-name.pipe";
import { FormatDatePipe } from "./pipes/format-date.pipe";
import { MarkdownToHtmlPipe } from "./pipes/markdown-to-html.pipe";
import { SiteListComponent } from "./components/site-list/site-list.component";

import faSewio from "./icons/faSewio";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatCardModule } from "@angular/material/card";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatStepperModule } from "@angular/material/stepper";
import { MatSliderModule } from "@angular/material/slider";
import { MatListModule } from "@angular/material/list";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { IconComponent } from "./components/icon/icon.component";
import { CardComponent } from "./components/card/card.component";
import { EdjsToHtmlPipe } from "./pipes/edjs-to-html.pipe";
import { LinkComponent } from "./components/link/link.component";
import { SectionHeaderComponent } from "./components/section-header/section-header.component";
import { PlanListComponent } from "./components/plan-list/plan-list.component";
import { MergeProjectsPlansPipe } from "./pipes/merge-projects-plans.pipe";
import { TicketListComponent } from "./components/ticket-list/ticket-list.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { UtcToLocalPipe } from "./pipes/utc-to-local.pipe";
import { MergeProjectsTicketsPipe } from "./pipes/merge-projects-tickets.pipe";

@NgModule({
    imports: [
        /** Shared Modules */
        CommonModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        MatPaginatorModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatCardModule,
        MatTooltipModule,
        MatDialogModule,
        MatSelectModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        MatStepperModule,
        MatSliderModule,
        MatListModule,
        MatInputModule,
        MatButtonModule,
        MatFormFieldModule,
        MatSlideToggleModule,
    ],
    declarations: [
        /** Shared Components */
        UserComponent,
        BreadcrumbsComponent,
        StatusComponent,
        LevelComponent,
        LoadingComponent,
        CompanyDetailComponent,
        ProjectListComponent,
        SiteListComponent,
        ExplorerComponent,
        FolderComponent,
        FileComponent,
        FileUploadComponent,
        PartnerLevelComponent,
        ArticlePreviewComponent,
        MenuItemComponent,
        IconComponent,
        CardComponent,
        LinkComponent,
        SectionHeaderComponent,
        PlanListComponent,
        TicketListComponent,

        /** Shared Pipes */
        RoundPipe,
        NumberToLocaleStringPipe,
        FirstNPipe,
        SortByIdPipe,
        SafeHtmlPipe,
        FilterOrdersFromOrdersPipe,
        FilterQuotesFromOrdersPipe,
        PlayerStatusToStatePipe,
        MinutesFromNowToDatePipe,
        SecondsFromNowToDatePipe,
        FormatNewLinesPipe,
        FormatAttachmentNamePipe,
        RemoveEmptyFromArrayPipe,
        FormatPipe,
        MarkdownToHtmlPipe,
        SortArticlesByDatePipe,
        SortByPriorityPipe,
        FormatDatePipe,
        FilterActivitiesByTypePipe,
        IdToArrayItemPipe,
        EdjsToHtmlPipe,
        MergeProjectsPlansPipe,
        MergeProjectsTicketsPipe,
        UtcToLocalPipe,
    ],
    exports: [
        /** Shared Modules */
        CommonModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        MatPaginatorModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatCardModule,
        MatTooltipModule,
        MatDialogModule,
        MatSelectModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        MatStepperModule,
        MatSliderModule,
        MatListModule,
        MatInputModule,
        MatButtonModule,
        MatFormFieldModule,
        MatSlideToggleModule,

        /** Shared Components */
        UserComponent,
        BreadcrumbsComponent,
        StatusComponent,
        LevelComponent,
        LoadingComponent,
        CompanyDetailComponent,
        ProjectListComponent,
        SiteListComponent,
        ExplorerComponent,
        FolderComponent,
        FileComponent,
        FileUploadComponent,
        PartnerLevelComponent,
        ArticlePreviewComponent,
        MenuItemComponent,
        IconComponent,
        CardComponent,
        LinkComponent,
        SectionHeaderComponent,
        PlanListComponent,
        TicketListComponent,

        /** Shared Pipes */
        RoundPipe,
        NumberToLocaleStringPipe,
        FirstNPipe,
        SortByIdPipe,
        SafeHtmlPipe,
        FilterOrdersFromOrdersPipe,
        FilterQuotesFromOrdersPipe,
        PlayerStatusToStatePipe,
        MinutesFromNowToDatePipe,
        SecondsFromNowToDatePipe,
        FormatNewLinesPipe,
        FormatAttachmentNamePipe,
        RemoveEmptyFromArrayPipe,
        FormatPipe,
        MarkdownToHtmlPipe,
        SortArticlesByDatePipe,
        SortByPriorityPipe,
        FormatDatePipe,
        FilterActivitiesByTypePipe,
        IdToArrayItemPipe,
        EdjsToHtmlPipe,
        MergeProjectsPlansPipe,
        MergeProjectsTicketsPipe,
        UtcToLocalPipe,
    ],
})
export class SharedModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas);
        library.addIcons(faSewio, faFolder, faFolderOpen);
    }

    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [],
        };
    }
}

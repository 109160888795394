import { Component, Input } from "@angular/core";

@Component({
    selector: "portal-state",
    templateUrl: "./state.component.html",
    styleUrls: ["./state.component.scss"],
})
export class StateComponent {
    @Input() text!: string;
    @Input() color!: string;
}

import { Component, Input } from "@angular/core";
import { TimeUtils } from "@portal/shared";
import { ProjectQuery } from "../../../../core";
import { countries } from "../../../../shared";

@Component({
    selector: "portal-project-detail",
    templateUrl: "./project-detail.component.html",
    styleUrls: ["./project-detail.component.scss"],
})
export class ProjectDetailComponent {
    @Input() project!: ProjectQuery["project"];

    allCountries = countries;

    selection: "basic" | "pre-sales" | "notes" = "basic";

    checkExpired(time: string) {
        return TimeUtils.isDateTodayOrInThePast(time);
    }

    checkExpiring(time: string, months: number) {
        return TimeUtils.isDateLessThanNMonthsInTheFuture(time, months);
    }
}

import {
    Component,
    Input,
    ViewChild,
    OnInit,
    ViewChildren,
    ElementRef,
    QueryList,
} from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatRow, MatTableDataSource } from "@angular/material/table";
import { environment } from "../../../../environments/environment";
import { CalculatedSite, Plan, Project } from "@portal/shared";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { first } from "rxjs";
import {
    ApprovePlanGQL,
    ChangePlanStateGQL,
    CreatePlanGQL,
    DeletePlanGQL,
    DuplicatePlanGQL,
    ProjectDocument,
    RenamePlanGQL,
    SiteDocument,
} from "../../../core";
import { RenamePlanDialogComponent } from "../../../modules/project/components/rename-plan-dialog/rename-plan-dialog.component";
import { CreateDialogComponent } from "../../../modules/project/components/create-dialog/create-dialog.component";
import { InternalRefetchQueriesInclude } from "@apollo/client/core";

@Component({
    selector: "portal-plan-list",
    templateUrl: "./plan-list.component.html",
    styleUrls: ["./plan-list.component.scss"],
})
export class PlanListComponent implements OnInit {
    @ViewChild(MatSort, { static: false }) sort!: MatSort;
    @ViewChildren("planRow", { read: ElementRef })
    planRows!: QueryList<ElementRef>;

    displayedColumns: string[] = ["name", "state", "actions"];
    dataSource!: MatTableDataSource<Plan[][number]>;

    private _plans!: Plan[];
    private _site?: CalculatedSite;

    loading = 0;

    get plans(): Plan[] {
        return this._plans;
    }

    @Input()
    set plans(plans: Plan[]) {
        let shouldScroll = false;

        if (this._plans && this._plans.length < plans.length) {
            shouldScroll = true;
        }

        this._plans = plans;
        this.dataSource = new MatTableDataSource(this._plans);

        if (shouldScroll) {
            this.planRows.changes.pipe(first()).subscribe(() => {
                this.planRows.last?.nativeElement.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "nearest",
                });
            });
        }
    }

    get site(): CalculatedSite | undefined {
        return this._site;
    }

    @Input()
    set site(site: CalculatedSite | undefined) {
        if (site !== undefined && site !== null) {
            this.displayedColumns = ["name", "opportunity", "state", "actions"];
        } else {
            this.displayedColumns = ["name", "state", "actions"];
        }

        this._site = site;
    }

    constructor(
        private toastrService: ToastrService,
        private dialog: MatDialog,
        private createPlanGql: CreatePlanGQL,
        private renamePlanGql: RenamePlanGQL,
        private deletePlanGql: DeletePlanGQL,
        private approvePlanGql: ApprovePlanGQL,
        private duplicatePlanGql: DuplicatePlanGQL,
        private changePlanStateGql: ChangePlanStateGQL
    ) {}

    ngOnInit() {
        this.dataSource.sort = this.sort;
    }

    editPlan(id: string) {
        window.open(environment.planner_address + "/plan/" + id, "_blank");
    }

    opportunityIdToName(id: string | null): string | undefined {
        return (
            this.site?.projects?.find((opportunity) => opportunity?.id === id)
                ?.name || "N/A"
        );
    }

    createPlan(projects: Project[]) {
        const dialogReference = this.dialog.open(CreateDialogComponent, {
            width: window.innerWidth >= 768 ? "600px" : "300px",
            data: { projects },
        });

        dialogReference
            .afterClosed()
            .pipe(first())
            .subscribe((result) => {
                if (result) {
                    this.loading++;

                    const queries: InternalRefetchQueriesInclude = [
                        {
                            query: ProjectDocument,
                            variables: {
                                projectId: result.projectId as string,
                            },
                        },
                    ];

                    if (this._site?.id) {
                        queries.push({
                            query: SiteDocument,
                            variables: {
                                siteId: this._site.id,
                            },
                        });
                    }

                    this.createPlanGql
                        .mutate(
                            { id: result.projectId, name: result.name },
                            { refetchQueries: queries }
                        )
                        .pipe(first())
                        .subscribe(() => {
                            this.loading--;
                            this.toastrService.success(
                                "Plan created successfully."
                            );
                        });
                }
            });
    }

    renamePlan(id: string, name: string, projectId: string) {
        const dialogReference = this.dialog.open(RenamePlanDialogComponent, {
            width: window.innerWidth >= 768 ? "600px" : "300px",
            data: { name },
        });

        dialogReference
            .afterClosed()
            .pipe(first())
            .subscribe((result) => {
                if (result) {
                    this.loading++;

                    const queries: InternalRefetchQueriesInclude = [
                        {
                            query: ProjectDocument,
                            variables: {
                                projectId: projectId,
                            },
                        },
                    ];

                    if (this._site?.id) {
                        queries.push({
                            query: SiteDocument,
                            variables: {
                                siteId: this._site.id,
                            },
                        });
                    }

                    this.renamePlanGql
                        .mutate(
                            { id, name: result.name },
                            {
                                refetchQueries: queries,
                            }
                        )
                        .pipe(first())
                        .subscribe(() => {
                            this.loading--;
                            this.toastrService.success(
                                "Plan renamed successfully."
                            );
                        });
                }
            });
    }

    duplicatePlan(id: string, projectId: string) {
        this.loading++;

        const queries: InternalRefetchQueriesInclude = [
            {
                query: ProjectDocument,
                variables: {
                    projectId: projectId,
                },
            },
        ];

        if (this._site?.id) {
            queries.push({
                query: SiteDocument,
                variables: {
                    siteId: this._site.id,
                },
            });
        }

        this.duplicatePlanGql
            .mutate(
                { id: id },
                {
                    refetchQueries: queries,
                }
            )
            .pipe(first())
            .subscribe(() => {
                this.loading--;
                this.toastrService.success("Plan duplicated successfully.");
            });
    }

    deletePlan(id: string, projectId: string) {
        this.loading++;

        const queries: InternalRefetchQueriesInclude = [
            {
                query: ProjectDocument,
                variables: {
                    projectId: projectId,
                },
            },
        ];

        if (this._site?.id) {
            queries.push({
                query: SiteDocument,
                variables: {
                    siteId: this._site.id,
                },
            });
        }

        this.deletePlanGql
            .mutate(
                { id },
                {
                    refetchQueries: queries,
                }
            )
            .pipe(first())
            .subscribe(() => {
                this.loading--;
                this.toastrService.success("Plan deleted successfully.");
            });
    }

    requestPlanApproval(id: string, state: string, projectId: string) {
        this.loading++;

        const queries: InternalRefetchQueriesInclude = [
            {
                query: ProjectDocument,
                variables: {
                    projectId: projectId,
                },
            },
        ];

        if (this._site?.id) {
            queries.push({
                query: SiteDocument,
                variables: {
                    siteId: this._site.id,
                },
            });
        }

        this.changePlanStateGql
            .mutate(
                { id: id, state: state },
                {
                    refetchQueries: queries,
                }
            )
            .pipe(first())
            .subscribe(() => {
                this.loading--;
                this.toastrService.success("Plan state changed successfully.");
            });
    }
}

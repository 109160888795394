<div class="flex items-center justify-between">
    <portal-section-header
        text="users"
        subtext="Users with access to your company information."
    ></portal-section-header>
    <button
        *ngIf="user !== null && user.companyRole === 'admin'"
        class="mr-4 w-32"
        mat-stroked-button
        color="primary"
        (click)="inviteUser()"
        [disabled]="users === null"
    >
        <span *ngIf="users === null">LOADING</span>
        <span *ngIf="users !== null">INVITE USER</span>
    </button>
</div>
<portal-loading *ngIf="users === null" [height]="112"></portal-loading>
<portal-user-list
    *ngIf="users !== null && user !== null"
    [users]="users"
    [user]="user"
    (editUser)="editUser($event)"
    (deleteUser)="deleteUser($event)"
></portal-user-list>

<div class="mat-elevation-z2 table-container">
    <table
        class="users-table"
        mat-table
        [dataSource]="dataSource"
        matSort
        multiTemplateDataRows
    >
        <ng-container matColumnDef="email">
            <th
                class="email-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Email
            </th>
            <td class="email-column" mat-cell *matCellDef="let row">
                {{ row.email }}
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th
                class="name-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Name
            </th>
            <td class="name-column" mat-cell *matCellDef="let row">
                {{ row.name }}
            </td>
        </ng-container>

        <ng-container matColumnDef="state">
            <th
                class="state-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                State
            </th>
            <td class="state-column" mat-cell *matCellDef="let row">
                {{ row.state }}
            </td>
        </ng-container>

        <ng-container matColumnDef="role">
            <th
                class="role-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Role
            </th>
            <td class="role-column" mat-cell *matCellDef="let row">
                {{ row.companyRole }}
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th
                class="actions-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Actions
            </th>
            <td class="actions-column" mat-cell *matCellDef="let row">
                <div class="flex gap-2" *ngIf="user !== null">
                    <button
                        *ngIf="
                            user.companyRole === 'admin' &&
                            row.state !== 'invited'
                        "
                        mat-icon-button
                        aria-label="Edit User"
                        matTooltip="Edit User"
                        (click)="
                            $event.stopPropagation();
                            editUser.emit({ id: row.id })
                        "
                    >
                        <portal-icon icon="edit"></portal-icon>
                    </button>
                    <button
                        *ngIf="
                            user.companyRole === 'admin' && user.id !== row.id
                        "
                        mat-icon-button
                        aria-label="Delete User"
                        matTooltip="Delete User"
                        (click)="
                            $event.stopPropagation();
                            deleteUser.emit({ id: row.id })
                        "
                    >
                        <portal-icon icon="delete"></portal-icon>
                    </button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="user-row"
        ></tr>
    </table>

    <mat-paginator
        class="paginator"
        [pageSizeOptions]="pageSizes"
    ></mat-paginator>
</div>

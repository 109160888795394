import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "format" })
export class FormatPipe implements PipeTransform {
    transform(value: string) {
        const stringParts = value.split("_");

        for (let i = 0; i < stringParts.length; i++) {
            stringParts[i] = stringParts[i].charAt(0).toUpperCase() + stringParts[i].substr(1);
        }
        
        return stringParts.join(" ");
    }
}

import { Component, ViewChild, ElementRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../../../core";
import { first } from "rxjs/operators";

@Component({
    selector: "portal-reset",
    templateUrl: "./reset.component.html",
    styleUrls: ["./reset.component.scss"],
})
export class ResetComponent {
    loading = false;
    sent = false;

    @ViewChild("email", { static: false }) email!: ElementRef;

    constructor(
        private toastrService: ToastrService,
        private authService: AuthService
    ) {}

    sendLink() {
        const emailValue = (this.email.nativeElement as HTMLInputElement).value;

        if (emailValue === "") {
            this.toastrService.error("Email not entered.");
            return;
        }

        this.authService
            .resetPassword(emailValue)
            .pipe(first())
            .subscribe(
                (value) => {
                    if (value) {
                        this.loading = false;
                        this.sent = true;
                    } else {
                        this.loading = false;
                        this.sent = false;
                        this.toastrService.error(
                            "User with selected email doesn't exist."
                        );
                    }
                },
                () => {
                    this.loading = false;
                    this.sent = false;
                    this.toastrService.error("Something went wrong.");
                }
            );
    }
}

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Article = {
  __typename?: 'Article';
  author?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  date?: Maybe<Scalars['String']>;
  minutes?: Maybe<Scalars['Float']>;
  preview: Scalars['String'];
  slug: Scalars['ID'];
  title: Scalars['String'];
};

export type BusinessReview = {
  __typename?: 'BusinessReview';
  companyId: Scalars['String'];
  createdAt: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
};

export type Companies = {
  __typename?: 'Companies';
  items: Array<Company>;
  totalCount: Scalars['Float'];
};

export type Company = {
  __typename?: 'Company';
  admin?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoicingFy: Scalars['Float'];
  level: Scalars['String'];
  name: Scalars['String'];
  ndaFrom: Scalars['String'];
  playerLimit: Scalars['Float'];
  region: Scalars['String'];
  salesPersonEmail: Scalars['String'];
  salesPersonName: Scalars['String'];
  salesPersonPhone: Scalars['String'];
};

export type File = {
  __typename?: 'File';
  name: Scalars['String'];
  path: Scalars['String'];
  project: Scalars['String'];
  size: Scalars['String'];
};

export type Folder = {
  __typename?: 'Folder';
  children: Array<Scalars['Float']>;
  files: Array<File>;
  id: Scalars['Float'];
  name: Scalars['String'];
  path: Scalars['String'];
  root: Scalars['Boolean'];
};

export type Invoice = {
  __typename?: 'Invoice';
  currency: Scalars['String'];
  dueAmount: Scalars['Float'];
  dueDate: Scalars['String'];
  id: Scalars['ID'];
  invoiceDate: Scalars['String'];
  number: Scalars['String'];
  origin: Scalars['String'];
  state: Scalars['String'];
  totalAmount: Scalars['Float'];
  trackingNumber: Scalars['String'];
};

export type Item = {
  __typename?: 'Item';
  id: Scalars['ID'];
  mac: Scalars['String'];
  type: Scalars['String'];
};

export type MenuItem = {
  __typename?: 'MenuItem';
  children?: Maybe<Array<MenuItem>>;
  icon?: Maybe<Scalars['String']>;
  pageContent?: Maybe<Scalars['String']>;
  parent?: Maybe<MenuItem>;
  slug: Scalars['ID'];
  title: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  approvePlan: Scalars['String'];
  changePlanState: Scalars['String'];
  createBusinessReview: BusinessReview;
  createFolder: Array<Folder>;
  createPartnerActivity: PartnerActivity;
  createPlan: Plan;
  createPlayer: Player;
  createProject: Project;
  createSiteUpdate: SiteUpdate;
  createTicket: Ticket;
  deleteFile: Array<Folder>;
  deleteFolder: Array<Folder>;
  deletePlan: Scalars['Boolean'];
  deletePlayer: Player;
  deleteSiteUpdate: Scalars['Boolean'];
  deleteUser: Scalars['ID'];
  duplicatePlan: Plan;
  editBusinessReview: BusinessReview;
  editPartnerActivity: PartnerActivity;
  editProject: Project;
  editSiteUpdate: SiteUpdate;
  editUser: User;
  inviteUser: User;
  prolongPlayer: Player;
  renamePlan: Scalars['String'];
  resetCompany: Scalars['Boolean'];
  saveScanning: Scanning;
  setCompany: Scalars['Boolean'];
  startPlayer: Player;
  stopPlayer: Player;
};


export type MutationApprovePlanArgs = {
  approved: Scalars['Boolean'];
  id: Scalars['ID'];
};


export type MutationChangePlanStateArgs = {
  id: Scalars['ID'];
  state: Scalars['String'];
};


export type MutationCreateBusinessReviewArgs = {
  description: Scalars['String'];
};


export type MutationCreateFolderArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  path: Scalars['String'];
};


export type MutationCreatePartnerActivityArgs = {
  attachment: Scalars['String'];
  description: Scalars['String'];
  link: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};


export type MutationCreatePlanArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationCreatePlayerArgs = {
  deleteOnTime: Scalars['Boolean'];
  id: Scalars['String'];
  time: Scalars['Int'];
  version: Scalars['String'];
};


export type MutationCreateProjectArgs = {
  accuracy: Scalars['String'];
  batteryLife: Scalars['String'];
  closing: Scalars['String'];
  country: Scalars['Int'];
  customer: Scalars['String'];
  description: Scalars['String'];
  name: Scalars['String'];
  numberOfObjects: Scalars['String'];
  problem: Scalars['String'];
  refreshRate: Scalars['String'];
  revenue: Scalars['String'];
  solution: Scalars['String'];
  tagMounting: Scalars['String'];
  trackedObjects: Scalars['String'];
  useCase: Scalars['String'];
  zAxis: Scalars['Boolean'];
};


export type MutationCreateSiteUpdateArgs = {
  content: Scalars['String'];
  date: Scalars['String'];
  internal: Scalars['Boolean'];
  opportunityId?: InputMaybe<Scalars['String']>;
  siteId: Scalars['String'];
  title: Scalars['String'];
};


export type MutationCreateTicketArgs = {
  id: Scalars['ID'];
  info?: InputMaybe<Scalars['String']>;
  priority: Scalars['String'];
  subject: Scalars['String'];
  type: Scalars['String'];
};


export type MutationDeleteFileArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  path: Scalars['String'];
};


export type MutationDeleteFolderArgs = {
  id: Scalars['ID'];
  path: Scalars['String'];
};


export type MutationDeletePlanArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePlayerArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSiteUpdateArgs = {
  id: Scalars['ID'];
  siteId: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationDuplicatePlanArgs = {
  id: Scalars['ID'];
};


export type MutationEditBusinessReviewArgs = {
  description: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationEditPartnerActivityArgs = {
  attachment: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  link: Scalars['String'];
  name: Scalars['String'];
};


export type MutationEditProjectArgs = {
  accuracy: Scalars['String'];
  batteryLife: Scalars['String'];
  closing: Scalars['String'];
  country: Scalars['Int'];
  customer: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  numberOfObjects: Scalars['String'];
  problem: Scalars['String'];
  refreshRate: Scalars['String'];
  revenue: Scalars['String'];
  solution: Scalars['String'];
  tagMounting: Scalars['String'];
  trackedObjects: Scalars['String'];
  useCase: Scalars['String'];
  zAxis: Scalars['Boolean'];
};


export type MutationEditSiteUpdateArgs = {
  content: Scalars['String'];
  date: Scalars['String'];
  id: Scalars['ID'];
  internal: Scalars['Boolean'];
  opportunityId?: InputMaybe<Scalars['String']>;
  siteId: Scalars['String'];
  title: Scalars['String'];
};


export type MutationEditUserArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  role: Scalars['String'];
};


export type MutationInviteUserArgs = {
  email: Scalars['String'];
  role: Scalars['String'];
};


export type MutationProlongPlayerArgs = {
  deleteOnTime: Scalars['Boolean'];
  id: Scalars['ID'];
  time: Scalars['Int'];
};


export type MutationRenamePlanArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationSaveScanningArgs = {
  ids: Array<Scalars['Int']>;
  name: Scalars['String'];
};


export type MutationSetCompanyArgs = {
  id: Scalars['ID'];
};


export type MutationStartPlayerArgs = {
  deleteOnTime: Scalars['Boolean'];
  id: Scalars['ID'];
  time: Scalars['Int'];
};


export type MutationStopPlayerArgs = {
  id: Scalars['ID'];
};

export type Order = {
  __typename?: 'Order';
  amount: Scalars['Float'];
  currency: Scalars['String'];
  deliveryMethod: Scalars['String'];
  expiration: Scalars['String'];
  id: Scalars['ID'];
  items: Array<Item>;
  lastUpdate: Scalars['String'];
  name: Scalars['String'];
  shippingDate: Scalars['String'];
  state: Scalars['String'];
};

export type Page = {
  __typename?: 'Page';
  content: Scalars['String'];
  icon: Scalars['String'];
  name: Scalars['String'];
  path: Scalars['ID'];
  priority: Scalars['Int'];
  type: Scalars['String'];
};

export type PartnerActivity = {
  __typename?: 'PartnerActivity';
  attachment: Scalars['String'];
  description: Scalars['String'];
  expiration: Scalars['String'];
  id: Scalars['ID'];
  link: Scalars['String'];
  name: Scalars['String'];
  points: Scalars['Float'];
  state: Scalars['String'];
  type: Scalars['String'];
};

export type PartnerActivityType = {
  __typename?: 'PartnerActivityType';
  description: Scalars['String'];
  expiration: Scalars['Int'];
  id: Scalars['ID'];
  limit: Scalars['Int'];
  name: Scalars['String'];
  order: Scalars['Int'];
  points: Scalars['Float'];
  requireAttachment: Scalars['Boolean'];
  requireDescription: Scalars['Boolean'];
  requireLink: Scalars['Boolean'];
  requireName: Scalars['Boolean'];
  showAttachment: Scalars['Boolean'];
  showDescription: Scalars['Boolean'];
  showLink: Scalars['Boolean'];
  showName: Scalars['Boolean'];
};

export type PartnerLevel = {
  __typename?: 'PartnerLevel';
  color: Scalars['String'];
  discount: Scalars['Float'];
  lockingDiscount: Scalars['Boolean'];
  minAnchors: Scalars['Float'];
  minL1Trainings: Scalars['Float'];
  minL2Trainings: Scalars['Float'];
  minL3Trainings: Scalars['Float'];
  minPoints: Scalars['Float'];
  name: Scalars['String'];
  paymentTerm: Scalars['String'];
};

export type PartnerStatus = {
  __typename?: 'PartnerStatus';
  anchors: Scalars['Int'];
  currentLevel?: Maybe<PartnerLevel>;
  date: Scalars['String'];
  invoicing: Scalars['Float'];
  l1Trainings: Scalars['Int'];
  l2Trainings: Scalars['Int'];
  l3Trainings: Scalars['Int'];
  nextLevel?: Maybe<PartnerLevel>;
  points: Scalars['Int'];
  profileUrl?: Maybe<Scalars['String']>;
  recalculationL1: Scalars['Int'];
  recalculationL2: Scalars['Int'];
  recalculationL3: Scalars['Int'];
  recalculationLevel: Scalars['String'];
  recalculationPoints: Scalars['Int'];
};

export type Plan = {
  __typename?: 'Plan';
  approvedAt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  projectId: Scalars['String'];
  requestedAt?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  ticketId?: Maybe<Scalars['Float']>;
  userEmail?: Maybe<Scalars['String']>;
};

export type Player = {
  __typename?: 'Player';
  deleteOnTime: Scalars['Boolean'];
  id: Scalars['ID'];
  password: Scalars['String'];
  project: Scalars['Int'];
  remainingTime: Scalars['Float'];
  status: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  userEmail: Scalars['String'];
  username: Scalars['String'];
  version: Scalars['String'];
};

export type PlayerLogEntry = {
  __typename?: 'PlayerLogEntry';
  action: Scalars['String'];
  atString: Scalars['String'];
  details: Scalars['String'];
  id: Scalars['ID'];
  playerName: Scalars['String'];
  userEmail: Scalars['String'];
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  lotId: Scalars['Float'];
  name: Scalars['String'];
};

export type Project = {
  __typename?: 'Project';
  accuracy: Scalars['String'];
  active: Scalars['Boolean'];
  batteryLife: Scalars['String'];
  country: Scalars['Int'];
  createdAt: Scalars['String'];
  customer: Scalars['String'];
  deadline: Scalars['String'];
  description: Scalars['String'];
  folders: Array<Folder>;
  id: Scalars['ID'];
  implementationSupportFrom: Scalars['String'];
  implementationSupportTo: Scalars['String'];
  invoices: Array<Invoice>;
  locked: Scalars['Boolean'];
  name: Scalars['String'];
  numberOfObjects: Scalars['String'];
  orders: Array<Order>;
  partner: Scalars['String'];
  partnerId: Scalars['String'];
  plannerLoaded: Scalars['Boolean'];
  plans: Array<Plan>;
  problem: Scalars['String'];
  refreshRate: Scalars['String'];
  revenue: Scalars['Float'];
  siteId?: Maybe<Scalars['Float']>;
  siteName?: Maybe<Scalars['String']>;
  slaType: Scalars['String'];
  solution: Scalars['String'];
  stage: Scalars['String'];
  stageId: Scalars['Int'];
  supportFrom: Scalars['String'];
  supportTo: Scalars['String'];
  tagMounting: Scalars['String'];
  tickets: Array<Ticket>;
  ticketsLoaded: Scalars['Boolean'];
  trackedObjects: Scalars['String'];
  useCase: Scalars['String'];
  zAxis: Scalars['Boolean'];
};

export type ProjectInfo = {
  __typename?: 'ProjectInfo';
  id: Scalars['ID'];
  name: Scalars['String'];
  partnerId: Scalars['String'];
  stage?: Maybe<ProjectStage>;
};

export type ProjectStage = {
  __typename?: 'ProjectStage';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  activityFileUrl: Scalars['String'];
  article: Article;
  articles: Array<Article>;
  businessReviews: Array<BusinessReview>;
  companies: Companies;
  company: Company;
  fileUrl: Scalars['String'];
  menuItems: Array<MenuItem>;
  otherPlayers: Array<Player>;
  partnerActivities: Array<PartnerActivity>;
  partnerActivityTypes: Array<PartnerActivityType>;
  partnerInvoices: Array<Invoice>;
  partnerStatus: PartnerStatus;
  partnerTrainings: Array<PartnerActivity>;
  plans: Array<Plan>;
  playerTypes: Array<Scalars['String']>;
  players: Array<Player>;
  playersHistory: Array<PlayerLogEntry>;
  product: Product;
  project: Project;
  projectInfo: Array<ProjectInfo>;
  projects: Array<Project>;
  responseTimes: ResponseTimes;
  scanning: Scanning;
  site: Site;
  siteUpdates: Array<SiteUpdate>;
  sites: Array<Site>;
  trainingAccess: TrainingAccess;
  trainingResults: Array<TrainingResult>;
  user: User;
  users: Array<User>;
};


export type QueryActivityFileUrlArgs = {
  path: Scalars['String'];
};


export type QueryArticleArgs = {
  slug: Scalars['ID'];
};


export type QueryArticlesArgs = {
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
};


export type QueryCompaniesArgs = {
  filter?: Scalars['String'];
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
};


export type QueryFileUrlArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  path: Scalars['String'];
};


export type QueryProductArgs = {
  serial: Scalars['String'];
};


export type QueryProjectArgs = {
  id: Scalars['ID'];
};


export type QueryProjectInfoArgs = {
  ids: Scalars['String'];
};


export type QueryResponseTimesArgs = {
  id: Scalars['ID'];
};


export type QueryScanningArgs = {
  name: Scalars['String'];
};


export type QuerySiteArgs = {
  siteId: Scalars['ID'];
};


export type QuerySiteUpdatesArgs = {
  siteId: Scalars['String'];
};


export type QueryTrainingAccessArgs = {
  name: Scalars['String'];
};

export type ResponseTimes = {
  __typename?: 'ResponseTimes';
  critical: Scalars['String'];
  major: Scalars['String'];
  minor: Scalars['String'];
};

export type ScannedProduct = {
  __typename?: 'ScannedProduct';
  id: Scalars['ID'];
  name: Scalars['String'];
  quantity: Scalars['Float'];
  saved: Scalars['Float'];
  serials: Array<Scalars['String']>;
};

export type Scanning = {
  __typename?: 'Scanning';
  scannedProducts: Array<ScannedProduct>;
};

export type Site = {
  __typename?: 'Site';
  id: Scalars['ID'];
  name: Scalars['String'];
  partnerId: Scalars['String'];
  plan?: Maybe<Scalars['String']>;
  planActivation?: Maybe<Scalars['String']>;
  planExpiration?: Maybe<Scalars['String']>;
  projects: Array<Project>;
};

export type SiteUpdate = {
  __typename?: 'SiteUpdate';
  content: Scalars['String'];
  date: Scalars['String'];
  id: Scalars['ID'];
  internal: Scalars['Boolean'];
  opportunityId?: Maybe<Scalars['String']>;
  siteId: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type Ticket = {
  __typename?: 'Ticket';
  from: Scalars['String'];
  id: Scalars['ID'];
  number?: Maybe<Scalars['String']>;
  priority: Scalars['String'];
  projectId: Scalars['String'];
  status: Scalars['String'];
  subject: Scalars['String'];
  type: Scalars['String'];
  updatedString: Scalars['String'];
};

export type TrainingAccess = {
  __typename?: 'TrainingAccess';
  accessCode: Scalars['String'];
  linkUrlId: Scalars['String'];
};

export type TrainingResult = {
  __typename?: 'TrainingResult';
  id: Scalars['Float'];
  passed: Scalars['Boolean'];
  pointsGot: Scalars['Float'];
  pointsMax: Scalars['Float'];
  resultsLink: Scalars['String'];
  testName: Scalars['String'];
  time: Scalars['String'];
  userEmail: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  company: Scalars['String'];
  companyRole: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  sewio: Scalars['Boolean'];
  state: Scalars['String'];
};

export type ActivityFileUrlQueryVariables = Exact<{
  path: Scalars['String'];
}>;


export type ActivityFileUrlQuery = { __typename?: 'Query', activityFileUrl: string };

export type ApprovePlanMutationVariables = Exact<{
  id: Scalars['ID'];
  approved: Scalars['Boolean'];
}>;


export type ApprovePlanMutation = { __typename?: 'Mutation', approvePlan: string };

export type ArticleQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type ArticleQuery = { __typename?: 'Query', article: { __typename?: 'Article', slug: string, title: string, author?: string | null, date?: string | null, minutes?: number | null, preview: string, content: string } };

export type ArticlesQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type ArticlesQuery = { __typename?: 'Query', articles: Array<{ __typename?: 'Article', slug: string, title: string, author?: string | null, date?: string | null, minutes?: number | null, preview: string, content: string }> };

export type BusinessReviewsQueryVariables = Exact<{ [key: string]: never; }>;


export type BusinessReviewsQuery = { __typename?: 'Query', businessReviews: Array<{ __typename?: 'BusinessReview', id: string, createdAt: string, description: string }> };

export type ChangePlanStateMutationVariables = Exact<{
  id: Scalars['ID'];
  state: Scalars['String'];
}>;


export type ChangePlanStateMutation = { __typename?: 'Mutation', changePlanState: string };

export type CompaniesQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<Scalars['String']>;
}>;


export type CompaniesQuery = { __typename?: 'Query', companies: { __typename?: 'Companies', totalCount: number, items: Array<{ __typename?: 'Company', id: string, name: string, level: string, salesPersonName: string }> } };

export type CompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type CompanyQuery = { __typename?: 'Query', company: { __typename?: 'Company', id: string, name: string, level: string, ndaFrom: string, invoicingFy: number, salesPersonName: string, salesPersonEmail: string, salesPersonPhone: string, playerLimit: number, admin?: string | null, region: string } };

export type CreateBusinessReviewMutationVariables = Exact<{
  description: Scalars['String'];
}>;


export type CreateBusinessReviewMutation = { __typename?: 'Mutation', createBusinessReview: { __typename?: 'BusinessReview', id: string, createdAt: string, description: string } };

export type CreateFolderMutationVariables = Exact<{
  id: Scalars['ID'];
  path: Scalars['String'];
  name: Scalars['String'];
}>;


export type CreateFolderMutation = { __typename?: 'Mutation', createFolder: Array<{ __typename?: 'Folder', id: number, name: string, path: string, children: Array<number>, root: boolean, files: Array<{ __typename?: 'File', name: string, path: string, project: string, size: string }> }> };

export type CreatePartnerActivityMutationVariables = Exact<{
  name: Scalars['String'];
  type: Scalars['String'];
  description: Scalars['String'];
  link: Scalars['String'];
  attachment: Scalars['String'];
}>;


export type CreatePartnerActivityMutation = { __typename?: 'Mutation', createPartnerActivity: { __typename?: 'PartnerActivity', id: string, name: string, type: string, state: string, points: number, description: string, link: string, expiration: string, attachment: string } };

export type CreatePlanMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type CreatePlanMutation = { __typename?: 'Mutation', createPlan: { __typename?: 'Plan', id: string, name: string, state: string, projectId: string } };

export type CreatePlayerMutationVariables = Exact<{
  version: Scalars['String'];
  time: Scalars['Int'];
  id: Scalars['String'];
  deleteOnTime: Scalars['Boolean'];
}>;


export type CreatePlayerMutation = { __typename?: 'Mutation', createPlayer: { __typename?: 'Player', id: string, project: number, userEmail: string, version: string, deleteOnTime: boolean, status: string, username: string, password: string, remainingTime: number } };

export type CreateProjectMutationVariables = Exact<{
  name: Scalars['String'];
  customer: Scalars['String'];
  country: Scalars['Int'];
  problem: Scalars['String'];
  solution: Scalars['String'];
  useCase: Scalars['String'];
  revenue: Scalars['String'];
  closing: Scalars['String'];
  description: Scalars['String'];
  trackedObjects: Scalars['String'];
  numberOfObjects: Scalars['String'];
  tagMounting: Scalars['String'];
  refreshRate: Scalars['String'];
  accuracy: Scalars['String'];
  batteryLife: Scalars['String'];
  zAxis: Scalars['Boolean'];
}>;


export type CreateProjectMutation = { __typename?: 'Mutation', createProject: { __typename?: 'Project', id: string, name: string, stage: string, active: boolean, stageId: number, createdAt: string, customer: string, country: number, partner: string, partnerId: string, deadline: string, revenue: number, locked: boolean, problem: string, solution: string, supportFrom: string, trackedObjects: string, numberOfObjects: string, tagMounting: string, refreshRate: string, accuracy: string, batteryLife: string, zAxis: boolean, implementationSupportFrom: string, supportTo: string, implementationSupportTo: string, slaType: string, useCase: string, description: string, plannerLoaded: boolean, ticketsLoaded: boolean, orders: Array<{ __typename?: 'Order', id: string, name: string, state: string, amount: number, currency: string, deliveryMethod: string, shippingDate: string, expiration: string, lastUpdate: string, items: Array<{ __typename?: 'Item', id: string, type: string, mac: string }> }>, invoices: Array<{ __typename?: 'Invoice', id: string, number: string, origin: string, currency: string, invoiceDate: string, dueDate: string, totalAmount: number, dueAmount: number, trackingNumber: string, state: string }>, plans: Array<{ __typename?: 'Plan', id: string, name: string, state: string, projectId: string }>, tickets: Array<{ __typename?: 'Ticket', id: string, number?: string | null, subject: string, status: string, priority: string, type: string, updatedString: string, from: string, projectId: string }>, folders: Array<{ __typename?: 'Folder', id: number, name: string, path: string, children: Array<number>, root: boolean, files: Array<{ __typename?: 'File', name: string, path: string, project: string, size: string }> }> } };

export type CreateSiteUpdateMutationVariables = Exact<{
  siteId: Scalars['String'];
  title: Scalars['String'];
  opportunityId?: InputMaybe<Scalars['String']>;
  content: Scalars['String'];
  date: Scalars['String'];
  internal: Scalars['Boolean'];
}>;


export type CreateSiteUpdateMutation = { __typename?: 'Mutation', createSiteUpdate: { __typename?: 'SiteUpdate', id: string, siteId: string, opportunityId?: string | null, title: string, content: string, date: string, internal: boolean, updatedAt: string } };

export type CreateTicketMutationVariables = Exact<{
  id: Scalars['ID'];
  type: Scalars['String'];
  subject: Scalars['String'];
  priority: Scalars['String'];
  info?: InputMaybe<Scalars['String']>;
}>;


export type CreateTicketMutation = { __typename?: 'Mutation', createTicket: { __typename?: 'Ticket', id: string, number?: string | null, subject: string, status: string, priority: string, type: string, updatedString: string, from: string } };

export type DeleteFileMutationVariables = Exact<{
  id: Scalars['ID'];
  path: Scalars['String'];
  name: Scalars['String'];
}>;


export type DeleteFileMutation = { __typename?: 'Mutation', deleteFile: Array<{ __typename?: 'Folder', id: number, name: string, path: string, children: Array<number>, root: boolean, files: Array<{ __typename?: 'File', name: string, path: string, project: string, size: string }> }> };

export type DeleteFolderMutationVariables = Exact<{
  id: Scalars['ID'];
  path: Scalars['String'];
}>;


export type DeleteFolderMutation = { __typename?: 'Mutation', deleteFolder: Array<{ __typename?: 'Folder', id: number, name: string, path: string, children: Array<number>, root: boolean, files: Array<{ __typename?: 'File', name: string, path: string, project: string, size: string }> }> };

export type DeletePlanMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePlanMutation = { __typename?: 'Mutation', deletePlan: boolean };

export type DeletePlayerMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePlayerMutation = { __typename?: 'Mutation', deletePlayer: { __typename?: 'Player', status: string } };

export type DeleteSiteUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  siteId: Scalars['String'];
}>;


export type DeleteSiteUpdateMutation = { __typename?: 'Mutation', deleteSiteUpdate: boolean };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: string };

export type DuplicatePlanMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DuplicatePlanMutation = { __typename?: 'Mutation', duplicatePlan: { __typename?: 'Plan', id: string, name: string, state: string, projectId: string } };

export type EditBusinessReviewMutationVariables = Exact<{
  id: Scalars['ID'];
  description: Scalars['String'];
}>;


export type EditBusinessReviewMutation = { __typename?: 'Mutation', editBusinessReview: { __typename?: 'BusinessReview', id: string, createdAt: string, description: string } };

export type EditPartnerActivityMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  link: Scalars['String'];
  attachment: Scalars['String'];
}>;


export type EditPartnerActivityMutation = { __typename?: 'Mutation', editPartnerActivity: { __typename?: 'PartnerActivity', id: string, name: string, state: string, type: string, points: number, description: string, link: string, expiration: string, attachment: string } };

export type EditProjectMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  customer: Scalars['String'];
  country: Scalars['Int'];
  problem: Scalars['String'];
  solution: Scalars['String'];
  useCase: Scalars['String'];
  revenue: Scalars['String'];
  closing: Scalars['String'];
  description: Scalars['String'];
  trackedObjects: Scalars['String'];
  numberOfObjects: Scalars['String'];
  tagMounting: Scalars['String'];
  refreshRate: Scalars['String'];
  accuracy: Scalars['String'];
  batteryLife: Scalars['String'];
  zAxis: Scalars['Boolean'];
}>;


export type EditProjectMutation = { __typename?: 'Mutation', editProject: { __typename?: 'Project', id: string, name: string, stage: string, stageId: number, createdAt: string, customer: string, country: number, partner: string, deadline: string, revenue: number, locked: boolean, problem: string, solution: string, supportFrom: string, implementationSupportFrom: string, supportTo: string, implementationSupportTo: string, slaType: string, useCase: string, description: string, trackedObjects: string, numberOfObjects: string, tagMounting: string, refreshRate: string, accuracy: string, batteryLife: string, zAxis: boolean, plannerLoaded: boolean, ticketsLoaded: boolean, orders: Array<{ __typename?: 'Order', id: string, name: string, state: string, amount: number, currency: string, deliveryMethod: string, shippingDate: string, expiration: string, lastUpdate: string, items: Array<{ __typename?: 'Item', id: string, type: string, mac: string }> }>, invoices: Array<{ __typename?: 'Invoice', id: string, number: string, origin: string, currency: string, invoiceDate: string, dueDate: string, totalAmount: number, dueAmount: number, trackingNumber: string, state: string }>, plans: Array<{ __typename?: 'Plan', id: string, name: string, state: string, projectId: string }>, tickets: Array<{ __typename?: 'Ticket', id: string, number?: string | null, subject: string, status: string, priority: string, type: string, updatedString: string, from: string, projectId: string }>, folders: Array<{ __typename?: 'Folder', id: number, name: string, path: string, children: Array<number>, root: boolean, files: Array<{ __typename?: 'File', name: string, path: string, project: string, size: string }> }> } };

export type EditSiteUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  siteId: Scalars['String'];
  title: Scalars['String'];
  opportunityId?: InputMaybe<Scalars['String']>;
  content: Scalars['String'];
  date: Scalars['String'];
  internal: Scalars['Boolean'];
}>;


export type EditSiteUpdateMutation = { __typename?: 'Mutation', editSiteUpdate: { __typename?: 'SiteUpdate', id: string, siteId: string, opportunityId?: string | null, title: string, content: string, date: string, internal: boolean, updatedAt: string } };

export type EditUserMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  role: Scalars['String'];
}>;


export type EditUserMutation = { __typename?: 'Mutation', editUser: { __typename?: 'User', id: string, email: string, name?: string | null, state: string, companyRole: string } };

export type FileUrlQueryVariables = Exact<{
  path: Scalars['String'];
  name: Scalars['String'];
  id: Scalars['ID'];
}>;


export type FileUrlQuery = { __typename?: 'Query', fileUrl: string };

export type InviteUserMutationVariables = Exact<{
  email: Scalars['String'];
  role: Scalars['String'];
}>;


export type InviteUserMutation = { __typename?: 'Mutation', inviteUser: { __typename?: 'User', id: string, email: string, name?: string | null, state: string, companyRole: string } };

export type MenuItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type MenuItemsQuery = { __typename?: 'Query', menuItems: Array<{ __typename?: 'MenuItem', slug: string, title: string, icon?: string | null, url?: string | null, pageContent?: string | null, children?: Array<{ __typename?: 'MenuItem', slug: string, title: string, icon?: string | null, url?: string | null, pageContent?: string | null, parent?: { __typename?: 'MenuItem', slug: string, title: string, icon?: string | null, url?: string | null, pageContent?: string | null } | null }> | null }> };

export type OtherPlayersQueryVariables = Exact<{ [key: string]: never; }>;


export type OtherPlayersQuery = { __typename?: 'Query', otherPlayers: Array<{ __typename?: 'Player', id: string, project: number, userEmail: string, version: string, deleteOnTime: boolean, status: string, username: string, password: string, remainingTime: number, url?: string | null }> };

export type PartnerActivitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type PartnerActivitiesQuery = { __typename?: 'Query', partnerActivities: Array<{ __typename?: 'PartnerActivity', id: string, name: string, type: string, state: string, points: number, description: string, link: string, expiration: string, attachment: string }> };

export type PartnerActivityTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type PartnerActivityTypesQuery = { __typename?: 'Query', partnerActivityTypes: Array<{ __typename?: 'PartnerActivityType', id: string, name: string, points: number, expiration: number, limit: number, order: number, showName: boolean, showLink: boolean, showAttachment: boolean, showDescription: boolean, requireName: boolean, requireLink: boolean, requireAttachment: boolean, requireDescription: boolean, description: string }> };

export type PartnerInvoicesQueryVariables = Exact<{ [key: string]: never; }>;


export type PartnerInvoicesQuery = { __typename?: 'Query', partnerInvoices: Array<{ __typename?: 'Invoice', id: string, number: string, origin: string, currency: string, invoiceDate: string, dueDate: string, totalAmount: number, dueAmount: number, trackingNumber: string, state: string }> };

export type PartnerStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type PartnerStatusQuery = { __typename?: 'Query', partnerStatus: { __typename?: 'PartnerStatus', points: number, anchors: number, date: string, recalculationLevel: string, recalculationPoints: number, recalculationL1: number, recalculationL2: number, recalculationL3: number, l1Trainings: number, l2Trainings: number, l3Trainings: number, invoicing: number, profileUrl?: string | null, nextLevel?: { __typename?: 'PartnerLevel', name: string, minAnchors: number, minL1Trainings: number, minL2Trainings: number, minL3Trainings: number, minPoints: number, paymentTerm: string, discount: number, color: string, lockingDiscount: boolean } | null, currentLevel?: { __typename?: 'PartnerLevel', name: string, minAnchors: number, minL1Trainings: number, minL2Trainings: number, minL3Trainings: number, minPoints: number, paymentTerm: string, discount: number, color: string, lockingDiscount: boolean } | null } };

export type PartnerTrainingsQueryVariables = Exact<{ [key: string]: never; }>;


export type PartnerTrainingsQuery = { __typename?: 'Query', partnerTrainings: Array<{ __typename?: 'PartnerActivity', id: string, name: string, type: string, state: string, points: number, description: string, link: string, expiration: string, attachment: string }> };

export type PlansQueryVariables = Exact<{ [key: string]: never; }>;


export type PlansQuery = { __typename?: 'Query', plans: Array<{ __typename?: 'Plan', id: string, name: string, state: string, projectId: string, createdAt?: string | null, requestedAt?: string | null, approvedAt?: string | null, userEmail?: string | null, ticketId?: number | null }> };

export type PlayerTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type PlayerTypesQuery = { __typename?: 'Query', playerTypes: Array<string> };

export type PlayersHistoryQueryVariables = Exact<{ [key: string]: never; }>;


export type PlayersHistoryQuery = { __typename?: 'Query', playersHistory: Array<{ __typename?: 'PlayerLogEntry', id: string, userEmail: string, playerName: string, action: string, details: string, atString: string }> };

export type PlayersQueryVariables = Exact<{ [key: string]: never; }>;


export type PlayersQuery = { __typename?: 'Query', players: Array<{ __typename?: 'Player', id: string, project: number, userEmail: string, version: string, deleteOnTime: boolean, status: string, username: string, password: string, remainingTime: number, url?: string | null }> };

export type ProductQueryVariables = Exact<{
  serial: Scalars['String'];
}>;


export type ProductQuery = { __typename?: 'Query', product: { __typename?: 'Product', id: string, name: string, lotId: number } };

export type ProjectInfoQueryVariables = Exact<{
  ids: Scalars['String'];
}>;


export type ProjectInfoQuery = { __typename?: 'Query', projectInfo: Array<{ __typename?: 'ProjectInfo', id: string, name: string, partnerId: string, stage?: { __typename?: 'ProjectStage', id: string, name: string } | null }> };

export type ProjectQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type ProjectQuery = { __typename?: 'Query', project: { __typename?: 'Project', id: string, name: string, active: boolean, stage: string, stageId: number, createdAt: string, customer: string, country: number, partner: string, partnerId: string, deadline: string, revenue: number, locked: boolean, problem: string, solution: string, supportFrom: string, implementationSupportFrom: string, supportTo: string, implementationSupportTo: string, slaType: string, useCase: string, description: string, trackedObjects: string, numberOfObjects: string, tagMounting: string, refreshRate: string, accuracy: string, batteryLife: string, zAxis: boolean, plannerLoaded: boolean, ticketsLoaded: boolean, siteId?: number | null, siteName?: string | null, orders: Array<{ __typename?: 'Order', id: string, name: string, state: string, amount: number, currency: string, deliveryMethod: string, shippingDate: string, expiration: string, lastUpdate: string, items: Array<{ __typename?: 'Item', id: string, type: string, mac: string }> }>, invoices: Array<{ __typename?: 'Invoice', id: string, number: string, origin: string, currency: string, invoiceDate: string, dueDate: string, totalAmount: number, dueAmount: number, trackingNumber: string, state: string }>, plans: Array<{ __typename?: 'Plan', id: string, name: string, state: string, projectId: string }>, tickets: Array<{ __typename?: 'Ticket', id: string, number?: string | null, subject: string, status: string, priority: string, type: string, updatedString: string, from: string, projectId: string }>, folders: Array<{ __typename?: 'Folder', id: number, name: string, path: string, children: Array<number>, root: boolean, files: Array<{ __typename?: 'File', name: string, path: string, project: string, size: string }> }> } };

export type ProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectsQuery = { __typename?: 'Query', projects: Array<{ __typename?: 'Project', id: string, name: string, active: boolean, stage: string, stageId: number, createdAt: string, customer: string, country: number, partner: string, partnerId: string, deadline: string, revenue: number, locked: boolean, problem: string, solution: string, supportFrom: string, implementationSupportFrom: string, supportTo: string, implementationSupportTo: string, slaType: string, useCase: string, description: string, trackedObjects: string, numberOfObjects: string, tagMounting: string, refreshRate: string, accuracy: string, batteryLife: string, zAxis: boolean, plannerLoaded: boolean, ticketsLoaded: boolean, siteId?: number | null, siteName?: string | null, orders: Array<{ __typename?: 'Order', id: string, name: string, state: string, amount: number, currency: string, deliveryMethod: string, shippingDate: string, expiration: string, lastUpdate: string, items: Array<{ __typename?: 'Item', id: string, type: string, mac: string }> }>, invoices: Array<{ __typename?: 'Invoice', id: string, number: string, origin: string, currency: string, invoiceDate: string, dueDate: string, totalAmount: number, dueAmount: number, trackingNumber: string, state: string }>, plans: Array<{ __typename?: 'Plan', id: string, name: string, state: string, projectId: string }>, tickets: Array<{ __typename?: 'Ticket', id: string, number?: string | null, subject: string, status: string, priority: string, type: string, updatedString: string, from: string, projectId: string }>, folders: Array<{ __typename?: 'Folder', id: number, name: string, path: string, children: Array<number>, root: boolean, files: Array<{ __typename?: 'File', name: string, path: string, project: string, size: string }> }> }> };

export type ProlongPlayerMutationVariables = Exact<{
  time: Scalars['Int'];
  id: Scalars['ID'];
  deleteOnTime: Scalars['Boolean'];
}>;


export type ProlongPlayerMutation = { __typename?: 'Mutation', prolongPlayer: { __typename?: 'Player', status: string, remainingTime: number, deleteOnTime: boolean } };

export type RenamePlanMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type RenamePlanMutation = { __typename?: 'Mutation', renamePlan: string };

export type ResetCompanyMutationVariables = Exact<{ [key: string]: never; }>;


export type ResetCompanyMutation = { __typename?: 'Mutation', resetCompany: boolean };

export type ResponseTimesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ResponseTimesQuery = { __typename?: 'Query', responseTimes: { __typename?: 'ResponseTimes', critical: string, major: string, minor: string } };

export type SaveScanningMutationVariables = Exact<{
  name: Scalars['String'];
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type SaveScanningMutation = { __typename?: 'Mutation', saveScanning: { __typename?: 'Scanning', scannedProducts: Array<{ __typename?: 'ScannedProduct', id: string, name: string, quantity: number, saved: number, serials: Array<string> }> } };

export type ScanningQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type ScanningQuery = { __typename?: 'Query', scanning: { __typename?: 'Scanning', scannedProducts: Array<{ __typename?: 'ScannedProduct', id: string, name: string, quantity: number, saved: number, serials: Array<string> }> } };

export type SetCompanyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SetCompanyMutation = { __typename?: 'Mutation', setCompany: boolean };

export type SiteUpdatesQueryVariables = Exact<{
  siteId: Scalars['String'];
}>;


export type SiteUpdatesQuery = { __typename?: 'Query', siteUpdates: Array<{ __typename?: 'SiteUpdate', id: string, siteId: string, opportunityId?: string | null, title: string, content: string, date: string, internal: boolean, updatedAt: string }> };

export type SiteQueryVariables = Exact<{
  siteId: Scalars['ID'];
}>;


export type SiteQuery = { __typename?: 'Query', site: { __typename?: 'Site', id: string, name: string, partnerId: string, plan?: string | null, planActivation?: string | null, planExpiration?: string | null, projects: Array<{ __typename?: 'Project', id: string, name: string, stage: string, stageId: number, createdAt: string, customer: string, country: number, partner: string, partnerId: string, deadline: string, revenue: number, locked: boolean, problem: string, solution: string, supportFrom: string, implementationSupportFrom: string, supportTo: string, implementationSupportTo: string, slaType: string, useCase: string, description: string, trackedObjects: string, numberOfObjects: string, tagMounting: string, refreshRate: string, accuracy: string, batteryLife: string, zAxis: boolean, plannerLoaded: boolean, ticketsLoaded: boolean, siteId?: number | null, siteName?: string | null, active: boolean, orders: Array<{ __typename?: 'Order', id: string, name: string, state: string, amount: number, currency: string, deliveryMethod: string, shippingDate: string, expiration: string, lastUpdate: string, items: Array<{ __typename?: 'Item', id: string, type: string, mac: string }> }>, invoices: Array<{ __typename?: 'Invoice', id: string, number: string, origin: string, currency: string, invoiceDate: string, dueDate: string, totalAmount: number, dueAmount: number, trackingNumber: string, state: string }>, plans: Array<{ __typename?: 'Plan', id: string, name: string, state: string, projectId: string }>, tickets: Array<{ __typename?: 'Ticket', id: string, number?: string | null, subject: string, status: string, priority: string, type: string, updatedString: string, from: string, projectId: string }>, folders: Array<{ __typename?: 'Folder', id: number, name: string, path: string, children: Array<number>, root: boolean, files: Array<{ __typename?: 'File', name: string, path: string, project: string, size: string }> }> }> } };

export type SitesQueryVariables = Exact<{ [key: string]: never; }>;


export type SitesQuery = { __typename?: 'Query', sites: Array<{ __typename?: 'Site', id: string, name: string, partnerId: string, plan?: string | null, planActivation?: string | null, planExpiration?: string | null, projects: Array<{ __typename?: 'Project', id: string, name: string, stage: string, stageId: number, createdAt: string, customer: string, country: number, partner: string, partnerId: string, deadline: string, revenue: number, locked: boolean, problem: string, solution: string, supportFrom: string, implementationSupportFrom: string, supportTo: string, implementationSupportTo: string, slaType: string, useCase: string, description: string, trackedObjects: string, numberOfObjects: string, tagMounting: string, refreshRate: string, accuracy: string, batteryLife: string, zAxis: boolean, plannerLoaded: boolean, ticketsLoaded: boolean, siteId?: number | null, siteName?: string | null, active: boolean, orders: Array<{ __typename?: 'Order', id: string, name: string, state: string, amount: number, currency: string, deliveryMethod: string, shippingDate: string, expiration: string, lastUpdate: string, items: Array<{ __typename?: 'Item', id: string, type: string, mac: string }> }>, invoices: Array<{ __typename?: 'Invoice', id: string, number: string, origin: string, currency: string, invoiceDate: string, dueDate: string, totalAmount: number, dueAmount: number, trackingNumber: string, state: string }>, plans: Array<{ __typename?: 'Plan', id: string, name: string, state: string, projectId: string }>, tickets: Array<{ __typename?: 'Ticket', id: string, number?: string | null, subject: string, status: string, priority: string, type: string, updatedString: string, from: string, projectId: string }>, folders: Array<{ __typename?: 'Folder', id: number, name: string, path: string, children: Array<number>, root: boolean, files: Array<{ __typename?: 'File', name: string, path: string, project: string, size: string }> }> }> }> };

export type StartPlayerMutationVariables = Exact<{
  time: Scalars['Int'];
  id: Scalars['ID'];
  deleteOnTime: Scalars['Boolean'];
}>;


export type StartPlayerMutation = { __typename?: 'Mutation', startPlayer: { __typename?: 'Player', status: string } };

export type StopPlayerMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type StopPlayerMutation = { __typename?: 'Mutation', stopPlayer: { __typename?: 'Player', status: string } };

export type TrainingAccessQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type TrainingAccessQuery = { __typename?: 'Query', trainingAccess: { __typename?: 'TrainingAccess', linkUrlId: string, accessCode: string } };

export type TrainingResultsQueryVariables = Exact<{ [key: string]: never; }>;


export type TrainingResultsQuery = { __typename?: 'Query', trainingResults: Array<{ __typename?: 'TrainingResult', id: number, userEmail: string, pointsGot: number, pointsMax: number, passed: boolean, testName: string, resultsLink: string, time: string }> };

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, email: string, sewio: boolean, companyRole: string } };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, email: string, name?: string | null, state: string, companyRole: string }> };

export const ActivityFileUrlDocument = gql`
    query activityFileUrl($path: String!) {
  activityFileUrl(path: $path)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ActivityFileUrlGQL extends Apollo.Query<ActivityFileUrlQuery, ActivityFileUrlQueryVariables> {
    override document = ActivityFileUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ApprovePlanDocument = gql`
    mutation approvePlan($id: ID!, $approved: Boolean!) {
  approvePlan(id: $id, approved: $approved)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApprovePlanGQL extends Apollo.Mutation<ApprovePlanMutation, ApprovePlanMutationVariables> {
    override document = ApprovePlanDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArticleDocument = gql`
    query article($slug: ID!) {
  article(slug: $slug) {
    slug
    title
    author
    date
    minutes
    preview
    content
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArticleGQL extends Apollo.Query<ArticleQuery, ArticleQueryVariables> {
    override document = ArticleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArticlesDocument = gql`
    query articles($offset: Int, $limit: Int) {
  articles(offset: $offset, limit: $limit) {
    slug
    title
    author
    date
    minutes
    preview
    content
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArticlesGQL extends Apollo.Query<ArticlesQuery, ArticlesQueryVariables> {
    override document = ArticlesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BusinessReviewsDocument = gql`
    query businessReviews {
  businessReviews {
    id
    createdAt
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BusinessReviewsGQL extends Apollo.Query<BusinessReviewsQuery, BusinessReviewsQueryVariables> {
    override document = BusinessReviewsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChangePlanStateDocument = gql`
    mutation changePlanState($id: ID!, $state: String!) {
  changePlanState(id: $id, state: $state)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ChangePlanStateGQL extends Apollo.Mutation<ChangePlanStateMutation, ChangePlanStateMutationVariables> {
    override document = ChangePlanStateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CompaniesDocument = gql`
    query companies($offset: Int, $limit: Int, $filter: String) {
  companies(offset: $offset, limit: $limit, filter: $filter) {
    totalCount
    items {
      id
      name
      level
      salesPersonName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CompaniesGQL extends Apollo.Query<CompaniesQuery, CompaniesQueryVariables> {
    override document = CompaniesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CompanyDocument = gql`
    query company {
  company {
    id
    name
    level
    ndaFrom
    invoicingFy
    salesPersonName
    salesPersonEmail
    salesPersonPhone
    playerLimit
    admin
    region
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CompanyGQL extends Apollo.Query<CompanyQuery, CompanyQueryVariables> {
    override document = CompanyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateBusinessReviewDocument = gql`
    mutation createBusinessReview($description: String!) {
  createBusinessReview(description: $description) {
    id
    createdAt
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBusinessReviewGQL extends Apollo.Mutation<CreateBusinessReviewMutation, CreateBusinessReviewMutationVariables> {
    override document = CreateBusinessReviewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateFolderDocument = gql`
    mutation createFolder($id: ID!, $path: String!, $name: String!) {
  createFolder(id: $id, path: $path, name: $name) {
    id
    name
    path
    files {
      name
      path
      project
      size
    }
    children
    root
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateFolderGQL extends Apollo.Mutation<CreateFolderMutation, CreateFolderMutationVariables> {
    override document = CreateFolderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePartnerActivityDocument = gql`
    mutation createPartnerActivity($name: String!, $type: String!, $description: String!, $link: String!, $attachment: String!) {
  createPartnerActivity(
    name: $name
    type: $type
    description: $description
    link: $link
    attachment: $attachment
  ) {
    id
    name
    type
    state
    points
    description
    link
    expiration
    attachment
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePartnerActivityGQL extends Apollo.Mutation<CreatePartnerActivityMutation, CreatePartnerActivityMutationVariables> {
    override document = CreatePartnerActivityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePlanDocument = gql`
    mutation createPlan($id: ID!, $name: String!) {
  createPlan(id: $id, name: $name) {
    id
    name
    state
    projectId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePlanGQL extends Apollo.Mutation<CreatePlanMutation, CreatePlanMutationVariables> {
    override document = CreatePlanDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePlayerDocument = gql`
    mutation createPlayer($version: String!, $time: Int!, $id: String!, $deleteOnTime: Boolean!) {
  createPlayer(
    version: $version
    time: $time
    id: $id
    deleteOnTime: $deleteOnTime
  ) {
    id
    project
    userEmail
    version
    deleteOnTime
    status
    username
    password
    remainingTime
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePlayerGQL extends Apollo.Mutation<CreatePlayerMutation, CreatePlayerMutationVariables> {
    override document = CreatePlayerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateProjectDocument = gql`
    mutation createProject($name: String!, $customer: String!, $country: Int!, $problem: String!, $solution: String!, $useCase: String!, $revenue: String!, $closing: String!, $description: String!, $trackedObjects: String!, $numberOfObjects: String!, $tagMounting: String!, $refreshRate: String!, $accuracy: String!, $batteryLife: String!, $zAxis: Boolean!) {
  createProject(
    name: $name
    customer: $customer
    country: $country
    problem: $problem
    solution: $solution
    useCase: $useCase
    revenue: $revenue
    closing: $closing
    description: $description
    trackedObjects: $trackedObjects
    numberOfObjects: $numberOfObjects
    tagMounting: $tagMounting
    refreshRate: $refreshRate
    accuracy: $accuracy
    batteryLife: $batteryLife
    zAxis: $zAxis
  ) {
    id
    name
    stage
    active
    stageId
    createdAt
    customer
    country
    partner
    partnerId
    deadline
    revenue
    locked
    problem
    solution
    supportFrom
    trackedObjects
    numberOfObjects
    tagMounting
    refreshRate
    accuracy
    batteryLife
    zAxis
    implementationSupportFrom
    supportTo
    implementationSupportTo
    slaType
    useCase
    description
    orders {
      id
      name
      state
      amount
      currency
      deliveryMethod
      shippingDate
      expiration
      lastUpdate
      items {
        id
        type
        mac
      }
    }
    invoices {
      id
      number
      origin
      currency
      invoiceDate
      dueDate
      totalAmount
      dueAmount
      trackingNumber
      state
    }
    plans {
      id
      name
      state
      projectId
    }
    plannerLoaded
    tickets {
      id
      number
      subject
      status
      priority
      type
      updatedString
      from
      projectId
    }
    ticketsLoaded
    folders {
      id
      name
      path
      files {
        name
        path
        project
        size
      }
      children
      root
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateProjectGQL extends Apollo.Mutation<CreateProjectMutation, CreateProjectMutationVariables> {
    override document = CreateProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateSiteUpdateDocument = gql`
    mutation createSiteUpdate($siteId: String!, $title: String!, $opportunityId: String, $content: String!, $date: String!, $internal: Boolean!) {
  createSiteUpdate(
    siteId: $siteId
    title: $title
    opportunityId: $opportunityId
    content: $content
    date: $date
    internal: $internal
  ) {
    id
    siteId
    opportunityId
    title
    content
    date
    internal
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSiteUpdateGQL extends Apollo.Mutation<CreateSiteUpdateMutation, CreateSiteUpdateMutationVariables> {
    override document = CreateSiteUpdateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTicketDocument = gql`
    mutation createTicket($id: ID!, $type: String!, $subject: String!, $priority: String!, $info: String) {
  createTicket(
    id: $id
    type: $type
    subject: $subject
    priority: $priority
    info: $info
  ) {
    id
    number
    subject
    status
    priority
    type
    updatedString
    from
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTicketGQL extends Apollo.Mutation<CreateTicketMutation, CreateTicketMutationVariables> {
    override document = CreateTicketDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteFileDocument = gql`
    mutation deleteFile($id: ID!, $path: String!, $name: String!) {
  deleteFile(id: $id, path: $path, name: $name) {
    id
    name
    path
    files {
      name
      path
      project
      size
    }
    children
    root
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteFileGQL extends Apollo.Mutation<DeleteFileMutation, DeleteFileMutationVariables> {
    override document = DeleteFileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteFolderDocument = gql`
    mutation deleteFolder($id: ID!, $path: String!) {
  deleteFolder(id: $id, path: $path) {
    id
    name
    path
    files {
      name
      path
      project
      size
    }
    children
    root
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteFolderGQL extends Apollo.Mutation<DeleteFolderMutation, DeleteFolderMutationVariables> {
    override document = DeleteFolderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeletePlanDocument = gql`
    mutation deletePlan($id: ID!) {
  deletePlan(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletePlanGQL extends Apollo.Mutation<DeletePlanMutation, DeletePlanMutationVariables> {
    override document = DeletePlanDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeletePlayerDocument = gql`
    mutation deletePlayer($id: ID!) {
  deletePlayer(id: $id) {
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletePlayerGQL extends Apollo.Mutation<DeletePlayerMutation, DeletePlayerMutationVariables> {
    override document = DeletePlayerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteSiteUpdateDocument = gql`
    mutation deleteSiteUpdate($id: ID!, $siteId: String!) {
  deleteSiteUpdate(id: $id, siteId: $siteId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteSiteUpdateGQL extends Apollo.Mutation<DeleteSiteUpdateMutation, DeleteSiteUpdateMutationVariables> {
    override document = DeleteSiteUpdateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUserDocument = gql`
    mutation deleteUser($id: ID!) {
  deleteUser(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserGQL extends Apollo.Mutation<DeleteUserMutation, DeleteUserMutationVariables> {
    override document = DeleteUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DuplicatePlanDocument = gql`
    mutation duplicatePlan($id: ID!) {
  duplicatePlan(id: $id) {
    id
    name
    state
    projectId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DuplicatePlanGQL extends Apollo.Mutation<DuplicatePlanMutation, DuplicatePlanMutationVariables> {
    override document = DuplicatePlanDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EditBusinessReviewDocument = gql`
    mutation editBusinessReview($id: ID!, $description: String!) {
  editBusinessReview(id: $id, description: $description) {
    id
    createdAt
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EditBusinessReviewGQL extends Apollo.Mutation<EditBusinessReviewMutation, EditBusinessReviewMutationVariables> {
    override document = EditBusinessReviewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EditPartnerActivityDocument = gql`
    mutation editPartnerActivity($id: ID!, $name: String!, $description: String!, $link: String!, $attachment: String!) {
  editPartnerActivity(
    id: $id
    name: $name
    description: $description
    link: $link
    attachment: $attachment
  ) {
    id
    name
    state
    type
    points
    description
    link
    expiration
    attachment
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EditPartnerActivityGQL extends Apollo.Mutation<EditPartnerActivityMutation, EditPartnerActivityMutationVariables> {
    override document = EditPartnerActivityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EditProjectDocument = gql`
    mutation editProject($id: ID!, $name: String!, $customer: String!, $country: Int!, $problem: String!, $solution: String!, $useCase: String!, $revenue: String!, $closing: String!, $description: String!, $trackedObjects: String!, $numberOfObjects: String!, $tagMounting: String!, $refreshRate: String!, $accuracy: String!, $batteryLife: String!, $zAxis: Boolean!) {
  editProject(
    id: $id
    name: $name
    customer: $customer
    country: $country
    problem: $problem
    solution: $solution
    useCase: $useCase
    revenue: $revenue
    closing: $closing
    description: $description
    trackedObjects: $trackedObjects
    numberOfObjects: $numberOfObjects
    tagMounting: $tagMounting
    refreshRate: $refreshRate
    accuracy: $accuracy
    batteryLife: $batteryLife
    zAxis: $zAxis
  ) {
    id
    name
    stage
    stageId
    createdAt
    customer
    country
    partner
    deadline
    revenue
    locked
    problem
    solution
    supportFrom
    implementationSupportFrom
    supportTo
    implementationSupportTo
    slaType
    useCase
    description
    trackedObjects
    numberOfObjects
    tagMounting
    refreshRate
    accuracy
    batteryLife
    zAxis
    orders {
      id
      name
      state
      amount
      currency
      deliveryMethod
      shippingDate
      expiration
      lastUpdate
      items {
        id
        type
        mac
      }
    }
    invoices {
      id
      number
      origin
      currency
      invoiceDate
      dueDate
      totalAmount
      dueAmount
      trackingNumber
      state
    }
    plans {
      id
      name
      state
      projectId
    }
    plannerLoaded
    tickets {
      id
      number
      subject
      status
      priority
      type
      updatedString
      from
      projectId
    }
    ticketsLoaded
    folders {
      id
      name
      path
      files {
        name
        path
        project
        size
      }
      children
      root
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EditProjectGQL extends Apollo.Mutation<EditProjectMutation, EditProjectMutationVariables> {
    override document = EditProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EditSiteUpdateDocument = gql`
    mutation editSiteUpdate($id: ID!, $siteId: String!, $title: String!, $opportunityId: String, $content: String!, $date: String!, $internal: Boolean!) {
  editSiteUpdate(
    id: $id
    siteId: $siteId
    title: $title
    opportunityId: $opportunityId
    content: $content
    date: $date
    internal: $internal
  ) {
    id
    siteId
    opportunityId
    title
    content
    date
    internal
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EditSiteUpdateGQL extends Apollo.Mutation<EditSiteUpdateMutation, EditSiteUpdateMutationVariables> {
    override document = EditSiteUpdateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EditUserDocument = gql`
    mutation editUser($id: ID!, $name: String!, $role: String!) {
  editUser(id: $id, name: $name, role: $role) {
    id
    email
    name
    state
    companyRole
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EditUserGQL extends Apollo.Mutation<EditUserMutation, EditUserMutationVariables> {
    override document = EditUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FileUrlDocument = gql`
    query fileUrl($path: String!, $name: String!, $id: ID!) {
  fileUrl(path: $path, name: $name, id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FileUrlGQL extends Apollo.Query<FileUrlQuery, FileUrlQueryVariables> {
    override document = FileUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InviteUserDocument = gql`
    mutation inviteUser($email: String!, $role: String!) {
  inviteUser(email: $email, role: $role) {
    id
    email
    name
    state
    companyRole
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InviteUserGQL extends Apollo.Mutation<InviteUserMutation, InviteUserMutationVariables> {
    override document = InviteUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MenuItemsDocument = gql`
    query menuItems {
  menuItems {
    slug
    title
    icon
    url
    pageContent
    children {
      slug
      title
      icon
      url
      pageContent
      parent {
        slug
        title
        icon
        url
        pageContent
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MenuItemsGQL extends Apollo.Query<MenuItemsQuery, MenuItemsQueryVariables> {
    override document = MenuItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OtherPlayersDocument = gql`
    query otherPlayers {
  otherPlayers {
    id
    project
    userEmail
    version
    deleteOnTime
    status
    username
    password
    remainingTime
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OtherPlayersGQL extends Apollo.Query<OtherPlayersQuery, OtherPlayersQueryVariables> {
    override document = OtherPlayersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PartnerActivitiesDocument = gql`
    query partnerActivities {
  partnerActivities {
    id
    name
    type
    state
    points
    description
    link
    expiration
    attachment
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerActivitiesGQL extends Apollo.Query<PartnerActivitiesQuery, PartnerActivitiesQueryVariables> {
    override document = PartnerActivitiesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PartnerActivityTypesDocument = gql`
    query partnerActivityTypes {
  partnerActivityTypes {
    id
    name
    points
    expiration
    limit
    order
    showName
    showLink
    showAttachment
    showDescription
    requireName
    requireLink
    requireAttachment
    requireDescription
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerActivityTypesGQL extends Apollo.Query<PartnerActivityTypesQuery, PartnerActivityTypesQueryVariables> {
    override document = PartnerActivityTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PartnerInvoicesDocument = gql`
    query partnerInvoices {
  partnerInvoices {
    id
    number
    origin
    currency
    invoiceDate
    dueDate
    totalAmount
    dueAmount
    trackingNumber
    state
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerInvoicesGQL extends Apollo.Query<PartnerInvoicesQuery, PartnerInvoicesQueryVariables> {
    override document = PartnerInvoicesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PartnerStatusDocument = gql`
    query partnerStatus {
  partnerStatus {
    points
    anchors
    date
    recalculationLevel
    recalculationPoints
    recalculationL1
    recalculationL2
    recalculationL3
    l1Trainings
    l2Trainings
    l3Trainings
    invoicing
    profileUrl
    nextLevel {
      name
      minAnchors
      minL1Trainings
      minL2Trainings
      minL3Trainings
      minPoints
      paymentTerm
      discount
      color
      lockingDiscount
    }
    currentLevel {
      name
      minAnchors
      minL1Trainings
      minL2Trainings
      minL3Trainings
      minPoints
      paymentTerm
      discount
      color
      lockingDiscount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerStatusGQL extends Apollo.Query<PartnerStatusQuery, PartnerStatusQueryVariables> {
    override document = PartnerStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PartnerTrainingsDocument = gql`
    query partnerTrainings {
  partnerTrainings {
    id
    name
    type
    state
    points
    description
    link
    expiration
    attachment
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerTrainingsGQL extends Apollo.Query<PartnerTrainingsQuery, PartnerTrainingsQueryVariables> {
    override document = PartnerTrainingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PlansDocument = gql`
    query plans {
  plans {
    id
    name
    state
    projectId
    createdAt
    requestedAt
    approvedAt
    userEmail
    ticketId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PlansGQL extends Apollo.Query<PlansQuery, PlansQueryVariables> {
    override document = PlansDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PlayerTypesDocument = gql`
    query playerTypes {
  playerTypes
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PlayerTypesGQL extends Apollo.Query<PlayerTypesQuery, PlayerTypesQueryVariables> {
    override document = PlayerTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PlayersHistoryDocument = gql`
    query playersHistory {
  playersHistory {
    id
    userEmail
    playerName
    action
    details
    atString
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PlayersHistoryGQL extends Apollo.Query<PlayersHistoryQuery, PlayersHistoryQueryVariables> {
    override document = PlayersHistoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PlayersDocument = gql`
    query players {
  players {
    id
    project
    userEmail
    version
    deleteOnTime
    status
    username
    password
    remainingTime
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PlayersGQL extends Apollo.Query<PlayersQuery, PlayersQueryVariables> {
    override document = PlayersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductDocument = gql`
    query product($serial: String!) {
  product(serial: $serial) {
    id
    name
    lotId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductGQL extends Apollo.Query<ProductQuery, ProductQueryVariables> {
    override document = ProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectInfoDocument = gql`
    query projectInfo($ids: String!) {
  projectInfo(ids: $ids) {
    id
    name
    stage {
      id
      name
    }
    partnerId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectInfoGQL extends Apollo.Query<ProjectInfoQuery, ProjectInfoQueryVariables> {
    override document = ProjectInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectDocument = gql`
    query project($projectId: ID!) {
  project(id: $projectId) {
    id
    name
    active
    stage
    stageId
    createdAt
    customer
    country
    partner
    partnerId
    deadline
    revenue
    locked
    problem
    solution
    supportFrom
    implementationSupportFrom
    supportTo
    implementationSupportTo
    slaType
    useCase
    description
    trackedObjects
    numberOfObjects
    tagMounting
    refreshRate
    accuracy
    batteryLife
    zAxis
    orders {
      id
      name
      state
      amount
      currency
      deliveryMethod
      shippingDate
      expiration
      lastUpdate
      items {
        id
        type
        mac
      }
    }
    invoices {
      id
      number
      origin
      currency
      invoiceDate
      dueDate
      totalAmount
      dueAmount
      trackingNumber
      state
    }
    plans {
      id
      name
      state
      projectId
    }
    plannerLoaded
    tickets {
      id
      number
      subject
      status
      priority
      type
      updatedString
      from
      projectId
    }
    ticketsLoaded
    folders {
      id
      name
      path
      files {
        name
        path
        project
        size
      }
      children
      root
    }
    siteId
    siteName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectGQL extends Apollo.Query<ProjectQuery, ProjectQueryVariables> {
    override document = ProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectsDocument = gql`
    query projects {
  projects {
    id
    name
    active
    stage
    stageId
    createdAt
    customer
    country
    partner
    partnerId
    deadline
    revenue
    locked
    problem
    solution
    supportFrom
    implementationSupportFrom
    supportTo
    implementationSupportTo
    slaType
    useCase
    description
    trackedObjects
    numberOfObjects
    tagMounting
    refreshRate
    accuracy
    batteryLife
    zAxis
    orders {
      id
      name
      state
      amount
      currency
      deliveryMethod
      shippingDate
      expiration
      lastUpdate
      items {
        id
        type
        mac
      }
    }
    invoices {
      id
      number
      origin
      currency
      invoiceDate
      dueDate
      totalAmount
      dueAmount
      trackingNumber
      state
    }
    plans {
      id
      name
      state
      projectId
    }
    plannerLoaded
    tickets {
      id
      number
      subject
      status
      priority
      type
      updatedString
      from
      projectId
    }
    ticketsLoaded
    folders {
      id
      name
      path
      files {
        name
        path
        project
        size
      }
      children
      root
    }
    siteId
    siteName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectsGQL extends Apollo.Query<ProjectsQuery, ProjectsQueryVariables> {
    override document = ProjectsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProlongPlayerDocument = gql`
    mutation prolongPlayer($time: Int!, $id: ID!, $deleteOnTime: Boolean!) {
  prolongPlayer(id: $id, time: $time, deleteOnTime: $deleteOnTime) {
    status
    remainingTime
    deleteOnTime
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProlongPlayerGQL extends Apollo.Mutation<ProlongPlayerMutation, ProlongPlayerMutationVariables> {
    override document = ProlongPlayerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RenamePlanDocument = gql`
    mutation renamePlan($id: ID!, $name: String!) {
  renamePlan(id: $id, name: $name)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RenamePlanGQL extends Apollo.Mutation<RenamePlanMutation, RenamePlanMutationVariables> {
    override document = RenamePlanDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResetCompanyDocument = gql`
    mutation resetCompany {
  resetCompany
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResetCompanyGQL extends Apollo.Mutation<ResetCompanyMutation, ResetCompanyMutationVariables> {
    override document = ResetCompanyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResponseTimesDocument = gql`
    query responseTimes($id: ID!) {
  responseTimes(id: $id) {
    critical
    major
    minor
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResponseTimesGQL extends Apollo.Query<ResponseTimesQuery, ResponseTimesQueryVariables> {
    override document = ResponseTimesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveScanningDocument = gql`
    mutation saveScanning($name: String!, $ids: [Int!]!) {
  saveScanning(name: $name, ids: $ids) {
    scannedProducts {
      id
      name
      quantity
      saved
      serials
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveScanningGQL extends Apollo.Mutation<SaveScanningMutation, SaveScanningMutationVariables> {
    override document = SaveScanningDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ScanningDocument = gql`
    query scanning($name: String!) {
  scanning(name: $name) {
    scannedProducts {
      id
      name
      quantity
      saved
      serials
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ScanningGQL extends Apollo.Query<ScanningQuery, ScanningQueryVariables> {
    override document = ScanningDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetCompanyDocument = gql`
    mutation setCompany($id: ID!) {
  setCompany(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetCompanyGQL extends Apollo.Mutation<SetCompanyMutation, SetCompanyMutationVariables> {
    override document = SetCompanyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SiteUpdatesDocument = gql`
    query siteUpdates($siteId: String!) {
  siteUpdates(siteId: $siteId) {
    id
    siteId
    opportunityId
    title
    content
    date
    internal
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SiteUpdatesGQL extends Apollo.Query<SiteUpdatesQuery, SiteUpdatesQueryVariables> {
    override document = SiteUpdatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SiteDocument = gql`
    query site($siteId: ID!) {
  site(siteId: $siteId) {
    id
    name
    partnerId
    projects {
      id
      name
      stage
      stageId
      createdAt
      customer
      country
      partner
      partnerId
      deadline
      revenue
      locked
      problem
      solution
      supportFrom
      implementationSupportFrom
      supportTo
      implementationSupportTo
      slaType
      useCase
      description
      trackedObjects
      numberOfObjects
      tagMounting
      refreshRate
      accuracy
      batteryLife
      zAxis
      orders {
        id
        name
        state
        amount
        currency
        deliveryMethod
        shippingDate
        expiration
        lastUpdate
        items {
          id
          type
          mac
        }
      }
      invoices {
        id
        number
        origin
        currency
        invoiceDate
        dueDate
        totalAmount
        dueAmount
        trackingNumber
        state
      }
      plans {
        id
        name
        state
        projectId
      }
      plannerLoaded
      tickets {
        id
        number
        subject
        status
        priority
        type
        updatedString
        from
        projectId
      }
      ticketsLoaded
      folders {
        id
        name
        path
        files {
          name
          path
          project
          size
        }
        children
        root
      }
      siteId
      siteName
      active
    }
    plan
    planActivation
    planExpiration
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SiteGQL extends Apollo.Query<SiteQuery, SiteQueryVariables> {
    override document = SiteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SitesDocument = gql`
    query sites {
  sites {
    id
    name
    partnerId
    projects {
      id
      name
      stage
      stageId
      createdAt
      customer
      country
      partner
      partnerId
      deadline
      revenue
      locked
      problem
      solution
      supportFrom
      implementationSupportFrom
      supportTo
      implementationSupportTo
      slaType
      useCase
      description
      trackedObjects
      numberOfObjects
      tagMounting
      refreshRate
      accuracy
      batteryLife
      zAxis
      orders {
        id
        name
        state
        amount
        currency
        deliveryMethod
        shippingDate
        expiration
        lastUpdate
        items {
          id
          type
          mac
        }
      }
      invoices {
        id
        number
        origin
        currency
        invoiceDate
        dueDate
        totalAmount
        dueAmount
        trackingNumber
        state
      }
      plans {
        id
        name
        state
        projectId
      }
      plannerLoaded
      tickets {
        id
        number
        subject
        status
        priority
        type
        updatedString
        from
        projectId
      }
      ticketsLoaded
      folders {
        id
        name
        path
        files {
          name
          path
          project
          size
        }
        children
        root
      }
      siteId
      siteName
      active
    }
    plan
    planActivation
    planExpiration
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SitesGQL extends Apollo.Query<SitesQuery, SitesQueryVariables> {
    override document = SitesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StartPlayerDocument = gql`
    mutation startPlayer($time: Int!, $id: ID!, $deleteOnTime: Boolean!) {
  startPlayer(id: $id, time: $time, deleteOnTime: $deleteOnTime) {
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StartPlayerGQL extends Apollo.Mutation<StartPlayerMutation, StartPlayerMutationVariables> {
    override document = StartPlayerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StopPlayerDocument = gql`
    mutation stopPlayer($id: ID!) {
  stopPlayer(id: $id) {
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StopPlayerGQL extends Apollo.Mutation<StopPlayerMutation, StopPlayerMutationVariables> {
    override document = StopPlayerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TrainingAccessDocument = gql`
    query trainingAccess($name: String!) {
  trainingAccess(name: $name) {
    linkUrlId
    accessCode
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TrainingAccessGQL extends Apollo.Query<TrainingAccessQuery, TrainingAccessQueryVariables> {
    override document = TrainingAccessDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TrainingResultsDocument = gql`
    query trainingResults {
  trainingResults {
    id
    userEmail
    pointsGot
    pointsMax
    passed
    testName
    resultsLink
    time
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TrainingResultsGQL extends Apollo.Query<TrainingResultsQuery, TrainingResultsQueryVariables> {
    override document = TrainingResultsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserDocument = gql`
    query user {
  user {
    id
    email
    sewio
    companyRole
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserGQL extends Apollo.Query<UserQuery, UserQueryVariables> {
    override document = UserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UsersDocument = gql`
    query users {
  users {
    id
    email
    name
    state
    companyRole
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersGQL extends Apollo.Query<UsersQuery, UsersQueryVariables> {
    override document = UsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
<div class="w-full h-full flex items-center justify-center">
    <mat-card>
        <div *ngIf="loading" class="loading">
            <mat-spinner [diameter]="50" [strokeWidth]="5"></mat-spinner>
        </div>
        <mat-card-content class="login-content">
            <div class="login-header">
                <img class="login-logo" src="./assets/logo.svg" />
            </div>
            <mat-form-field appearance="outline">
                <mat-label>New Password</mat-label>
                <input #password matInput [type]="'password'" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Confirm New Password</mat-label>
                <input #confirmPassword matInput [type]="'password'" />
            </mat-form-field>
            <button
                class="login-button"
                mat-flat-button
                color="primary"
                (click)="changePassword()"
            >
                CHANGE PASSWORD
            </button>
        </mat-card-content>
    </mat-card>
</div>

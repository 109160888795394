import { Component, Input } from "@angular/core";

@Component({
    selector: "portal-loading",
    templateUrl: "./loading.component.html",
    styleUrls: ["./loading.component.scss"]
})
export class LoadingComponent {
    @Input() height = 60;
    @Input() type : "bar" | "spinner" = "bar";
}

<mat-card *ngIf="partnerStatus !== null" class="partner-level-card">
    <mat-card-content class="partner-level-info">
        <portal-partner-level [status]="partnerStatus"></portal-partner-level>
        <div
            class="column"
            *ngIf="partnerStatus.date && partnerStatus.currentLevel"
        >
            <b class="row">YOUR CURRENT LEVEL IS FIXED FOR YOU UNTIL</b>
            <div
                class="row"
                style="display: flex; flex-wrap: wrap; align-items: center"
            >
                {{ partnerStatus.date }}
            </div>
        </div>
        <div
            class="column"
            *ngIf="partnerStatus.date && partnerStatus.currentLevel"
        >
            <b class="row"
                >BY THE END OF THIS DAY YOUR LEVEL WILL CHANGE FROM</b
            >
            <div
                class="row"
                style="display: flex; flex-wrap: wrap; align-items: center"
            >
                <div style="display: flex; margin-right: 10px">
                    <portal-level
                        [type]="toLevelType(partnerStatus.currentLevel.name)"
                    ></portal-level>
                    <div style="margin: 0 5px">→</div>
                    <portal-level
                        [type]="toLevelType(partnerStatus.recalculationLevel)"
                    ></portal-level>
                </div>
                (Points: {{ partnerStatus.recalculationPoints }}, L1 Trainings:
                {{ partnerStatus.recalculationL1 }}, L2 Trainings:
                {{ partnerStatus.recalculationL2 }}, L3 Trainings:
                {{ partnerStatus.recalculationL3 }})
            </div>
        </div>
        <div class="column" *ngIf="partnerStatus.profileUrl">
            <b class="row">MY PROFILE AT SEWIO.NET</b>
            <div class="row">
                <portal-link
                    [href]="partnerStatus.profileUrl"
                    target="_blank"
                    >{{ partnerStatus.profileUrl }}</portal-link
                >
            </div>
        </div>
        <div class="column" *ngIf="partnerStatus.currentLevel">
            <b class="row">CURRENT LEVEL BENEFITS</b>
            <div class="row">
                <span class="column-item">Payment Terms:</span>
                {{ partnerStatus.currentLevel.paymentTerm }}
            </div>
            <div class="row">
                <span class="column-item">Discount:</span>
                {{ partnerStatus.currentLevel.discount }}%
                <span *ngIf="partnerStatus.currentLevel.lockingDiscount"
                    >+ 5% for lead registration</span
                >
            </div>
        </div>
        <div
            class="column"
            *ngIf="partnerStatus.currentLevel && partnerStatus.nextLevel"
        >
            <b>NEXT LEVEL BENEFITS</b>
            <div>
                <span class="column-item">Payment Terms:</span>
                {{ partnerStatus.nextLevel.paymentTerm }}
            </div>
            <div>
                <span class="column-item">Discount:</span>
                {{ partnerStatus.nextLevel.discount }}%
                <span *ngIf="partnerStatus.nextLevel.lockingDiscount"
                    >+ 5% for lead registration</span
                >
            </div>
        </div>
        <div class="column" *ngIf="partnerStatus.currentLevel">
            <portal-link class="link" href="/other/partner-program/information"
                >All benefits...</portal-link
            >
        </div>
    </mat-card-content>
</mat-card>
<portal-loading *ngIf="loading" [height]="128"></portal-loading>
<ng-container *ngIf="!loading">
    <div class="select-activities-container">
        <mat-select
            #activitiesFilter
            class="select-activities"
            [(value)]="selectedFilter"
        >
            <mat-option value="all"> Show All </mat-option>
            <mat-option value="active"> Show Active Only </mat-option>
            <mat-option value="expired"> Show Expired Only </mat-option>
        </mat-select>
    </div>
    <portal-section-header
        text="invoices"
        subtext="(1/100 points, 2 years expiration)"
    ></portal-section-header>
    <portal-partner-invoice-list
        *ngIf="invoices !== null"
        [filter]="selectedFilter"
        [invoices]="invoices"
    ></portal-partner-invoice-list>
    <div class="mt-2 ml-4">
        The values here are not always precise due to rounding. Check your
        overall points for the correct values.
    </div>
    <portal-section-header
        text="trainings"
        subtext="(L1 Training: 10 points, L2 Training: 10 points, L3 Training: 30
        points, 3 years expiration)"
    ></portal-section-header>
    <portal-partner-training-list
        *ngIf="trainings !== null"
        [filter]="selectedFilter"
        [activities]="trainings"
    ></portal-partner-training-list>
    <ng-container *ngFor="let activityType of activityTypes">
        <div class="flex items-center justify-between">
            <portal-section-header
                [text]="activityType.name"
                [subtext]="
                    '(' +
                    activityType.points +
                    ' points, ' +
                    activityType.expiration +
                    (activityType.expiration > 1 ? 'years' : 'year') +
                    ' expiration)'
                "
            ></portal-section-header>
            <button
                class="mr-4 w-28"
                mat-stroked-button
                color="primary"
                (click)="createActivity(activityType)"
                [disabled]="loading || activityLimit[activityType.id]"
            >
                <span *ngIf="!activityLimit[activityType.id]">ADD</span>
                <span *ngIf="activityLimit[activityType.id]"
                    >LIMIT OF THIS ACTIVITY TYPE REACHED</span
                >
            </button>
        </div>
        <portal-partner-activity-list
            *ngIf="activities !== null"
            [activities]="activities | filterActivitiesByType : activityType.id"
            [activityType]="activityType"
            [filter]="selectedFilter"
            (editActivity)="editActivity($event)"
        ></portal-partner-activity-list>
        <div
            *ngIf="activityType.description"
            class="mt-2 ml-4"
            [innerHtml]="activityType.description"
        ></div>
    </ng-container>
</ng-container>

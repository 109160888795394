import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

import { AuthService } from "../services/auth.service";

@Injectable({ providedIn: "root" })
export class AuthGuard  {
    constructor(private router: Router, private authService: AuthService) {}

    canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const token = this.authService.token;

        if (token) {
            return true;
        }

        void this.router.navigate(["/login"], {
            queryParams: { from: state.url }
        });

        return false;
    }
}

import { Component, OnInit } from "@angular/core";
import { MenuItemsGQL } from "../../../../core";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "portal-other",
    templateUrl: "./other.component.html",
    styleUrls: ["./other.component.scss"],
})
export class OtherComponent implements OnInit {
    private subscriptions: Subscription[] = [];
    content: string | null = null;

    constructor(
        private route: ActivatedRoute,
        private menuItemsGql: MenuItemsGQL
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                this.content = "";

                this.subscriptions.push(
                    this.menuItemsGql
                        .watch()
                        .valueChanges.subscribe((result) => {
                            if (params["menu"]) {
                                const parent = result.data.menuItems.find(
                                    (menuItem) =>
                                        menuItem.slug === params["menu"]
                                );

                                if (parent) {
                                    const page = parent.children?.find(
                                        (menuItem) =>
                                            menuItem.slug === params["page"]
                                    );

                                    if (page && page.pageContent) {
                                        this.content = page.pageContent;
                                    } else {
                                        this.content = `{ "blocks": [{ "type": "paragraph", "data": { "text": "<b>ERROR:</b> This page doesn't exist or your permissions are insufficient." }}]}`;
                                    }
                                } else {
                                    this.content = `{ "blocks": [{ "type": "paragraph", "data": { "text": "<b>ERROR:</b> This page doesn't exist or your permissions are insufficient." }}]}`;
                                }
                            }
                        })
                );
            })
        );
    }
}

import { Component, Input, ViewChild } from "@angular/core";
import { PartnerActivity, Invoice } from "../../../../core";
import moment from "moment";
import { TimeUtils } from "@portal/shared";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";

@Component({
    selector: "portal-partner-invoice-list",
    templateUrl: "./partner-invoice-list.component.html",
    styleUrls: ["./partner-invoice-list.component.scss"],
})
export class PartnerInvoiceListComponent {
    activities!: Partial<PartnerActivity>[];

    private _invoices!: Invoice[];
    private _filter!: string;

    get filter(): string {
        return this._filter;
    }

    @Input()
    set filter(filter: string) {
        this._filter = filter;

        if (this.dataSource) {
            this.dataSource.filter = this._filter;
            this.dataSource.paginator?.firstPage();
        }
    }

    get invoices(): Invoice[] {
        return this._invoices;
    }

    @Input()
    set invoices(invoices: Invoice[]) {
        this._invoices = invoices;

        this.activities = [];

        for (const invoice of invoices) {
            let convertedAmount = 0;

            if (invoice.currency === "CZK") {
                convertedAmount = invoice.totalAmount / 25.7;
            } else if (invoice.currency === "USD") {
                convertedAmount = invoice.totalAmount / 1.13;
            } else {
                convertedAmount = invoice.totalAmount;
            }

            this.activities.push({
                name: invoice.number,
                description:
                    invoice.totalAmount.toLocaleString() +
                    " " +
                    invoice.currency,
                points:
                    convertedAmount < 0
                        ? Math.ceil(convertedAmount / 100 - 0.5)
                        : Math.round(convertedAmount / 100),
                expiration: moment(invoice.invoiceDate)
                    .add(2, "years")
                    .format("YYYY-MM-DD"),
                state: TimeUtils.isDateTodayOrInThePast(
                    moment(invoice.invoiceDate)
                        .add(2, "years")
                        .format("YYYY-MM-DD")
                )
                    ? "expired"
                    : "approved",
            });
        }

        this.activities.slice().sort((a, b) => {
            if (a.expiration === undefined && b.expiration === undefined) {
                return 0;
            }

            if (a.expiration === undefined) {
                return 1;
            }

            if (b.expiration === undefined) {
                return -1;
            }

            return TimeUtils.isTimeBeforeOtherTime(a.expiration, b.expiration)
                ? -1
                : 1;
        });

        this.dataSource = new MatTableDataSource(this.activities);
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = (data, filter) => {
            if (filter === "active" && data.state === "expired") {
                return false;
            } else if (filter === "expired" && data.state !== "expired") {
                return false;
            }

            return true;
        };
    }

    displayedColumns: string[] = [
        "name",
        "state",
        "expiration",
        "amount",
        "points",
    ];

    dataSource!: MatTableDataSource<Partial<PartnerActivity>>;

    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
}

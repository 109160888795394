import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from "@angular/core";
import {
    trigger,
    state,
    style,
    transition,
    animate,
} from "@angular/animations";
import { BusinessReviewsQuery } from "../../../../core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";

type BusinessReview = BusinessReviewsQuery["businessReviews"][0];

@Component({
    selector: "portal-business-reviews-list",
    templateUrl: "./business-reviews-list.component.html",
    styleUrls: ["./business-reviews-list.component.scss"],
    animations: [
        trigger("detailExpand", [
            state("collapsed", style({ height: "0px", minHeight: "0" })),
            state("expanded", style({ height: "*" })),
            transition(
                "expanded <=> collapsed",
                animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
            ),
        ]),
    ],
})
export class BusinessReviewsListComponent {
    private _businessReviews!: BusinessReviewsQuery["businessReviews"];

    get businessReviews(): BusinessReviewsQuery["businessReviews"] {
        return this._businessReviews;
    }

    @Input()
    set businessReviews(
        businessReviews: BusinessReviewsQuery["businessReviews"]
    ) {
        if (businessReviews) {
            this._businessReviews = businessReviews;
        } else {
            this._businessReviews = [];
        }

        this.dataSource = new MatTableDataSource(
            this._businessReviews
                .slice()
                .sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10))
        );

        this.dataSource.paginator = this.paginator;
    }

    @Output() editReview = new EventEmitter<BusinessReview>();

    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

    displayedColumns: string[] = ["date", "actions"];
    pageSizes: number[] = [10, 20, 30];
    dataSource!: MatTableDataSource<BusinessReview>;

    expandedBusinessReview!: string | null;

    expandBusinessReview($event: Event, id: string) {
        $event.stopPropagation();
        $event.preventDefault();

        this.expandedBusinessReview =
            this.expandedBusinessReview === id ? null : id;
    }
}

import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { PlayersComponent } from "./views/players/players.component";
import { menuGuard } from "../../core/guards/menu.guard";

const playersRoutes: Routes = [
    {
        path: "rtls_players",
        redirectTo: "rtls-players",
        pathMatch: "full",
    },
    {
        path: "rtls-players",
        component: PlayersComponent,
        data: {
            showTopPanel: true,
            showLeftPanel: true,
        },
        canActivate: [menuGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(playersRoutes)],
    exports: [RouterModule],
})
export class PlayersRoutes {}

<div class="flex items-center justify-between">
    <portal-section-header
        text="business reviews"
        subtext="Synchronization and alignment of business plans."
    ></portal-section-header>
    <button
        class="mr-4 w-36"
        mat-stroked-button
        color="primary"
        (click)="addReview()"
        [disabled]="loading !== 0"
    >
        <span *ngIf="loading === 0">NEW REVIEW</span>
        <span *ngIf="loading !== 0">LOADING</span>
    </button>
</div>
<portal-loading
    *ngIf="businessReviews === null"
    [height]="112"
></portal-loading>
<portal-business-reviews-list
    *ngIf="businessReviews !== null"
    [businessReviews]="businessReviews"
    (editReview)="editReview($event)"
></portal-business-reviews-list>

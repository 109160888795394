<portal-section-header
    text="Sites"
    subtext="Select site to view details."
></portal-section-header>
<ng-container *ngIf="sites !== null; else loading">
    <portal-site-list [sites]="sites"></portal-site-list>
</ng-container>
<ng-template #loading>
    <portal-loading [height]="112"></portal-loading>
</ng-template>

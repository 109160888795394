import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Plan, ProjectInfo, ProjectStage } from "../../../../core";
import { environment } from "../../../../../environments/environment";
import { MatTableDataSource } from "@angular/material/table";

@Component({
    selector: "portal-all-plans-list",
    templateUrl: "./all-plans-list.component.html",
    styleUrls: ["./all-plans-list.component.scss"],
})
export class AllPlansListComponent {
    sdUrl = environment.sd_address;
    odooUrl = environment.odoo_address;
    plannerUrl = environment.planner_address;

    displayedColumns: string[] = [
        "number",
        "name",
        "project",
        "stage",
        "state",
        "ticket",
        "created",
        "requested",
        "approved",
        "actions",
    ];

    dataSource: MatTableDataSource<Plan>;

    private _plans!: Plan[];
    private _projects!: { [key: string]: ProjectInfo };
    private _selectedStates!: string[];
    private _selectedStages!: string[];
    private _hideSewioPlans!: boolean;

    get plans(): Plan[] {
        return this._plans;
    }

    @Input()
    set plans(plans: Plan[]) {
        this._plans = plans;

        this.updateData();
    }

    get selectedStates(): string[] {
        return this._selectedStates;
    }

    @Input()
    set selectedStates(selectedStates: string[]) {
        this._selectedStates = selectedStates;

        this.updateData();
    }

    get selectedStages(): string[] {
        return this._selectedStages;
    }

    @Input()
    set selectedStages(selectedStages: string[]) {
        this._selectedStages = selectedStages;

        this.updateData();
    }

    get hideSewioPlans(): boolean {
        return this._hideSewioPlans;
    }

    @Input()
    set hideSewioPlans(hideSewioPlans: boolean) {
        this._hideSewioPlans = hideSewioPlans;

        this.updateData();
    }

    get projects(): { [key: string]: ProjectInfo } {
        return this._projects;
    }

    @Input()
    set projects(projects: { [key: string]: ProjectInfo }) {
        this._projects = projects;

        this.updateData();
    }

    @Input() blockActions!: boolean;

    @Output() approvePlan = new EventEmitter();
    @Output() returnPlan = new EventEmitter();

    constructor() {
        this.dataSource = new MatTableDataSource();
    }

    updateData() {
        let allPlans = this._plans;

        if (this._selectedStates && this._selectedStates.length > 0) {
            allPlans = allPlans.filter((plan) =>
                this._selectedStates.includes(plan.state)
            );
        }

        if (this._selectedStages && this._selectedStages.length > 0) {
            allPlans = allPlans.filter((plan) => {
                if (
                    !this.projects[plan.projectId] ||
                    !this.projects[plan.projectId].stage
                ) {
                    return false;
                }

                return this._selectedStages.includes(
                    (this.projects[plan.projectId].stage as ProjectStage).id
                );
            });
        }

        if (this._hideSewioPlans) {
            allPlans = allPlans.filter((plan) => {
                if (
                    this.projects[plan.projectId] &&
                    this.projects[plan.projectId].partnerId === "1"
                ) {
                    return false;
                }

                return true;
            });
        }

        this.dataSource.data = allPlans;
    }
}

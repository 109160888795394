import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared";
import { PartnerProgramComponent } from "./views/partner-program/partner-program.component";
import { PartnerProgramRoutes } from "./partner-program.routes";
import { PartnerActivityListComponent } from "./components/partner-activity-list/partner-activity-list.component";
import { CreatePartnerActivityDialogComponent } from "./components/create-partner-activity-dialog/create-partner-activity-dialog.component";
import { EditPartnerActivityDialogComponent } from "./components/edit-partner-activity-dialog/edit-partner-activity-dialog.component";
import { PartnerInvoiceListComponent } from "./components/partner-invoice-list/partner-invoice-list.component";
import { PartnerTrainingListComponent } from "./components/partner-training-list/partner-training-list.component";

@NgModule({
    imports: [SharedModule, PartnerProgramRoutes],
    declarations: [
        PartnerProgramComponent,
        PartnerActivityListComponent,
        PartnerInvoiceListComponent,
        PartnerTrainingListComponent,
        CreatePartnerActivityDialogComponent,
        EditPartnerActivityDialogComponent,
    ],
    providers: [],
})
export class PartnerProgramModule {}

import { Component, Input, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { PartnerActivity } from "../../../../core";

@Component({
    selector: "portal-partner-training-list",
    templateUrl: "./partner-training-list.component.html",
    styleUrls: ["./partner-training-list.component.scss"],
})
export class PartnerTrainingListComponent {
    private _activities!: PartnerActivity[];
    private _filter!: string;

    get filter(): string {
        return this._filter;
    }

    @Input()
    set filter(filter: string) {
        this._filter = filter;

        if (this.dataSource) {
            this.dataSource.filter = this._filter;
            this.dataSource.paginator?.firstPage();
        }
    }

    get activities(): PartnerActivity[] {
        return this._activities;
    }

    @Input()
    set activities(activities: PartnerActivity[]) {
        if (activities) {
            this._activities = activities
                .slice()
                .sort(
                    (a, b) =>
                        parseInt(b.expiration, 10) - parseInt(a.expiration, 10)
                );
        } else {
            this._activities = [];
        }

        this.dataSource = new MatTableDataSource(this._activities);
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = (data, filter) => {
            if (filter === "active" && data.state === "expired") {
                return false;
            } else if (filter === "expired" && data.state !== "expired") {
                return false;
            }

            return true;
        };
    }

    displayedColumns: string[] = ["name", "expiration", "state", "points"];
    dataSource!: MatTableDataSource<PartnerActivity>;

    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
}

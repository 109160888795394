<div class="rounded-lg shadow relative bg-white overflow-auto">
    <table
        *ngIf="players.length > 0"
        class="players-table"
        mat-table
        [dataSource]="dataSource"
        matSort
        multiTemplateDataRows
    >
        <ng-container matColumnDef="id">
            <th
                class="id-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                ID
            </th>
            <td class="id-column" mat-cell *matCellDef="let row">
                {{ row.id }}
            </td>
        </ng-container>

        <ng-container matColumnDef="user">
            <th
                class="user-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Creator
            </th>
            <td class="user-column" mat-cell *matCellDef="let row">
                {{ row.userEmail }}
            </td>
        </ng-container>

        <ng-container matColumnDef="version">
            <th
                class="version-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Version
            </th>
            <td class="version-column" mat-cell *matCellDef="let row">
                {{ row.version }}
            </td>
        </ng-container>

        <ng-container matColumnDef="time">
            <th
                class="time-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                End Time
            </th>
            <td class="time-column" mat-cell *matCellDef="let row">
                <span *ngIf="row.status === 'running'"
                    >{{ row.remainingTime | secondsFromNowToDate }}
                    {{ row.deleteOnTime ? "DELETE" : "STOP" }}</span
                >
            </td>
        </ng-container>

        <ng-container matColumnDef="username">
            <th
                class="username-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Username
            </th>
            <td class="username-column" mat-cell *matCellDef="let row">
                {{ row.username }}
            </td>
        </ng-container>

        <ng-container matColumnDef="password">
            <th
                class="password-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Password
            </th>
            <td class="password-column" mat-cell *matCellDef="let row">
                {{ row.password }}
            </td>
        </ng-container>

        <ng-container matColumnDef="state">
            <th
                class="state-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                State
            </th>
            <td class="state-column" mat-cell *matCellDef="let row">
                <portal-status
                    [text]="row.status | playerStatusToState | titlecase"
                    [type]="row.status | playerStatusToState"
                ></portal-status>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th
                class="actions-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Actions
            </th>
            <td class="actions-column" mat-cell *matCellDef="let row">
                <div class="flex gap-2">
                    <ng-container *ngIf="row.status === 'running'">
                        <button
                            mat-icon-button
                            aria-label="Open"
                            matTooltip="Open"
                            (click)="openPlayer.emit({ id: row.id })"
                        >
                            <portal-icon icon="open_in_new"></portal-icon>
                        </button>
                        <button
                            mat-icon-button
                            aria-label="Prolong"
                            matTooltip="Prolong"
                            (click)="prolongPlayer.emit({ id: row.id })"
                        >
                            <portal-icon icon="hourglass_top"></portal-icon>
                        </button>
                        <button
                            mat-icon-button
                            aria-label="Stop"
                            matTooltip="Stop"
                            (click)="stopPlayer.emit({ id: row.id })"
                        >
                            <portal-icon
                                icon="stop"
                                [big]="true"
                                [fill]="true"
                            ></portal-icon>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="row.status === 'stopped'">
                        <button
                            mat-icon-button
                            aria-label="Start"
                            matTooltip="Start"
                            (click)="startPlayer.emit({ id: row.id })"
                        >
                            <portal-icon
                                [big]="true"
                                [fill]="true"
                                icon="play_arrow"
                            ></portal-icon>
                        </button>
                    </ng-container>
                    <ng-container
                        *ngIf="
                            row.status === 'stopped' || row.status === 'running'
                        "
                    >
                        <button
                            mat-icon-button
                            aria-label="Delete"
                            matTooltip="Delete"
                            (click)="deletePlayer.emit({ id: row.id })"
                        >
                            <portal-icon
                                [big]="true"
                                icon="delete"
                            ></portal-icon>
                        </button>
                    </ng-container>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="company-row"
        ></tr>
    </table>

    <div class="none-row" *ngIf="players.length === 0">
        <portal-status
            [text]="'None'"
            [type]="'none'"
            [big]="true"
        ></portal-status>
    </div>
</div>

/* eslint-disable no-case-declarations */

export type EdjsListItem = {
    content: string;
    items: Array<EdjsListItem>;
};

export interface EdjsDelimiterBlock {
    type: "delimiter";
    data: object;
}

export interface EdjsHeaderBlock {
    type: "header";
    data: {
        level: number;
        text: string;
    };
}

export interface EdjsParagraphBlock {
    type: "paragraph";
    data: {
        text: string;
    };
}

export interface EdjsImageBlock {
    type: "image";
    data: {
        caption?: string;
        stretched: boolean;
        withBackground: boolean;
        withBorder: boolean;
    } & (
        | {
              file: {
                  url: string;
                  alt: string;
                  formats: unknown;
                  height: number;
                  mime: string;
                  size: number;
                  width: number;
              };
          }
        | {
              url: string;
          }
    );
}

export interface EdjsTableBlock {
    type: "table";
    data: {
        content: string[][];
        withHeadings: boolean;
    };
}

export interface EdjsListBlock {
    type: "list";
    data: {
        items: string[];
        style: "ordered" | "unordered";
    };
}

export interface EdjsQuoteBlock {
    type: "quote";
    data: {
        alignment: string;
        text: string;
        caption?: string;
    };
}

export interface EdjsHtmlBlock {
    type: "raw";
    data: {
        html: string;
    };
}

export type EdjsBlock =
    | EdjsDelimiterBlock
    | EdjsHeaderBlock
    | EdjsParagraphBlock
    | EdjsImageBlock
    | EdjsTableBlock
    | EdjsListBlock
    | EdjsQuoteBlock
    | EdjsHtmlBlock;

export class EdjsUtils {
    static toHtml(blocks: EdjsBlock[]) {
        return blocks
            .map((block, index) => EdjsUtils.parseBlock(block, index))
            .join("\n");
    }

    static parseBlock(block: EdjsBlock, index: number): string {
        switch (block.type) {
            case "delimiter":
                return `<hr/>`;
            case "header":
                const header = block.data;
                return `<h${header.level}>${header.text}</h${header.level}>`;
            case "paragraph":
                const paragraph = block.data;
                return `<p>${paragraph.text}</p>`;
            case "image":
                const image = block.data;
                const url = "file" in image ? image.file.url : image.url;
                const caption = image.caption
                    ? `<figcaption>${image.caption}</figcaption>`
                    : "";

                return `<figure><a href="${url}" target="_blank"><img src="${url}" alt/>${caption}</a></figure>`;
            case "table":
                const table = block.data;
                const rows = table.content.map((row, index) => {
                    const cellType =
                        index === 0 && table.withHeadings ? "th" : "td";

                    return `<tr>${row.reduce(
                        (acc, cell) =>
                            acc + `<${cellType}>${cell}</${cellType}>`,
                        ""
                    )}</tr>`;
                });

                return `<table><thead>${
                    table.withHeadings && rows.length > 0 ? rows[0] : ""
                }</thead><tbody>${
                    table.withHeadings && rows.length > 0
                        ? rows.slice(1).join("")
                        : rows.join("")
                }</table></tbody>`;
            case "list":
                const items = block.data.items;
                const type = block.data.style === "ordered" ? "ol" : "ul";
                const recursor = (items: any, listType: "ol" | "ul") => {
                    const listParts = items.map((item: any) => {
                        if (!item.content && !item.items) {
                            return `<li>${item}</li>`;
                        }

                        let list = "";

                        if (item.items) {
                            list = recursor(item.items, listType);
                        }

                        if (item.content) {
                            return `<li>${item.content}</li>` + list;
                        }

                        return `<${listType}>${list}</${listType}>`;
                    });

                    return `<${listType}>${listParts.join("")}</${listType}>`;
                };

                return recursor(items, type);
            case "quote":
                const quote = block.data;
                return `<blockquote><p>${quote.text}</p><cite>${quote.caption}</cite></blockquote>`;
            case "raw":
                const raw = block.data;
                return `${raw.html}`;
            default:
                return "<div>Unknown element.</div>";
        }
    }

    static createHeaderBlock(text: string, level: number): EdjsHeaderBlock {
        return { type: "header", data: { text, level } };
    }

    static createParagraphBlock(text: string): EdjsParagraphBlock {
        return { type: "paragraph", data: { text } };
    }
}

import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FolderStructure } from "../../../modules/project/components/storage/storage.component";

@Component({
    selector: "portal-folder",
    templateUrl: "./folder.component.html",
    styleUrls: ["./folder.component.scss"],
})
export class FolderComponent {
    @Input() folder!: FolderStructure;
    @Output() deleteFolder = new EventEmitter<{ path: string; name: string }>();
    @Output() deleteFile = new EventEmitter<{ path: string; name: string }>();
    @Output() openFileRequest = new EventEmitter();

    closed = true;

    toggleFolder() {
        this.closed = !this.closed;
    }

    getFolderDisplayName(name: string) {
        let displayName = name;

        if (!this.isUserFolder(displayName)) {
            displayName = displayName.slice(1, displayName.length);
        }

        return displayName;
    }

    isUserFolder(name: string) {
        return name.length > 0 && name.charAt(0) !== "$";
    }
}

<div mat-dialog-title class="title">Invite User</div>
<div mat-dialog-content>
    <div class="form">
        <mat-form-field>
            <input
                #emailInput
                matInput
                placeholder="Email"
                [formControl]="emailValidator"
                required
            />
        </mat-form-field>
        <mat-form-field>
            <mat-label>Role</mat-label>
            <mat-select #roleSelect [formControl]="roleValidator" required>
                <mat-option *ngFor="let role of roles" [value]="role">
                    {{ role | format }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CANCEL</button>
    <button mat-button color="primary" cdkFocusInitial (click)="invite()">
        INVITE
    </button>
</mat-dialog-actions>

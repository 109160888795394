import { Component, Input } from "@angular/core";
import { Article } from "../../../core";
import { TimeUtils } from "@portal/shared";
import { Router } from "@angular/router";

@Component({
    selector: "portal-article-preview",
    templateUrl: "./article-preview.component.html",
    styleUrls: ["./article-preview.component.scss"],
})
export class ArticlePreviewComponent {
    private _article!: Article;

    content = "";

    get article(): Article {
        return this._article;
    }

    @Input()
    set article(article: Article) {
        this._article = article;
    }

    constructor(private router: Router) {}

    formatDate(date: string) {
        return TimeUtils.formatDateToReadableString(date);
    }

    openArticle() {
        void this.router.navigate(["/articles/" + this._article.slug]);
    }
}

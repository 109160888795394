<mat-card *ngIf="type === 'bar'">
    <mat-card-content class="loading-content" [style.height.px]="height - 32">
        <mat-progress-bar [color]="'primary'" [mode]="'indeterminate'">
        </mat-progress-bar>
    </mat-card-content>
</mat-card>
<div
    *ngIf="type === 'spinner'"
    class="spinner-container"
    [style.height.px]="height"
>
    <mat-progress-spinner [diameter]="30" [mode]="'indeterminate'">
    </mat-progress-spinner>
</div>

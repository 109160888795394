import { Component, ViewChild, ElementRef, Inject } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { Project } from "@portal/shared";

@Component({
    selector: "portal-create-dialog",
    styleUrls: ["create-dialog.component.scss"],
    templateUrl: "create-dialog.component.html",
})
export class CreateDialogComponent {
    @ViewChild("nameInput", { static: false }) nameInput!: ElementRef;
    @ViewChild("opportunityInput", { static: false })
    opportunityInput!: MatSelect;

    opportunityValidator = new UntypedFormControl("", [Validators.required]);
    nameValidator = new UntypedFormControl("", [Validators.required]);

    constructor(
        public dialogRef: MatDialogRef<CreateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { projects: Project[] }
    ) {
        if (this.data.projects.length === 1) {
            this.opportunityValidator.setValue(this.data.projects[0].id);
            this.opportunityValidator.disable();
        }
    }

    create(): void {
        if (this.opportunityValidator.invalid) {
            this.opportunityInput.focus();
            return;
        }

        if (this.nameValidator.invalid) {
            this.nameInput.nativeElement.focus();
            return;
        }

        this.dialogRef.close({
            name: this.nameInput.nativeElement.value,
            projectId: this.opportunityValidator.value,
        });
    }
}

import { Pipe, PipeTransform } from "@angular/core";
import { Order } from "../../core";

@Pipe({ name: "filterOrdersFromOrders" })
export class FilterOrdersFromOrdersPipe implements PipeTransform {
    transform(values: Order[]) {
        return values.filter((value) =>
            ["payment", "prepare", "sale", "done"].includes(value.state)
        );
    }
}

<div class="flex justify-between items-center">
    <portal-section-header
        text="rtls players"
        subtext="RTLS Players belonging to your company."
    ></portal-section-header>
    <div
        class="flex gap-4 items-center mr-4"
        *ngIf="company !== null && players !== null"
    >
        <div *ngIf="!loading">
            <b>Available slots:</b> {{ company.playerLimit - players.length }}
        </div>
        <button
            mat-stroked-button
            color="primary"
            (click)="createPlayer()"
            [disabled]="loading || company.playerLimit - players.length <= 0"
        >
            <span *ngIf="loading">LOADING</span>
            <span *ngIf="!loading && company.playerLimit - players.length > 0"
                >CREATE RTLS PLAYER</span
            >
            <span *ngIf="!loading && company.playerLimit - players.length <= 0"
                >NOT AVAILABLE</span
            >
        </button>
    </div>
</div>
<portal-loading *ngIf="players === null" [height]="128"></portal-loading>
<portal-players-active-list
    (openPlayer)="openPlayer($event)"
    (stopPlayer)="stopPlayer($event)"
    (startPlayer)="startPlayer($event)"
    (deletePlayer)="deletePlayer($event)"
    (prolongPlayer)="prolongPlayer($event)"
    *ngIf="players !== null"
    [players]="players"
></portal-players-active-list>

<div
    *ngIf="company !== null && company.id === '1' && otherPlayers !== null"
    class="other-players"
>
    <portal-section-header
        text="other rtls players"
        subtext="RTLS Players belonging to other companies. Only Sewio users can see these."
    ></portal-section-header>
    <portal-players-active-list
        (openPlayer)="openPlayer($event)"
        (stopPlayer)="stopPlayer($event)"
        (startPlayer)="startPlayer($event)"
        (deletePlayer)="deletePlayer($event)"
        (prolongPlayer)="prolongPlayer($event)"
        *ngIf="company !== null && company.id === '1' && otherPlayers !== null"
        [players]="otherPlayers"
    ></portal-players-active-list>
</div>

<portal-section-header
    text="history log"
    subtext="History of your RTLS Players."
></portal-section-header>
<portal-loading *ngIf="playersHistory === null" [height]="128"></portal-loading>
<portal-players-history-list
    *ngIf="playersHistory !== null"
    [playersHistory]="playersHistory"
    [filter]="filterValue"
></portal-players-history-list>

import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./views/login/login.component";
import { NgModule } from "@angular/core";
import { ResetComponent } from "./views/reset/reset.component";
import { NewPasswordComponent } from "./views/new-password/new-password.component";
import { ActivateUserComponent } from "./views/activate-user/activate-user.component";

const loginRoutes: Routes = [
    {
        path: "login",
        component: LoginComponent,
        data: {
            showTopPanel: false,
            showLeftPanel: false,
        },
    },
    {
        path: "reset",
        component: ResetComponent,
        data: {
            showTopPanel: false,
            showLeftPanel: false,
        },
    },
    {
        path: "new_password",
        redirectTo: "new-password",
        pathMatch: "full",
    },
    {
        path: "new-password",
        component: NewPasswordComponent,
        data: {
            showTopPanel: false,
            showLeftPanel: false,
        },
    },
    {
        path: "activate_user",
        redirectTo: "activate-user",
        pathMatch: "full",
    },
    {
        path: "activate-user",
        component: ActivateUserComponent,
        data: {
            showTopPanel: false,
            showLeftPanel: false,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(loginRoutes)],
    exports: [RouterModule],
})
export class LoginRoutes {}

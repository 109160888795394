import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { AuthGuard } from "../../core";
import { OtherComponent } from "./views/other/other.component";

const otherRoutes: Routes = [
    {
        path: "other/:menu/:page",
        component: OtherComponent,
        data: {
            showTopPanel: true,
            showLeftPanel: true,
        },
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(otherRoutes)],
    exports: [RouterModule],
})
export class OtherRoutes {}

<div mat-dialog-title class="title">Create Folder</div>
<div mat-dialog-content>
    <div class="form">
        <mat-form-field>
            <mat-label>Parent Folder</mat-label>
            <mat-select #parentSelect [formControl]="parentValidator">
                <mat-option *ngFor="let type of parents" [value]="type.value">
                    {{ type.viewValue }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <input
                #nameInput
                matInput
                placeholder="Folder Name"
                [formControl]="nameValidator"
                required
            />
        </mat-form-field>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CANCEL</button>
    <button mat-button color="primary" cdkFocusInitial (click)="create()">
        CREATE
    </button>
</mat-dialog-actions>

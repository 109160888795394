import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "formatAttachmentName" })
export class FormatAttachmentNamePipe implements PipeTransform {
    transform(value: string) {
        const match = value.match(
            /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}\.(.*)$/
        );

        if (!match || match.length < 2) {
            return "";
        }

        return match[1];
    }
}

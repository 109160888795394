import { Component, ViewChild, ElementRef, Inject } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { ResponseTimes } from "../../../../core";
import { Project } from "@portal/shared";

@Component({
    selector: "portal-create-ticket-dialog",
    styleUrls: ["create-ticket-dialog.component.scss"],
    templateUrl: "create-ticket-dialog.component.html",
})
export class CreateTicketDialogComponent {
    @ViewChild("typeSelect", { static: false }) typeSelect!: MatSelect;
    @ViewChild("subjectInput", { static: false }) subjectInput!: ElementRef;
    @ViewChild("levelSelect", { static: false }) levelSelect!: MatSelect;
    @ViewChild("studioVersionInput", { static: false })
    studioVersionInput!: ElementRef;
    @ViewChild("anchorCountInput", { static: false })
    anchorCountInput!: ElementRef;
    @ViewChild("anchorTypesInput", { static: false })
    anchorTypesInput!: ElementRef;
    @ViewChild("tagCountInput", { static: false }) tagCountInput!: ElementRef;
    @ViewChild("tagTypesInput", { static: false }) tagTypesInput!: ElementRef;
    @ViewChild("deploymentSelect", { static: false })
    deploymentSelect!: MatSelect;
    @ViewChild("expectedBehaviourInput", { static: false })
    expectedBehaviourInput!: ElementRef;
    @ViewChild("currentBehaviourInput", { static: false })
    currentBehaviourInput!: ElementRef;

    opportunityValidator = new UntypedFormControl("", [Validators.required]);
    typeValidator = new UntypedFormControl("", [Validators.required]);
    subjectValidator = new UntypedFormControl("", [Validators.required]);
    levelValidator = new UntypedFormControl("minor", [Validators.required]);
    studioVersionValidator = new UntypedFormControl("", []);
    anchorCountValidator = new UntypedFormControl("", []);
    anchorTypesValidator = new UntypedFormControl("", []);
    tagCountValidator = new UntypedFormControl("", []);
    tagTypesValidator = new UntypedFormControl("", []);
    deploymentTypeValidator = new UntypedFormControl("", []);
    expectedBehaviourValidator = new UntypedFormControl("", [
        Validators.required,
    ]);
    currentBehaviourValidator = new UntypedFormControl("", [
        Validators.required,
    ]);

    ticketTypes = [
        { value: "incident", viewValue: "Incident" },
        { value: "rma", viewValue: "RMA" },
        { value: "question", viewValue: "Question" },
        { value: "feedback", viewValue: "Feedback" },
    ];

    ticketLevels = [
        { value: "other", viewValue: "Other" },
        { value: "minor", viewValue: "Minor" },
        { value: "major", viewValue: "Major" },
        { value: "critical", viewValue: "Critical" },
    ];

    deploymentTypes = [
        {
            value: "virtual",
            viewValue: "Virtual Installation (Virtualbox, Hyper-V)",
        },
        { value: "native", viewValue: "Native Installation" },
        { value: "docker", viewValue: "Docker Installation" },
    ];

    responseTimes: ResponseTimes;

    constructor(
        public dialogRef: MatDialogRef<CreateTicketDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { responseTimes: ResponseTimes; projects: Project[] }
    ) {
        this.responseTimes = data.responseTimes;

        if (this.data.projects.length === 1) {
            this.opportunityValidator.setValue(this.data.projects[0].id);
            this.opportunityValidator.disable();
        }
    }

    create(): void {
        if (this.typeValidator.invalid) {
            this.typeSelect.open();
            return;
        }

        if (this.subjectValidator.invalid) {
            this.subjectInput.nativeElement.focus();
            return;
        }

        if (this.levelValidator.invalid) {
            this.levelSelect.open();
            return;
        }

        let output;

        if (this.typeValidator.value === "incident") {
            if (this.studioVersionValidator.invalid) {
                this.studioVersionInput.nativeElement.focus();
                return;
            }

            if (this.anchorCountValidator.invalid) {
                this.anchorCountInput.nativeElement.focus();
                return;
            }

            if (this.tagCountValidator.invalid) {
                this.tagCountInput.nativeElement.focus();
                return;
            }

            if (this.tagTypesValidator.invalid) {
                this.tagTypesInput.nativeElement.focus();
                return;
            }

            if (this.deploymentTypeValidator.invalid) {
                this.deploymentSelect.open();
                return;
            }

            if (this.expectedBehaviourValidator.invalid) {
                this.expectedBehaviourInput.nativeElement.focus();
                return;
            }

            if (this.currentBehaviourValidator.invalid) {
                this.currentBehaviourInput.nativeElement.focus();
                return;
            }

            let infoString =
                "\nExpected Behaviour:\n" +
                this.expectedBehaviourValidator.value +
                "\n\nCurrent Behaviour:\n" +
                this.currentBehaviourValidator.value +
                "\n\n";

            if (this.studioVersionValidator.value !== "") {
                infoString +=
                    "\nRTLS Studio Version: " +
                    this.studioVersionValidator.value;
            }

            if (this.anchorCountValidator.value !== "") {
                infoString +=
                    "\nNumber of Anchors: " + this.anchorCountValidator.value;
            }

            if (this.anchorTypesValidator.value !== "") {
                infoString +=
                    "\nAnchor Types & Versions: " +
                    this.anchorTypesValidator.value;
            }

            if (this.tagCountValidator.value !== "") {
                infoString +=
                    "\nNumber of Tags: " + this.tagCountValidator.value;
            }

            if (this.tagTypesValidator.value !== "") {
                infoString +=
                    "\nTag Types & Versions: " + this.tagTypesValidator.value;
            }

            if (this.deploymentTypeValidator.value !== "") {
                infoString +=
                    "\nDeployment Type: " + this.deploymentTypeValidator.value;
            }

            output = {
                type: this.typeValidator.value,
                subject: this.subjectValidator.value,
                level: this.levelValidator.value,
                info: infoString,
                projectId: this.opportunityValidator.value,
            };
        } else {
            output = {
                type: this.typeValidator.value,
                subject: this.subjectValidator.value,
                level: this.levelValidator.value,
                projectId: this.opportunityValidator.value,
            };
        }

        this.dialogRef.close(output);
    }
}

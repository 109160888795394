import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared";
import { SiteRoutes } from "./site.routes";
import { SiteComponent } from "./views/site.component";
import { UpdatesListComponent } from "./components/updates-list/updates-list.component";
import { UpdateModalComponent } from "./components/update-modal/update-modal.component";
import { CreateUpdateDialogComponent } from "./components/create-update-dialog/create-update-dialog.component";
import { EditUpdateDialogComponent } from "./components/edit-update-dialog/edit-update-dialog.component";

@NgModule({
    imports: [SharedModule, SiteRoutes],
    declarations: [
        SiteComponent,
        UpdatesListComponent,
        UpdateModalComponent,
        CreateUpdateDialogComponent,
        EditUpdateDialogComponent,
    ],
    providers: [],
})
export class SiteModule {}

<div class="mat-elevation-z2">
    <table
        *ngIf="results.length > 0"
        class="results-table"
        mat-table
        [dataSource]="dataSource"
    >
        <ng-container matColumnDef="email">
            <th class="email-column" mat-header-cell *matHeaderCellDef>User</th>
            <td class="email-column" mat-cell *matCellDef="let row">
                {{ row.userEmail }}
            </td>
        </ng-container>

        <ng-container matColumnDef="test">
            <th class="test-column" mat-header-cell *matHeaderCellDef>Test</th>
            <td class="test-column" mat-cell *matCellDef="let row">
                {{ row.testName.toUpperCase() }}
            </td>
        </ng-container>

        <ng-container matColumnDef="score">
            <th class="score-column" mat-header-cell *matHeaderCellDef>
                Score
            </th>
            <td class="score-column" mat-cell *matCellDef="let row">
                {{ row.pointsGot }}/{{ row.pointsMax }}
            </td>
        </ng-container>

        <ng-container matColumnDef="result">
            <th class="result-column" mat-header-cell *matHeaderCellDef>
                Result
            </th>
            <td class="result-column" mat-cell *matCellDef="let row">
                <portal-status
                    *ngIf="row.passed"
                    [text]="'Passed'"
                    [type]="'success'"
                ></portal-status>
                <portal-status
                    *ngIf="!row.passed"
                    [text]="'Failed'"
                    [type]="'danger'"
                ></portal-status>
            </td>
        </ng-container>

        <ng-container matColumnDef="time">
            <th class="time-column" mat-header-cell *matHeaderCellDef>Time</th>
            <td class="time-column" mat-cell *matCellDef="let row">
                {{ row.time }}
            </td>
        </ng-container>

        <ng-container matColumnDef="open">
            <th class="open-column" mat-header-cell *matHeaderCellDef>
                Answers
            </th>
            <td class="open-column" mat-cell *matCellDef="let row">
                <button
                    *ngIf="row.resultsLink"
                    mat-icon-button
                    aria-label="Show Answers"
                    matTooltip="Show Answers"
                    (click)="
                        $event.stopPropagation(); showResults(row.resultsLink)
                    "
                >
                    <portal-icon icon="done_all"></portal-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="result-row"
        ></tr>
    </table>

    <div class="none-row" *ngIf="results.length === 0">
        <portal-status
            [text]="'None'"
            [type]="'none'"
            [big]="true"
        ></portal-status>
    </div>
</div>

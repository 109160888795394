import { Pipe, PipeTransform } from "@angular/core";
import { StatusType } from "../components/status/status.component";

@Pipe({ name: "playerStatusToState" })
export class PlayerStatusToStatePipe implements PipeTransform {
    transform(value: string): StatusType {
        switch (value) {
            case "install_request":
                return "installing";
            case "stop_request":
                return "stopping";
            case "start_request":
                return "starting";
            case "delete_stopped_request":
                return "deleting";
            case "delete_running_request":
                return "deleting";
            case "delete_request":
                return "deleting";
            default:
                return value as StatusType;
        }
    }
}

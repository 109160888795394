<div class="folder" (click)="toggleFolder()">
    <div class="folder-name">
        <fa-icon
            *ngIf="closed"
            class="folder-icon"
            [icon]="['far', 'folder']"
        ></fa-icon>
        <fa-icon
            *ngIf="!closed"
            class="folder-icon"
            [icon]="['far', 'folder-open']"
        ></fa-icon>
        {{ getFolderDisplayName(folder.name) }}
    </div>
    <div>
        <fa-icon
            *ngIf="isUserFolder(folder.name)"
            matTooltip="Delete"
            class="icon-button"
            [icon]="['fas', 'trash-alt']"
            (click)="
                $event.stopPropagation();
                deleteFolder.emit({
                    name: getFolderDisplayName(folder.name),
                    path: folder.path
                })
            "
        ></fa-icon>
    </div>
</div>
<div [hidden]="closed" class="content">
    <portal-folder
        *ngFor="let subfolder of folder.folders"
        [folder]="subfolder"
        (deleteFolder)="deleteFolder.emit($event)"
        (deleteFile)="deleteFile.emit($event)"
        (openFileRequest)="openFileRequest.emit()"
    >
    </portal-folder>
    <portal-file
        *ngFor="let file of folder.files"
        [file]="file"
        (openFileRequest)="openFileRequest.emit()"
        (deleteFile)="deleteFile.emit($event)"
    ></portal-file>
</div>

<div *ngIf="site" class="w-full grid gap-2 auto-rows-min">
    <portal-card headline="site" subtitle="Important site details.">
        <div card-content class="flex flex-wrap gap-2 justify-between">
            <div class="text-2xl font-bold font-sans w-full mb-2">
                S{{ site.id }} - {{ site.name }}
            </div>
            <div class="flex flex-col">
                <div class="font-bold">Link to myRTLS</div>
                <portal-link
                    href="https://myrtls.com/site/{{
                        site.id
                    }}/device-care-dashboard"
                >
                    https://myrtls.com/site/{{ site.id }}
                </portal-link>
            </div>
            <div class="flex flex-col">
                <div class="font-bold">Subscription</div>
                <div>
                    <portal-status
                        *ngIf="site"
                        [type]="site.subscription.type"
                        [big]="true"
                        [text]="site.subscription.text"
                    ></portal-status>
                </div>
            </div>
            <div class="flex flex-col">
                <div class="font-bold">Opportunities</div>
                <portal-link
                    *ngFor="let project of site.projects"
                    [href]="'/opportunities/' + project?.id"
                >
                    [{{ project?.id }}] {{ project?.name }}
                </portal-link>
            </div>
        </div>
    </portal-card>
    <portal-card
        headline="updates"
        subtitle="Updates and notes. Select for details."
    >
        <div card-content class="w-full max-h-96 overflow-y-auto">
            <portal-updates-list #updateList></portal-updates-list>
        </div>
        <div card-actions>
            <button
                class="mr-4 w-40"
                mat-stroked-button
                color="primary"
                (click)="createUpdate()"
                [disabled]="loading > 0"
            >
                <span *ngIf="loading > 0">LOADING</span>
                <span *ngIf="loading === 0">CREATE UPDATE</span>
            </button>
        </div>
    </portal-card>
    <portal-card
        headline="plans"
        subtitle="Plans related to this site. Select to open in RTLS Planner."
    >
        <div card-content class="w-full max-h-96 overflow-y-auto">
            <portal-plan-list
                #planList
                *ngIf="site.projects"
                [plans]="site.projects | mergeProjectsPlans"
                [site]="site"
            ></portal-plan-list>
        </div>
        <div card-actions>
            <button
                class="mr-4 w-36"
                mat-stroked-button
                color="primary"
                (click)="createPlan()"
                [disabled]="loading > 0 || site.projects.length === 0"
            >
                <span *ngIf="loading > 0">LOADING</span>
                <span *ngIf="loading === 0">CREATE PLAN</span>
            </button>
        </div>
    </portal-card>
    <portal-card
        headline="issues"
        subtitle="Tickets and RMAs related to this site. Select to open in Service Desk."
    >
        <div card-content class="w-full max-h-96 overflow-y-auto">
            <portal-ticket-list
                #ticketList
                *ngIf="site.projects"
                [tickets]="site.projects | mergeProjectsTickets"
                [site]="site"
            ></portal-ticket-list>
        </div>
        <div card-actions>
            <button
                class="mr-4 w-36"
                mat-stroked-button
                color="primary"
                (click)="createTicket()"
                [disabled]="loading > 0 || site.projects.length === 0"
            >
                <span *ngIf="loading > 0">LOADING</span>
                <span *ngIf="loading === 0">CREATE TICKET</span>
            </button>
        </div>
    </portal-card>
</div>

import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared";
import { PlayersComponent } from "./views/players/players.component";
import { PlayersRoutes } from "./players.routes";
import { PlayersActiveListComponent } from "./components/players-active-list/players-active-list.component";
import { PlayersHistoryListComponent } from "./components/players-history-list/players-history-list.component";
import { CreatePlayerDialogComponent } from "./components/create-player-dialog/create-player-dialog.component";
import { StartPlayerDialogComponent } from "./components/start-palyer-dialog/start-player-dialog.component";
import { ProlongPlayerDialogComponent } from "./components/prolong-player-dialog/prolong-player-dialog.component";
import { DeletePlayerDialogComponent } from "./components/delete-player-dialog/delete-player-dialog.component";

@NgModule({
    imports: [SharedModule, PlayersRoutes],
    declarations: [
        PlayersComponent,
        PlayersActiveListComponent,
        PlayersHistoryListComponent,
        CreatePlayerDialogComponent,
        StartPlayerDialogComponent,
        ProlongPlayerDialogComponent,
        DeletePlayerDialogComponent,
    ],
    providers: [],
})
export class PlayersModule {}

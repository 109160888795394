import { NgModule } from "@angular/core";
import { CompanyRoutes } from "./company.routes";
import { CompanyComponent } from "./views/company/company.component";
import { SharedModule } from "../../shared";

@NgModule({
    imports: [SharedModule, CompanyRoutes],
    declarations: [CompanyComponent],
    providers: [],
})
export class CompanyModule {}

import { Component, ViewChild, ElementRef, Inject } from "@angular/core";
import {
    AbstractControl,
    UntypedFormControl,
    Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";

@Component({
    selector: "portal-create-folder-dialog",
    styleUrls: ["create-folder-dialog.component.scss"],
    templateUrl: "create-folder-dialog.component.html",
})
export class CreateFolderDialogComponent {
    @ViewChild("parentSelect", { static: false }) parentSelect!: MatSelect;
    @ViewChild("nameInput", { static: false }) nameInput!: ElementRef;

    parentValidator = new UntypedFormControl("", []);
    nameValidator = new UntypedFormControl("", [
        Validators.required,
        this.validateFolderName,
    ]);

    parents: { value: string; viewValue: string }[];

    constructor(
        public dialogRef: MatDialogRef<CreateFolderDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { value: string; viewValue: string }[]
    ) {
        this.parents = data;
    }

    validateFolderName(c: AbstractControl) {
        const invalidCharacters = [
            "\\\\",
            "\\/",
            "\\?",
            "\\%",
            "\\*",
            "\\:",
            "\\|",
            '\\"',
            "\\<",
            "\\>",
            "\\.",
            "\\+",
        ];

        return new RegExp("(" + invalidCharacters.join("|") + ")").test(c.value)
            ? { validateFolderName: { valid: false } }
            : null;
    }

    create(): void {
        if (this.parentValidator.invalid) {
            this.parentSelect.open();
            return;
        }

        if (this.nameValidator.invalid) {
            this.nameInput.nativeElement.focus();
            return;
        }

        this.dialogRef.close({
            parent: this.parentValidator.value,
            name: this.nameValidator.value,
        });
    }
}

import { AfterViewInit, Component, Input, OnDestroy } from "@angular/core";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import {
    Player,
    PartnerStatus,
    PartnerLevel,
    CompanyQuery,
    BusinessReviewsGQL,
} from "../../../core";
import { LevelType } from "../level/level.component";
import { Subject, takeUntil } from "rxjs";
import { TimeUtils } from "@portal/shared";

@Component({
    selector: "portal-company-detail",
    templateUrl: "./company-detail.component.html",
    styleUrls: ["./company-detail.component.scss"],
})
export class CompanyDetailComponent implements AfterViewInit, OnDestroy {
    @Input() company!: CompanyQuery["company"];
    @Input() players!: Player[];
    @Input() partnerStatus!: PartnerStatus;

    lastBusinessReview: {
        tooltip: string;
        color: string;
        icon: IconName;
        date: string;
    } | null = null;

    private readonly unsubscribe$ = new Subject<void>();

    constructor(private businessReviewsGql: BusinessReviewsGQL) {}

    ngAfterViewInit(): void {
        this.businessReviewsGql
            .watch()
            .valueChanges.pipe(takeUntil(this.unsubscribe$))
            .subscribe((result) => {
                if (
                    !result.data.businessReviews ||
                    result.data.businessReviews.length === 0
                ) {
                    this.lastBusinessReview = {
                        tooltip: "No business review found.",
                        color: "text-error-dark",
                        icon: "close",
                        date: "None",
                    };
                    return;
                }

                const lastReview = result.data.businessReviews
                    .slice()
                    .sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10))[0];
                const isOld = TimeUtils.isDateMoreThanNMonthsInThePast(
                    lastReview.createdAt,
                    3
                );

                if (isOld) {
                    this.lastBusinessReview = {
                        tooltip: "Business review is outdated.",
                        color: "text-warning-dark",
                        icon: "warning",
                        date: TimeUtils.formatDateToReadableString(
                            lastReview.createdAt
                        ),
                    };
                } else {
                    this.lastBusinessReview = {
                        tooltip: "",
                        color: "",
                        icon: "close",
                        date: TimeUtils.formatDateToReadableString(
                            lastReview.createdAt
                        ),
                    };
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getLevelType(level?: PartnerLevel): LevelType {
        if (!level) {
            return "none";
        }

        return level.name as LevelType;
    }
}

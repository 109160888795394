import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { PartnerProgramComponent } from "./views/partner-program/partner-program.component";
import { menuGuard } from "../../core/guards/menu.guard";

const playersRoutes: Routes = [
    {
        path: "partner_status",
        redirectTo: "partner-status",
        pathMatch: "full",
    },
    {
        path: "partner-status",
        component: PartnerProgramComponent,
        data: {
            showTopPanel: true,
            showLeftPanel: true,
        },
        canActivate: [menuGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(playersRoutes)],
    exports: [RouterModule],
})
export class PartnerProgramRoutes {}

<table
    class="plans-table"
    mat-table
    [dataSource]="dataSource"
    matSort
    *ngIf="plans.length !== 0"
>
    <ng-container matColumnDef="name">
        <th
            class="name-column"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
        >
            Name
        </th>
        <td class="name-column" mat-cell *matCellDef="let row">
            {{ row.name }}
        </td>
    </ng-container>

    <ng-container matColumnDef="opportunity">
        <th
            class="opportunity-column"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
        >
            Opportunity
        </th>
        <td class="opportunity-column" mat-cell *matCellDef="let row">
            <span *ngIf="row.projectId !== null"> [{{ row.projectId }}] </span>
            {{ opportunityIdToName(row.projectId) }}
        </td>
    </ng-container>

    <ng-container matColumnDef="state">
        <th
            class="state-column"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
        >
            State
        </th>
        <td class="state-column" mat-cell *matCellDef="let row">
            {{ row.state }}
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th
            class="actions-column"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
        >
            Actions
        </th>
        <td class="actions-column" mat-cell *matCellDef="let row">
            <div class="flex gap-2">
                <button
                    mat-icon-button
                    aria-label="Duplicate"
                    matTooltip="Duplicate"
                    (click)="
                        $event.stopPropagation();
                        duplicatePlan(row.id, row.projectId)
                    "
                >
                    <portal-icon icon="content_copy"></portal-icon>
                </button>
                <button
                    mat-icon-button
                    aria-label="Rename"
                    matTooltip="Rename"
                    (click)="
                        $event.stopPropagation();
                        renamePlan(row.id, row.name, row.projectId)
                    "
                >
                    <portal-icon icon="edit_note"></portal-icon>
                </button>
                <button
                    *ngIf="row.state !== 'approved'"
                    mat-icon-button
                    aria-label="Request Approval"
                    matTooltip="Request Approval"
                    (click)="
                        $event.stopPropagation();
                        requestPlanApproval(
                            row.id,
                            row.state === 'pending' ? 'draft' : 'pending',
                            row.projectId
                        )
                    "
                >
                    <portal-icon
                        icon="check_box"
                        [ngClass]="{
                            'text-sewio': row.state === 'pending'
                        }"
                    ></portal-icon>
                </button>
                <button
                    mat-icon-button
                    aria-label="Delete"
                    matTooltip="Delete"
                    (click)="
                        $event.stopPropagation();
                        deletePlan(row.id, row.projectId)
                    "
                >
                    <portal-icon icon="delete"></portal-icon>
                </button>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
        #planRow
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="cursor-pointer hover:bg-stone-50"
        (click)="editPlan(row.id)"
    ></tr>
</table>

<div class="none-row" *ngIf="plans.length === 0">
    <portal-status [text]="'None'" [type]="'none'" [big]="true"></portal-status>
</div>

import {
    AfterViewInit,
    Component,
    Input,
    OnDestroy,
    ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { CompaniesGQL, CompaniesQuery, SetCompanyGQL } from "../../../../core";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { Apollo } from "apollo-angular";
import {
    Subject,
    debounceTime,
    first,
    map,
    merge,
    startWith,
    switchMap,
    takeUntil,
} from "rxjs";

type Company = CompaniesQuery["companies"]["items"][0];

@Component({
    selector: "portal-partner-list",
    templateUrl: "./partner-list.component.html",
    styleUrls: ["./partner-list.component.scss"],
})
export class PartnerListComponent implements OnDestroy, AfterViewInit {
    private readonly unsubscribe$ = new Subject<void>();
    private readonly filter$ = new Subject<string>();

    displayedColumns: string[] = ["id", "name", "manager", "level"];
    data: Company[] = [];
    resultsLength = 0;
    isLoading = true;

    private _filter = "";

    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort!: MatSort;

    @Input()
    set filter(filterValue: string) {
        this._filter = filterValue;
        this.filter$.next(filterValue);
    }

    constructor(
        private companiesGql: CompaniesGQL,
        private setCompanyGql: SetCompanyGQL,
        private router: Router,
        private apollo: Apollo
    ) {}

    ngAfterViewInit() {
        this.filter$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.paginator.pageIndex = 0;
        });

        merge(this.filter$.pipe(debounceTime(500)), this.paginator.page)
            .pipe(
                takeUntil(this.unsubscribe$),
                startWith({}),
                switchMap(() => {
                    this.isLoading = true;
                    return this.companiesGql
                        .watch({
                            limit: 10,
                            offset: 10 * this.paginator.pageIndex,
                            filter: this._filter,
                        })
                        .valueChanges.pipe(first());
                }),
                map((data) => {
                    this.isLoading = false;
                    this.resultsLength = data.data.companies.totalCount;
                    return data.data.companies.items;
                })
            )
            .subscribe((data) => {
                this.data = data;
            });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    setCompany(id: string) {
        this.setCompanyGql
            .mutate(
                { id: id },
                {
                    update: () => {
                        this.apollo.client.resetStore();
                        void this.router.navigate(["/dashboard"]);
                    },
                }
            )
            .subscribe()
            .unsubscribe();
    }
}

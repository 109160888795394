<portal-loading *ngIf="!content" [height]="592"></portal-loading>
<mat-card
    *ngIf="content"
    class="md:container md:mx-auto max-w-screen-lg flex flex-col p-8"
>
    <div
        class="my-2 prose prose-sm max-w-3xl mx-auto"
        [innerHTML]="content | edjsToHtml | safeHtml"
    ></div>
</mat-card>

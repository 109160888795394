import { Component, Inject, ViewChild } from "@angular/core";
import { TimeUtils } from "@portal/shared";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";

@Component({
    selector: "portal-create-player-dialog",
    styleUrls: ["create-player-dialog.component.scss"],
    templateUrl: "create-player-dialog.component.html",
})
export class CreatePlayerDialogComponent {
    types: string[];
    projects: { id: string; name: string }[];

    value = 30;
    endTime: string;
    startTime!: string;

    deleteOnTime = false;

    typeValidator: UntypedFormControl;
    projectValidator = new UntypedFormControl("", [Validators.required]);

    @ViewChild("typeSelect", { static: false }) typeSelect!: MatSelect;
    @ViewChild("projectSelect", { static: false }) projectSelect!: MatSelect;

    constructor(
        public dialogRef: MatDialogRef<CreatePlayerDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            playerTypes: string[];
            projects: { id: string; name: string }[];
        }
    ) {
        this.types = [...data.playerTypes].reverse();
        this.typeValidator = new UntypedFormControl(this.types[0], [
            Validators.required,
        ]);
        this.projects = data.projects;
        this.endTime = TimeUtils.getMinutesFromNowInReadableString(30);
    }

    formatMinutes(minutes: number) {
        if (minutes >= 60) {
            return (
                Math.floor(minutes / 60) +
                ":" +
                ("0" + (minutes % 60)).slice(-2)
            );
        } else {
            return "0:" + ("0" + (minutes % 60)).slice(-2);
        }
    }

    onSliderChange() {
        this.endTime = TimeUtils.getMinutesFromNowInReadableString(this.value);
    }

    create(): void {
        if (this.typeValidator.invalid) {
            this.typeSelect.open();
            return;
        }

        if (this.projectValidator.invalid) {
            this.projectSelect.open();
            return;
        }

        this.dialogRef.close({
            version: this.typeValidator.value,
            time: this.value,
            project: this.projectValidator.value,
            deleteOnTime: this.deleteOnTime,
        });
    }
}

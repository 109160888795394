import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { RegisterDialogComponent } from "../../components/register-dialog/register-dialog.component";
import { ToastrService } from "ngx-toastr";
import {
    ProjectsQuery,
    ProjectsGQL,
    CreateProjectGQL,
    ProjectsDocument,
} from "../../../../core";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "portal-projects",
    templateUrl: "./projects.component.html",
    styleUrls: ["./projects.component.scss"],
})
export class ProjectsComponent implements OnInit, OnDestroy {
    projects: ProjectsQuery["projects"] | null = null;
    filterValue = "";
    attention = false;
    closed = false;
    archived = true;
    assigned = false;

    loading = 1;

    private subscriptions: Subscription[] = [];

    constructor(
        private projectsGql: ProjectsGQL,
        private dialog: MatDialog,
        private createProjectGql: CreateProjectGQL,
        private toastrService: ToastrService
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.projectsGql.watch().valueChanges.subscribe((result) => {
                this.projects = result.data.projects;
                this.loading = 0;
            })
        );
    }

    ngOnDestroy() {
        while (this.subscriptions.length) {
            this.subscriptions.pop()?.unsubscribe();
        }
    }

    registerProject() {
        const dialogReference = this.dialog.open(RegisterDialogComponent, {
            width: window.innerWidth >= 768 ? "600px" : "95%",
        });

        const dialogSubscription = dialogReference
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.loading++;
                    this.createProjectGql
                        .mutate(
                            {
                                name: result.name,
                                closing: result.closing,
                                useCase: result.useCase,
                                problem: result.problem,
                                solution: result.solution,
                                customer: result.customer,
                                revenue: result.revenue,
                                description: result.description,
                                accuracy: result.accuracy,
                                batteryLife: result.batteryLife,
                                country: result.country,
                                numberOfObjects: result.numberOfObjects,
                                tagMounting: result.tagMounting,
                                trackedObjects: result.trackedObjects,
                                refreshRate: result.refreshRate,
                                zAxis: result.zAxis,
                            },
                            {
                                update: (store, { data }) => {
                                    if (!data) {
                                        return;
                                    }

                                    this.toastrService.success(
                                        "Project registered."
                                    );

                                    const projects =
                                        store.readQuery<ProjectsQuery>({
                                            query: ProjectsDocument,
                                        });

                                    if (projects === null) {
                                        return;
                                    }

                                    store.writeQuery({
                                        query: ProjectsDocument,
                                        data: {
                                            projects: projects.projects.concat([
                                                data.createProject,
                                            ]),
                                        },
                                    });
                                },
                            }
                        )
                        .subscribe()
                        .unsubscribe();
                }

                dialogSubscription.unsubscribe();
            });
    }

    onAttentionChanged() {
        this.attention = !this.attention;
    }

    onHideClosedChanged() {
        this.closed = !this.closed;
    }

    onHideArchivedChanged() {
        this.archived = !this.archived;
    }

    onHideAssignedChanged() {
        this.assigned = !this.assigned;
    }

    applyFilter(filterValue: string) {
        this.filterValue = filterValue;
    }
}

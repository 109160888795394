<portal-loading *ngIf="loading" [height]="592"></portal-loading>
<mat-card
    *ngIf="!loading && article !== null"
    class="md:container md:mx-auto max-w-screen-lg flex flex-col p-8"
>
    <div class="text-stone-900 text-2xl font-bold w-full max-w-2xl mx-auto">
        {{ article.title }}
    </div>
    <div
        class="text-stone-400 text-xs w-full max-w-2xl mx-auto flex gap-2 pb-4 border-b border-stone-200"
    >
        <div *ngIf="article.date">
            {{ formatDate(article.date) }}
        </div>
        <div class="first-of-type:hidden last-of-type:hidden">|</div>
        <div *ngIf="article.author">By {{ article.author }}</div>
        <div class="first-of-type:hidden last-of-type:hidden">|</div>
        <div *ngIf="article.minutes">
            {{ article.minutes }} minute{{ article.minutes > 1 ? "s" : "" }}
            read
        </div>
    </div>
    <div
        class="my-2 prose prose-sm max-w-2xl mx-auto"
        [innerHTML]="article.content | edjsToHtml | safeHtml"
    ></div>
</mat-card>

import { RouterModule } from "@angular/router";
import { LeftPanelComponent } from "./components/left-panel/left-panel.component";
import { TopPanelComponent } from "./components/top-panel/top-panel.component";
import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared";

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild([]),
    ],
    exports: [LeftPanelComponent, TopPanelComponent],
    declarations: [LeftPanelComponent, TopPanelComponent],
})
export class LayoutModule {}

<div class="table-container mat-elevation-z2">
    <table
        class="quotes-table"
        mat-table
        [dataSource]="dataSource"
        *ngIf="quotes.length !== 0"
        matSort
    >
        <ng-container matColumnDef="id">
            <th
                class="id-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                ID
            </th>
            <td class="id-column" mat-cell *matCellDef="let row">
                {{ row.name }}
            </td>
        </ng-container>

        <ng-container matColumnDef="amount">
            <th
                class="amount-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Amount
            </th>
            <td class="amount-column" mat-cell *matCellDef="let row">
                {{ row.amount | round | numberToLocaleString }}
                {{ row.currency }}
            </td>
        </ng-container>

        <ng-container matColumnDef="expiration">
            <th
                class="expiration-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Expiration Date
            </th>
            <td class="expiration-column" mat-cell *matCellDef="let row">
                {{ row.expiration }}
            </td>
        </ng-container>

        <ng-container matColumnDef="update">
            <th
                class="update-column"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Last Update
            </th>
            <td class="update-column" mat-cell *matCellDef="let row">
                {{ row.lastUpdate }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="order-row"
        ></tr>
    </table>

    <div class="none-row" *ngIf="quotes.length === 0">
        <portal-status
            [text]="'None'"
            [type]="'none'"
            [big]="true"
        ></portal-status>
    </div>
</div>

import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { TimeUtils } from "@portal/shared";

@Component({
    selector: "portal-prolong-player-dialog",
    styleUrls: ["prolong-player-dialog.component.scss"],
    templateUrl: "prolong-player-dialog.component.html",
})
export class ProlongPlayerDialogComponent {
    value = 30;
    endTime: string;
    startTime!: string;

    deleteOnTime = false;

    constructor(public dialogRef: MatDialogRef<ProlongPlayerDialogComponent>) {
        this.endTime = TimeUtils.getMinutesFromNowInReadableString(30);
    }

    formatMinutes(minutes: number) {
        if (minutes >= 60) {
            return (
                Math.floor(minutes / 60) +
                ":" +
                ("0" + (minutes % 60)).slice(-2)
            );
        } else {
            return "0:" + ("0" + (minutes % 60)).slice(-2);
        }
    }

    onSliderChange() {
        this.endTime = TimeUtils.getMinutesFromNowInReadableString(this.value);
    }

    prolong(): void {
        this.dialogRef.close({
            time: this.value,
            deleteOnTime: this.deleteOnTime,
        });
    }
}

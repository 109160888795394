<div class="rounded-lg shadow relative bg-white overflow-auto h-96">
    <table
        *ngIf="playersHistory.length > 0"
        class="players-history-table"
        mat-table
        [dataSource]="dataSource"
        multiTemplateDataRows
    >
        <ng-container matColumnDef="name">
            <th class="name-column" mat-header-cell *matHeaderCellDef>
                RTLS Player
            </th>
            <td class="name-column" mat-cell *matCellDef="let row">
                {{ row.playerName }}
            </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th class="email-column" mat-header-cell *matHeaderCellDef>Who</th>
            <td class="email-column" mat-cell *matCellDef="let row">
                <portal-level
                    *ngIf="!row.userEmail"
                    [type]="'green'"
                ></portal-level>
                <span *ngIf="row.userEmail">{{ row.userEmail }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th class="action-column" mat-header-cell *matHeaderCellDef>
                Action
            </th>
            <td class="action-column" mat-cell *matCellDef="let row">
                <portal-status
                    [text]="row.action | playerStatusToState | titlecase"
                    [type]="row.action | playerStatusToState"
                ></portal-status>
            </td>
        </ng-container>

        <ng-container matColumnDef="detail">
            <th class="detail-column" mat-header-cell *matHeaderCellDef>
                Details
            </th>
            <td class="detail-column" mat-cell *matCellDef="let row">
                <span [innerHTML]="row.details | formatNewLines"></span>
            </td>
        </ng-container>

        <ng-container matColumnDef="at">
            <th class="at-column" mat-header-cell *matHeaderCellDef>Time</th>
            <td class="at-column" mat-cell *matCellDef="let row">
                {{ row.atString }}
            </td>
        </ng-container>

        <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="player-history-row"
        ></tr>
    </table>

    <div class="none-row" *ngIf="playersHistory.length === 0">
        <portal-status
            [text]="'None'"
            [type]="'none'"
            [big]="true"
        ></portal-status>
    </div>
</div>

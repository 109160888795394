<div mat-dialog-title class="title">Create RTLS Player</div>
<div mat-dialog-content>
    <div class="form">
        <mat-form-field>
            <mat-label>Version</mat-label>
            <mat-select #typeSelect [formControl]="typeValidator" required>
                <mat-option *ngFor="let type of types" [value]="type">
                    {{ type }}
                </mat-option>
            </mat-select>
            <mat-hint align="start" class="hint">
                RTLS Player is backwards compatible, meaning that newer version
                of RTLS Player can play captures made on older versions of RTLS
                Studios.
            </mat-hint>
        </mat-form-field>
        <mat-form-field class="hint-offset">
            <mat-label>Project</mat-label>
            <mat-select
                #projectSelect
                [formControl]="projectValidator"
                required
            >
                <mat-option
                    *ngFor="let project of projects"
                    [value]="project.id"
                >
                    [{{ project.id }}] {{ project.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="pr-3">
            <div class="mb-2">Hours Available</div>
            <mat-slider
                class="w-full"
                [max]="60 * 8"
                [min]="30"
                [step]="30"
                [displayWith]="formatMinutes"
                (change)="onSliderChange()"
                discrete
                showTickMarks
            >
                <input matSliderThumb [(ngModel)]="value" />
            </mat-slider>
            <div class="columns">
                <div>Will be deleted approx. at:</div>
                <div>{{ endTime }}</div>
            </div>
        </div>
        <div class="top-margin">
            <mat-checkbox [(ngModel)]="deleteOnTime"
                >Delete After Time's Up</mat-checkbox
            ><br />
            <mat-hint align="start" class="hint">
                All the data will be lost.
            </mat-hint>
        </div>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CANCEL</button>
    <button mat-button color="primary" cdkFocusInitial (click)="create()">
        CREATE
    </button>
</mat-dialog-actions>

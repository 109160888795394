import {
    animate,
    state,
    style,
    transition,
    trigger,
} from "@angular/animations";
import { Component, Input, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { CalculatedSite, Site, SiteUtils } from "@portal/shared";

@Component({
    selector: "portal-site-list",
    templateUrl: "./site-list.component.html",
    styleUrls: ["./site-list.component.scss"],
    animations: [
        trigger("detailExpand", [
            state("collapsed", style({ height: "0px", minHeight: "0" })),
            state("expanded", style({ height: "*" })),
            transition(
                "expanded <=> collapsed",
                animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
            ),
        ]),
    ],
})
export class SiteListComponent {
    @ViewChild(MatPaginator) paginator!: MatPaginator;

    displayedColumns: string[] = [
        "id",
        "name",
        "subscription",
        "status",
        "expand",
    ];

    dataSource!: MatTableDataSource<CalculatedSite>;
    tableSites!: CalculatedSite[];

    @Input()
    set sites(sites: Site[]) {
        this.tableSites = sites.map((site) =>
            SiteUtils.getCalculatedSite(site)
        );
        this.dataSource = new MatTableDataSource(this.tableSites);
        this.dataSource.paginator = this.paginator;
    }

    constructor(private router: Router) {}

    expandSite($event: Event, site: CalculatedSite) {
        $event.stopPropagation();
        $event.preventDefault();

        site.expanded = !site.expanded;
    }

    openSite(id: string) {
        void this.router.navigate(["/sites", id]);
    }
}

import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Folder, File } from "../../../../core";
import * as _ from "lodash";

import { find } from "lodash";

export type FolderStructure = {
    name: string;
    path: string;
    files: File[];
    folders: FolderStructure[];
};

@Component({
    selector: "portal-storage",
    templateUrl: "./storage.component.html",
    styleUrls: ["./storage.component.scss"],
})
export class StorageComponent {
    folders: FolderStructure[] | null = null;
    files: File[] | null = null;

    @Output() deleteFolder = new EventEmitter<{ path: string; name: string }>();
    @Output() deleteFile = new EventEmitter<{ path: string; name: string }>();
    @Output() openFileRequest = new EventEmitter();

    private _flatFolders!: Folder[];

    get flatFolders() {
        return this._flatFolders;
    }

    @Input()
    set flatFolders(folders: Folder[]) {
        const rootFolder = find(
            folders,
            (lookedFolder) => lookedFolder.root === true
        );

        if (!rootFolder) {
            return;
        }

        const clonedRootFolder: Folder = _.cloneDeep(rootFolder) || null;

        if (!clonedRootFolder) {
            this.folders = [];
            this.files = [];
            this._flatFolders = folders;
            return;
        }

        const skippedRootChildrenIds: number[] = [];

        for (const child of clonedRootFolder.children) {
            const folder =
                _.cloneDeep(
                    find(folders, (lookedFolder) => lookedFolder.id === child)
                ) || null;

            if (folder === null) {
                continue;
            }

            if (
                folder.name.match(
                    /^\$?[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/
                )
            ) {
                clonedRootFolder.files.push(...folder.files);
                skippedRootChildrenIds.push(child);
            }
        }

        const mapChildren = (childId: number) => {
            const foundFolder =
                _.cloneDeep(
                    find(folders, (lookedFolder) => lookedFolder.id === childId)
                ) || null;

            if (foundFolder === null) {
                return null;
            }

            const folder: FolderStructure = {
                name: foundFolder.name,
                path: foundFolder.path,
                files: foundFolder.files,
                folders: [],
            };

            const hiddenFoldersFiles = [];
            const hiddenFoldersIds: number[] = [];

            if (foundFolder.children.length > 0) {
                for (const child of foundFolder.children) {
                    const childFolder = find(
                        folders,
                        (lookedFolder) => lookedFolder.id === child
                    );

                    if (!childFolder) {
                        continue;
                    }

                    const clonedChildFolder = _.cloneDeep(childFolder);

                    if (
                        clonedChildFolder.name.match(
                            /^\$?[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/
                        )
                    ) {
                        hiddenFoldersFiles.push(...clonedChildFolder.files);
                        hiddenFoldersIds.push(child);
                    }
                }

                folder.folders = foundFolder.children
                    .filter(
                        (filteredFolder) =>
                            !hiddenFoldersIds.includes(filteredFolder)
                    )
                    .map(mapChildren)
                    .filter(
                        (filteredFolder): filteredFolder is FolderStructure =>
                            filteredFolder !== null
                    );

                folder.files.push(...hiddenFoldersFiles);
            }

            return folder;
        };

        const folderTree = clonedRootFolder.children
            .filter((child) => !skippedRootChildrenIds.includes(child))
            .map(mapChildren)
            .filter(
                (filteredFolder): filteredFolder is FolderStructure =>
                    filteredFolder !== null
            );

        this.folders = folderTree;
        this.files = clonedRootFolder.files;
        this._flatFolders = folders;
    }
}

import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "portal-delete-player-dialog",
    styleUrls: ["delete-player-dialog.component.scss"],
    templateUrl: "delete-player-dialog.component.html"
})
export class DeletePlayerDialogComponent {
    constructor(public dialogRef: MatDialogRef<DeletePlayerDialogComponent>) {}

    delete(): void {
        this.dialogRef.close(true);
    }
}

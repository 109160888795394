import { Component, Input } from "@angular/core";
import {
    IconName,
    IconPrefix,
    IconProp,
} from "@fortawesome/fontawesome-svg-core";

export type LevelType =
    | "Gold Level"
    | "Silver Level"
    | "Bronze Level"
    | "none"
    | "green"
    | "prospect"
    | "partner"
    | "customer"
    | "prospect_customer";

@Component({
    selector: "portal-level",
    templateUrl: "./level.component.html",
    styleUrls: ["./level.component.scss"],
})
export class LevelComponent {
    private _type!: LevelType;
    colorClass = "sewio-blue";
    icon: IconProp = ["fab" as IconPrefix, "sewio" as IconName];
    display = "";

    @Input() iconOnly = false;
    @Input() prospectOnly = true;

    get type() {
        return this._type;
    }

    @Input()
    set type(type: LevelType) {
        switch (type) {
            case "Gold Level":
                this.colorClass = "sewio-gold";
                this.display = "Gold";
                break;
            case "Silver Level":
                this.colorClass = "sewio-silver";
                this.display = "Silver";
                break;
            case "Bronze Level":
                this.colorClass = "sewio-bronze";
                this.display = "Bronze";
                break;
            case "green":
                this.colorClass = "sewio-green";
                this.display = "Sewio Portal";
                break;
            case "prospect":
                this.colorClass = "sewio-green-light";
                this.display = "Prospect Partner";
                break;
            case "partner":
                this.colorClass = "sewio-green";
                this.display = "Partner";
                break;
            case "customer":
                this.colorClass = "sewio-grey";
                this.display = "Customer";
                break;
            case "prospect_customer":
                this.colorClass = "sewio-grey-light";
                this.display = "Prospect Customer";
                break;
            default:
                this.colorClass = "sewio-grey";

                if (this.prospectOnly) {
                    this.display = "None";
                } else {
                    this.display = type
                        .split("_")
                        .map((part) => {
                            return part.charAt(0).toUpperCase() + part.slice(1);
                        })
                        .join(" ");
                }

                break;
        }

        this._type = type;
    }
}

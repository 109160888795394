import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";
import {
    BusinessReviewsQuery,
    BusinessReviewsGQL,
    CreateBusinessReviewGQL,
    EditBusinessReviewGQL,
    BusinessReviewsDocument,
} from "../../../core";
import { AddBusinessReviewDialogComponent } from "../components/add-business-review-dialog/add-business-review-dialog.component";
import { EditBusinessReviewDialogComponent } from "../components/edit-business-review-dialog/edit-business-review-dialog.component";

type BusinessReview = BusinessReviewsQuery["businessReviews"][0];

@Component({
    selector: "portal-business-reviews",
    templateUrl: "./business-reviews.component.html",
    styleUrls: ["./business-reviews.component.scss"],
})
export class BusinessReviewsComponent implements OnInit, OnDestroy {
    businessReviews: BusinessReviewsQuery["businessReviews"] | null = null;
    loading = 1;

    private subscriptions: Subscription[] = [];

    constructor(
        private businessReviewsGql: BusinessReviewsGQL,
        private createBusinessReviewGql: CreateBusinessReviewGQL,
        private editBusinessReviewGql: EditBusinessReviewGQL,
        private toastrService: ToastrService,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.subscriptions.push(
            this.businessReviewsGql.watch().valueChanges.subscribe((result) => {
                this.businessReviews = result.data.businessReviews;
                this.loading = 0;
            })
        );
    }

    ngOnDestroy(): void {
        while (this.subscriptions.length) {
            this.subscriptions.pop()?.unsubscribe();
        }
    }

    addReview() {
        const dialogReference = this.dialog.open(
            AddBusinessReviewDialogComponent,
            {
                width: window.innerWidth >= 768 ? "600px" : "300px",
            }
        );

        dialogReference
            .afterClosed()
            .pipe(first())
            .subscribe((result) => {
                if (result) {
                    this.createBusinessReviewGql
                        .mutate(
                            {
                                description: result.description,
                            },
                            {
                                update: (store, { data }) => {
                                    this.toastrService.success(
                                        "Business review created."
                                    );

                                    const businessReviews =
                                        store.readQuery<BusinessReviewsQuery>({
                                            query: BusinessReviewsDocument,
                                        });

                                    if (businessReviews === null) {
                                        return;
                                    }

                                    if (data?.createBusinessReview) {
                                        store.writeQuery({
                                            query: BusinessReviewsDocument,
                                            data: {
                                                businessReviews:
                                                    businessReviews.businessReviews.concat(
                                                        [
                                                            data.createBusinessReview,
                                                        ]
                                                    ),
                                            },
                                        });
                                    }
                                },
                            }
                        )
                        .subscribe()
                        .unsubscribe();
                }
            });
    }

    editReview(review: BusinessReview) {
        const dialogReference = this.dialog.open(
            EditBusinessReviewDialogComponent,
            {
                width: window.innerWidth >= 768 ? "600px" : "300px",
                data: {
                    description: review.description,
                },
            }
        );

        dialogReference
            .afterClosed()
            .pipe(first())
            .subscribe((result) => {
                if (result) {
                    this.editBusinessReviewGql
                        .mutate(
                            {
                                id: review.id,
                                description: result.description,
                            },
                            {
                                update: (store, { data }) => {
                                    this.toastrService.success(
                                        "Business review edited."
                                    );

                                    if (data?.editBusinessReview) {
                                        store.writeQuery({
                                            query: BusinessReviewsDocument,
                                            variables: {
                                                id: review.id,
                                            },
                                            data: {
                                                businessReview:
                                                    data.editBusinessReview,
                                            },
                                        });
                                    }
                                },
                            }
                        )
                        .subscribe()
                        .unsubscribe();
                }
            });
    }
}

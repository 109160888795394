<div class="header">
    <span>MY PARTNER LEVEL:</span>
    <span *ngIf="!status.currentLevel" class="sewio-grey">NONE</span>
    <span
        *ngIf="status.currentLevel"
        [ngStyle]="{
            color: status.currentLevel.color
        }"
        >{{ status.currentLevel.name | uppercase }}</span
    >
</div>
<div class="level-container" *ngIf="status.currentLevel">
    <div class="spinner-container">
        <svg class="spinner" width="90" height="90" viewBox="0 0 120 120">
            <circle
                class="progress-meter"
                cx="60"
                cy="60"
                r="54"
                stroke-width="12"
            />
            <circle
                class="progress-value"
                [ngStyle]="{
                    stroke: status.nextLevel
                        ? status.nextLevel.color
                        : status.currentLevel.color
                }"
                cx="60"
                cy="60"
                r="54"
                stroke-width="12"
                [style.stroke-dasharray]="CIRCUMFERENCE"
                [style.stroke-dashoffset]="getOffset(calculatePercentage())"
            />
        </svg>
        <fa-icon
            class="partner-icon"
            [icon]="sewioIcon"
            [ngStyle]="{
                color: status.currentLevel.color
            }"
        ></fa-icon>
    </div>
    <div *ngIf="status.nextLevel" class="summary">
        <div class="partner-level">
            <span
                [ngStyle]="{
                    color: status.currentLevel.color
                }"
                >{{ status.currentLevel.name | uppercase }}</span
            >
            TO
            <span
                [ngStyle]="{
                    color: status.nextLevel.color
                }"
                >{{ status.nextLevel.name | uppercase }}</span
            >
            PROGRESS
        </div>
        <div *ngIf="status.nextLevel.minPoints > 0">
            <div class="points">
                {{ status.points }} / {{ status.nextLevel.minPoints }}
            </div>
            <div class="points-description">Points</div>
        </div>
        <div *ngIf="status.nextLevel.minAnchors > 0">
            <div class="points">
                {{ status.anchors }} / {{ status.nextLevel.minAnchors }}
            </div>
            <div class="points-description">Anchors</div>
        </div>
        <div
            *ngIf="
                status.nextLevel.minL1Trainings > 0 &&
                status.nextLevel.minL1Trainings -
                    status.currentLevel.minL1Trainings !==
                    0
            "
        >
            <div class="points">
                <portal-status
                    class="completion"
                    *ngIf="status.l1Trainings > 0"
                    [type]="'success'"
                ></portal-status>
                <portal-status
                    class="completion"
                    *ngIf="status.l1Trainings === 0"
                    [type]="'danger'"
                ></portal-status>
            </div>
            <div class="points-description">L1 training</div>
        </div>
        <div
            *ngIf="
                status.nextLevel.minL2Trainings > 0 &&
                status.nextLevel.minL2Trainings -
                    status.currentLevel.minL2Trainings !==
                    0
            "
        >
            <div class="points">
                <portal-status
                    class="completion"
                    *ngIf="status.l2Trainings > 0"
                    [type]="'success'"
                ></portal-status>
                <portal-status
                    class="completion"
                    *ngIf="status.l2Trainings === 0"
                    [type]="'danger'"
                ></portal-status>
            </div>
            <div class="points-description">L2 training</div>
        </div>
        <div
            *ngIf="
                status.nextLevel.minL3Trainings > 0 &&
                status.nextLevel.minL3Trainings -
                    status.currentLevel.minL3Trainings !==
                    0
            "
        >
            <div class="points">
                <portal-status
                    class="completion"
                    *ngIf="status.l3Trainings > 0"
                    [type]="'success'"
                ></portal-status>
                <portal-status
                    class="completion"
                    *ngIf="status.l3Trainings === 0"
                    [type]="'danger'"
                ></portal-status>
            </div>
            <div class="points-description">L3 training</div>
        </div>
    </div>
    <div *ngIf="!status.nextLevel && status.currentLevel" class="summary">
        <div class="partner-level">
            MY
            <span
                [ngStyle]="{
                    color: status.currentLevel.color
                }"
                >{{ status.currentLevel.name | uppercase }}</span
            >
            STATISTICS
        </div>
        <div>
            <div class="points">
                {{ status.points }} / {{ status.currentLevel.minPoints }}
            </div>
            <div class="points-description">Points</div>
        </div>
        <div>
            <div class="points">
                {{ status.anchors }} / {{ status.currentLevel.minAnchors }}
            </div>
            <div class="points-description">Anchors</div>
        </div>
        <div>
            <div class="points">
                <portal-status
                    class="completion"
                    *ngIf="status.l1Trainings > 0"
                    [type]="'success'"
                ></portal-status>
                <portal-status
                    class="completion"
                    *ngIf="status.l1Trainings === 0"
                    [type]="'danger'"
                ></portal-status>
            </div>
            <div class="points-description">L1 training</div>
        </div>
        <div>
            <div class="points">
                <portal-status
                    class="completion"
                    *ngIf="status.l2Trainings > 0"
                    [type]="'success'"
                ></portal-status>
                <portal-status
                    class="completion"
                    *ngIf="status.l2Trainings === 0"
                    [type]="'danger'"
                ></portal-status>
            </div>
            <div class="points-description">L2 training</div>
        </div>
        <div>
            <div class="points">
                <portal-status
                    class="completion"
                    *ngIf="status.l3Trainings > 0"
                    [type]="'success'"
                ></portal-status>
                <portal-status
                    class="completion"
                    *ngIf="status.l3Trainings === 0"
                    [type]="'danger'"
                ></portal-status>
            </div>
            <div class="points-description">L3 training</div>
        </div>
    </div>
</div>

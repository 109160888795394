<div class="explorer-container">
    <portal-folder
        *ngFor="let folder of folders"
        [folder]="folder"
        (deleteFolder)="deleteFolder.emit($event)"
        (deleteFile)="deleteFile.emit($event)"
        (openFileRequest)="openFileRequest.emit()"
    ></portal-folder>
    <portal-file
        *ngFor="let file of files"
        [file]="file"
        (openFileRequest)="openFileRequest.emit($event)"
        (deleteFile)="deleteFile.emit($event)"
    ></portal-file>
</div>

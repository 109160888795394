import { Pipe, PipeTransform } from "@angular/core";
import { Ticket } from "@portal/shared";

@Pipe({ name: "mergeProjectsTickets" })
export class MergeProjectsTicketsPipe implements PipeTransform {
    transform(projects: { tickets: Ticket[] }[]) {
        return projects.reduce<Ticket[]>((acc, project) => {
            if (project) {
                acc.push(...project.tickets);
            }

            return acc;
        }, []);
    }
}

<div class="flex justify-between items-end mt-3">
    <div class="grow ml-4 mb-4">
        <div class="font-bold text-stone-800">
            {{ "Partners" | uppercase }}
        </div>
        <div class="text-xs text-stone-400 h-4">
            {{ "Select partner see the partner view." }}
        </div>
    </div>
    <mat-form-field>
        <mat-label>Filter Names</mat-label>
        <input #filter matInput (keyup)="applyFilter(filter.value)" />
    </mat-form-field>
</div>
<portal-partner-list [filter]="filterValue"></portal-partner-list>

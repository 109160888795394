import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "removeEmptyFromArray" })
export class RemoveEmptyFromArrayPipe implements PipeTransform {
    transform(values: string[]) {
        return values.filter(value => {
            if (value) {
                return true;
            } else {
                return false;
            }
        });
    }
}

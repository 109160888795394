import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { DownloadComponent } from "./views/download/download.component";

const downloadRoutes: Routes = [
    {
        path: "download",
        component: DownloadComponent,
        data: {
            showTopPanel: false,
            showLeftPanel: false
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(downloadRoutes)],
    exports: [RouterModule]
})
export class DownloadRoutes {}

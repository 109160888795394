<div class="flex justify-between p-5" mat-dialog-title>
    <div class="flex-grow">
        <span
            *ngIf="update.internal"
            class="bg-sewio text-white rounded-full px-2 mr-2 text-sm"
            >INTERNAL</span
        >{{ update.title }}
    </div>
    <div class="text-stone-500 text-base font-normal">
        {{ update.date }}
    </div>
</div>
<div mat-dialog-content>
    <div
        *ngIf="
            update.opportunityId !== null && update.opportunityId !== undefined
        "
        class="prose prose-sm mb-4"
    >
        <h4>Opportunity</h4>
        <p>
            <a [href]="'opportunities/' + update.opportunityId"
                >[{{ update.opportunityId }}]
                {{ opportunityIdToName(update.opportunityId) }}</a
            >
        </p>
    </div>
    <div
        class="prose prose-sm"
        [innerHTML]="update.content | edjsToHtml | safeHtml"
    ></div>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CLOSE</button>
</mat-dialog-actions>

<div class="flex p-5 justify-between" mat-dialog-title>
    <div class="flex-grow">Create Site Update</div>
    <mat-slide-toggle
        *ngIf="sewio"
        class="example-margin"
        color="primary"
        [formControl]="internalValidator"
    >
        Internal
    </mat-slide-toggle>
</div>
<div mat-dialog-content>
    <div class="pt-2 flex flex-col">
        <mat-form-field>
            <input matInput [formControl]="titleValidator" required />
            <mat-label>Title</mat-label>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Opportunity</mat-label>
            <mat-select [formControl]="opportunityValidator">
                <mat-option [value]="'null'">N/A</mat-option>
                <mat-option
                    *ngFor="let project of site.projects"
                    [value]="project.id"
                >
                    [{{ project.id }}] {{ project.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <input
                matInput
                [matDatepicker]="siteUpdateDate"
                [formControl]="dateValidator"
                readonly
                required
                (click)="siteUpdateDate.open()"
            />
            <mat-label>Date</mat-label>
            <mat-datepicker #siteUpdateDate disabled="false"></mat-datepicker>
        </mat-form-field>
        <div class="text-base text-stone-400 pl-4 pb-2">Content</div>
    </div>
    <div
        id="create-update-dialog-content"
        class="rounded-l-lg rounde border border-stone-400 p-4 overflow-y-scroll max-h-96 prose prose-sm"
    ></div>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CLOSE</button>
    <button mat-button color="primary" cdkFocusInitial (click)="create()">
        CREATE
    </button>
</mat-dialog-actions>
